import React, { FC, useEffect, useMemo, useState } from 'react';
import { LastMileForm } from './styles';
import InputBlock from '../ui/InputBlock';
import RegionDropdown from '../ui/Dropdown/RegionDropdown';
import AutoCompleteInput from '../ui/Input/AutoCompleteInput';
import { searchByCountry } from '../../utils/global';
import InputBlockSecondary from '../ui/InputBlock/secondary';
import CustomDropdown from '../ui/Dropdown/Dropdown';
import { updateLastMile } from '../../utils/axios/post';
import { useDetailsContext } from '../Threads/DetailsContext';
import { phoneNormalize, phoneValidation } from '../../utils/validation';
import { useMobile } from '../../constants/breakpoints';
import dayjs from 'dayjs';
import { InputWarningMessage, InputWrapper, PrimaryLabel, RequiredIndicator } from '../styles';
import { PhoneInput } from 'react-international-phone';

interface ILastMileContentProps {
  isModal?: boolean;
  submitLastMilesModal: boolean;
  setSubmitLastMilesModal: (value: boolean) => void;
}
enum DeliveryMethod {
  'Поштова на відділення' = 'post_office',
  "Адресна кур'єром" = 'address_courier',
  'Догруз по території України' = 'supplemental_load_ukraine',
  'Самовивіз' = 'pickup',
  'Збірне авто по Києву' = 'consolidated_vehicle_kyiv',
  'Окреме авто' = 'dedicated_vehicle',
}
enum DeliveryService {
  'НП' = 'nova_poshta',
  'Міст Експрес' = 'meest',
  'Делівері' = 'delivery',
  'САТ' = 'cat',
}

export interface LastMileConfig {
  delivery_method: string;
  post_service: string;
  recipient_full_name: string;
  recipient_phone: string;
  recipient_city: string;
  recipient_address: {
    location: any;
    address: string;
  } | null;
  post_service_office: string;
  recipient_data_for_delivery: string;
  car_brand: string;
  car_number: string;
  full_name_driver: string;
  car_arrival_at: string;
  price_idea: string;
  estimated_cost: string;
  comment: string;
  delivery_comment: string;
  payer: string;
  tax_number: string;
}

const deliveryLastMileMock = [
  'Поштова на відділення',
  "Адресна кур'єром",
  'Догруз по території України',
  'Самовивіз',
  'Збірне авто по Києву',
  'Окреме авто',
];

const postServiceMock = ['НП', 'Делівері', 'САТ', 'Міст Експрес'];

const LastMileContent: FC<ILastMileContentProps> = ({
  isModal,
  submitLastMilesModal,
  setSubmitLastMilesModal,
}) => {
  const {
    config,
    setIsLastMilesModal,
    setIsLoading,
    setIsLastStepSuccessModal,
    updateConfig,
  } = useDetailsContext();
  const isMobile = useMobile();

  const [lastMileConfig, setLastMileConfig] = useState<LastMileConfig>({
    delivery_method: '',

    post_service: '',
    recipient_full_name: '',
    recipient_phone: '',
    recipient_city: '',
    recipient_address: null,
    post_service_office: '',
    // Якщо отримувач ФОП або ТОВ код ІПН
    tax_number: '',
    // Дані отримувача ТТН
    recipient_data_for_delivery: '',
    car_brand: '',
    car_number: '',
    full_name_driver: '',
    car_arrival_at: '',
    price_idea: '',
    delivery_comment: '',
    estimated_cost: '',
    comment: '',
    payer: 'client',
  });

  const [validation, setValidation] = useState({
    delivery_method: '',
    post_service: '',
    recipient_full_name: '',
    recipient_phone: '',
    recipient_city: '',
    recipient_address: '',
    estimated_cost: '',
    car_brand: '',
    car_number: '',
    full_name_driver: '',
    price_idea: '',
    car_arrival_at: '',
    post_service_office: '',
    recipient_data_for_delivery: '',
  });

  const validateFields = (clear: boolean = false) => {
    const newValidation: Record<string, string> = {};

    if (clear) {
      Object.entries(lastMileConfig).forEach(([key, value]) => {
        newValidation[key] = '';
      });
    } else {
      Object.entries(lastMileConfig).forEach(([key, value]) => {
        if (!value && key !== 'price_idea') {
          newValidation[key] = 'Обов`язкове поле';
        }
        if (key === 'recipient_phone' && value && value?.length !== 19) {
          newValidation[key] = 'Поле має бути повністю заповнено';
        }
        if (key === 'car_arrival_at' && value && value === 'Invalid Date') {
          newValidation[key] = 'Введіть повністю дату та час';
        }
      });
    }

    setValidation(prevData => ({
      ...prevData,
      ...newValidation,
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value, name } = e.target;

    setLastMileConfig((prev: LastMileConfig) => ({
      ...prev,
      [id || name]: value,
    }));

    validateFields(true);
  };

  const handleChangeDate = (value: any) => {
    setLastMileConfig((prev: LastMileConfig) => ({
      ...prev,
      car_arrival_at: value,
    }));
  };

  const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;

    setLastMileConfig((prev: LastMileConfig) => ({
      ...prev,
      recipient_phone:
        prev?.recipient_phone?.length > value?.length
          ? value
          : phoneValidation(value),
    }));
    if (phoneNormalize(value).length === 13) {
      setLastMileConfig(prevInfo => ({
        ...prevInfo,
        recipient_phone: prevInfo?.recipient_phone,
      }));
    }
    validateFields(true);
  };

  const handleChangePhoneNew = (value: any) => {
    // let { value } = e.target;

    setLastMileConfig((prev: LastMileConfig) => ({
      ...prev,
      recipient_phone:
        prev?.recipient_phone?.length > value?.length
          ? value
          : value,
    }));
    // if (phoneNormalize(value).length === 13) {
    //   setLastMileConfig(prevInfo => ({
    //     ...prevInfo,
    //     recipient_phone: prevInfo?.recipient_phone,
    //   }));
    // }
    validateFields(true);
  };

  const handleChangeAddress = (
    address: string,
    name: string,
    latLng: {
      lat: number;
      lng: number;
    } | null,
  ) => {
    const location = latLng
      ? { lat: latLng.lat.toString(), lng: latLng.lng.toString() }
      : { lat: '', lng: '' };

    setLastMileConfig((prev: LastMileConfig) => ({
      ...prev,
      [name]: {
        location: location,
        address: address,
      },
    }));
    validateFields(true);
  };

  const handleChangeInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    maxLength: number,
  ) => {
    const { id, value, name } = e.target;
    if (e.target.value.length <= maxLength) {
      setLastMileConfig((prev: LastMileConfig) => ({
        ...prev,
        [name]: value,
      }));
    }
    setValidation(prev => ({
      ...prev,
      [name]: '',
    }));
  };

  const formatDate = (dateString: string) => {
    const date = dayjs(dateString, 'DD.MM.YY HH:mm');
    return date.format('YYYY-MM-DDThh:mm:00.000[Z]');
  };

  const confirmLastMilesModal = async () => {
    setIsLoading(true);
    const universalModal = document.getElementById('universal-modal');
    try {
      const updatedLastMileConfig = {
        ...lastMileConfig,
        delivery_method:
          DeliveryMethod[
            lastMileConfig.delivery_method as keyof typeof DeliveryMethod
          ],
        post_service:
          DeliveryService[
            lastMileConfig.post_service as keyof typeof DeliveryService
          ],
        recipient_phone: phoneNormalize(lastMileConfig?.recipient_phone),
        // car_arrival_at: formatDate(lastMileConfig?.car_arrival_at),
        payer: 'client',
      };
      if (
        phoneNormalize(lastMileConfig?.recipient_phone).length !== 13 &&
        (updatedLastMileConfig?.delivery_method === 'post_office' ||
          updatedLastMileConfig?.delivery_method === 'dedicated_vehicle')
      ) {
        universalModal?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        return;
      }
      if (updatedLastMileConfig.car_arrival_at === 'Invalid Date') {
        updatedLastMileConfig.car_arrival_at = '';
      }
      if (updatedLastMileConfig?.delivery_method === 'pickup') {
        updatedLastMileConfig.car_arrival_at = formatDate(
          lastMileConfig?.car_arrival_at,
        );
      }

      const result = await updateLastMile({
        id: config?.id,
        body: updatedLastMileConfig,
      });

      if (result?.success) {
        setIsLastMilesModal(false);
        setIsLastStepSuccessModal(true);
      } else {
        universalModal?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }
    } catch (e) {
      universalModal?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setLastMileConfig(prev => ({
      ...prev,
      post_service: '',
      recipient_full_name: '',
      recipient_phone: '',
      recipient_city: '',
      recipient_address: null,
      post_service_office: '',
      tax_number: '',
      recipient_data_for_delivery: '',
      car_brand: '',
      car_number: '',
      full_name_driver: '',
      car_arrival_at: '',
      price_idea: '',
      delivery_comment: '',
      estimated_cost: '',
      comment: '',
      payer: '',
    }));
  }, [lastMileConfig.delivery_method]);

  useEffect(() => {
    if (submitLastMilesModal) {
      validateFields();
      if (!validation?.recipient_phone) {
        setSubmitLastMilesModal(false);
        confirmLastMilesModal();
      }
    }
  }, [submitLastMilesModal]);

  const content = useMemo(() => {
    switch (lastMileConfig.delivery_method) {
      case 'Поштова на відділення':
        return (
          <>
            <RegionDropdown
              label={'Поштова служба'}
              placeholder={'Оберіть зі списку'}
              options={postServiceMock}
              name={'post_service'}
              value={lastMileConfig.post_service}
              onSelect={handleChange}
              warningMessage={validation?.post_service}
              required
            />
            <InputBlock
              id={'recipient_full_name'}
              type={'text'}
              text={'ПІБ отримувача (повністю)'}
              placeholder={'Введіть ПІБ отримувача'}
              value={lastMileConfig.recipient_full_name}
              onChange={handleChange}
              required
              warningMessage={validation?.recipient_full_name}
            />
            <InputBlock
              text={'Телефон отримувача'}
              id={'recipient_phone'}
              value={lastMileConfig.recipient_phone}
              warningMessage={validation.recipient_phone}
              onChange={handleChangePhone}
              type={'text'}
              placeholder={'Введіть телефон отримувача'}
              required
            />
            {/*<>*/}
            {/*  <InputWrapper>*/}
            {/*    <PrimaryLabel htmlFor={'phone'}>*/}
            {/*      Телефон отримувача <RequiredIndicator>*</RequiredIndicator>*/}
            {/*    </PrimaryLabel>*/}
            {/*    <PhoneInput*/}
            {/*      defaultCountry="ua"*/}
            {/*      value={lastMileConfig.recipient_phone}*/}
            {/*      onChange={(phone) => handleChangePhoneNew(phone)}*/}
            {/*    />*/}
            {/*  </InputWrapper>*/}
            {/*  {validation.recipient_phone && (*/}
            {/*    <InputWarningMessage>{validation.recipient_phone}</InputWarningMessage>*/}
            {/*  )}*/}
            {/*</>*/}

            <InputBlock
              text={'Місто'}
              id={'recipient_city'}
              value={lastMileConfig.recipient_city}
              warningMessage={validation.recipient_city}
              onChange={handleChange}
              type={'text'}
              placeholder={'Введіть місто'}
              required
            />
            <InputBlock
              text={'Номер вантажного відділення'}
              id={'post_service_office'}
              value={lastMileConfig.post_service_office}
              warningMessage={validation.post_service_office}
              onChange={handleChange}
              type={'text'}
              placeholder={'Введіть номер вантажного відділення'}
              required
            />
            <InputBlock
              text={'Код ІПН або ЄДРПОУ'}
              id={'tax_number'}
              value={lastMileConfig.tax_number}
              onChange={handleChange}
              type={'text'}
              placeholder={'Введіть код ІПН або ЄДРПОУ'}
            />
            <InputBlock
              text={'Страховка або оціночна вартість'}
              id={'estimated_cost'}
              value={lastMileConfig.estimated_cost}
              warningMessage={validation.estimated_cost}
              onChange={handleChange}
              type={'text'}
              placeholder={'Введіть Страховку або оціночну вартість'}
              required
            />
          </>
        );
      case "Адресна кур'єром":
        return (
          <>
            <RegionDropdown
              label={'Поштова служба'}
              placeholder={'Оберіть зі списку'}
              options={postServiceMock}
              name={'post_service'}
              value={lastMileConfig.post_service}
              onSelect={handleChange}
              warningMessage={validation?.post_service}
              required
            />
            <InputBlock
              text={'ПІБ отримувача (повністю)'}
              id={'recipient_full_name'}
              value={lastMileConfig.recipient_full_name}
              warningMessage={validation.recipient_full_name}
              onChange={handleChange}
              type={'text'}
              placeholder={'Введіть ПІБ отримувача'}
              required
            />

            <InputBlock
              text={'Страховка або оціночна вартість'}
              value={lastMileConfig.estimated_cost}
              warningMessage={validation.estimated_cost}
              id={'estimated_cost'}
              onChange={handleChange}
              type={'text'}
              placeholder={'Введіть страховку або оціночну вартість'}
              required
            />
          </>
        );
      case 'Догруз по території України':
        return (
          <>
            <InputBlock
              text={'Дані отримувача для ТТН (ФОП/ТОВ/Фізособа)'}
              value={lastMileConfig.recipient_data_for_delivery}
              warningMessage={validation.recipient_data_for_delivery}
              id={'recipient_data_for_delivery'}
              onChange={handleChange}
              type={'text'}
              placeholder={'Введіть дані отримувача'}
              required
            />
            <InputBlock
              text={'Ідея ціни'}
              id={'price_idea'}
              value={lastMileConfig.price_idea}
              warningMessage={validation.price_idea}
              onChange={handleChange}
              type={'text'}
              placeholder={'Введіть ідею ціни'}
            />
          </>
        );
      case 'Самовивіз':
        return (
          <>
            <InputBlock
              text={'ПІБ отримувача (повністю)'}
              id={'recipient_full_name'}
              value={lastMileConfig.recipient_full_name}
              warningMessage={validation.recipient_full_name}
              onChange={handleChange}
              type={'text'}
              placeholder={'Введіть ПІБ отримувача'}
              required
            />

            <InputBlock
              text={'Дані отримувача для ТТН (ФОП/ТОВ/Фізособа)'}
              id={'recipient_data_for_delivery'}
              value={lastMileConfig.recipient_data_for_delivery}
              warningMessage={validation.recipient_data_for_delivery}
              onChange={handleChange}
              type={'text'}
              placeholder={'Введіть дані отримувача'}
              required
            />
            <InputBlock
              text={'Марка авто'}
              id={'car_brand'}
              value={lastMileConfig.car_brand}
              warningMessage={validation.car_brand}
              onChange={handleChange}
              type={'text'}
              placeholder={'Вкажіть марку авто'}
              required
            />
            <InputBlock
              text={'Номерні знаки'}
              id={'car_number'}
              value={lastMileConfig.car_number}
              warningMessage={validation.car_number}
              onChange={handleChange}
              type={'text'}
              placeholder={'Введіть номерні знаки'}
              required
            />
            <InputBlock
              text={'ПІБ водія'}
              id={'full_name_driver'}
              value={lastMileConfig.full_name_driver}
              warningMessage={validation.full_name_driver}
              onChange={handleChange}
              type={'text'}
              placeholder={'Вкажіть ПІБ водія'}
              required
            />
            <InputBlock
              text={'Час приїзду авто'}
              id={'car_arrival_at'}
              name={'car_arrival_at'}
              value={lastMileConfig.car_arrival_at}
              warningMessage={validation.car_arrival_at}
              onChange={handleChangeDate}
              type={'text'}
              placeholder={'Вкажіть час приїзду авто'}
              required
            />
            <InputBlockSecondary
              inputStyle
              id={'delivery_comment'}
              value={lastMileConfig.delivery_comment}
              text={'Коментар (якщо є)'}
              placeholder={
                'З приводу проміжку часу, в який клієнт може отримати вантаж; розміру авто, яке може заїхати на територію чи інші обмеження.'
              }
              type={'text'}
              onChange={e => handleChangeInput(e, 128)}
              name={'delivery_comment'}
              isTextarea
            />
          </>
        );
      case 'Збірне авто по Києву':
        return (
          <>
            <InputBlock
              text={'Дані отримувача для ТТН (ФОП/ТОВ/Фізособа)'}
              id={'recipient_data_for_delivery'}
              value={lastMileConfig.recipient_data_for_delivery}
              warningMessage={validation.recipient_data_for_delivery}
              onChange={handleChange}
              type={'text'}
              placeholder={'Введіть дані отримувача'}
              required
            />
            <InputBlock
              text={'Ідея ціни'}
              id={'price_idea'}
              value={lastMileConfig.price_idea}
              warningMessage={validation.price_idea}
              onChange={handleChange}
              type={'text'}
              placeholder={'Введіть ідею ціни'}
            />
          </>
        );
      case 'Окреме авто':
        return (
          <>
            <InputBlock
              text={'ПІБ отримувача (повністю)'}
              id={'recipient_full_name'}
              value={lastMileConfig.recipient_full_name}
              warningMessage={validation.recipient_full_name}
              onChange={handleChange}
              type={'text'}
              placeholder={'Введіть ПІБ отримувача'}
              required
            />
            {/*<>*/}
            {/*  <InputWrapper>*/}
            {/*    <PrimaryLabel htmlFor={'phone'}>*/}
            {/*      Телефон отримувача <RequiredIndicator>*</RequiredIndicator>*/}
            {/*    </PrimaryLabel>*/}
            {/*    <PhoneInput*/}
            {/*      defaultCountry="ua"*/}
            {/*      value={lastMileConfig.recipient_phone}*/}
            {/*      onChange={(phone) => handleChangePhoneNew(phone)}*/}
            {/*    />*/}
            {/*  </InputWrapper>*/}
            {/*  {validation.recipient_phone && (*/}
            {/*    <InputWarningMessage>{validation.recipient_phone}</InputWarningMessage>*/}
            {/*  )}*/}
            {/*</>*/}
            <InputBlock
              text={'Телефон отримувача'}
              id={'recipient_phone'}
              value={lastMileConfig.recipient_phone}
              warningMessage={validation.recipient_phone}
              onChange={handleChangePhone}
              type={'text'}
              placeholder={'Вкажіть телефон отримувача'}
              required
            />
            <InputBlockSecondary
              inputStyle
              id={'delivery_comment'}
              text={'Коментар (якщо є)'}
              placeholder={
                'З приводу проміжку часу, в який клієнт може отримати вантаж; розміру авто, яке може заїхати на територію чи інші обмеження.'
              }
              type={'text'}
              onChange={e => handleChangeInput(e, 128)}
              name={'delivery_comment'}
              value={lastMileConfig.delivery_comment}
              isTextarea
            />
          </>
        );
    }
  }, [lastMileConfig, validation]);
  return (
    <LastMileForm
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <RegionDropdown
        label={'Спосіб доставки Остання Миля'}
        placeholder={'Оберіть зі списку'}
        options={deliveryLastMileMock}
        name={'delivery_method'}
        value={lastMileConfig.delivery_method}
        required
        warningMessage={validation.delivery_method}
        onSelect={handleChange}
      />
      {content}
      {lastMileConfig?.delivery_method && lastMileConfig?.delivery_method !== 'Поштова на відділення' && (
        <AutoCompleteInput
          value={lastMileConfig?.recipient_address?.address}
          name={'recipient_address'}
          searchOptions={searchByCountry('UA')}
          placeholder={
            isMobile
              ? 'Місто, район, вулиця, № будинку'
              : 'Адреса: Місто, район, вулиця, номер будинку'
          }
          handleChangeAddress={handleChangeAddress}
          text={'Адреса'}
          warningMessage={validation?.recipient_address}
          required
        />
      )}
      {
        lastMileConfig?.delivery_method && (
          <InputBlockSecondary
            inputStyle
            id={'comment'}
            name={'comment'}
            text={'Коментар'}
            placeholder={'Введіть коментар'}
            type={'text'}
            onChange={e => handleChangeInput(e, 128)}
            value={lastMileConfig?.comment}
            isTextarea
          />
        )
      }
    </LastMileForm>
  );
};

export default LastMileContent;

import React, { useRef, useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
// @ts-ignore
import SignatureCanvas from 'react-signature-canvas';
import {
  SignCanvasWrapper,
  SignCanvasLabel,
  SignAgreementContentWrapper,
  ButtonGroupSignAgreement,
  LoadingContract,
} from './style';
import Button from '../ui/Button';
import { useNavigate } from 'react-router-dom';
import { saveSignSignature } from '../../utils/axios/post';
import { getContractStatus, getSignContract, profileInfo } from '../../utils/axios/get';
import CircularProgress from '@mui/material/CircularProgress';
import { Colors } from '../colors';
import { useDesktop, useMobile, useTablet } from '../../constants/breakpoints';
import { debounce } from 'lodash';
import { AuthContext } from '../../index';

const replacePlaceholders = (template: string) => {
  return template.replace('{{client_signature}}', '<div id="react-signature"></div>');
};

const SignAgreementContent = () => {
  const [htmlContent, setHtmlContent] = useState<string>('');
  const [navigateLink, setNavigateLink] = useState<any>('/');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const sigCanvas = useRef<SignatureCanvas | null>(null);
  const isTablet = useTablet();
  const isMobile = useMobile();
  const isDesktop = useDesktop();
  const { updateIsContractSigned } = useContext(AuthContext);
  const [error, setError] = useState(false);

  useEffect(() => {
    const link = sessionStorage.getItem('signedFrom');
    let lastSegment = link?.split('/').filter(Boolean).pop();
    if (lastSegment !== 'dashboard' && lastSegment !== 'new-request') {
      lastSegment = '';
    }
    setNavigateLink(lastSegment);
  }, []);


  const ClientSignature = () => {
    return (
      <SignCanvasWrapper>
        <SignCanvasLabel>Місце для підпису</SignCanvasLabel>
        <SignatureCanvas
          ref={sigCanvas}
          canvasProps={{
            width: isDesktop ? 650 : isTablet ? 500 : isMobile ? 300 : 750,
            height: 336,
            className: `${error} ? 'error-field sigCanvas' : 'sigCanvas'`,
            border: '1px solid black',
          }}
        />
      </SignCanvasWrapper>
    );
  };

  const dataURLToBlob = (dataURL: string) => {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const buffer = new ArrayBuffer(byteString.length);
    const dataArray = new Uint8Array(buffer);
    for (let i = 0; i < byteString.length; i++) {
      dataArray[i] = byteString.charCodeAt(i);
    }
    return new Blob([buffer], { type: mimeString });
  };

  const saveSignature = async () => {
    if (sigCanvas.current) {
      if (sigCanvas.current.getTrimmedCanvas().width === 1 && sigCanvas.current.getTrimmedCanvas().height === 1) {
        setError(true);
        return;
      }
      setIsLoading(true);
      const dataURL = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
      const blob = dataURLToBlob(dataURL);

      const formData = new FormData();
      formData.append('signature', blob, 'signature.png');

      const result = await saveSignSignature(formData);
      if (result?.success) {
        localStorage.setItem('signedContract', JSON.stringify(true));
        const profile = await profileInfo();
        if (profile?.success) {
          // @ts-ignore
          updateIsContractSigned(profile?.data?.is_contract_signed);
          navigate(`/${navigateLink}`);
        }
      }
      setIsLoading(false);
    }
  };

  const loadTemplate = async () => {
    const template = await getSignContract();
    const modifiedHtml = replacePlaceholders(template);
    setHtmlContent(modifiedHtml);
  };

  const debouncedFetch = debounce(loadTemplate, 300);

  useEffect(() => {
    debouncedFetch();
    return () => {
      debouncedFetch.cancel();
    };
  }, []);

  useEffect(() => {
    const handleGetContractStatus = async () => {
      const contractData = await getContractStatus();
      if (contractData?.data?.isSigned) {
        navigate('/profile');
      }
    };

    handleGetContractStatus();
  }, []);

  useEffect(() => {
    if (htmlContent) {
      const signatureImage = document.querySelector('img[src="signature"]');
      if (signatureImage && signatureImage.parentNode) {
        const signatureContainer = document.createElement('div');
        signatureImage.parentNode.replaceChild(signatureContainer, signatureImage);
        ReactDOM.render(<ClientSignature />, signatureContainer);
      }
    }
  }, [htmlContent]);

  return (
    <SignAgreementContentWrapper>
      {
        htmlContent ? (
          <>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
            {
              error && (
                <p className={'error-label'}>Вам необхідно поставити підпис.</p>
              )
            }
            <ButtonGroupSignAgreement>
              <Button text={'Скинути'} type={'secondary'} onClick={() => {
                navigate(`/${navigateLink}`);
              }} />
              <Button text={
                isLoading ? (
                  <CircularProgress color='inherit' size={'1.5rem'} />
                ) : (
                  'Підписати договір'
                )
              } type={'primary'} onClick={saveSignature} />
            </ButtonGroupSignAgreement>
          </>
        ) : (
          <LoadingContract>
            <CircularProgress style={{ color: `${Colors.white}` }} size={72} />
          </LoadingContract>
        )
      }
    </SignAgreementContentWrapper>
  );
};

export default SignAgreementContent;

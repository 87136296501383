import { useMobile, useTablet } from '../../constants/breakpoints';
import { formatDate } from '../../utils/global';
import { Colors } from '../colors';
import { EmptyGoods } from '../Details/styles';
import { useDetailsContext } from '../Threads/DetailsContext';
import Button from '../ui/Button';
import {
  TitleButtons,
  TitleContainer,
  TableContainer,
  TableReceivedGoodsBody,
  TableReceivedGoodsHeader,
  TableReceivedGoodsHeaderItem,
  TableReceivedGoodsRow,
  TableReceivedGoodsRowItem,
  TableReceivedGoodsWrapper,
} from './styles';

interface ITableReceivedGoods {
  isModal?: boolean;
  approveGoods: any;
  rejectGoods: any;
}

const TableReceivedGoods = ({
  isModal,
  approveGoods,
  rejectGoods,
}: ITableReceivedGoods) => {
  const { config, isShowWarehouseHotButtonsOnDetails } = useDetailsContext();
  const isMobile = useMobile();
  const isTablet = useTablet();

  return (
    <TableReceivedGoodsWrapper>
      <TitleContainer>
        <h5>Отримані на складі вантажі:</h5>
        {isShowWarehouseHotButtonsOnDetails &&
          !isModal &&
          config?.direction?.name === 'CN-UA' && (
            <TitleButtons>
              <Button
                text={'Всі вантажі отримано, вантажимо'}
                type={'color'}
                onClick={() => approveGoods()}
              />
              <Button
                text={isTablet || isMobile ? 'Ні' : 'Ні, чекаємо ще'}
                type={'color'}
                style={{ backgroundColor: Colors.deepRed }}
                onClick={() => rejectGoods()}
              />
            </TitleButtons>
          )}
      </TitleContainer>
      <TableContainer isModal={isModal}>
        {config?.warehouse_goods?.length > 0 ? (
          <>
            <TableReceivedGoodsHeader isModal={isModal}>
              <TableReceivedGoodsHeaderItem>Склад</TableReceivedGoodsHeaderItem>
              <TableReceivedGoodsHeaderItem className="right">
                Отримано
              </TableReceivedGoodsHeaderItem>
              <TableReceivedGoodsHeaderItem className={'right'}>
                К-ть місць
              </TableReceivedGoodsHeaderItem>
              <TableReceivedGoodsHeaderItem className={'right'}>
                Обʼєм партії
              </TableReceivedGoodsHeaderItem>
              <TableReceivedGoodsHeaderItem className={'right'}>
                Вага партії
              </TableReceivedGoodsHeaderItem>
              <TableReceivedGoodsHeaderItem className={'right'}>
                К-ть шт. товару
              </TableReceivedGoodsHeaderItem>
            </TableReceivedGoodsHeader>

            <TableReceivedGoodsBody>
              {config?.warehouse_goods?.map((item: any) => {
                return (
                  <TableReceivedGoodsRow isModal={isModal}>
                    <TableReceivedGoodsRowItem>
                      {item.warehouse?.name}
                    </TableReceivedGoodsRowItem>
                    <TableReceivedGoodsRowItem className={'right'}>
                      {formatDate(item.arrival_at)}
                    </TableReceivedGoodsRowItem>
                    <TableReceivedGoodsRowItem className={'right'}>
                      <p>{item.number_of_places}</p>
                    </TableReceivedGoodsRowItem>
                    <TableReceivedGoodsRowItem className={'right'}>
                      {item.volume}
                      <div className={'units-value'}>m3</div>
                    </TableReceivedGoodsRowItem>
                    <TableReceivedGoodsRowItem className={'right'}>
                      {item.gross_weight}
                      <div className={'units-value'}>kg</div>
                    </TableReceivedGoodsRowItem>
                    <TableReceivedGoodsRowItem className={'right'}>
                      <p>{item.quantity}</p>
                    </TableReceivedGoodsRowItem>
                  </TableReceivedGoodsRow>
                );
              })}

              <TableReceivedGoodsRow isModal={isModal}>
                <TableReceivedGoodsRowItem></TableReceivedGoodsRowItem>
                <TableReceivedGoodsRowItem></TableReceivedGoodsRowItem>
                <TableReceivedGoodsRowItem className={'right calculated_row'}>
                  <div className="units-value total">
                    <span>{config?.warehouse_total?.total_places}</span>
                  </div>
                </TableReceivedGoodsRowItem>
                <TableReceivedGoodsRowItem className={'right calculated_row'}>
                  <div className="units-value total">
                    <span>{config?.warehouse_total?.total_volume}</span>
                    m3
                  </div>
                </TableReceivedGoodsRowItem>
                <TableReceivedGoodsRowItem className={'right calculated_row'}>
                  <div className="units-value total">
                    <span>{config?.warehouse_total?.total_gross_weight}</span>
                    kg
                  </div>
                </TableReceivedGoodsRowItem>
                <TableReceivedGoodsRowItem className={'right calculated_row'}>
                  <div className="units-value total">
                    <span>{config?.warehouse_total?.total_quantity}</span>
                  </div>
                </TableReceivedGoodsRowItem>
              </TableReceivedGoodsRow>
            </TableReceivedGoodsBody>
          </>
        ) : (
          <TableReceivedGoodsBody
            style={{
              borderRadius: '4px',
              border: `1px solid ${Colors.periwinkle}`,
            }}
          >
            <EmptyGoods>
              <p>
                {isMobile
                  ? 'Поки що тут порожньо…'
                  : 'Поки що тут порожньо'}
              </p>
            </EmptyGoods>
          </TableReceivedGoodsBody>
        )}
      </TableContainer>
    </TableReceivedGoodsWrapper>
  );
};

export default TableReceivedGoods;

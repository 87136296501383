import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import RegistrationPage from '../pages/auth/Registration';
import PrivateRoute from './PrivateRoute';
import LoginPage from '../pages/auth/Login';
import RegistrationSuccessPage from '../pages/auth/Registration/success';
import ChangePasswordPage from '../pages/auth/ChangePassword';
import PasswordSetPage from '../pages/auth/ChangePassword/PasswordSet';
import PasswordSetSuccessPage from '../pages/auth/ChangePassword/PasswordSetSuccess';
import OrderForm from '../components/OrderForm';
import Profile from '../pages/auth/Profile';
import ConfirmRegistrationPage from '../pages/auth/Registration/confirm';
import Home from '../pages/auth/Home';
import GoogleRedirectPage from '../pages/auth/GoogleRedirectPage';
import Dashboard from '../pages/auth/Dashboard';
import ErrorPage from '../components/ErrorPage';
import ExistingClient from '../pages/ExistingClient';
import Details from '../pages/auth/Details';
import SuccessNewOrderPage from '../components/OrderForm/success';
import ThreadsPage from '../pages/auth/Threads';
import SignAgreementPage from '../pages/auth/SignAgreement';
import { DetailsProvider } from '../components/Threads/DetailsContext';
import { ChatProvider } from '../components/Threads/ChatContext';
import SetPasswordSuccessPage from '../pages/ExistingClient/success';

const router = createBrowserRouter([
  {
    path: '/register',
    element: <RegistrationPage />,
  },
  {
    path: '/register/success',
    element: <RegistrationSuccessPage />,
  },
  {
    path: '/register/confirm/:code',
    element: <ConfirmRegistrationPage />,
  },
  {
    path: '/change-password',
    element: <ChangePasswordPage />,
  },
  {
    path: '/change-password/update/:hash',
    element: <PasswordSetPage type={'change'} />,
  },
  {
    path: '/change-password/update-success',
    element: <PasswordSetSuccessPage />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/google-auth',
    element: <GoogleRedirectPage />,
  },
  {
    path: '/existing-client',
    element: <ExistingClient />,
  },
  {
    path: '/existing-client/update/:hash',
    element: <PasswordSetPage type={'set'} />,
  },
  {
    path: '/existing-client/success',
    element: <SetPasswordSuccessPage />,
  },
  {
    path: '/',
    element: <PrivateRoute />,
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: 'new-request',
        element: <OrderForm />,
      },
      {
        path: 'new-request/success',
        element: <SuccessNewOrderPage />,
      },
      {
        path: 'profile',
        element: <Profile />,
      },
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: 'details/:id',
        element: (
          <DetailsProvider>
            <Details />
          </DetailsProvider>
        ),
      },
      {
        path: 'threads',
        element: (
          <ChatProvider>
            <ThreadsPage />
          </ChatProvider>
        ),
      },
      {
        path: 'sign-agreement',
        element: <SignAgreementPage />,
      },
    ],
  },
  {
    path: '*',
    element: <ErrorPage type="page_not_found" />,
  },
]);

export { router };

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={48} height={48} fill="none" {...props}>
    <path
      stroke="#EA445A"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M28 4.54v8.26c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C29.52 16 30.08 16 31.2 16h8.261M28 4H17.6c-3.36 0-5.04 0-6.324.654a6 6 0 0 0-2.622 2.622C8 8.56 8 10.24 8 13.6v20.8c0 3.36 0 5.04.654 6.324a6 6 0 0 0 2.622 2.622C12.56 44 14.24 44 17.6 44h12.8c3.36 0 5.04 0 6.324-.654a6 6 0 0 0 2.622-2.622C40 39.44 40 37.76 40 34.4V16L28 4Z"
    />
    <path
      fill="#EA445A"
      d="M18.829 32.084c-.52 0-.996-.12-1.428-.36-.424-.24-.764-.6-1.02-1.08-.248-.488-.372-1.104-.372-1.848 0-.752.12-1.368.36-1.848.248-.48.584-.836 1.008-1.068.424-.24.908-.36 1.452-.36.632 0 1.188.136 1.668.408.488.272.872.652 1.152 1.14.288.488.432 1.064.432 1.728 0 .664-.144 1.244-.432 1.74-.28.488-.664.868-1.152 1.14-.48.272-1.036.408-1.668.408Zm-3.552 2.244v-8.736h1.428v1.512l-.048 1.704.12 1.704v3.816h-1.5ZM18.66 30.8c.36 0 .68-.08.96-.24.288-.16.516-.392.684-.696.168-.304.252-.66.252-1.068 0-.416-.084-.772-.252-1.068a1.736 1.736 0 0 0-.684-.696 1.9 1.9 0 0 0-.96-.24c-.36 0-.684.08-.972.24-.288.16-.516.392-.684.696-.168.296-.252.652-.252 1.068 0 .408.084.764.252 1.068.168.304.396.536.684.696.288.16.612.24.972.24Zm7.314 1.284a3.411 3.411 0 0 1-1.668-.408 3.063 3.063 0 0 1-1.152-1.152c-.28-.488-.42-1.064-.42-1.728 0-.664.14-1.24.42-1.728.28-.488.664-.868 1.152-1.14a3.411 3.411 0 0 1 1.668-.408c.536 0 1.016.12 1.44.36.424.232.76.588 1.008 1.068.248.48.372 1.096.372 1.848 0 .744-.12 1.36-.36 1.848-.24.48-.572.84-.996 1.08-.424.24-.912.36-1.464.36Zm.18-1.284c.36 0 .68-.08.96-.24.288-.16.516-.392.684-.696.176-.304.264-.66.264-1.068 0-.416-.088-.772-.264-1.068a1.736 1.736 0 0 0-.684-.696 1.9 1.9 0 0 0-.96-.24c-.36 0-.684.08-.972.24-.28.16-.508.392-.684.696-.168.296-.252.652-.252 1.068 0 .408.084.764.252 1.068.176.304.404.536.684.696.288.16.612.24.972.24ZM28.1 32v-1.512l.06-1.704-.12-1.704v-3.984h1.488V32H28.1Zm3.367 0v-6.768c0-.672.196-1.208.588-1.608.4-.408.968-.612 1.704-.612.264 0 .512.028.744.084.24.056.44.144.6.264l-.408 1.128a1.084 1.084 0 0 0-.384-.192 1.395 1.395 0 0 0-.444-.072c-.312 0-.548.088-.708.264-.16.168-.24.424-.24.768v.72l.048.672V32h-1.5Zm-1.056-5.16v-1.2h4.272v1.2H30.41Z"
    />
  </svg>
);
export default SvgComponent;

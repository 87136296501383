import React, { useEffect } from 'react';
import ListOfThreads from '../../../components/Threads/ListOfThreads';
import { LoadingCircularWrapper, MessagesPageWrapper } from '../styles';
import ChatZone from '../../../components/Threads/ChatZone';
import { useChatContext } from '../../../components/Threads/ChatContext';
import { CircularProgress } from '@mui/material';
import { useMobile } from '../../../constants/breakpoints';

const ThreadsPage = () => {
  const { isLoading } = useChatContext();
  const isMobile = useMobile();
  useEffect(() => {
    if (isMobile) {
      document.body.style.overflow = 'hidden';
      document.body.style.maxHeight = '100vh';
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      //@todo temp check
    }
  }, [])

  return (
    <>
      {
        isLoading ? (
          <LoadingCircularWrapper>
            <CircularProgress color='inherit' size={'2.5rem'} />
          </LoadingCircularWrapper>
        ) : (
          <MessagesPageWrapper>
            <ListOfThreads />
            <ChatZone size='big' />
          </MessagesPageWrapper>
        )
      }
    </>

  );
};

export default ThreadsPage;

import React, { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../index';

const GoogleRedirectPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setToken } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const token: any = searchParams.get('token');
    const refreshToken = searchParams.get('refresh_token');
    if (token && refreshToken) {
      setToken(token, refreshToken, 3);
      navigate('/');
    } else {
      navigate('/login');
    }
  }, [])

  return (
    <></>
  )
};

export default GoogleRedirectPage;
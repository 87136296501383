import React, { useRef, useState } from 'react';
import {
  FileDetailsCargoWrapper,
  FileDetailsWrapper,
} from '../OrderForm/styles';
import DocumentSvg from '../../assets/ReactSvg/DocumentSvg';
import { getFileLink } from '../../utils/requestHelpers';
import DownloadIconSvg from '../../assets/ReactSvg/DownloadIcon';
import AddIconSvg from '../../assets/ReactSvg/AddIcon';
import { uploadDocument } from '../../utils/axios/post';
import { CircularProgress, Modal } from '@mui/material';
import { ButtonWrapper, DownloadDocumentsWrapper, ModalTitle } from './styles';
import Button from '../ui/Button';
import { useDetailsContext } from '../Threads/DetailsContext';
import { DetailsConfig } from '../../utils/axios/get';
import { debounce } from 'lodash';
import { Colors } from '../colors';
import ClipIcon from '../../assets/ReactSvg/ClipIcon';

interface IProps {}

const DownloadDocumentsPackModal = ({}: IProps) => {
  const {
    config,
    setIsUploadDocumentsModal,
    isUploadDocumentsModal,
    setConfig,
    handleTogglerTableRecieveGoodsModal,
    setIsDocumentsModal,
    setIsShowWarehouseHotButtonsOnDetails,
    setIsShowDocumentsHotButtonsOnDetails,
  } = useDetailsContext();
  const [isLoading, setIsLoading] = useState(false);
  const [packingList, setPackingList] = useState<any>(null);
  const [invoice, setInvoice] = useState<any>(null);
  const [otherFile, setOtherFile] = useState<any>(null);
  const [onMouseOn, setOnMouseOn] = useState({
    packing_list: false,
    invoices: false,
    other_files: false,
  });

  const inputRef = useRef<HTMLInputElement>(null);

  const addFile = (event: any, type: string) => {
    const file = event.target.files[0];

    if (file) {
      switch (type) {
        case 'packing':
          return setPackingList(file);
        case 'invoice':
          return setInvoice(file);
        case 'other':
          return setOtherFile(file);
        default:
          break;
      }
    }
  };

  const getDetailsData = async () => {
    const { data } = await DetailsConfig(config?.id);
    setConfig(data);
    setIsUploadDocumentsModal(false);
    handleTogglerTableRecieveGoodsModal({ show: false });
    setIsDocumentsModal(true);
    setIsShowWarehouseHotButtonsOnDetails(false);
    setIsShowDocumentsHotButtonsOnDetails(true);
  };
  const debouncedFetch = debounce(getDetailsData, 300);

  const uploadFiles = async () => {
    setIsLoading(true);
    const formData = new FormData();

    if (packingList) {
      formData.append(`files['packing_list'][0]['file']`, packingList);
      formData.append(`files['packing_list'][0]['name']`, packingList?.name);
    }

    if (invoice) {
      formData.append(`files['invoices'][0]['file']`, invoice);
      formData.append(`files['invoices'][0]['name']`, invoice?.name);
    }
    if (otherFile) {
      formData.append(`files['other_files'][0]['file']`, otherFile);
      formData.append(`files['other_files'][0]['name']`, otherFile?.name);
    }
    try {
      const result = await uploadDocument(formData, config?.id);

      if (result?.success) {
        debouncedFetch();
        setPackingList(null);
        setInvoice(null);
        setOtherFile(null);
        return () => {
          debouncedFetch.cancel();
        };
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={isUploadDocumentsModal}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <DownloadDocumentsWrapper>
        <ModalTitle>Завантажити новий пакет документів</ModalTitle>
        <FileDetailsWrapper>
          <FileDetailsCargoWrapper>
            <div>
              <DocumentSvg />
              <a
                href={getFileLink('/static/Packing_template.xlsx')}
                download="PackingList.xlsx"
                target="_blank"
                rel="noopener noreferrer"
                className="text-spray"
              >
                Скачати шаблон Packing List
              </a>
              <DownloadIconSvg />
            </div>
          </FileDetailsCargoWrapper>
          <FileDetailsCargoWrapper>
            <div>
              <DocumentSvg />
              <p>Додати файл Packing List</p>
              <AddIconSvg
                color={
                  onMouseOn.packing_list ? Colors.primary60 : Colors.primary80
                }
              />
            </div>
            <input
              onMouseEnter={() =>
                setOnMouseOn(prev => ({ ...prev, packing_list: true }))
              }
              onMouseLeave={() =>
                setOnMouseOn(prev => ({ ...prev, packing_list: false }))
              }
              type="file"
              ref={inputRef}
              onChange={event => addFile(event, 'packing')}
              accept=".xlsx, .pdf, .doc, .docx, .xls"
            />
            {packingList?.name && (
              <div className="attached_documents">
                <ClipIcon color={Colors.warning} />
                <p>{packingList?.name}</p>
              </div>
            )}
          </FileDetailsCargoWrapper>
          <FileDetailsCargoWrapper>
            <div>
              <DocumentSvg />
              <p>Додати файл Invoice</p>
              <AddIconSvg
                color={onMouseOn.invoices ? Colors.primary60 : Colors.primary80}
              />
            </div>
            <input
              onMouseEnter={() =>
                setOnMouseOn(prev => ({ ...prev, invoices: true }))
              }
              onMouseLeave={() =>
                setOnMouseOn(prev => ({ ...prev, invoices: false }))
              }
              type="file"
              ref={inputRef}
              onChange={event => addFile(event, 'invoice')}
              accept=".xlsx, .pdf, .docx, .doc, .xls, .jpg, .png"
            />
            {invoice?.name && (
              <div className="attached_documents">
                <ClipIcon color={Colors.warning} />
                <p>{invoice?.name}</p>
              </div>
            )}
          </FileDetailsCargoWrapper>
          <FileDetailsCargoWrapper>
            <div>
              <DocumentSvg />
              <p>Додати інші файли</p>
              <AddIconSvg
                color={
                  onMouseOn.other_files ? Colors.primary60 : Colors.primary80
                }
              />
            </div>
            <input
              onMouseEnter={() =>
                setOnMouseOn(prev => ({ ...prev, other_files: true }))
              }
              onMouseLeave={() =>
                setOnMouseOn(prev => ({ ...prev, other_files: false }))
              }
              type="file"
              ref={inputRef}
              onChange={event => addFile(event, 'other')}
              accept=".xlsx, .pdf, .docx, .doc, .xls, .jpg, .png"
            />
            {otherFile?.name && (
              <div className="attached_documents">
                <ClipIcon color={Colors.warning} />
                <p>{otherFile?.name}</p>
              </div>
            )}
          </FileDetailsCargoWrapper>
        </FileDetailsWrapper>
        <ButtonWrapper>
          <Button
            text={
              isLoading ? (
                <CircularProgress color="inherit" size={'1.5rem'} />
              ) : (
                'Продовжити'
              )
            }
            type={'primary'}
            onClick={uploadFiles}
          />
          <Button
            text={'Скасувати'}
            type={'secondary'}
            onClick={() => setIsUploadDocumentsModal(false)}
          />
        </ButtonWrapper>
      </DownloadDocumentsWrapper>
    </Modal>
  );
};

export default DownloadDocumentsPackModal;

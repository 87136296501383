import React from 'react';
import RowIcon from '../../assets/ReactSvg/RowIcon';
import GridIcon from '../../assets/ReactSvg/GridIcon';
import { ViewSwitcherWrapper, ViewSwitcherItem } from './styles';

const DashboardDisplayViewSwitcher = ({ active, setActive }: any) => {
  return (
    <ViewSwitcherWrapper>
      <ViewSwitcherItem onClick={() => setActive(true)} active={active}>
        <RowIcon width={'1.25rem'} height={'1.25rem'} />
      </ViewSwitcherItem>
      <ViewSwitcherItem onClick={() => setActive(false)} active={!active}>
        <GridIcon width={'1.25rem'} height={'1.25rem'} />
      </ViewSwitcherItem>
    </ViewSwitcherWrapper>
  );
};

export default DashboardDisplayViewSwitcher;

import { useState } from 'react';
import ExpandDownSvg from '../../assets/ReactSvg/ShippingIcon/ExpandDownSvg';
import { Colors } from '../colors';
import Button from '../ui/Button';
import {
  DocumentDetails,
  Title,
  Subtitle,
  ExpandedDocument,
  DocumentTitle,
  ServiceList,
  Total,
  TitleText,
  ListInfo,
  Wrapper,
  DocumentsFile,
  DocumentWrapper,
  Label,
  BillContainer,
  RightSideWrapper,
  EmptyGoods,
  SvgWrapper,
  MobileWrapperModal,
} from './styles';
import {
  useDesktopBig,
  useMobile,
  useTablet,
} from '../../constants/breakpoints';
import ExpandSvg from '../../assets/ReactSvg/ShippingIcon/ExpandSvg';
import { Modal } from '@mui/material';
import CrossSvg from '../../assets/ReactSvg/CrossSvg';
import { TableReceivedGoodsBody } from '../DetailModals/styles';
import { getPriceOfferServiceLabel } from './utils';
import { useDetailsContext } from '../Threads/DetailsContext';
const REACT_APP_URL = process.env.REACT_APP_URL;

const Bills = ({ bill }: any) => {
  const isTablet = useTablet();
  const isMobile = useMobile();
  const isBigDesktop = useDesktopBig();
  const { commonConfig } = useDetailsContext();

  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleOpenModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const downloadFile = (url: any) => {
    const link = document.createElement('a');
    link.href = REACT_APP_URL + url;
    link.target = '_blank';
    link.download = 'Invoice_DiFreight.pdf'; // Specify the desired file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (isMobile) {
    return (
      <Wrapper>
        <BillContainer>
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex', gap: '1.25rem' }}>
              <DocumentDetails>
                <Title>Сума</Title>
                <Subtitle>$ {bill?.total_amount}</Subtitle>
              </DocumentDetails>

              <Button text={''} type={'download'} onClick={() => {}} />
            </div>
            <SvgWrapper onClick={handleOpenModal}>
              <ExpandSvg color={Colors.primary80} />
            </SvgWrapper>
          </div>
        </BillContainer>
        {isOpenModal && (
          <Modal open={isOpenModal}>
            <MobileWrapperModal>
              <DocumentsFile onClick={handleOpenModal} open={isOpenModal}>
                <DocumentWrapper>
                  <Label style={{ padding: 0 }}>Рахунки</Label>
                </DocumentWrapper>
                <SvgWrapper>
                  <CrossSvg />
                </SvgWrapper>
              </DocumentsFile>

              <>
                {bill?.services?.length === 0 ? (
                  <TableReceivedGoodsBody
                    style={{
                      borderRadius: '4px',
                      border: `1px solid ${Colors.periwinkle}`,
                    }}
                  >
                    <EmptyGoods>
                      <p>
                        {isMobile
                          ? 'Тут мають бути дані тарифу, але поки що тут порожньо…'
                          : 'Поки що тут порожньо'}
                      </p>
                    </EmptyGoods>
                  </TableReceivedGoodsBody>
                ) : (
                  <>
                    <ExpandedDocument>
                      <DocumentTitle>
                        <TitleText>Назва Послуги</TitleText>
                        <TitleText>Ціна за 1 послугу</TitleText>
                        <TitleText>К-ть послуг</TitleText>
                        <TitleText>Загальна ціна</TitleText>
                      </DocumentTitle>
                      {bill?.services?.map((service: any) => {
                        return (
                          <ServiceList>
                            <ListInfo>
                              {getPriceOfferServiceLabel(
                                commonConfig?.price_offer_services,
                                service?.type,
                              )}
                            </ListInfo>
                            <ListInfo>
                              $ {service?.client_price?.toFixed(2)}
                            </ListInfo>
                            <ListInfo>{service?.amount}</ListInfo>
                            <ListInfo>$ {service?.client_price_total?.toFixed(2)}</ListInfo>
                          </ServiceList>
                        );
                      })}
                      <Total document>$ {bill?.total_amount}</Total>
                    </ExpandedDocument>
                  </>
                )}
              </>
            </MobileWrapperModal>
          </Modal>
        )}
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <BillContainer>
        {!isTablet && (
          <DocumentDetails>
            <Title>Номер документа</Title>
            <Subtitle>{bill?.id}</Subtitle>
          </DocumentDetails>
        )}
        <DocumentDetails>
          <Title>Сума</Title>
          <Subtitle>$ {bill?.total_amount}</Subtitle>
        </DocumentDetails>

        <RightSideWrapper>
          <Button
            text={!isTablet ? 'Скачати' : ''}
            type={'download'}
            onClick={() => {
              downloadFile(bill?.file);
            }}
          />
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
            }}
          ></a>
          <SvgWrapper onClick={handleOpenModal}>
            <ExpandDownSvg
              color={Colors.primary}
              style={{
                cursor: 'pointer',
                transform: isOpenModal ? 'rotate(3.142rad)' : 'rotate(0)',
                transition: '0.5s',
              }}
            />
          </SvgWrapper>
        </RightSideWrapper>
      </BillContainer>
      {isOpenModal && (
        <>
          {bill?.services?.length === 0 ? (
            <TableReceivedGoodsBody
              style={{
                borderRadius: '4px',
                border: `1px solid ${Colors.periwinkle}`,
              }}
            >
              <EmptyGoods>
                <p>
                  {isMobile
                    ? 'Тут мають бути дані тарифу, але поки що тут порожньо…'
                    : 'Поки що тут порожньо'}
                </p>
              </EmptyGoods>
            </TableReceivedGoodsBody>
          ) : (
            <>
              <ExpandedDocument>
                <DocumentTitle>
                  <TitleText>Назва Послуги</TitleText>
                  <TitleText>Ціна за 1 послугу</TitleText>
                  <TitleText>К-ть послуг</TitleText>
                  <TitleText>Загальна ціна</TitleText>
                </DocumentTitle>
                {bill?.services?.map((service: any) => {
                  return (
                    <ServiceList>
                      <ListInfo>
                        {getPriceOfferServiceLabel(
                          commonConfig?.price_offer_services,
                          service?.type,
                        )}
                      </ListInfo>
                      <ListInfo>
                        $ {service?.client_price?.toFixed(2)}
                      </ListInfo>
                      <ListInfo>{service?.amount}</ListInfo>
                      <ListInfo>$ {service?.client_price_total?.toFixed(2)}</ListInfo>
                    </ServiceList>
                  );
                })}
                <Total document>$ {bill?.total_amount}</Total>
              </ExpandedDocument>
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default Bills;

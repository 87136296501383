import { TransportationModes } from './types';

export const OrderFormStepsDesktop = ['Перевезення', 'Пункт відправлення', 'Пункт доставки', 'Дані по вантажу'];
export const OrderFormStepsTablet = ['Перевезення', 'відправлення', 'доставка', 'вантаж'];
export const OrderFormStepDetails = [
  'Оберіть спосіб доставки',
  'Вкажіть дані пункту відправлення',
  'Вкажіть дані пункту доставки',
  'Розкажіть нам про ваші вантажі',
];

export const TransportationStepLabels: { type: Record<TransportationModes, string> } = {
  type: {
    land: 'Авто',
    air: 'Авіа',
    sea: 'Море',
  },
};
import React, { useState } from 'react';
import {
  ButtonWrapper,
  TitleH2,
  AuthWrapper,
} from '../../../components/styles';
import { LayoutSmall } from '../../../components/styles';
import { RegistrationForm } from '../styles';
import InputBlock from '../../../components/ui/InputBlock';
import Button from '../../../components/ui/Button';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  ChangePasswordError,
  ChangePasswordWarning,
} from '../../../constants/errors';
import { resetPasswordClient } from '../../../utils/axios/post';
import FullLogoPng from '../../../assets/png/FullLogoPng.png';

const PasswordSetPage = ({ type }: any) => {
  const navigate = useNavigate();
  const { hash } = useParams();
  const [newPassword, setNewPassword] = useState({
    password: '',
    passwordDuplicate: '',
  });

  const [validationInfo, setValidationInfo] = useState({
    password: '',
    passwordDuplicate: '',
  });

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    setNewPassword(prevData => ({
      ...prevData,
      [id]: value,
    }));
    setValidationInfo({
      password: '',
      passwordDuplicate: '',
    });
  };

  const handleValidate = async (e: any) => {
    e.preventDefault();
    if (newPassword.password.length < 6) {
      setValidationInfo(prevInfo => ({
        ...prevInfo,
        password: ChangePasswordWarning,
      }));
      return;
    } else if (newPassword.password !== newPassword.passwordDuplicate) {
      setValidationInfo(prevInfo => ({
        ...prevInfo,
        passwordDuplicate: ChangePasswordError,
      }));
      return;
    } else {
      // API call to change password;
      await handleResetPassword();
    }
  };

  const handleResetPassword = async () => {
    const response = await resetPasswordClient({
      password: newPassword.password,
      hash,
    });
    if (response?.success) {
      if (type === 'set') {
        navigate('/existing-client/success')
      } else {
        navigate('/login');
      }
    }
  };

  return (
    <AuthWrapper>
      <LayoutSmall>
        <img
          src={FullLogoPng}
          style={{
            width: '15.813rem',
            height: '3.5rem',
          }}
          alt={'DiFreight'}
        />
        <TitleH2>{type === 'set' ? 'Встановлення' : 'Зміна'} пароля</TitleH2>
        <RegistrationForm onSubmit={handleValidate}>
          <InputBlock
            id="password"
            placeholder="Придумайте новий пароль"
            type="password"
            onChange={handleChange}
            text="Новий пароль"
            warningMessage={validationInfo.password}
          />
          <InputBlock
            id="passwordDuplicate"
            placeholder="Напишіть новий пароль ще раз"
            type="password"
            onChange={handleChange}
            text="Новий пароль"
            errorMessage={validationInfo.passwordDuplicate}
          />

          <ButtonWrapper>
            <Button text={type === 'set' ? 'Встановити' :'Змінити'} type="submit" onClick={() => {}} />
            <Link to="/login">
              <Button text="Скасувати" type="secondary" onClick={() => {}} />
            </Link>
          </ButtonWrapper>
        </RegistrationForm>
      </LayoutSmall>
    </AuthWrapper>
  );
};

export default PasswordSetPage;

export const Colors = {
  primary: '#3A3875',
  primary80: '#5F5E8E',
  primary60: '#8988AC',
  white: '#FFFFFF',
  white60: '#BFBFD2',
  white80: '#DFDFE8',
  white90: '#EFEFF4',
  black90: '#1A1A1A',
  black80: '#333333',
  black60: '#666666',
  periwinkle: '#CCCBE6',
  aqua: '#84C4C8',
  lightGreen: '#E0F8E2',
  lightBlue: '#D1EDFC',
  deepRed: '#EA445A',
  warning: '#F39C12',
  linkColor: '#3498DB',
  mistyLavender: '#f6f6fb',
  Seafoam_Green: '#6BBD7D',
  moss_green: '#4fb064',
  terra_cotta: '#E74C3C',
  baby_blue: '#EFF9FA',
  dodger_blue: '#3498DB',
};

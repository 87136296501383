import React from 'react';
import {
  RadioGroupCompressed,
  RadioGroup,
  RadioGroupCompressedSpan,
} from '../../OrderForm/styles';
import RadioInput from '../Radio';

const RadioGroupComponent = (dataConfig: any) => {
  const renderRadioGroup = () => {
    return dataConfig.dataConfig?.map((data: any) => {
      const { id, text, name, onChange, value, isActive } = data;
      return (
        <RadioInput
          key={id}
          id={id}
          text={text}
          name={name}
          onChange={onChange}
          value={value}
          isActive={isActive}
        />
      );
    });
  };
  return (
    <>
      {dataConfig?.isCompressed ? (
        <RadioGroupCompressed className={dataConfig?.className}>
          {dataConfig?.labelText && (
            <RadioGroupCompressedSpan>
              {dataConfig?.labelText}
            </RadioGroupCompressedSpan>
          )}
          {renderRadioGroup()}
        </RadioGroupCompressed>
      ) : (
        <RadioGroup>{renderRadioGroup()}</RadioGroup>
      )}
    </>
  );
};

export default RadioGroupComponent;

import React, { useState, useRef, useEffect } from 'react';
import {
  Dropdown,
  DropdownContainer,
  DropdownLabel,
  DropdownOption,
  DropdownOptions,
  DropdownPlaceholder,
  DropdownSelected,
  RequiredIndicator,
  SecondaryLabel,
  SelectedOption,
  Image,
  DropdownWithCheckboxWrapper,
} from '../../styles';
import { chevronDown } from '../../../assets/images';
import Checkbox from '../Checkbox';
import { SvgWrapper } from '../../Details/styles';
import ExpandDownSvg from '../../../assets/ReactSvg/ShippingIcon/ExpandDownSvg';
import { Colors } from '../../colors';

interface Option {
  label: string;
  value: string;
}

interface CustomDropdownProps {
  label?: string;
  placeholder: string;
  options: Option[];
  required?: boolean;
  name: string;
  onSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
  showWarning?: boolean;
  type?: 'address';
  withValueLabel?: boolean;
  className?: string;
  withCheckbox?: boolean;
  checkboxInfo?: any;
  disabled?: boolean;
  defaultValue?: any;
  defaultValueTable?: any;
  displayValue?: any;
  valueUpdated?: any;
  validate?: boolean;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  label,
  placeholder,
  options,
  required,
  name,
  onSelect,
  showWarning,
  type,
  withValueLabel = true,
  className,
  withCheckbox = false,
  checkboxInfo,
  disabled = false,
  defaultValue = {
    label: '',
    value: '',
  },
  defaultValueTable = {
    label: '',
    value: '',
  },
  displayValue = true,
  valueUpdated,
  validate = false,
}) => {
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleOptionClick = (option: Option) => {
    setSelectedOption(option);
    setIsOpen(false);

    const syntheticEvent = {
      target: {
        name,
        value: option.value,
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>;

    onSelect(syntheticEvent);
  };

  useEffect(() => {
    if (valueUpdated?.value) {
      handleOptionClick(valueUpdated);
    }
  }, [valueUpdated]);

  React.useEffect(() => {
    if (defaultValue?.value) {
      setSelectedOption(defaultValue);
    }
  }, [defaultValue]);

  React.useEffect(() => {
    if (defaultValueTable?.value) {
      setSelectedOption(defaultValueTable);
    }
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Dropdown className={className} ref={dropdownRef}>
      {label && (
        <DropdownLabel>
          {label}
          {required && <RequiredIndicator>*</RequiredIndicator>}
        </DropdownLabel>
      )}
      <DropdownWithCheckboxWrapper className={className}>
        {withCheckbox && (
          <Checkbox
            checked={checkboxInfo?.checked}
            onChange={checkboxInfo?.onChange}
            id={checkboxInfo?.id}
            label={''}
          />
        )}
        <DropdownContainer
          onClick={() => {
            if (!disabled) {
              setIsOpen(!isOpen);
            }
          }}
        >
          <DropdownSelected
            open={isOpen}
            className={
              className ? className : validate ? 'validation_failed' : ''
            }
          >
            {selectedOption ? (
              <SelectedOption className={className}>
                {type !== 'address' && withValueLabel && (
                  <span>{selectedOption.value}</span>
                )}
                <p>{!displayValue ? placeholder : selectedOption.label}</p>
              </SelectedOption>
            ) : (
              <div>
                <DropdownPlaceholder className={className}>
                  {placeholder}
                </DropdownPlaceholder>
              </div>
            )}
            <SvgWrapper>
              <ExpandDownSvg
                width={'1.5rem'}
                height={'1.5rem'}
                color={Colors.primary}
                style={{
                  transform: isOpen ? 'rotate(3.142rad)' : 'rotate(0)',
                  transition: '0.5s',
                }}
              />
            </SvgWrapper>
          </DropdownSelected>
          {isOpen && (
            <DropdownOptions className={className} open={isOpen}>
              {options.map(option => (
                <DropdownOption
                  key={option.value}
                  className={className && 'dropdown-option'}
                  onClick={() => handleOptionClick(option)}
                >
                  {type !== 'address' && withValueLabel && (
                    <span>{option.value}</span>
                  )}
                  {option.label}
                </DropdownOption>
              ))}
            </DropdownOptions>
          )}
        </DropdownContainer>
      </DropdownWithCheckboxWrapper>
      {required && showWarning && (
        <SecondaryLabel>Обов'язкове поле</SecondaryLabel>
      )}
    </Dropdown>
  );
};

export default CustomDropdown;

import { SVGProps } from 'react';
import { Colors } from '../../components/colors';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width='1.5rem'
    height='1.5rem'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M10.5014 12.0008H5.0014M4.91675 12.2923L2.58183 19.267C2.39839 19.8149 2.30668 20.0889 2.3725 20.2576C2.42966 20.4041 2.55242 20.5152 2.7039 20.5575C2.87834 20.6061 3.1418 20.4876 3.66874 20.2505L20.3802 12.7303C20.8945 12.4989 21.1517 12.3831 21.2312 12.2224C21.3002 12.0827 21.3002 11.9188 21.2312 11.7792C21.1517 11.6184 20.8945 11.5027 20.3802 11.2712L3.66291 3.74849C3.13757 3.51209 2.87489 3.39389 2.70063 3.44237C2.54929 3.48448 2.42654 3.59527 2.36918 3.74151C2.30314 3.90991 2.39388 4.18328 2.57535 4.73004L4.9174 11.7863C4.94857 11.8802 4.96415 11.9272 4.9703 11.9752C4.97576 12.0178 4.97571 12.0609 4.97014 12.1035C4.96386 12.1515 4.94816 12.1984 4.91675 12.2923Z'
      stroke={Colors.primary80} strokeLinecap='round' strokeLinejoin='round' />
  </svg>


);
export default SvgComponent;

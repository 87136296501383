//@ts-ignore
import Cookies from 'js-cookie';
import axios from 'axios';

const chatBaseUrl = process.env.REACT_APP_CHAT_API_BASE_URL;

const axiosInstanceChat = axios.create({
  baseURL: chatBaseUrl,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstanceChat.interceptors.request.use(
  config => {
    const token = Cookies.get('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    // config.headers['Accept-Language'] = 'ua';

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

axiosInstanceChat.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error);
  },
);

export default axiosInstanceChat;

import { useMemo } from 'react';
import Button from '../ui/Button';
import Bills from './Bills';
import DocumentInfo from './DocumentInfo';
import {
  BillTotal,
  ContentContainer,
  EmptyGoods,
  Label,
  PaymentMethod,
  PaymentMethodBtn,
  PaymentMethodContainer,
  PriceOffersContainer,
  Subtitle,
  Title,
  TitleContainerPriceOffer,
} from './styles';
import { useMobile } from '../../constants/breakpoints';
import { useDetailsContext } from '../Threads/DetailsContext';
import { TableReceivedGoodsBody } from '../DetailModals/styles';
import { Colors } from '../colors';

interface IPriceOffers {
  core?: boolean;
  isModal?: boolean;
  offersStatus?: string | null;
}

const PriceOffers = ({
  core = false,
  isModal = false,
  offersStatus,
}: IPriceOffers) => {
  const {
    config,
    setIsPaymentMethodModal,
    handleClosePriceOffersModal,
    isPaymentMethodChoosen,
    commonConfig,
    isUpproveTariff,
  } = useDetailsContext();

  const isMobile = useMobile();
  const priceOffers = config?.price_offers;
  const invoices = config?.invoices;

  const findPaymentType = () => {
    if (commonConfig?.transportation_payments) {
      let paymentLabel = '';
      for (let index in commonConfig?.transportation_payments) {
        if (index === config?.payment_bill) {
          commonConfig?.transportation_payments?.[index]?.find(
            (method: any) => {
              if (method?.value === config?.payment_type) {
                paymentLabel = method?.label;
              }
            },
          );
        }
      }
      return paymentLabel;
    } else {
      return config?.payment_type;
    }
  };

  return (
    <PriceOffersContainer core={core}>
      <TitleContainerPriceOffer>
        {offersStatus === 'offers' ? (
          <Label>цінові пропозиції:</Label>
        ) : (
          <Label style={{ marginBottom: isMobile ? '0.25rem' : '' }}>
            рахунки:
          </Label>
        )}
        {config?.status === 'tariff_under_review' &&
          isUpproveTariff &&
          !isPaymentMethodChoosen &&
          config?.direction?.name === 'CN-UA' && (
            <PaymentMethodBtn>
              <Button
                text={'Оберіть бажаний спосіб оплати'}
                smallPadding
                type={'color'}
                onClick={() => setIsPaymentMethodModal(true)}
              />
            </PaymentMethodBtn>
          )}
        {isPaymentMethodChoosen && (
          <PaymentMethodContainer>
            <Label>спосіб оплати:</Label>
            <PaymentMethod>
              <p>
                {findPaymentType()} {config?.payment_bill}
              </p>
            </PaymentMethod>
          </PaymentMethodContainer>
        )}
      </TitleContainerPriceOffer>

      <ContentContainer>
        {offersStatus === 'offers' && priceOffers?.length > 0 ? (
          priceOffers?.map((item: any, idx: number) => (
            <DocumentInfo
              key={idx}
              modal={isModal}
              offer={item}
              setIsSuccessModal={handleClosePriceOffersModal}
            />
          ))
        ) : offersStatus === 'bills' && invoices?.length > 0 ? (
          invoices?.map((item: any, idx: number) => (
            <Bills key={idx} bill={item} />
          ))
        ) : (
          <TableReceivedGoodsBody
            style={{
              borderRadius: '4px',
              border: `1px solid ${Colors.periwinkle}`,
            }}
          >
            <EmptyGoods>
              <p>Поки що тут порожньо</p>
            </EmptyGoods>
          </TableReceivedGoodsBody>
        )}
      </ContentContainer>
      {offersStatus === 'bills' && (
        <BillTotal>
          <Title>Сплачено / борг:</Title>
          <Subtitle>
            ${' '}
            <span className="green">
              {config?.invoces_total?.total_paid?.toFixed(2)}
            </span>
            /
            <span className="red">
              {(
                config?.invoces_total?.total_amount?.toFixed(2) -
                config?.invoces_total?.total_paid?.toFixed(2)
              ).toFixed(2)}
            </span>
          </Subtitle>
        </BillTotal>
      )}
    </PriceOffersContainer>
  );
};

export default PriceOffers;

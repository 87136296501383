import styled from 'styled-components';
import { Colors } from '../colors';

export const UniversalLayout = styled.div`
  width: fit-content;
  max-width: 100.75rem;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 2.5rem;
  gap: 1.5rem;
  background-color: ${Colors.white};

  min-height: 600px;
  max-height: 54.938rem;

  overflow: auto;
  outline: none;
  @media(max-width: 1440px) {
    width: 100%;
  }

  @media ((min-width: 768px) and (max-width: 1199px)) {
    max-width: 680px;
    padding: 1.5rem;
    max-height: 43.75rem;
    width: 100%;
  }

  @media (max-width: 767px) {
    height: 100%;
    width: 100%;
    padding: 1.875rem;
  }
`;
export const CloseButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;

  > svg:hover {
    > path {
      stroke: ${Colors.primary60};
    }
  }
`;
export const Title = styled.div`
  font-size: 2rem;
  font-weight: 500;
  line-height: 3rem;
  color: ${Colors.primary};

  @media (max-width: 767px) {
    text-align: center;
    font-size: 1.313rem;
    line-height: 1.969rem;
  }
`;

interface IButtonsWrapper {
  isLastMile?: boolean;
}
export const ButtonsWrapper = styled.div<IButtonsWrapper>`
  width: 100%;
  max-width: 47rem;
  height: 3.063rem;
  margin: 0 auto;
  display: flex;
  gap: 1.5rem;

  @media ((min-width: 767px )and (max-width: 1199px)) {
    > button:nth-child(1) {
      width: ${props => props.isLastMile && '30%'};
    }

    > button:nth-child(2) {
      width: ${props => props.isLastMile && '70%'};
    }
  }
`;
export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 2.5rem;

  @media ((min-width: 767px )and (max-width: 1199px)) {
    flex-direction: column;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;
interface IContentContainer {
  disableScroll?: boolean;
  isLastMileModal?: boolean;
}
export const ContentContainer = styled.div<IContentContainer>`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  @media (min-width: 1440px) {
    min-width: ${props => props.isLastMileModal && '716px'};
  }

  @media (max-width: 1440px) {
    overflow: ${props => (props.disableScroll ? 'visible' : 'hidden')};
    width: 100%;
  }
  @media(max-width: 767px) {
    overflow: visible;
  }
`;

export const LastMileForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media(max-width: 1440px) {
    width: 100%;
  }
  //@media (min-width: 1439px) {
  //  width: 36.125rem;
  //}
`;

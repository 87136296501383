import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={48} height={48} fill="none" {...props}>
    <path
      stroke="#3A3875"
      strokeLinecap="round"
      strokeWidth={2}
      d="M15 12h32M15 24h32M15 36h32"
    />
  </svg>
);
export default SvgComponent;

import React, { useState } from 'react';
import {
  DownloadPdf,
  LogoWrapper,
  ModalButtonWrapper,
  ModalContent,
  ModalContentWrapper,
  ModalHeader,
  SubContent,
  UniversalModalLayout,
} from '../../styles';
import Button from '../Button';
import SignAlert from '../SignAlert';
import PdfFileIcon from '../../../assets/ReactSvg/PdfFileIcon';
import { useMobile } from '../../../constants/breakpoints';
import { getSupplierInstruction } from '../../../utils/axios/get';
import { debounce } from 'lodash';
import { useDetailsContext } from '../../Threads/DetailsContext';
import { LoadingCircularWrapper } from '../../../pages/auth/styles';
import { CircularProgress } from '@mui/material';

type UniversalModalType = {
  isOpenModal: boolean;
  width: string;
  onClose?: () => void;
  primaryButtonText?: string;
  primaryBtnClick?: () => void;
  content?: string | React.ReactNode;
  header?: string;
  secondaryBtnClick?: () => void;
  secondaryBtnText?: string;
  paddingTop?: string;
  btnGap?: string;
  btnDirection?: string;
  sign?: boolean;
  logo?: React.ReactNode;
  subcontent?: string;
  sendPdf?: boolean;
  className?: string;
  isShowBtn?: boolean;
  id?: any;
};

const Modal = ({
  isOpenModal,
  width,
  header,
  content,
  primaryButtonText,
  primaryBtnClick,
  secondaryBtnClick,
  secondaryBtnText,
  paddingTop,
  btnGap = '1.5rem',
  btnDirection = 'row',
  sign,
  logo,
  subcontent,
  sendPdf,
  isShowBtn = true,
  onClose,
  className,
  id,
}: UniversalModalType) => {
  const isSecondaryBtn = secondaryBtnText && secondaryBtnClick;
  const isMobile = useMobile();
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const sendPdfContent = isMobile
    ? 'Відправте файл постачальнику:'
    : 'Відправте цей файл постачальнику:';

  if (!isOpenModal) return null;

  const handleGetDocument = async (id: any, openPreview?: any) => {
    if (openPreview) {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
    } else {
      if (isDownloading) {
        return;
      }
      setIsDownloading(true);
    }
    try {
      await getSupplierInstruction(id, openPreview);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
    setIsDownloading(false);
  };

  const debouncedDocument = debounce(handleGetDocument, 300);

  return (
    <UniversalModalLayout
      style={{
        maxWidth: width,
        paddingTop: paddingTop,
      }}
      className={className}
    >
      <ModalContentWrapper width={width}>
        {logo && <LogoWrapper>{logo}</LogoWrapper>}
        {header && <ModalHeader>{header}</ModalHeader>}
        <ModalContent>{content}</ModalContent>
        {subcontent &&
          <SubContent onClick={() => debouncedDocument(id, true)}>{isLoading ? (
            <LoadingCircularWrapper>
              <CircularProgress color="inherit" size={'1.5rem'} />
            </LoadingCircularWrapper>
          ): (
            <>{subcontent}</>
          )}</SubContent>
        }
      </ModalContentWrapper>

      {isShowBtn && (
        <ModalButtonWrapper gap={btnGap} direction={btnDirection}>
          {sendPdf && (
            <DownloadPdf onClick={() => debouncedDocument(id)}>
              {<p>{isDownloading ? (
                <LoadingCircularWrapper>
                  <CircularProgress color="error" size={'1.5rem'} />
                </LoadingCircularWrapper>
              ): (
                <>{sendPdfContent}</>
              )}</p>}
              {
                !isDownloading && <PdfFileIcon />
              }
            </DownloadPdf>
          )}
          {sign && <SignAlert type="sign" />}
          {primaryButtonText && primaryBtnClick && (
            <Button
              text={primaryButtonText}
              type={'primary'}
              onClick={primaryBtnClick}
            />
          )}
          {isSecondaryBtn && (
            <Button
              text={secondaryBtnText}
              type={'secondary'}
              onClick={secondaryBtnClick}
            />
          )}
        </ModalButtonWrapper>
      )}
    </UniversalModalLayout>
  );
};

export default Modal;

import React from 'react';
import { DashboardControlPanelWrapper } from './styles';
import SearchDashboardInput from './SearchDashboardInput';
import DashboardStatusTab from './DashboardStatusTab';
import DashboardDisplayViewSwitcher from './DashboardDisplayViewSwitcher';

const DashboardControlPanel = ({
    active,
    setActive,
    isRowDisplay,
    setIsRowDisplay,
    searchValue,
    setSearchValue,
}: any) => {
  return (
    <DashboardControlPanelWrapper>
      <SearchDashboardInput
        value={searchValue}
        setValue={setSearchValue}
      />
      <DashboardStatusTab
        active={active}
        setActive={setActive}
      />
      <DashboardDisplayViewSwitcher
        active={isRowDisplay}
        setActive={setIsRowDisplay}
      />
    </DashboardControlPanelWrapper>
  );
};

export default DashboardControlPanel;
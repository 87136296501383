import React, { useEffect, useState } from 'react';
import CustomDropdown from '../ui/Dropdown/Dropdown';
import { DetailsConfig, orderConfig } from '../../utils/axios/get';
import RegionDropdown from '../ui/Dropdown/RegionDropdown';
import { useDetailsContext } from '../Threads/DetailsContext';
import { updatePaymentMethod } from '../../utils/axios/post';

const PaymentMethodModal = ({
  submitPaymentMethodModal,
  setSubmitPaymentMethodModal,
}: {
  submitPaymentMethodModal: boolean;
  setSubmitPaymentMethodModal: () => void;
}) => {
  const {
    config,
    paymentMethodConfig,
    setPaymentMethodConfig,
    handleTogglerPaymentMethodModal,
    handleTogglerLastMilesModal,
    setConfig,
    setIsLoading,
  } = useDetailsContext();
  const [currency, setCurrency] = useState<any>([]);
  const [paymentOptions, setPaymentOptions] = useState<any>([]);
  const [paymentValidation, setPaymentValidation] = useState({
    payment_bill: false,
    payment_type: false,
  });
  const handleChange = (e: any) => {
    const { name, value } = e.target;

    if (name === 'payment_bill') {
      setPaymentMethodConfig({
        payment_bill: value,
        payment_type: null,
      });
    } else {
      setPaymentMethodConfig((prevData: any) => ({
        ...prevData,
        [name]: value,
      }));
    }

    setPaymentValidation({
      payment_bill: false,
      payment_type: false,
    });
  };

  const submitPaymentMethod = async () => {
    setIsLoading(true);
    try {
      const result = await updatePaymentMethod({
        id: config?.id,
        body: paymentMethodConfig,
      });

      if (result?.success) {
        handleTogglerPaymentMethodModal({ show: false });
        handleTogglerLastMilesModal({ show: true });
        const { data } = await DetailsConfig(config?.id);
        setConfig(data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  const validatePaymentMethod = () => {
    const { payment_bill, payment_type } = paymentMethodConfig;
    setSubmitPaymentMethodModal();

    if (!payment_bill) {
      return setPaymentValidation(prev => ({
        ...prev,
        payment_bill: true,
      }));
    }
    if (!payment_type) {
      return setPaymentValidation(prev => ({
        ...prev,
        payment_type: true,
      }));
    }

    if (payment_bill && payment_type) return submitPaymentMethod();
  };

  useEffect(() => {
    const getPaymentConfig = async () => {
      try {
        await orderConfig().then(config => {
          const currency = Object.keys(config?.transportation_payments);
          const options = config?.transportation_payments;
          setCurrency(currency);
          setPaymentOptions(options);
        });
      } catch (error) {
        console.log(error);
      }
    };
    getPaymentConfig();
  }, []);

  useEffect(() => {
    if (submitPaymentMethodModal) {
      validatePaymentMethod();
    }
  }, [submitPaymentMethodModal]);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <RegionDropdown
        placeholder="Оберіть валюту"
        options={currency ?? []}
        name="payment_bill"
        value={paymentMethodConfig?.payment_bill}
        onSelect={handleChange}
        label={''}
        validate={paymentValidation.payment_bill}
      />
      {paymentMethodConfig?.payment_bill && (
        <CustomDropdown
          placeholder="Оберіть бажаний спосіб оплати"
          options={paymentOptions[paymentMethodConfig?.payment_bill] ?? []}
          name="payment_type"
          displayValue={paymentMethodConfig?.payment_type}
          onSelect={handleChange}
          withValueLabel={false}
          validate={paymentValidation.payment_type}
        />
      )}
    </div>
  );
};

export default PaymentMethodModal;

import React from 'react';
import { CargoTableInput } from '../../styles';
import usePreventScrollOnNumberInput from '../../../utils/global/usePreventScroll';

const TableInput = ({ name, type, id, placeholder, value, onChange, disabled, className }: any) => {
  const inputRef = usePreventScrollOnNumberInput();

  return (
    <CargoTableInput
      name={name}
      id={id}
      placeholder={placeholder}
      type={type}
      value={value}
      onChange={onChange}
      disabled={disabled}
      ref={inputRef}
      className={className}
    />
  )
};

export default TableInput;
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from '../FormContext';
import {
  AddressContainer,
  InnerContent,
  ContentWrapper,
  ContentContainer,
  DeliveryDirectionStepModal,
} from './styles';
import { ErrorLabel } from '../styles';
import InputBlock from '../../ui/InputBlock';
import Button from '../../ui/Button';
import CustomDropdown from '../../ui/Dropdown/Dropdown';
import RegionDropdown from '../../ui/Dropdown/RegionDropdown';
import { Modal } from '@mui/material';
import { useMobile, useTablet } from '../../../constants/breakpoints';
import CloseModalButton from '../../ui/CloseModalBtn';
import { Content } from '../styles';
import AutoCompleteInput from '../../ui/Input/AutoCompleteInput';
import { getSessionStorage, searchByCountry } from '../../../utils/global';
import { AddressProps } from './DepartureStep';

interface InputProps {
  text: string;
  placeholder: string;
  name: string;
  value: string;
  id: string;
  className?: string;
}

const DeliveryDirectionStep: React.FC = () => {
  const {
    formData,
    updateFormData,
    validateFormData,
    config,
    showModal,
    updateShowModal,
    resetError,
    warehousesConfigState
  } = useFormContext();
  const isMobile = useMobile();
  const isTablet = useTablet();

  const { step3 } = formData;
  const { amazon_warehouses } = config;
  const { country_to, region, fba_warehouse, to_address_type } = step3;
  const [updatedValueAddress, setUpdatedValueAddress] = useState<AddressProps | null>(null);

  const [amazonRegion, setAmazonRegion] = useState<string[]>();
  const [amazonWarehouses, setAmazonWarehouses] = useState<string[]>();
  const [fulfillmentWarehouses, setFulFillmentWarehouses] = useState<any[]>([]);
  const localData = getSessionStorage('data');

  const handleModalClose = () => {
    updateShowModal(false);
  };

  const handleSetWarehouses = () => {
    const warehouses = warehousesConfigState?.warehouses?.map((item: any) => {
      if (item?.is_fulfillment) {
        return item?.name;
      }
    });

    const filteredWarehouses = warehouses?.filter((item: any) => item);
    setFulFillmentWarehouses(filteredWarehouses);
  }

  useEffect(() => {
    if (!step3?.isCompleted) {
      updateFormData('step3', {
        receiving_warehouse_id: '',
        address: '',
      })
    }
  }, [to_address_type])

  const isShowWarning = !step3.isValid && (step3.isCompleted || step3?.hasError);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFormData('step3', { [e.target.name]: e.target.value });
    resetError('step3');
  };

  const inputProps = (): InputProps[] => {
    if (step3.to_address_type === 'fulfillment_center') {
      return [
        {
          text: 'Склад',
          name: 'receiving_warehouse_id',
          id: 'receiving_warehouse_id',
          value: step3.receiving_warehouse_id,
          placeholder: 'Введіть склад',
        },
      ];
    }
    if (step3.to_address_type === 'amazon_warehouse') {
      return [
        {
          text: 'Регіон FBA',
          name: 'region',
          id: 'region',
          value: step3.region,
          placeholder: 'Вкажіть регіон FBA',
        },
        {
          text: 'Склад FBA',
          name: 'fba_warehouse',
          id: 'fba_warehouse',
          value: step3.fba_warehouse,
          placeholder: 'Вкажіть склад FBA',
        },
      ];
    }
    return [
      // {
      //   text: 'Адреса',
      //   name: 'address',
      //   id: 'address',
      //   value: step3.address,
      //   placeholder: 'Введіть адресу призначення',
      //   className: 'address-input',
      // },
    ];
  };

  const renderContent = useMemo(() => {
    const commonProps = {
      type: 'text',
      onChange: handleChange,
    };

    const inputs = inputProps();

    if (to_address_type === 'amazon_warehouse') {
      return (
        <>
          {
            // @ts-ignore
            !!amazonRegion?.length ? (
              <>
                <RegionDropdown
                  value={region}
                  label="Регіон FBA"
                  placeholder="Вкажіть регіон FBA"
                  options={amazonRegion?.length ? amazonRegion : ['Нажаль немає доступних варіантів']}
                  name="region"
                  showWarning={isShowWarning}
                  onSelect={handleChange}
                  defaultValue={step3?.region}
                  disabled={!amazonRegion?.length}
                />
                <RegionDropdown
                  value={fba_warehouse}
                  label="Склад FBA"
                  placeholder="Вкажіть склад FBA"
                  options={amazonWarehouses?.length ? amazonWarehouses : ['Нажаль немає доступних варіантів']}
                  name="fba_warehouse"
                  showWarning={isShowWarning}
                  onSelect={handleChange}
                  disabled={!amazonWarehouses?.length}
                />
              </>
            ) : <ErrorLabel>В обраній країні немає складів Амазон</ErrorLabel>
          }
        </>
      );
    }
    if (to_address_type === 'fulfillment_center') {
      return (
        <>
          <RegionDropdown
            value={step3.receiving_warehouse_id}
            label="Склад"
            placeholder="Введіть склад"
            options={fulfillmentWarehouses ?? []}
            name="receiving_warehouse_id"
            showWarning={isShowWarning}
            onSelect={handleChange}
          />
        </>
      )
    }
    return inputs ? (
      <>
        {inputs?.map((props: any) => (
          <InputBlock
            {...commonProps}
            {...props}
            key={props.id}
            id={props.id}
          />
        ))}
      </>
    ) : null;
  }, [inputProps]);

  const handleChangeAddress = (value: any, name: string, coordinates?: any) => {
    const coordinatesNameLat = `${name}_coordinates_lat`;
    const coordinatesNameLng = `${name}_coordinates_lng`;
    updateFormData('step3', {
      [name]: value,
      [coordinatesNameLat]: coordinates?.lat,
      [coordinatesNameLng]: coordinates?.lng,
    })
  }

  useEffect(() => {
    setUpdatedValueAddress(null);
    if (step3?.address) {
      config?.sending_countries?.forEach((item: any) => {
        if (step3?.address?.includes(item?.label)) {
          setUpdatedValueAddress(item);
        } else if (step3?.address?.includes('Сполучені Штати Америки')) {
          setUpdatedValueAddress({
            value: 'US',
            label: 'США'
          });
        } else if (step3?.address?.includes('Велика Британія')) {
          setUpdatedValueAddress({
            value: 'GB',
            label: 'Великобританія'
          });
        }
      })
    }
  }, [step3?.address]);

  useEffect(() => {
    if (country_to && !step3.isCompleted && country_to !== localData?.step3?.country_to) {
      if (updatedValueAddress?.value !== country_to)
        updateFormData('step3', {
          address: ''
        })
    }
  }, [country_to])

  const content = () => {
    return (
      <ContentContainer>
        <AddressContainer>
          <CustomDropdown
            label="Країна отримання"
            placeholder="Оберіть країну отримання"
            options={config?.receiving_countries ?? []}
            name="country_to"
            required
            showWarning={isShowWarning}
            onSelect={handleChange}
            valueUpdated={updatedValueAddress}
            defaultValue={config?.receiving_countries?.find((item: any) => item.value === step3?.country_to) || null}
          />
          <CustomDropdown
            type={'address'}
            label="Тип адерси"
            placeholder="Оберіть тип адреси"
            options={config?.receiving_types ?? []}
            name="to_address_type"
            onSelect={handleChange}
            defaultValue={config?.receiving_types?.find((item: any) => item.value === step3?.to_address_type) || null}
          />
        </AddressContainer>
        {
          (to_address_type !== 'amazon_warehouse' && to_address_type !== 'fulfillment_center' && to_address_type) && (
            <AutoCompleteInput
              value={step3?.address}
              name={'address'}
              searchOptions={searchByCountry(country_to)}
              placeholder={'Адреса'}
              handleChangeAddress={handleChangeAddress}
              text={'Адреса'}
            />
          )

        }
        <ContentWrapper>
          <InnerContent>{renderContent}</InnerContent>
        </ContentWrapper>
        <Button
          text={'Підтвердити'}
          type={'primary'}
          onClick={() => validateFormData('step3', step3.country_to)}
        />
      </ContentContainer>
    );
  };

  useEffect(() => {
    updateFormData('step3', { isTouched: true });
    handleSetWarehouses();
  }, [])

  useEffect(() => {
    if (country_to) {
      const regionKeys = Object.keys(amazon_warehouses?.[country_to] ?? {});
      setAmazonRegion(regionKeys);
    }
    updateFormData('step3', { fba_warehouse: '', region: '' });
    resetError('step3');
  }, [country_to]);

  useEffect(() => {
    if (amazonRegion) {
      const warehouses = amazon_warehouses?.[country_to]?.[region] ?? [];
      setAmazonWarehouses(warehouses);
    }
  }, [region]);

  return (
    <DeliveryDirectionStepModal>
      {isMobile || isTablet ? (
        <Modal
          open={showModal}
          className="deliveryDirectionStep"
        >
          <Content className={'departureStep-content-visible'}>
            <CloseModalButton onClose={handleModalClose} />
            {content()}
          </Content>
        </Modal>
      ) : (
        <div className="deliveryDirectionStep">{content()}</div>
      )}
    </DeliveryDirectionStepModal>
  );
};

export default DeliveryDirectionStep;

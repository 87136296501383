import { FC } from 'react';
import { ConclusionList, ConclusionItems } from './styles';
import Checkbox from '../ui/Checkbox';
import { useDetailsContext } from '../Threads/DetailsContext';
import dayjs from 'dayjs';
import { getHarmfulTypeLabel, getLoadingTypeLabel } from './utils';

interface IConclusionProps {}

const Conclusion: FC<IConclusionProps> = ({}) => {
  const { config, commonConfig } = useDetailsContext();
  return (
    <ConclusionList>
      {
        config?.data?.loading_type && (
          <ConclusionItems>
            <p>Тип погрузки:</p>
            <span>{getLoadingTypeLabel(commonConfig?.loading_types ,config?.data?.loading_type)}</span>
          </ConclusionItems>
        )
      }

      {
        config?.data?.cargo_readiness && (
          <ConclusionItems>
            <p>Дата готовності</p>
            <span>{dayjs(config?.data?.cargo_readiness).format('DD.MM.YYYY')}</span>
          </ConclusionItems>
        )
      }

      {config?.has_battery && (
        <ConclusionItems>
          <p>Містить батареї:</p>
          <Checkbox checked={true} id="enter_mark" onChange={() => {}} />
        </ConclusionItems>
      )}
      {config?.data?.harmful_materials_class && (
        <ConclusionItems>
          <p>Небезпечний вантаж:</p>
          <span>{getHarmfulTypeLabel(commonConfig?.harmful_materials_classes, config?.data?.harmful_materials_class)}</span>
        </ConclusionItems>
      )}
    </ConclusionList>
  );
};

export default Conclusion;

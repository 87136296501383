import styled from 'styled-components';
import { Colors } from '../colors';

const NotificationItemWrapper = styled.div`
  display: flex;
  align-items: start !important;
  gap: 0 0.5rem !important;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  width: 100%;
  background: ${Colors.white};
  transition: 0.2s;
  &:hover {
    background: ${Colors.mistyLavender};
    transition: 0.2s;
  }
  &.active {
    background: ${Colors.baby_blue};
    border: 1px solid ${Colors.periwinkle};
  }
`;

const NotificationItemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.125rem 0 !important;
`;

const NotificationUnreadIcon = styled.div`
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  background: ${Colors.primary};
  gap: 0 !important;
  margin-top: 0.25rem;
`;

const NotificationTitle = styled.h6`
  font-size: 0.875rem;
  line-height: 1.313rem;
  font-weight: 600;
  color: ${Colors.primary};
`;

const NotificationText = styled.p`
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: ${Colors.black60};
`;

const NotificationTime = styled.p`
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: ${Colors.primary60};
`;

const NotificationsAllWrapper = styled.div`
  padding: 1rem;
  background: ${Colors.white};
  border-radius: 0.25rem;
  max-width: 550px;
  width: 100%;
  position: absolute;
  top: 6rem;
  right: 6rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem 0 !important;
  z-index: 1000;

  &.long {
    overflow-y: scroll;
    max-height: 25rem;
  }

  @media (max-width: 1024px) {
    right: 0;
  }

  @media (max-width: 767px) {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
`;

const NotificationModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  align-items: end;
  margin-bottom: 0.5rem !important;
`;

const NotificationModalTitle = styled.h5`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${Colors.primary60};
`;

const NotificationModalButton = styled.p`
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: ${Colors.primary};
  font-weight: 600;
  cursor: pointer;
`;

export {
  NotificationItemWrapper,
  NotificationItemContent,
  NotificationUnreadIcon,
  NotificationTitle,
  NotificationText,
  NotificationTime,
  NotificationsAllWrapper,
  NotificationModalHeader,
  NotificationModalTitle,
  NotificationModalButton,
};

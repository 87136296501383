const RegistrationErrors = {
  fullName: '',
  email: 'Невірний формат. Приклад: example@example.com',
  phone: 'Введіть коректний номер телефону.',
  password: '',
  emailDuplicate: 'Цей email вже використовується.',
  acceptRights: 'Вам необхідно прийняти умови публічної оферти.',
};

const AvatarWarnings = {
  image_size: 'Зображення має бути не більше 6 МБ',
};

const ProfileWarnings = {
  full_name: 'Поле не може бути порожнім.',
  email: 'Обовʼязкове поле.',
  phones: {
    required: 'Поле не може бути порожнім.',
    isValid: 'Номер не валідний',
    unique: 'Номер повинен бути унікальним',
  },
  password: 'Обовʼязкове поле. Від 6 до 20 символів.',
  avatar: '',
  full_name_limit: 'ПІБ не може бути більше 128 символів.',
};

const RegistrationWarnings = {
  fullName: 'Поле не може бути порожнім.',
  email: 'Обовʼязкове поле.',
  phone: 'Поле не може бути порожнім.',
  password: 'Обовʼязкове поле. Від 6 до 20 символів.',
  fullNameLength: 'ПІБ не може бути більше 128 символів.',
};

const AuthEmailError = 'Помилка в e-mail';
const AuthEmailWarning =
  'Невірний e-mail або пароль. Будь ласка, перевірте введені дані та повторіть спробу.';

const AuthUnverifiedEmailError =
  'Ваш email не підтверджено. Будь ласка, перевірте пошту.';

const AuthEmailEmptyError = 'Обовʼязкове поле.';
const AuthPasswordEmptyError = 'Обовʼязкове поле. Від 6 до 20 символів.';

const ForgotPasswordError = 'Помилка в e-mail';

const ForgotPasswordWarning = 'Поле не може бути порожнім.';

const ForgotPasswordUserNotExist =
  'Електронну адресу не знайдено. Будь ласка, перевірте введені дані та спробуйте ще раз.';

const ChangePasswordWarning =
  'Пароль повинен налічувати не менше ніж 6 символів';

const ChangePasswordError = 'Паролі не співпадають';

export {
  RegistrationErrors,
  RegistrationWarnings,
  ForgotPasswordError,
  ForgotPasswordWarning,
  ChangePasswordWarning,
  ChangePasswordError,
  AuthEmailError,
  AuthEmailWarning,
  AuthUnverifiedEmailError,
  ProfileWarnings,
  AuthEmailEmptyError,
  AuthPasswordEmptyError,
  AvatarWarnings,
  ForgotPasswordUserNotExist,
};

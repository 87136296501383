import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DetailsConfig,
  getSubflightStatuses,
  orderConfig, warehousesConfig,
} from '../../utils/axios/get';
import { debounce } from 'lodash';
import { useMobile } from '../../constants/breakpoints';

interface FormProviderProps {
  children: ReactNode;
}
interface IModalProps {
  show: boolean;
  onSuccess?: boolean;
}

const DetailsContext = createContext<any | null>(null);

export const useDetailsContext = (): any => {
  const context = useContext(DetailsContext);
  if (!context) {
    throw new Error('useFormContext must be used within a FormProvider');
  }
  return context;
};
export const DetailsProvider: React.FC<FormProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const [config, setConfig] = useState<any>();
  const [isPriceOffersModal, setIsPriceOffersModal] = useState(false);
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [isLastStepSuccessModal, setIsLastStepSuccessModal] = useState(false);
  const [isPaymentMethodModal, setIsPaymentMethodModal] = useState(false);
  const [commonConfig, setCommonConfig] = useState<any>({});
  const [subflightConfig, setSubflightConfig] = useState<any>(null);
  const [currentStatus, setCurrentStatus] = useState<any>('');
  const [isDocumentsModal, setIsDocumentsModal] = useState(false);
  const [isExpandModalOpen, setIsExpandModalOpen] = useState(false);
  const [isCompactMode, setIsCompactMode] = useState(false);
  const [isUpproveTariffModal, setIsUpproveTariffModal] = useState(false);
  const [isUpproveTariff, setIsUpproveTariff] = useState(false);
  const [warehousesConfigData, setWarehousesConfigData] = useState<any>([]);

  const [isTableRecieveGoods, setIsTableRecieveGoods] = useState(false);
  const [isLastMilesModal, setIsLastMilesModal] = useState(false);
  const [isUploadDocumentsModal, setIsUploadDocumentsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethodConfig, setPaymentMethodConfig] = useState<any>({
    payment_bill: null,
    payment_type: null,
  });
  const [statusesStack, setStatusesStack] = useState<any>([]);
  const [activeStatusIndexData, setActiveStatusIndex] = useState<any>('');

  const [
    isShowDocumentsHotButtonsOnDetails,
    setIsShowDocumentsHotButtonsOnDetails,
  ] = useState(false);
  const [
    isShowWarehouseHotButtonsOnDetails,
    setIsShowWarehouseHotButtonsOnDetails,
  ] = useState(false);

  const isPaymentMethodChoosen =
    config?.payment_bill && config?.payment_type ? true : false;

  const handleTogglerPriceOffersModal = ({
    show,
    onSuccess = false,
  }: IModalProps) => {
    if (onSuccess) {
      setIsPriceOffersModal(false);
      setIsSuccessModal(true);
    }
    setIsPriceOffersModal(show);

    if (onSuccess) {
      document.body.style.overflow = '';
      document.body.style.maxHeight = '100%';
      return;
    }
    if (show) {
      document.body.style.overflow = 'hidden';
      document.body.style.maxHeight = '100vh';
    } else {
      document.body.style.overflow = '';
      document.body.style.maxHeight = '100%';
    }
  };
  const handleTogglerPaymentMethodModal = ({ show }: IModalProps) => {
    setIsPaymentMethodModal(show);
  };
  const handleCloseUniversalModal = () => {
    setIsSuccessModal(false);
    document.body.style.overflow = '';
    document.body.style.maxHeight = 'auto';
    // navigate('/dashboard');
    updateConfig(config?.id);
  };

  useEffect(() => {
    if (!isPriceOffersModal) {
      document.body.style.overflow = 'visible';
      document.body.style.maxHeight = '100%';
      // document.body.style.overflow = '';
      //@todo Return back
      // document.body.style.maxHeight = 'auto';
    }
  }, [isPriceOffersModal]);

  useEffect(() => {
    if (!isSuccessModal) {
      document.body.style.overflow = 'visible';
      document.body.style.maxHeight = '100%';
    } else {
      document.body.style.overflow = 'hidden';
      document.body.style.maxHeight = '100vh';
    }
  }, [isSuccessModal])

  const handleTogglerTableRecieveGoodsModal = ({ show }: IModalProps) => {
    setIsTableRecieveGoods(show);
  };

  const handleTogglerLastMilesModal = ({ show }: IModalProps) => {
    setIsLastMilesModal(show);
    document.body.style.overflow = '';
    document.body.style.maxHeight = 'auto';
  };

  const fetchOrderConfigData = async () => {
    const orderConfigData = await orderConfig();
    if (orderConfigData?.success) {
      setCommonConfig(orderConfigData);
    }
    const subflightStatuses = await getSubflightStatuses();
    if (subflightStatuses?.success) {
      setSubflightConfig(subflightStatuses?.statuses);
    }
    const warehousesConfigDataResult = await warehousesConfig();
    if (warehousesConfigDataResult?.success) {
      setWarehousesConfigData(warehousesConfigDataResult?.warehouses);
    }
  };

  const debouncedFetch = debounce(fetchOrderConfigData, 300);

  useEffect(() => {
    if (commonConfig?.transportation_statuses) {
      commonConfig?.transportation_statuses?.forEach((status: any) => {
        if (status?.value === config?.status) {
          setCurrentStatus(status?.label);
        }
      });
    }
  }, [commonConfig]);

  useEffect(() => {
    debouncedFetch();
    return () => {
      debouncedFetch.cancel();
    };
  }, []);

  const updateConfig = async (id: string) => {
    const response = await DetailsConfig(id);
    setConfig(response?.data);
  };

  const updateStatusesStack = (stack: any) => {
    setStatusesStack(stack);
  };




  const filterStatusesByDirection = (statuses: any, subflight: any) => {
    return statuses?.filter((status: any) => {
      return (
        status.from.includes(subflight.country_from) && status.to.includes(subflight.country_to)
      );
    });
  }

  const filterStatusesByTransit = (statuses: any, subflight: any) => {
    if (subflight.is_transit) {
      return statuses?.filter((status: any) => status.is_transit);
    }

    return statuses;
  }

  const filterStatusesByMode = (statuses: any, subflight: any) => {
    return statuses?.filter((status: any) => {
      return status.ways.includes(subflight.mode_of_transportation);
    });
  }

  const subflightStatusesFilter = (statuses: any, subflight: any) => {
    let statusesData: any = [];
    if (!subflight) {
      statusesData = [];
    } else {
      const statusesByDirection = filterStatusesByDirection(statuses, subflight);

      const statusesByTransit = filterStatusesByTransit(statusesByDirection, subflight);

      statusesData = filterStatusesByMode(statusesByTransit, subflight);
    }

    return statusesData.length > 0 ? statusesData[0].stages : [];
  }

  const getSubflightsStatuses = (subflightStatuses: any, countries: any, transportation: any) => {
    if (!transportation) {
      return [];
    }

    let list: any = [];

    transportation?.subflights?.forEach((subflight: any) => {
      const subflightStatusesResult = subflightStatusesFilter(subflightStatuses, subflight);

      const prepared = subflightStatusesResult?.filter((statusFilter: any) => {
        if (statusFilter.move_to_transportation) {
          return statusFilter;
        }
      })?.map((status: any) => {
        if (!status.country_label) {
          return status;
        } else {
          const prefix =
            countries?.find((country: any) => country.value === subflight[status.country_label!])
              ?.label ?? '';

          return {
            ...status,
            label: prefix + '. ' + status.label,
          };
        }
      });

      list = [...list, ...prepared];
    });
    return list;
  }

  const combineStatuses = () => {
    if (commonConfig?.transportation_statuses) {
      const stack: any = [];
      const filteredData = commonConfig?.transportation_statuses?.filter((status: any) => {
        if (status?.value === config?.status) {
          status.isHighlighted = true;
        } else {
          status.isHighlighted = false;
        }
        if (status?.value !== 'cargo_issues' && status?.value !== 'rejected' && status?.value !== 'tariff_lost' && status?.value !== 'tariff_request') {
          return status;
        }
      });
      const subflightStatuses = getSubflightsStatuses(subflightConfig, commonConfig?.countries, config);

      const readyForLoadingIndex = filteredData.findIndex(
        (s: any) => s.value === 'ready_for_loading'
      );

      filteredData?.forEach((el: any, index: any) => {
        if (index > readyForLoadingIndex) return;
        else stack.push({ ...el, isSubflight: false });
      });


      const correctStatuses = subflightStatuses?.filter((item: any, index: any) => {
        if (item?.value === config?.status) {
          item.id = index;
          return item;
        }
      });
      let indexToHighlight = correctStatuses[correctStatuses.length - 1]?.id || 0;

      subflightStatuses?.forEach((el: any, index: any) => {
        if (index === indexToHighlight) {
          el.isHighlighted = true;
        } else {
          el.isHighlighted = false;
        }
        stack.push({ ...el, isSubflight: true });
      });

      filteredData?.forEach((el: any, index: any) => {
        if (index <= readyForLoadingIndex) return;
        else stack.push({ ...el, isSubflight: false });
      });
      const activeStatusIndex = stack.map((i: any) => i.value).lastIndexOf(config?.status);
      setActiveStatusIndex(activeStatusIndex);
      if (!!stack?.length) {
        updateStatusesStack(stack);
      } else {
        updateStatusesStack(filteredData);
      }
    }
  }

  useEffect(() => {
    if (subflightConfig && commonConfig && config) {
      combineStatuses();
    }
  }, [commonConfig, subflightConfig, config, config?.status]);


  return (
    <DetailsContext.Provider
      value={{
        config,
        setConfig,
        isLastMilesModal,
        setIsLastMilesModal,
        isSuccessModal,
        setIsSuccessModal,
        isPriceOffersModal,
        setIsPriceOffersModal,
        isTableRecieveGoods,
        setIsTableRecieveGoods,
        setIsUploadDocumentsModal,
        isUploadDocumentsModal,
        isPaymentMethodModal,
        setIsPaymentMethodModal,
        handleCloseUniversalModal,
        handleTogglerPriceOffersModal,
        handleTogglerTableRecieveGoodsModal,
        handleTogglerPaymentMethodModal,
        handleTogglerLastMilesModal,
        setIsShowDocumentsHotButtonsOnDetails,
        setIsShowWarehouseHotButtonsOnDetails,
        isShowWarehouseHotButtonsOnDetails,
        isShowDocumentsHotButtonsOnDetails,
        setPaymentMethodConfig,
        paymentMethodConfig,
        isPaymentMethodChoosen,
        setIsLoading,
        isLoading,
        commonConfig,
        subflightConfig,
        currentStatus,
        isLastStepSuccessModal,
        setIsLastStepSuccessModal,
        isDocumentsModal,
        setIsDocumentsModal,
        isExpandModalOpen,
        setIsExpandModalOpen,
        isCompactMode,
        setIsCompactMode,
        isUpproveTariffModal,
        setIsUpproveTariffModal,
        isUpproveTariff,
        setIsUpproveTariff,
        updateConfig,
        updateStatusesStack,
        statusesStack,
        warehousesConfigData,
        activeStatusIndexData,
      }}
    >
      {children}
    </DetailsContext.Provider>
  );
};

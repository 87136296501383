const API_URL = process.env.REACT_APP_URL;

const getFileLink = (path: string | undefined | null): string => {
  if (!path) return '';
  return API_URL + path;
};

const getFileLinkChat = (path: string | undefined | null): string => {
  if (!path) return '';
  return API_URL + '/chat' + path;
};

export {
  getFileLink,
  getFileLinkChat
}
import React, { useState, useRef, useEffect } from 'react';
import {
  AuthWrapper,
  InputErrorMessage,
  LayoutSmall,
  PrimaryLabel,
  TitleH2,
  InputWrapper, InputWarningMessage,
} from '../../../components/styles';
import { AuthVariantLine, AuthWithButton, RegistrationForm, RegistrationLabelForTerms } from '../styles';
import InputBlock from '../../../components/ui/InputBlock';
import Checkbox from '../../../components/ui/Checkbox';
import GoogleIcon from '../../../assets/images/googleIcon.svg';
import { registerClient, handleCheckRegisteredUser } from '../../../utils/axios/post';
import {
  RegistrationErrors,
  RegistrationWarnings,
} from '../../../constants/errors';
import Button from '../../../components/ui/Button';
import { Link, useNavigate } from 'react-router-dom';
import { phoneNormalize, phoneValidation } from '../../../utils/validation';
import FullLogoPng from '../../../assets/png/FullLogoPng.png';
import { CircularProgress } from '@mui/material';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

const RegistrationPage = () => {
  const navigate = useNavigate();
  const googleAuthUrl = process.env.REACT_APP_GOOGLE_AUTH_URL;
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [registrationData, setRegistrationData] = useState({
    email: '',
    fullName: '',
    phone: '',
    password: '',
  });

  const [validationInfo, setValidationInfo] = useState({
    email: '',
    fullName: '',
    phone: '',
    password: '',
    emailError: '',
    acceptRights: '',
  });

  const inputPhone: any = useRef();

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    setRegistrationData(prevData => ({
      ...prevData,
      [id]: value,
    }));
    setValidationInfo(prevValidation => ({
      ...prevValidation,
      [id]: '',
    }));
    if (id === 'email') {
      setValidationInfo(prevValidation => ({
        ...prevValidation,
        emailError: '',
      }));
    }
  };

  useEffect(() => {
    setValidationInfo(prevData => ({
      ...prevData,
      acceptRights: '',
    }));
  }, [checked]);

  const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    setRegistrationData(prevData => ({
      ...prevData,
      phone:
        prevData?.phone?.length > value?.length
          ? value
          : phoneValidation(value),
    }));
    if (phoneNormalize(value).length === 13) {
      setValidationInfo(prevInfo => ({
        ...prevInfo,
        phone: '',
      }));
    }
  };

  const validateForm = async () => {
    for (let key in registrationData) {
      if (
        registrationData.hasOwnProperty(key) &&
        !registrationData[key as keyof typeof registrationData]
      ) {
        setValidationInfo(prevData => ({
          ...prevData,
          [key]: RegistrationWarnings[key as keyof typeof registrationData],
        }));
      }
      if (!registrationData.email.includes('@')) {
        setValidationInfo(prevData => ({
          ...prevData,
          emailError: RegistrationErrors.email,
        }));
      }
      if (registrationData?.password?.length < 6) {
        setValidationInfo(prevData => ({
          ...prevData,
          password: RegistrationWarnings.password,
        }));
      }
      if (registrationData.fullName?.length > 128) {
        setValidationInfo(prevData => ({
          ...prevData,
          fullName: RegistrationWarnings.fullNameLength,
        }));
      }
      if (registrationData?.phone?.length < 12) {
        setValidationInfo(prevData => ({
          ...prevData,
          phone: RegistrationWarnings.phone,
        }));
      }
    }
    // if (!checked) {
    //   setValidationInfo(prevData => ({
    //     ...prevData,
    //     acceptRights: RegistrationErrors.acceptRights,
    //   }));
    // }
  };

  const handleRegister = async (e: any) => {
    e.preventDefault();
    await validateForm();
    // if (!checked) {
    //   return;
    // }
    if (Object.values(registrationData).includes('')) {
      return;
    }
    const { fullName, email, password, phone } = registrationData;
    setIsLoading(true);

    const postBody = {
      full_name: fullName,
      email,
      password,
      phone: phoneNormalize(phone),
    };

    const registeredUserData = await handleCheckRegisteredUser(postBody?.email);
    if (registeredUserData?.success) {
      if (!registeredUserData?.data?.is_registered) {
        navigate('/existing-client', { state: { email } });
      } else {
        const result = await registerClient(postBody);
        if (result?.success) {
          navigate('/register/success', { state: { email } });
        } else {
          setIsLoading(false);
          result?.response?.data?.errors.forEach((error: any) => {
            if (
              error.field === 'email' &&
              error.message === 'unique validation failed on email'
            ) {
              setValidationInfo(prevData => ({
                ...prevData,
                emailError: RegistrationErrors.emailDuplicate,
              }));
            }
            if (error.field === 'phone') {
              setValidationInfo(prevData => ({
                ...prevData,
                phone: RegistrationErrors.phone,
              }));
            }
            if (error.field === 'email' && !registrationData.email.includes('@')) {
              setValidationInfo(prevData => ({
                ...prevData,
                email: RegistrationErrors.email,
              }));
            }
          });
        }
      }
    } else {
      if (registeredUserData?.response?.status === 404) {
        const result = await registerClient(postBody);

        if (result?.success) {
          navigate('/register/success', { state: { email } });
        } else {
          setIsLoading(false);
          result?.response?.data?.errors.forEach((error: any) => {
            if (
              error.field === 'email' &&
              error.message === 'unique validation failed on email'
            ) {
              setValidationInfo(prevData => ({
                ...prevData,
                emailError: RegistrationErrors.emailDuplicate,
              }));
            }
            if (error.field === 'phone') {
              setValidationInfo(prevData => ({
                ...prevData,
                phone: RegistrationErrors.phone,
              }));
            }
            if (error.field === 'email' && !registrationData.email.includes('@')) {
              setValidationInfo(prevData => ({
                ...prevData,
                email: RegistrationErrors.email,
              }));
            }
          });
        }
      }
    }
  };

  return (
    <AuthWrapper>
      <LayoutSmall>
        <img
          src={FullLogoPng}
          style={{
            width: '15.813rem',
            height: '3.5rem',
          }}
          alt={'DiFreight'}
        />
        <TitleH2>Реєстрація</TitleH2>
        <RegistrationForm onSubmit={handleRegister}>
          <InputBlock
            id="fullName"
            placeholder="Введіть ваше повне імʼя"
            type="text"
            onChange={handleChange}
            text="ПІБ користувача"
            warningMessage={validationInfo.fullName}
          />
          {/*<InputBlock*/}
          {/*  id="phone"*/}
          {/*  placeholder="+ 38 (093) 000 00 00"*/}
          {/*  type="text"*/}
          {/*  onChange={handleChangePhone}*/}
          {/*  text="Телефон"*/}
          {/*  value={registrationData.phone}*/}
          {/*  warningMessage={validationInfo.phone}*/}
          {/*  ref={inputPhone}*/}
          {/*/>*/}

          <div>
            <InputWrapper>
              <PrimaryLabel htmlFor={'phone'}>
                Телефон
              </PrimaryLabel>
              <PhoneInput
                defaultCountry="ua"
                value={registrationData.phone}
                onChange={(phone) => {
                  setRegistrationData(prevData => ({
                    ...prevData,
                    phone,
                  }));
                  setValidationInfo(prevValidation => ({
                    ...prevValidation,
                    phone: '',
                  }));
                }}
              />
            </InputWrapper>
            {validationInfo.phone && (
              <InputWarningMessage>{validationInfo.phone}</InputWarningMessage>
            )}
          </div>
          <InputBlock
            id="email"
            placeholder="Вкажіть вашу електронну скриньку"
            type="text"
            onChange={handleChange}
            text="E-mail"
            warningMessage={validationInfo.email}
            errorMessage={validationInfo.emailError}
          />
          <InputBlock
            id="password"
            placeholder="Придумайте пароль"
            type="password"
            onChange={handleChange}
            text="Пароль"
            warningMessage={validationInfo.password}
          />
          {/*<div>*/}
          {/*  <Checkbox*/}
          {/*    checked={checked}*/}
          {/*    id="accept-checkbox"*/}
          {/*    onChange={() => setChecked(!checked)}*/}
          {/*    label="Приймаю умови"*/}
          {/*    labelForLink="публічної оферти."*/}
          {/*    link="https://difreight.com/en/terms-and-conditions"*/}
          {/*  />*/}
          {/*  {validationInfo.acceptRights && (*/}
          {/*    <InputErrorMessage>*/}
          {/*      {validationInfo.acceptRights}*/}
          {/*    </InputErrorMessage>*/}
          {/*  )}*/}
          {/*</div>*/}

          <AuthVariantLine>
            <span>або</span>
          </AuthVariantLine>

          <Link to={`${googleAuthUrl}`}>
            <AuthWithButton>
              <img src={GoogleIcon} alt="google-icon" />
            </AuthWithButton>
          </Link>

          <Button
            text={
              isLoading ? (
                <CircularProgress color="inherit" size={'1.5rem'} />
              ) : (
                'Зареєструватись'
              )
            }
            type="submit"
            onClick={() => {}}
          />

          <RegistrationLabelForTerms>
            Натискаючи «Зареєструватись», ви погоджуєтеся з
            <a href='https://diffreight.com/en/terms-and-conditions' target={'_blank'}> Умовами надання послуг </a>
            та підтверджуєте, що <a href='https://diffreight.com/en/privacy' target={'_blank'}> Політика конфіденційності </a> нашої компанії застосовується до вас.
          </RegistrationLabelForTerms>
        </RegistrationForm>
      </LayoutSmall>
    </AuthWrapper>
  );
};

export default RegistrationPage;

import React from 'react';
import { TableBodyWrapper } from './styles';
import TableRow from './TableRow';

const TableBody = ({ data, isShowActive }: any) => {
  return (
    <TableBodyWrapper>
      {data?.map((config: any) => {
        return <TableRow data={config} key={config?.id} isShowActive={isShowActive} />;
      })}
    </TableBodyWrapper>
  );
};

export default TableBody;

import { SVGProps } from 'react';
import { Colors } from '../../components/colors';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='48'
    height='48'
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M28 4.53906V12.8001C28 13.9202 28 14.4803 28.218 14.9081C28.4097 15.2844 28.7157 15.5904 29.092 15.7822C29.5198 16.0001 30.0799 16.0001 31.2 16.0001H39.4611M32 26H16M32 34H16M20 18H16M28 4H17.6C14.2397 4 12.5595 4 11.2761 4.65396C10.1471 5.2292 9.2292 6.14708 8.65396 7.27606C8 8.55953 8 10.2397 8 13.6V34.4C8 37.7603 8 39.4405 8.65396 40.7239C9.2292 41.8529 10.1471 42.7708 11.2761 43.346C12.5595 44 14.2397 44 17.6 44H30.4C33.7603 44 35.4405 44 36.7239 43.346C37.8529 42.7708 38.7708 41.8529 39.346 40.7239C40 39.4405 40 37.7603 40 34.4V16L28 4Z'
      stroke={props?.color ? props?.color : Colors.white} strokeLinecap='round' strokeLinejoin='round' />
  </svg>

);
export default SvgComponent;

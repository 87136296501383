import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ?? 24}
    height={props.height ?? 24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      stroke="#3A3875"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m18 10-4-4M2.5 21.5l3.384-.376c.414-.046.62-.069.814-.131a2 2 0 0 0 .485-.233c.17-.112.317-.26.61-.553L21 7a2.828 2.828 0 1 0-4-4L3.794 16.207c-.294.294-.442.44-.553.61a2 2 0 0 0-.233.485c-.063.194-.086.4-.132.814L2.5 21.5ZM13 22h9"
    />
  </svg>
);
export default SvgComponent;

import styled from 'styled-components';
import { Colors } from '../colors';

interface StepProps {
  active: string;
}

const WizardWrapper = styled.div`
  width: 100%;
  height: 100vh;
`;

const StepNavigationWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  gap: 0 3.75rem;
  background: ${Colors.white};
  box-shadow: 0 0 33px 0 #00000017;
  padding: 1rem;
  border-radius: 0.25rem;
  margin-top: 2.5rem;
  @media (max-width: 1670px) {
    gap: 0 1.875rem;
    padding: 0.75rem;
  }
  @media (max-width: 1250px) {
    gap: 0 1rem;
    margin-top: 2rem;
  }
  @media (max-width: 1024px) {
    margin-top: 1.25rem;
    flex-direction: column;
    gap: 1rem 0;
    background: transparent;
    box-shadow: none;
    padding: 0;
  }
  @media (max-width: 424px) {
    margin-top: 1.875rem;
  }
`;

const StepWrapper = styled.div<StepProps>`
  background: ${props =>
    props.active === 'true' ? `${Colors.primary80}` : `${Colors.white}`};
  color: ${props =>
    props.active === 'true' ? `${Colors.white}` : `${Colors.primary}`};
  max-width: 315px;
  width: 100%;
  height: 8.875rem;
  border-radius: 0.25rem;
  padding: 0.75rem 1rem 0.75rem 1rem;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: ${props =>
      props.active === 'true' ? `${Colors.primary80}` : `${Colors.white90}`};
    transition: 0.2s;
  }

  @media (max-width: 1440px) {
    padding: 0.75rem;
  }
  @media (max-width: 1024px) {
    max-width: 100%;
    height: auto;
    box-shadow: 0 0 33px 0 #00000017;
    padding: 1rem;
  }
`;

const StepHeader = styled.div<StepProps>`
  display: flex;
  align-items: center;
  gap: 0 0.75rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid
    ${props =>
      props.active === 'true' ? `${Colors.white}` : `${Colors.periwinkle}`};
  @media (max-width: 1170px) {
    gap: 0 0.5rem;
  }
  @media (max-width: 1024px) {
    position: relative;
  }

  p {
    border: 1px solid
      ${props =>
        props.active === 'true' ? `${Colors.white}` : `${Colors.primary}`};
    //padding: 0.625rem 15px;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 2.5rem;
    font-size: 1.125rem;
    line-height: 1.125rem;
    height: 2.5rem;
    font-weight: 500;
    @media (max-width: 768px) {
      font-size: 1rem;
      line-height: 1rem;
      width: 1.5rem;
      height: 1.5rem;
      min-width: auto;
    }
  }
  > .completed-icon {
    path {
      stroke: ${props =>
        props.active === 'true' ? `${Colors.white}` : `${Colors.primary}`};
    }
  }

  h4 {
    font-size: 1.125rem;
    line-height: 1.125rem;
    color: ${props =>
      props.active === 'true' ? `${Colors.white}` : `${Colors.primary}`};
    text-transform: uppercase;
    font-weight: 500;
    @media (max-width: 1670px) {
      font-size: 1rem;
      line-height: 1rem;
    }
    @media (max-width: 1170px) {
      font-size: 0.938rem;
      line-height: 0.938rem;
    }
    @media (max-width: 768px) {
      font-size: 1rem;
      line-height: 1rem;
    }
  }

  svg {
    @media (max-width: 1024px) {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
`;

const StepDetailsInfo = styled.div`
  display: flex;
  width: 100%;
  gap: 0 0.75rem;
  padding-top: 0.75rem;

  p {
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: ${Colors.primary80};
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 1px 0;
  }

  h4 {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 700;
    color: ${Colors.primary};
  }

  h5 {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.313rem;
    color: ${Colors.primary};
  }
`;

const StepDetailsTabletLabel = styled.p`
  padding-top: 1rem;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: ${Colors.primary60};
`;

const NavigationButtonWrapper = styled.div`
  padding: 0.75rem 1rem;
  width: 100%;
  max-width: 19.75rem;
  @media (max-width: 1676px) {
    max-width: 15.375rem;
    padding: 0.75rem;
    margin: 0 0 0 auto;
  }
  @media (max-width: 1024px) {
    background: ${Colors.white};
    padding: 1rem;
    max-width: 100%;
  }
`;

const CheckmarkIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;

  @media (max-width: 768px) {
    position: absolute;
    right: 0;
    top: 0;
  }
`;

const RadioGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem 0;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid ${Colors.aqua};
`;

const RadioGroupWrapperAnimation = styled.div`
  padding: 1.5rem 0;
  border-bottom: 1px solid ${Colors.aqua};
  flex-direction: column;
  display: none;
  max-height: 0;
  overflow: hidden;
  transition:
    display 0.5s ease-out,
    max-height 0.5s ease-out;

  &.visible {
    display: flex;
    max-height: fit-content;
  }

  &.tariff {
    padding: 1rem 0;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  gap: 0 1.25rem;
  @media (max-width: 370px) {
    gap: 0 0.75rem;
  }
`;

const RadioGroupCompressed = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;

  &.inTariff {
    padding-bottom: 1rem;
    border-bottom: 1px solid ${Colors.aqua};

    @media (max-width: 767px) {
      flex-direction: row !important;

      div {
        max-width: 6.563rem;

        &:nth-child(2) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: none;
        }

        &:nth-child(3) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: none;
        }
      }
    }
  }

  &.mobileTariff {
    @media (max-width: 767px) {
      flex-direction: row !important;
      padding-bottom: 1rem;

      div {
        max-width: 6.563rem;

        &:nth-child(2) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: none;
        }

        &:nth-child(3) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: none;
        }
      }
    }
  }

  &.outTariff {
    padding-top: 1rem;

    @media (max-width: 767px) {
      flex-direction: row !important;

      div {
        max-width: 6.563rem;

        &:nth-child(2) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: none;
        }

        &:nth-child(3) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: none;
        }
      }
    }
  }

  &.packageType {
    @media (max-width: 767px) {
      flex-direction: row !important;

      div {
        &:nth-child(1) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        &:nth-child(2) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: none;
        }
      }
    }
  }

  &.cargoData {
    padding-top: 0.375rem;
    @media (max-width: 767px) {
      flex-direction: row !important;
    }

    > div {
      border-top: 1px solid ${Colors.periwinkle};
    }
  }

  &.cargo {
    div {
      @media (max-width: 767px) {
        &:first-child {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          border-bottom: none;
          border-top-right-radius: 0.25rem;
        }

        &:last-child {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-left-radius: 0.25rem;
          border-left: 1px solid ${Colors.periwinkle};
        }
      }
    }
  }

  &.packingList {
    @media (max-width: 1023px) {
      flex-direction: column !important;
      > div {
        border-radius: 0.25rem;

        &:first-child {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0.25rem;
          border-bottom-left-radius: 0;
          border-bottom: none;
        }

        &:last-child {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-left-radius: 0.25rem;
          border-left: 1px solid ${Colors.periwinkle} !important;
        }
      }
    }
  }

  &.cargoDataSolo {
    padding-top: 0.375rem;

    > div {
      border-top: 1px solid ${Colors.periwinkle};
      border-left: 1px solid ${Colors.periwinkle} !important;
      border-radius: 0.25rem !important;
    }
  }

  &.small {
    max-width: 10.188rem;
    max-height: 1.813rem;

    > div {
      border-color: ${Colors.white};
      font-size: 1rem;
      line-height: 1rem;
      max-height: 1.813rem;
    }
  }

  div {
    max-width: 100%;
    height: 3rem;

    @media (min-width: 768px) {
      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:nth-last-child(2) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
      }

      span {
        font-weight: 400;
        line-height: 1rem;
      }
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    div {
      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:nth-last-child(2) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
      }
    }
  }
`;

const StepFormWrapper = styled.div`
  .transportationStep {
    max-width: 41.75rem;
    width: 100%;
    background: ${Colors.white};
    padding: 1rem;
    border-radius: 0 0 0.25rem 0.25rem;
    border-top: 1px solid ${Colors.aqua};
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    button {
      margin-top: 1.75rem;
    }

    @media (max-width: 1023px) {
      padding: 0;
    }
  }
`;

const Content = styled.div`
  background: ${Colors.white};
  margin: 0 auto;
  padding: 0.75rem;
  max-width: 42.5rem;
  border-radius: 0.25rem;
  max-height: 100vh;
  overflow-y: auto;

  &.cargoTable-content {
    overflow-x: hidden;
    overflow-y: scroll !important;
  }
  &.ios {
    @media (max-width: 1200px) {
      padding-bottom: 9rem;
    }
    @media (max-width: 1200px) and (min-height: 1000px) {
      padding-bottom: 12rem;
    }
  }

  &.content-uploaded-file {
    overflow-y: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    max-width: 27rem;
    width: 100%;
    padding-bottom: 0;
    min-height: 165px;
    > #send-file {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      transform: none;
    }
    @media (max-width: 1024px) {
      max-height: 165px;
    }
  }

  &#ios_departure {
    @media (max-width: 1024px) {
      padding-bottom: 6rem;
    }
  }

  @media (max-width: 1024px) {
    max-height: 100vh;
    height: 100%;
    transform: translate(-50%, 0);
    position: absolute;
    left: 50%;
    top: 0;
    width: 100%;
    max-width: 100%;
  }
  &.chat-content {
    @media (max-width: 767px) {
      height: 100%;
      position: relative;
      overflow-y: hidden;
      padding: 0.75rem 0;
      //padding-bottom: 4.375rem;
      max-height: 70vh;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &:focus {
      border: none;
      outline: none;
    }
  }

  @media (max-width: 767px) {
    max-width: 100%;
  }
`;
const CloseBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SignContractWrapper = styled.div`
  max-width: 432px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 1.5rem auto 0;
  &.error-field {
    border: 1px solid ${Colors.deepRed};
  }
  a {
    width: 100%;
  }
  @media (max-width: 480px) {
    max-width: 316px;
  }
`;

const FullWidthStepFormWrapperButtons = styled.div`
  width: 100%;
  background: ${Colors.white};
  border-radius: 0 0 0.25rem 0.25rem;
  display: flex;
  flex-direction: column;
  margin-top: 1.75rem;
`;

const FullWidthStepFormWrapper = styled.div`
  width: 100%;
  background: ${Colors.white};
  border-radius: 0 0 0.25rem 0.25rem;
  display: flex;
  flex-direction: column;
  &.no-task {
    padding-top: 1.5rem;
    border-top: 1px solid ${Colors.aqua};
  }

  div {
    //border-top: 0;
  }

  > button {
  }

  @media (min-width: 1024px) {
    //padding: 0 1rem 1rem 1rem;
  }
  @media (max-width: 1023px) {
    padding-bottom: 1rem;
  }
`;

const CargoWrapper = styled.div`
  @media (min-width: 768px) and (max-width: 1023px) {
    display: flex;
    flex-direction: column;
  }
`;

const CargoDataStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${Colors.aqua};
  background-color: ${Colors.white};
  border-radius: 0.25rem;
  @media (min-width: 1024px) {
    padding: 1rem;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    border-top: none;
    gap: 1rem;
    overflow-y: scroll;
  }

  @media (max-width: 767px) {
    border-top: none;
  }
`;

const CargoTableFullWrapper = styled.div`
  margin-bottom: 4.063rem;
`;

const CargoTableSelectWrapper = styled.div`
  max-width: 26rem;
  width: 100%;
  margin: 1.5rem 0;
  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;

const RequiredLabel = styled.span`
  color: ${Colors.warning};
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
`;

const RadioGroupCompressedSpan = styled.span`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-right: 0.5rem;
  max-width: 8.313rem;
  width: 100%;
  color: ${Colors.warning};
`;

const FileDetailsCargoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0 0.5rem;
  color: ${Colors.primary};
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  border: 1px solid ${Colors.periwinkle} !important;
  cursor: pointer;
  position: relative;

  div {
    display: flex;
    align-items: center;
    gap: 0 0.5rem;
    width: 100%;
    a {
      display: flex;
      align-items: center;
      gap: 0 0.5rem;
      width: 100%;
    }
  }

  .icon-with-hover {
    transition: 0.2s;

    path {
      fill: ${Colors.primary60};
    }
  }

  a {
    color: ${Colors.primary};
  }

  input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
  }

  > div.attached_documents {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    overflow: hidden;
    > p {
      font-size: 0.875rem;
      line-height: 1.313rem;
      color: ${Colors.warning};
    }
  }
`;

const FileDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem 0;
  margin: 0.375rem 0 0.125rem 0;
`;

const ModalSuccessPage = styled.div`
  box-shadow: 0 0 33px 0 #00000017;
  background: ${Colors.white};
  border-radius: 0.25rem;
  padding: 1rem;
  max-width: 41.75rem;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 670px) {
    width: 90%;
  }
  @media (max-width: 480px) {
    width: 100%;
    max-width: 19.125rem;
  }

  h2 {
    @media (max-width: 480px) {
      font-size: 2rem;
      line-height: 3rem;
    }
  }

  > div {
    @media (max-width: 480px) {
      margin-top: 0;
    }
  }
`;

const ErrorLabel = styled.p`
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: ${Colors.deepRed};
  margin-top: -0.625rem;
`;

export {
  StepNavigationWrapper,
  WizardWrapper,
  StepWrapper,
  StepHeader,
  NavigationButtonWrapper,
  StepDetailsTabletLabel,
  CheckmarkIcon,
  StepDetailsInfo,
  RadioGroupWrapper,
  RadioGroup,
  RadioGroupWrapperAnimation,
  StepFormWrapper,
  RequiredLabel,
  RadioGroupCompressed,
  RadioGroupCompressedSpan,
  FullWidthStepFormWrapper,
  FileDetailsCargoWrapper,
  FileDetailsWrapper,
  Content,
  CloseBtnWrapper,
  CargoDataStepContainer,
  CargoWrapper,
  CargoTableSelectWrapper,
  CargoTableFullWrapper,
  ModalSuccessPage,
  FullWidthStepFormWrapperButtons,
  ErrorLabel,
  SignContractWrapper,
};

import * as React from 'react';
import { SVGProps } from 'react';
import { Colors } from '../../components/colors';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ?? 16}
    height={props.height ?? 16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M9.3507 8.3573L3.7507 13.8573C3.65718 13.9493 3.53125 14.0008 3.40007 14.0008C3.26889 14.0008 3.14297 13.9493 3.04945 13.8573L0.64945 11.5004C0.602625 11.4544 0.565328 11.3996 0.539689 11.3391C0.514049 11.2786 0.500569 11.2137 0.500018 11.1481C0.499466 11.0824 0.511855 11.0173 0.536475 10.9564C0.561096 10.8955 0.597467 10.8401 0.643512 10.7932C0.689557 10.7464 0.744374 10.7091 0.804832 10.6835C0.865291 10.6578 0.930208 10.6444 0.995876 10.6438C1.06154 10.6433 1.12668 10.6556 1.18756 10.6803C1.24844 10.7049 1.30388 10.7413 1.3507 10.7873L3.40007 12.7998L8.65007 7.64355C8.74472 7.55055 8.87244 7.49897 9.00512 7.50014C9.07082 7.50072 9.13576 7.51424 9.19623 7.53992C9.2567 7.56559 9.31153 7.60293 9.35757 7.6498C9.40362 7.69666 9.43998 7.75214 9.46459 7.81306C9.48919 7.87397 9.50156 7.93914 9.50098 8.00484C9.5004 8.07054 9.48688 8.13548 9.4612 8.19595C9.43553 8.25643 9.39819 8.31125 9.35132 8.3573H9.3507ZM15.3569 7.6498C15.3109 7.6029 15.2561 7.56553 15.1956 7.53983C15.1352 7.51413 15.0702 7.5006 15.0045 7.50002C14.9388 7.49944 14.8736 7.51182 14.8127 7.53644C14.7518 7.56107 14.6963 7.59747 14.6494 7.64355L9.39945 12.7998L8.22257 11.6435C8.12792 11.5506 8.00024 11.4991 7.86762 11.5004C7.735 11.5016 7.6083 11.5555 7.51539 11.6501C7.42248 11.7448 7.37097 11.8724 7.3722 12.0051C7.37344 12.1377 7.4273 12.2644 7.52195 12.3573L9.04882 13.8573C9.14234 13.9493 9.26827 14.0008 9.39945 14.0008C9.53063 14.0008 9.65655 13.9493 9.75007 13.8573L15.3501 8.3573C15.397 8.31131 15.4344 8.25653 15.4602 8.19607C15.4859 8.13562 15.4995 8.07068 15.5002 8.00497C15.5008 7.93926 15.4885 7.87407 15.4639 7.81313C15.4393 7.75218 15.403 7.69668 15.3569 7.6498Z"
      fill={Colors.white80}
    />
  </svg>
);
export default SvgComponent;

import { FC, useState } from 'react';
import {
  BlueButton,
  ButtonWrapper,
  Direction,
  DirectionButtonWrapper,
  DirectionWrapper,
  BlueButtonWrapper,
  SvgWrapper,
} from './styles';
import Ship from '../../assets/ReactSvg/Ship';
import ExpandDownSvg from '../../assets/ReactSvg/ShippingIcon/ExpandDownSvg';
import OrderDetails from './OrderDetails';
import { useDesktop, useMobile } from '../../constants/breakpoints';
import ExpandSvg from '../../assets/ReactSvg/ShippingIcon/ExpandSvg';
import { CircularProgress, Modal } from '@mui/material';
import { useDetailsContext } from '../Threads/DetailsContext';
import Bus from '../../assets/ReactSvg/Bus';
import Plane from '../../assets/ReactSvg/Plane';
import { getCountryLabel } from './utils';
import Button from '../ui/Button';
import ExpandCloseSvg from '../../assets/ReactSvg/ShippingIcon/ExpandCloseSvg';

interface IOrderDirectionsProps {}

const OrderDirections: FC<IOrderDirectionsProps> = ({}) => {
  const {
    config,
    commonConfig,
    handleTogglerLastMilesModal,
    isPaymentMethodChoosen,
  } = useDetailsContext();
  const isMobile = useMobile();
  const isDesktop = useDesktop();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalToggler = () => {
    setIsModalOpen(!isModalOpen);
  };

  const renderTitleDirection = () => {
    return `${getCountryLabel(commonConfig?.countries, config?.country_from?.country_from)} - ${getCountryLabel(commonConfig?.countries, config?.country_to?.country_to)}`;
  };

  if (isMobile && isModalOpen) {
    return (
      <Modal open={isModalOpen}>
        <DirectionWrapper>
          <BlueButton className={'modal'} open={isModalOpen}>
            <div>
              {config?.mode_of_transportation === 'land' ? (
                <Bus />
              ) : config?.mode_of_transportation === 'air' ? (
                <Plane />
              ) : config?.mode_of_transportation === 'sea' ? (
                <Ship />
              ) : (
                <></>
              )}
              <Direction>{renderTitleDirection()}</Direction>
            </div>
            {isMobile ? (
              <SvgWrapper>
                {
                  !isModalOpen ? (
                    <ExpandSvg onClick={handleModalToggler} />
                  ) : (
                    <ExpandCloseSvg
                      style={{ cursor: 'pointer' }}
                      onClick={handleModalToggler}
                    />
                  )
                }

              </SvgWrapper>
            ) : (
              <div
                style={{
                  transform: isModalOpen ? 'rotate(3.142rad)' : 'rotate(0)',
                  transition: '0.5s',
                }}
              >
                <SvgWrapper>
                  <ExpandDownSvg onClick={handleModalToggler} />
                </SvgWrapper>
              </div>
            )}
          </BlueButton>
          {isModalOpen && <OrderDetails />}
        </DirectionWrapper>
      </Modal>
    );
  }

  return (
    <DirectionWrapper>
      <BlueButton open={isModalOpen}>
        <BlueButtonWrapper>
          <div>
            {config?.mode_of_transportation === 'land' ? (
              <Bus />
            ) : config?.mode_of_transportation === 'air' ? (
              <Plane />
            ) : config?.mode_of_transportation === 'sea' ? (
              <Ship />
            ) : (
              <></>
            )}
            <Direction>{renderTitleDirection()}</Direction>
          </div>
          <DirectionButtonWrapper>
            {isPaymentMethodChoosen && !config?.last_mile && !isMobile && (
              <ButtonWrapper>
                <Button
                  text={
                    isDesktop
                      ? 'Введіть дані останньої милі'
                      : 'Введіть останню милю'
                  }
                  type={'color'}
                  onClick={() => handleTogglerLastMilesModal({ show: true })}
                />
              </ButtonWrapper>
            )}
            {isMobile ? (
              <SvgWrapper>
                <ExpandSvg
                  onClick={handleModalToggler}
                  width={'1.5rem'}
                  height={'1.5rem'}
                />
              </SvgWrapper>
            ) : (
              <div
                onClick={handleModalToggler}
                style={{
                  transform: isModalOpen ? 'rotate(3.142rad)' : 'rotate(0)',
                  transition: '0.5s',
                }}
              >
                <SvgWrapper>
                  <ExpandDownSvg width={'1.5rem'} height={'1.5rem'} />
                </SvgWrapper>
              </div>
            )}
          </DirectionButtonWrapper>
        </BlueButtonWrapper>
        {isPaymentMethodChoosen && !config?.last_mile && isMobile && (
          <ButtonWrapper>
            <Button
              text={
                isDesktop
                  ? 'Введіть дані останньої милі'
                  : 'Введіть останню милю'
              }
              type={'color'}
              onClick={() => handleTogglerLastMilesModal({ show: true })}
            />
          </ButtonWrapper>
        )}
      </BlueButton>
      {isModalOpen && <OrderDetails />}
    </DirectionWrapper>
  );
};

export default OrderDirections;

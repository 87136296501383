import React, {
  createContext,
  ReactNode,
  useContext,
  useLayoutEffect,
  useState,
} from 'react';
import { getThreadsList } from '../../utils/axios/chat';
import { debounce } from 'lodash';

interface ChatContextProps {
  currentChat: string;
  updateCurrentChat: (name: string) => void;
  currentChatID: string;
  updateCurrentChatID: (uid: string, entity_id: string) => void;
  messages: any;
  updateCurrentChatMessages: (messagesArray: any) => void;
  threadsList: any;
  updateThreadsList: (threads: any) => void;
  currentChatUsers: any;
  updateCurrentChatUsers: (usersArray: any) => void;
  isLoading?: boolean;
  updateIsLoading?: (value: boolean) => void;
  fetchThreads: () => void;
  chatID: string;
}

const ChatContext = createContext<ChatContextProps | null>(null);

export const useChatContext = (): ChatContextProps => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useFormContext must be used within a FormProvider');
  }
  return context;
};

interface FormProviderProps {
  children: ReactNode;
}

export const ChatProvider: React.FC<FormProviderProps> = ({ children }) => {
  const [currentChat, setCurrentChat] = useState('');
  const [currentChatID, setCurrentChatID] = useState('');
  const [chatID, setChatID] = useState('');
  const [messages, setMessages] = useState([]);
  const [threadsList, setThreadsList] = useState([]);
  const [currentChatUsers, setCurrentChatUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const updateCurrentChatUsers = (usersArray: any) => {
    setCurrentChatUsers(usersArray);
  };

  const updateIsLoading = (value: boolean) => {
    setIsLoading(value);
  };

  const fetchThreads = async () => {
    const result = await getThreadsList();
    if (result.success) {
      updateThreadsList(result?.threads);
    }
  };

  const debouncedFetch = debounce(fetchThreads, 300);

  useLayoutEffect(() => {
    debouncedFetch();
    return () => {
      debouncedFetch.cancel();
    };
  }, []);

  const updateThreadsList = (threads: any) => {
    setThreadsList(threads);
    // @ts-ignore
    updateIsLoading(false);
  };

  const updateCurrentChat = (name: string) => {
    setCurrentChat(name);
  };

  const updateCurrentChatID = (uid: string, entity_id: string) => {
    setCurrentChatID(uid);
    setChatID(entity_id);
  };

  const updateCurrentChatMessages = (messagesArray: any) => {
    setMessages(messagesArray);
  };

  return (
    <ChatContext.Provider
      value={{
        currentChat,
        updateCurrentChat,
        messages,
        updateCurrentChatMessages,
        currentChatID,
        updateCurrentChatID,
        threadsList,
        updateThreadsList,
        currentChatUsers,
        updateCurrentChatUsers,
        isLoading,
        updateIsLoading,
        fetchThreads,
        chatID,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

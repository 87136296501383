import styled from 'styled-components';
import { Colors } from '../colors';

export const calendarStyles = {
  '& .MuiPickersCalendarHeader-root': {
    backgroundColor: Colors.primary80,
    color: Colors.white,
    maxHeight: '3.5rem',
    minHeight: '3.5rem',
    borderRadius: '0.375rem 0.375rem 0 0',
    margin: 0,
  },
  '& .MuiPickersCalendarHeader-label': {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '1.125rem',
  },
  '& .MuiPickersCalendarHeader-label::first-letter': {
    textTransform: 'uppercase',
  },
  '& .MuiDayCalendar-root': {
    background: Colors.white,
    padding: '0.5rem 0 0 0',
  },
  '& .MuiYearCalendar-root': {
    background: Colors.white,
  },
  '&.MuiDateCalendar-root': {
    height: 'auto',
    maxHeight: 'fit-content !important',
    marginBottom: '1rem',
  },
  '& .MuiDayCalendar-slideTransition': {
    minHeight: '200px',
  },
  '& .MuiPickersCalendarHeader-switchViewIcon': {
    display: 'none',
  },
  '& .MuiSvgIcon-fontSizeInherit': {
    color: Colors.white,
    width: '1.5rem',
    height: '1.5rem',
  },
  '& .MuiButtonBase-root, .MuiDayCalendar-weekDayLabel': {
    fontFamily: 'inherit',
    fontSize: '0.875rem',
    fontWeight: '600',
    lineHeight: '1.313rem',
    color: Colors.primary80,
    width: '38px',
  },
  '& .MuiPickersDay-root.Mui-selected': {
    background: `${Colors.primary80} !important`,
    color: Colors.white,
  },
  '& .MuiPickersDay-root.MuiPickersDay-today': {
    background: '#DFDFE8',
    border: 'none',
  },
  '& .MuiPickersDay-dayOutsideMonth': {
    color: Colors.periwinkle,
  },
  '& .MuiDayCalendar-weekDayLabel': {
    color: Colors.primary60,
  },
};

interface CalendarWrapperProps {
  open: boolean;
  compact?: boolean;
}

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
  background: ${Colors.white};
  padding: 0 1rem 1rem 1rem;
`;

export const CalendarDateWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const CalendarDate = styled.span`
  width: 114px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: ${Colors.primary80};
`;

export const Image = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;

export const ImageChevron = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  right: 0.75rem;
`;

export const Title = styled.span`
  color: ${Colors.primary};
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.313rem;
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 211px;
  padding: 0.75rem 1rem 0.75rem 1rem;
  gap: 0.75rem;
  border-radius: 0.25rem;
  border: 1px solid ${Colors.periwinkle};
`;

export const DateContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const CalendarWrapper = styled.div<CalendarWrapperProps>`
  position: absolute;
  display: ${props => (props.open ? 'block' : 'none')};
  top: ${props => (props.compact ? '-150px' : '-175%')};
  left: ${props => (props.compact ? '370px' : '25%')};
  z-index: 10;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  -webkit-tap-highlight-color: transparent;
  &.calendar-near {
    left: 250px;
    top: -300px;
  }
  &#car_arrival_at {
    @media ((min-width: 1024px) and (max-width: 1200px)) {
      left: 10rem;
    }
    @media ((min-width: 1200px) and (max-width: 1670px)) { 
      left: 20rem;
    }
    @media ((min-width: 768px) and (max-width: 1023px)) {
      top: ${props => (props.compact ? '-150px' : '-175%')};
      left: 24px;
    }
    @media (max-width: 767px) {
      width: fit-content;
      right: 0 !important;
      left: auto;
    }
  }

  @media ((min-width: 768px) and (max-width: 1023px)) {
    top: ${props => (props.compact ? '-150px' : '-175%')};
    left: ${props => (props.compact ? '336px' : '25%')};
  }

  @media (max-width: 767px) {
    top: ${props => (props.compact ? '-150px' : '-175%')};
    left: ${props => (props.compact ? '31px' : '25%')};
  }
`;

export const CalendarWithTimeContainer = styled.div`
  display: flex;
  background: ${Colors.white};
  &.car_arrival_at {
    @media (max-width: 767px) {
      flex-direction: column;
    }
    & .MuiPickersCalendarHeader-root {
      border-radius: 0.375rem 0 0 0;
      @media (max-width: 767px) {
        border-radius: 0.375rem 0.375rem 0 0;
      }
    }
  }
`;

export const TimeListWrapper = styled.div`
  overflow-y: auto;
  display: flex;
  gap: 1rem;
  justify-content: center;
  padding: 1rem;
  margin-bottom: 1rem;
  max-height: 300px;
  @media (max-width: 767px) {
    max-height: 200px;
  }
`;

export const TimeListItems = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TimeItem = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.313rem;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Colors.white};
  color: ${Colors.primary80};
  text-align: center;
  &.selected {
    color: ${Colors.white};
    background: ${Colors.primary80};
  }
`;

export const TimeListHeader = styled.div`
  background: ${Colors.primary80};
  color: ${Colors.white};
  height: 3.469rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 0.25rem;
  @media (max-width: 767px) {
    border-top-left-radius: 0.25rem;
  }
`;

export const TimeListContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 150px;
  width: 100%;
  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

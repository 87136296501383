import React from 'react';
import {
  LayoutSmall,
  ModalWrapper,
  ModalSubtitle,
  ModalTitleH2,
  AuthWrapper,
} from '../../../components/styles';
import { Link, useLocation } from 'react-router-dom';
import Button from '../../../components/ui/Button';
import FullLogoPng from '../../../assets/png/FullLogoPng.png';

const RegistrationSuccessPage = () => {
  const location = useLocation();
  const { email } = location.state || {};

  return (
    <AuthWrapper>
      <LayoutSmall>
        <img
          src={FullLogoPng}
          style={{
            width: '15.813rem',
            height: '3.5rem',
          }}
          alt={'DiFreight'}
        />
        <ModalWrapper>
          <ModalTitleH2>Вітаємо!</ModalTitleH2>
          <ModalSubtitle>
            Лист з підтвердженням реєстрації було надіслано на вашу поштову
            скриньку
            <span>{email}</span>
          </ModalSubtitle>
          <Link to="/login">
            <Button text="OK" type="primary" onClick={() => {}} />
          </Link>
        </ModalWrapper>
      </LayoutSmall>
    </AuthWrapper>
  );
};

export default RegistrationSuccessPage;

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ?? 25}
    height={props.height ?? 24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      stroke={props.color ?? '#8988AC'}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11.41 5.092c.406-.06.826-.092 1.257-.092 5.105 0 8.455 4.505 9.58 6.287.137.215.205.323.243.49.029.125.029.322 0 .447-.038.166-.107.274-.244.492-.3.474-.757 1.141-1.362 1.865M7.39 6.715c-2.162 1.467-3.63 3.504-4.303 4.57-.137.217-.205.325-.243.492a1.173 1.173 0 0 0 0 .446c.038.167.106.274.242.49C4.213 14.495 7.562 19 12.667 19c2.059 0 3.832-.732 5.289-1.723M3.667 3l18 18M10.547 9.879a3 3 0 1 0 4.243 4.243"
    />
  </svg>
);
export default SvgComponent;

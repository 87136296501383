import React from 'react';
import {
  TableBodyContainer,
  BodyItem,
  CalculatedItemData,
  TableBodyContainerFType,
} from './styles';
import { useFormContext } from '../FormContext';
import { parseToFloatDigits } from '../../../utils/global';

const CalculatedRowFType = () => {
  const { formData } = useFormContext();
  const { step4 } = formData;

  return (
    <TableBodyContainerFType>
      <BodyItem>
      </BodyItem>

      <BodyItem>
      </BodyItem>

      <BodyItem>
        <CalculatedItemData>
          {parseToFloatDigits(step4?.goods?.reduce((sum: any, item: any) => {
            return sum + parseFloat(item?.amount || 0);
          }, 0), 3) || 0}
        </CalculatedItemData>
      </BodyItem>


      <BodyItem>
        <CalculatedItemData>
          {parseToFloatDigits(step4?.goods?.reduce((sum: any, item: any) => {
            return sum + parseFloat(item?.gross_weight || 0);
          }, 0), 3) || 0}
        </CalculatedItemData>
      </BodyItem>

      <BodyItem>
        <CalculatedItemData>
          {parseToFloatDigits(step4?.goods?.reduce((sum: any, item: any) => {
            return sum + parseFloat(item?.price || 0);
          }, 0), 3) || 0}
        </CalculatedItemData>
      </BodyItem>

    </TableBodyContainerFType>

  );
};

export default CalculatedRowFType;

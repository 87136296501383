import { useEffect, useState } from 'react';
import ClipIcon from '../../assets/ReactSvg/ClipIcon';
import ExpandDownSvg from '../../assets/ReactSvg/ShippingIcon/ExpandDownSvg';
import { Colors } from '../colors';
import {
  AddFile,
  DocumentFilesList,
  DocumentsFile,
  DocumentsItem,
  DocumentsList,
  DocumentsModal,
  DocumentWrapper,
  HeaderItem,
  Item,
  Label,
  ListHeader,
  Status,
  SvgWrapper,
  Wrapper,
} from './styles';
import DownloadIcon from '../../assets/ReactSvg/DownloadIcon';
import {
  useDesktop,
  useDesktopBig,
  useMobile,
  useTablet,
} from '../../constants/breakpoints';
import { Modal } from '@mui/material';
import ExpandSvg from '../../assets/ReactSvg/ShippingIcon/ExpandSvg';
import CrossSvg from '../../assets/ReactSvg/CrossSvg';
import dayjs from 'dayjs';
import UniversalModal from '../ui/UniversalModal';
import { useDetailsContext } from '../Threads/DetailsContext';
import { MobileWrapperModal } from './styles';

interface IFile {
  uploaded_at: string;
  name: string;
  reference: string;
  uuid?: string;
  type: string;
}

interface IDocuments {
  isModal?: boolean;
  approveDocuments: () => void;
}
const REACT_APP_URL = process.env.REACT_APP_URL;
const Documents = ({ isModal, approveDocuments }: IDocuments) => {
  const {
    config,
    setIsUploadDocumentsModal,
    isShowDocumentsHotButtonsOnDetails,
  } = useDetailsContext();
  const isMobile = useMobile();
  const isBigDesktop = useDesktopBig();

  const formatDate = (dateString: string) => {
    return dayjs(dateString).format('DD.MM.YY');
  };

  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleModalToggler = () => {
    setIsOpenModal(!isOpenModal);
  };

  const documentType = (type: string) => {
    switch (type) {
      case 'packing_list':
        return 'Packing';
      case 'invoices':
        return 'Invoice';
      case 'certificates':
        return 'MSDS сертифікат';
      case 'other_files':
        return 'Інші файли';
      case 'contract':
        return 'Contract';
      case 'application':
        return 'Application';
      case 'cmr_convention':
        return 'CMR';
      case 'another_docs':
        return 'Інші файли';
      default:
        break;
    }
  };

  const downloadFile = (url: any, name: any) => {
    const splitUrl = name.split('.');
    const link = document.createElement('a');
    const fileUrl = url.replace('undefined', splitUrl[splitUrl?.length - 1]);
    link.href = REACT_APP_URL + fileUrl;
    link.target = '_blank';
    link.download = `DiFreight_file.${splitUrl[splitUrl?.length - 1]}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (isModal) setIsOpenModal(true);
  }, [isMobile]);

  if (isMobile && !isModal) {
    return (
      <div
        style={{
          gap: '0.5rem',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Wrapper>
          <DocumentsFile onClick={handleModalToggler} open={isOpenModal}>
            <DocumentWrapper>
              <ClipIcon />
              <Label style={{ padding: 0 }}>Документи</Label>
            </DocumentWrapper>
            <SvgWrapper>
              <ExpandSvg
                color={Colors.primary80}
                width={'1.5rem'}
                height={'1.5rem'}
              />
            </SvgWrapper>
          </DocumentsFile>

          {isOpenModal && (
            <Modal open={isOpenModal}>
              <MobileWrapperModal>
                <DocumentsFile onClick={handleModalToggler} open={isOpenModal}>
                  <DocumentWrapper>
                    <ClipIcon />
                    <Label style={{ padding: 0 }}>Документи</Label>
                  </DocumentWrapper>
                  <SvgWrapper>
                    <CrossSvg />
                  </SvgWrapper>
                </DocumentsFile>

                <DocumentsModal
                  style={{
                    overflowX:
                      config?.data?.files?.length === 0 ? 'clip' : 'scroll',
                  }}
                >
                  <DocumentsList
                    style={{
                      overflowX:
                        config?.data?.files?.length === 0 ? 'clip' : 'scroll',
                    }}
                  >
                    <ListHeader>
                      <HeaderItem>Назва</HeaderItem>
                      <HeaderItem>Тип</HeaderItem>
                      <HeaderItem>Завантажено</HeaderItem>
                      <HeaderItem>Скачати</HeaderItem>
                    </ListHeader>
                    <DocumentFilesList
                      style={{
                        minHeight:
                          config?.data?.files?.length === 0 ? '14.5rem' : '',
                      }}
                    >
                      {config?.data?.files?.length ? (
                        <>
                          {config?.data?.files?.map(
                            (item: IFile, idx: number) => {
                              return (
                                <DocumentsItem key={idx} modal={isModal}>
                                  <Item>
                                    <p>{item?.name}</p>
                                  </Item>
                                  <Item>{documentType(item?.type)}</Item>
                                  <Item>{formatDate(item.uploaded_at)}</Item>
                                  <Item>
                                    <a
                                      href={'#'}
                                      download
                                      onClick={e => {
                                        e.preventDefault();
                                        downloadFile(
                                          item?.reference,
                                          item?.name,
                                        );
                                      }}
                                    >
                                      <DownloadIcon />
                                    </a>
                                  </Item>
                                </DocumentsItem>
                              );
                            },
                          )}
                        </>
                      ) : (
                        <UniversalModal
                          isOpenModal={config?.data?.files?.length === 0}
                          width={'229px'}
                          content="Поки що тут порожньо..."
                          isShowBtn={false}
                        />
                      )}
                    </DocumentFilesList>
                  </DocumentsList>
                </DocumentsModal>
              </MobileWrapperModal>
            </Modal>
          )}
        </Wrapper>
        {isShowDocumentsHotButtonsOnDetails && !isModal && (
          <>
            <Status type="success" onClick={approveDocuments}>
              Параметри вантажу відповідають документам
            </Status>
            <Status
              type="failure"
              onClick={() => setIsUploadDocumentsModal(true)}
            >
              Ні, завантажити новий пакет документів
            </Status>
          </>
        )}
      </div>
    );
  }
  return (
    <Wrapper modal={isModal}>
      <DocumentsFile open={isOpenModal}>
        <DocumentWrapper>
          <ClipIcon width={'1.5rem'} height={'1.5rem'} />
          <Label style={{ padding: 0 }}>Документи</Label>
        </DocumentWrapper>
        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          {isShowDocumentsHotButtonsOnDetails &&
            !isModal &&
            config?.direction?.name === 'CN-UA' && (
              <>
                <Status type="success" onClick={approveDocuments}>
                  {isBigDesktop
                    ? 'Товар, вага та об’єм відповідають прикріпленим документам'
                    : 'Параметри вантажу відповідають документам'}
                </Status>
                <Status
                  type="failure"
                  onClick={() => setIsUploadDocumentsModal(true)}
                >
                  {isBigDesktop
                    ? 'Ні, завантажити новий пакет документів'
                    : 'Ні'}
                </Status>
              </>
            )}
          <SvgWrapper>
            <ExpandDownSvg
              onClick={handleModalToggler}
              color={Colors.primary}
              width={'1.5rem'}
              height={'1.5rem'}
              style={{
                cursor: 'pointer',
                transform: isOpenModal ? 'rotate(3.142rad)' : 'rotate(0)',
                transition: '0.5s',
              }}
            />
          </SvgWrapper>
        </div>
      </DocumentsFile>
      {isOpenModal && (
        <DocumentsModal>
          <DocumentsList>
            <ListHeader modal={isModal}>
              <HeaderItem>Назва</HeaderItem>
              <HeaderItem>Тип</HeaderItem>
              <HeaderItem>Завантажено</HeaderItem>
              <HeaderItem>Скачати</HeaderItem>
            </ListHeader>
            <DocumentFilesList
              modal={isModal}
              style={{
                minHeight: config?.data?.files?.length === 0 ? '19.5rem' : '',
              }}
            >
              {config?.data?.files?.length ? (
                <>
                  {config?.data?.files?.map((item: IFile, idx: number) => {
                    return (
                      <DocumentsItem key={idx} modal={isModal}>
                        <Item>
                          <p>{item?.name}</p>
                        </Item>
                        <Item>{documentType(item?.type)}</Item>
                        <Item>{formatDate(item.uploaded_at)}</Item>
                        <Item>
                          <a
                            href={'#'}
                            download
                            onClick={e => {
                              e.preventDefault();
                              downloadFile(item?.reference, item?.name);
                            }}
                          >
                            <DownloadIcon />
                          </a>
                        </Item>
                      </DocumentsItem>
                    );
                  })}
                </>
              ) : (
                <UniversalModal
                  isOpenModal={config?.data?.files?.length === 0}
                  width={'432px'}
                  content="Поки що тут порожньо..."
                  isShowBtn={false}
                />
              )}
            </DocumentFilesList>
          </DocumentsList>
        </DocumentsModal>
      )}
    </Wrapper>
  );
};

export default Documents;

import React from 'react';
import {
  NotificationItemWrapper,
  NotificationItemContent,
  NotificationUnreadIcon,
  NotificationTitle,
  NotificationText,
  NotificationTime
} from './styles';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { formatTransportationStatus } from '../../utils/global';
import { readNotification } from '../../utils/axios/post';

const NotificationItem = ({ notification, updateShowNotification, handleFetchNotificationInfo, handleFetchNotifications }: any) => {
  const navigate = useNavigate();

  const handleNavigateToDetails = (id: string) => {
    navigate(`/details/${id}`)
  };

  const handleReadNotification = async (id: string) => {
    const result = await readNotification(id);
    if (result?.success) {
      handleFetchNotificationInfo();
      handleFetchNotifications();
    }
  };

  return (
    <NotificationItemWrapper onClick={() => {
      if (!notification?.is_read) {
        handleReadNotification(notification?.id)
      }
      updateShowNotification();
      handleNavigateToDetails(notification?.payload?.transportation?.id);
    }} className={!notification?.is_read ? 'active' : ''}>
      {
        !notification?.is_read && (
          <NotificationUnreadIcon></NotificationUnreadIcon>
        )
      }

      <NotificationItemContent>
        <NotificationTitle>Оновлення {notification?.payload?.transportation?.df_number}</NotificationTitle>
        <NotificationText>{formatTransportationStatus(notification?.type)}</NotificationText>
        <NotificationTime>{dayjs(notification?.updated_at).format('HH:mm')}</NotificationTime>
      </NotificationItemContent>
    </NotificationItemWrapper>
  )
};

export default NotificationItem;

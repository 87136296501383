import React, { useEffect, useState } from 'react';
import CargoMarkingHeader from '../../../components/Details/CargoMarkingHeader';
import Conclusion from '../../../components/Details/Conclusion';
import Documents from '../../../components/Details/Documents';
import OrderDirections from '../../../components/Details/OrderDirections';
import PriceOffers from '../../../components/Details/PriceOffers';
import TariffCalculate from '../../../components/Details/TariffCalculate';
import OrderTracking from '../../../components/OrderTracking';
import { DetailsContainer, OrderDetails, TrackingDetails } from './styles';
import {
  DetailsConfig,
  getSupplierInstruction,
} from '../../../utils/axios/get';
import { ChatProvider } from '../../../components/Threads/ChatContext';
import ChatZone from '../../../components/Threads/ChatZone';
import UniversalDetailsModal from '../../../components/UniversalDetailsModal';
import LastMileContent from '../../../components/UniversalDetailsModal/LastMileContent';
import UniversalModal from '../../../components/ui/UniversalModal';
import {
  useDesktop,
  useMobile,
  useTablet,
} from '../../../constants/breakpoints';
import TableReceivedGoods from '../../../components/DetailModals/TableReceivedGoods';
import PaymentMethodModal from '../../../components/DetailModals/PaymentMethod';
import { useDetailsContext } from '../../../components/Threads/DetailsContext';
import { useNavigate, useParams } from 'react-router-dom';
import {
  confirmReceivedGoods,
  rejectReceivedGoods,
  updatePaymentMethod,
} from '../../../utils/axios/post';
import DownloadDocumentsPackModal from '../../../components/DownloadDocumentsModal';
import { BackGroupShadow } from '../../../layout/styles';
import {
  CargoIssuesStatus,
  CargoSuccessStatus,
  ControlHeader,
  ExpandedOrderTrackingContainer,
  ExpandedStatusContainer,
  HeaderContent,
} from '../../../components/OrderTracking/styles';
import ExpandCloseSvg from '../../../assets/ReactSvg/ShippingIcon/ExpandCloseSvg';
import VerticalOrderStepper from '../../../components/OrderTracking/VerticalStepper';
import { debounce } from 'lodash';
import { LoadingCircularWrapper } from '../styles';
import { CircularProgress } from '@mui/material';

const Details = ({}) => {
  const params = useParams();
  const { id } = params;
  const isMobile = useMobile();
  const isTablet = useTablet();
  const {
    config,
    setConfig,
    isPriceOffersModal,
    isSuccessModal,
    isPaymentMethodModal,
    setIsUploadDocumentsModal,
    isLastMilesModal,
    handleTogglerPaymentMethodModal,
    handleTogglerPriceOffersModal,
    handleCloseUniversalModal,
    handleTogglerTableRecieveGoodsModal,
    isTableRecieveGoods,
    handleTogglerLastMilesModal,
    setIsShowDocumentsHotButtonsOnDetails,
    isPaymentMethodChoosen,
    setIsShowWarehouseHotButtonsOnDetails,
    isLastStepSuccessModal,
    setIsLastStepSuccessModal,
    isShowWarehouseHotButtonsOnDetails,
    isDocumentsModal,
    setIsDocumentsModal,
    isExpandModalOpen,
    setIsExpandModalOpen,
    isCompactMode,
    setIsCompactMode,
    isUpproveTariffModal,
    setIsUpproveTariffModal,
    isUpproveTariff,
    setIsUpproveTariff,
    setIsPriceOffersModal,
    updateConfig,
  } = useDetailsContext();

  const isBills = config?.invoices?.length > 0;
  const isPriceOffers = config?.price_offers[0].total > 0;
  const offersStatus = isBills ? 'bills' : isPriceOffers ? 'offers' : null;

  const [submitLastMilesModal, setSubmitLastMilesModal] = useState(false);
  const [submitPaymentMethodModal, setSubmitPaymentMethodModal] =
    useState(false);
  const [pdfString, setPdfString] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const handleSubmitPaymentMethod = () => {
    updateConfig(config?.id);
    setSubmitPaymentMethodModal(!submitPaymentMethodModal);
  };

  const handleSubmitLastMile = async () => {
    setSubmitLastMilesModal(!submitLastMilesModal);
    const { data } = await DetailsConfig(config?.id);
    setConfig(data);
  };

  const adaptiveSizeUniversalModal = isMobile
    ? '19.75rem'
    : isTablet
      ? '31.5rem'
      : '48.75rem';

  const approveDocuments = async () => {
    try {
      await confirmReceivedGoods({ id: config?.id }).then(() => {
        setIsDocumentsModal(false);
        setIsShowDocumentsHotButtonsOnDetails(false);
      });
      updateConfig(config?.id);
    } catch (err) {
      console.log(err);
    }
  };
  const closeDocumentModal = () => {
    setIsDocumentsModal(false);
    setIsShowWarehouseHotButtonsOnDetails(false);
    handleTogglerTableRecieveGoodsModal({ show: false });
  };

  const uploadNewDocuments = () => {
    setIsUploadDocumentsModal(true);
    updateConfig(config?.id);
  };

  const approveGoods = () => {
    handleTogglerTableRecieveGoodsModal({ show: false });
    setIsDocumentsModal(true);
    setIsShowWarehouseHotButtonsOnDetails(false);
    setIsShowDocumentsHotButtonsOnDetails(true);
    updateConfig(config?.id);
  };

  const rejectGoods = async () => {
    try {
      await rejectReceivedGoods({ id: config?.id }).then(() => {
        handleTogglerTableRecieveGoodsModal({ show: false });
        setIsShowWarehouseHotButtonsOnDetails(false);
      });
      updateConfig(config?.id);
    } catch (err) {
      console.log(err);
    }
  };

  const getConfig = async (id: any) => {
    setIsLoading(true);
    try {
      const result = await DetailsConfig(id);
      if (result?.success) {
        setConfig(result?.data);
      } else {
        if (result?.response?.status === 404) {
          navigate('/404');
        }
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const debouncedFetch = debounce(getConfig, 300);

  useEffect(() => {
    if (id) {
      debouncedFetch(id);
      return () => {
        debouncedFetch.cancel();
      };
    }
  }, [id]);

  useEffect(() => {
    if (
      config?.status === 'tariff_sent' ||
      (config?.status === 'tariff_under_review' &&
        !isPaymentMethodModal &&
        !isLastMilesModal)
    ) {
      handleTogglerPriceOffersModal({ show: true });
    }
    if (config?.status === 'received_at_warehouse') {
      handleTogglerTableRecieveGoodsModal({ show: true });
      setIsShowWarehouseHotButtonsOnDetails(true);
    }
    if (config?.status === 'tariff_under_review') {
      if (isPaymentMethodChoosen) {
        return handleTogglerLastMilesModal({ show: true });
      } else {
        setIsUpproveTariffModal(true);
      }
    }
  }, [config?.status]);

  useEffect(() => {
    if (!isUpproveTariffModal) {
      handleTogglerPriceOffersModal({ show: false });
    }
  }, [isUpproveTariffModal]);

  return (
    <ChatProvider>
      {isLoading ? (
          <LoadingCircularWrapper>
            <CircularProgress color="inherit" size={'2.5rem'} />
          </LoadingCircularWrapper>
        ) : (
          <>
            <>
              <UniversalDetailsModal
                title={
                  config?.status === 'tariff_sent'
                    ? 'Підтвердіть цінову пропозицію'
                    : 'Підтвердіть тариф'
                }
                content={<PriceOffers isModal offersStatus={offersStatus} />}
                open={
                  config?.status === 'tariff_sent'
                    ? isPriceOffersModal
                    : isPriceOffersModal && !isPaymentMethodChoosen
                }
                onClose={() => {
                  document.body.style.overflow = 'visible';
                  document.body.style.maxHeight = '100%';
                  setIsPriceOffersModal(false);
                }}
              />
              <UniversalModal
                isOpenModal={isSuccessModal}
                width={adaptiveSizeUniversalModal}
                header="Дякуємо за підтвердження!"
                content={`Ваш вантаж з маркуванням ${config?.df_number} очікується на складі. Будь ласка, пройдіть за посиланням щоб отримати адресу складу та інструкції:`}
                subcontent="DiFreight / supplier_instruction.pdf"
                sendPdf
                btnDirection="column"
                primaryButtonText={'Закрити'}
                primaryBtnClick={handleCloseUniversalModal}
                className={'success-details'}
                id={config?.id}
              />

              <UniversalDetailsModal
                title={'Чи всі вантажі отримано на складі?'}
                content={
                  <TableReceivedGoods
                    isModal
                    approveGoods={approveGoods}
                    rejectGoods={rejectGoods}
                  />
                }
                btnGreenText="Так, вантажити"
                btnRedText="Ні, чекаємо ще"
                btnGreenFunc={approveGoods}
                btnRedFunc={rejectGoods}
                open={
                  isTableRecieveGoods &&
                  !isDocumentsModal &&
                  config?.direction?.name === 'CN-UA'
                }
                onClose={() => handleTogglerTableRecieveGoodsModal({ show: false })}
              />
              {isDocumentsModal && (
                <UniversalDetailsModal
                  type="document"
                  title={
                    'Чи відповідають товар, вага та об’єм прикріпленим документам?'
                  }
                  content={<Documents isModal approveDocuments={approveDocuments} />}
                  btnGreenText="Так"
                  btnRedText={
                    isMobile ? 'Ні' : 'Ні, завантажити новий пакет документів'
                  }
                  btnGreenFunc={approveDocuments}
                  btnRedFunc={uploadNewDocuments}
                  isLastMile
                  open={isDocumentsModal && config?.direction?.name === 'CN-UA'}
                  onClose={closeDocumentModal}
                />
              )}
              <DownloadDocumentsPackModal />
              <UniversalDetailsModal
                paymentType
                open={isPaymentMethodModal && config?.direction?.name === 'CN-UA'}
                onClose={() => handleTogglerPaymentMethodModal({ show: false })}
                title={'Який спосіб оплати вам підходить?'}
                content={
                  <PaymentMethodModal
                    submitPaymentMethodModal={submitPaymentMethodModal}
                    setSubmitPaymentMethodModal={handleSubmitPaymentMethod}
                  />
                }
                btnGreenText="Підтвердити"
                btnGreenFunc={() => handleSubmitPaymentMethod()}
              />
              <UniversalDetailsModal
                title={'Введіть дані останньої милі'}
                content={
                  <LastMileContent
                    isModal
                    submitLastMilesModal={submitLastMilesModal}
                    setSubmitLastMilesModal={handleSubmitLastMile}
                  />
                }
                btnGreenText="Підтвердити"
                btnGreenFunc={() => handleSubmitLastMile()}
                open={
                  isLastMilesModal &&
                  !config?.last_mile &&
                  config?.direction?.name === 'CN-UA'
                }
                onClose={handleTogglerLastMilesModal}
                id={'universal-modal'}
              />

              <UniversalModal
                isOpenModal={isLastStepSuccessModal}
                width={adaptiveSizeUniversalModal}
                header="Дякуємо!"
                content={`Ми отримали всю необхідну інформацію. Слідкуйте за оновленнями в особистому кабінеті.`}
                btnDirection="column"
                primaryButtonText={'Закрити'}
                primaryBtnClick={() => {
                  updateConfig(config?.id);
                  setIsLastStepSuccessModal(false);
                  document.body.style.overflow = '';
                  document.body.style.maxHeight = '';
                }}
                className={'success-details'}
              />
              {isExpandModalOpen && !isCompactMode && (
                <ExpandedOrderTrackingContainer compact={false}>
                  <ControlHeader>
                    <HeaderContent>Відстеження відправлення</HeaderContent>
                    <ExpandCloseSvg
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setIsExpandModalOpen(false);
                        document.body.style.overflow = '';
                        document.body.style.maxHeight = '';
                      }}
                    />
                  </ControlHeader>
                  <ExpandedStatusContainer>
                    {config?.status === 'cargo_issues' ? (
                      <CargoIssuesStatus>Проблеми з вантажем</CargoIssuesStatus>
                    ) : config?.status === 'tariff_lost' ? (
                      <CargoIssuesStatus>Відхилено тариф</CargoIssuesStatus>
                    ) : config?.status === 'rejected' ? (
                      <CargoIssuesStatus>Відхилено</CargoIssuesStatus>
                    ) : config?.status === 'received' ? (
                      <CargoSuccessStatus>Отримано</CargoSuccessStatus>
                    ) : (
                      <VerticalOrderStepper statusTransportation={config?.status} />
                    )}
                  </ExpandedStatusContainer>
                </ExpandedOrderTrackingContainer>
              )}
              {(isSuccessModal ||
                isLastStepSuccessModal ||
                (isExpandModalOpen && !isCompactMode)) && (
                <BackGroupShadow className={'details'}></BackGroupShadow>
              )}
            </>
            <DetailsContainer>
              <OrderDetails>
                <div>
                  <CargoMarkingHeader />
                  <OrderDirections />
                </div>

                {offersStatus && <PriceOffers offersStatus={offersStatus} />}
                <Documents approveDocuments={approveDocuments} />
                <TableReceivedGoods
                  approveGoods={approveGoods}
                  rejectGoods={rejectGoods}
                />
                <TariffCalculate />
                <Conclusion />
              </OrderDetails>

              <TrackingDetails>
                <OrderTracking status={config?.status} />
                {!isMobile && <ChatZone size="small" id={id} />}
              </TrackingDetails>
            </DetailsContainer>
          </>
        )
      }
    </ChatProvider>
  );
};

export default Details;

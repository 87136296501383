import React from 'react';
import { SearchInputWithIcon, SearchInputWrapper } from './styles';
import SearchIcon from '../../assets/ReactSvg/SearchIcon';

const SearchDashboardInput = ({ value, setValue }: any) => {

  return (
    <SearchInputWrapper>
      <SearchIcon />
      <SearchInputWithIcon
        placeholder={'Пошук'}
        onChange={(e) => setValue(e.target.value)}
        value={value}
      />
    </SearchInputWrapper>
  )
};

export default SearchDashboardInput;
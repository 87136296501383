//@ts-ignore
import Cookies from 'js-cookie';
import axiosInstance from '../index';
import {
  RegistrationDataType,
  ResetPasswordType,
  VerifyEmailType,
  LoginDataType,
  RefreshTokenDataType,
} from '../../../constants/types';
import axiosInstanceFormData from '../form-data';
import { LastMileConfig } from '../../../components/UniversalDetailsModal/LastMileContent';
import { InternalAxiosRequestConfig } from 'axios/index';

const registerClient = async (registrationData: RegistrationDataType) => {
  try {
    const response = await axiosInstance.post(
      '/auth/register',
      registrationData,
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

const handleCheckRegisteredUser = async (email: string) => {
  try {
    const response = await axiosInstance.post(
      '/auth/check_registered_email',
      {
        email
      }
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

const handleSetupPassword = async (email: string) => {
  try {
    const response = await axiosInstance.post(
      '/auth/setup_password',
      {
        email
      }
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

const confirmVerifyRegistration = async (body: VerifyEmailType) => {
  try {
    const response = await axiosInstance.post('/auth/verify', body);
    return response.data;
  } catch (err: any) {
    console.error(err);
    return err?.response?.data;
  }
};

const loginClient = async (loginData: LoginDataType) => {
  try {
    const response = await axiosInstance.post('/auth/login', loginData);
    return response.data;
  } catch (err: any) {
    return err?.response;
  }
};

const fetchRefreshToken = async (refreshToken: RefreshTokenDataType) => {
  try {
    const response = await axiosInstance.post('/auth/refresh_token', {
      refresh_token: refreshToken
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

const forgotPasswordClient = async (email: string) => {
  try {
    const response = await axiosInstance.post('/auth/forgot_password', {
      email,
    });
    return response.data;
  } catch (err: any) {
    console.error(err);
    return err?.response;
  }
};

const resetPasswordClient = async (resetData: ResetPasswordType) => {
  try {
    const response = await axiosInstance.post(
      '/auth/reset_password',
      resetData,
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

const resetPasswordProfile = async () => {
  try {
    const response = await axiosInstance.post('/reset_password');
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

const updateProfileInfo = async (profileInfo: any) => {
  try {
    const response = await axiosInstanceFormData.post('/profile', profileInfo);
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};
const uploadDocument = async (file: any, id: number) => {
  try {
    const response = await axiosInstanceFormData.post(
      `/transportations/${id}/files`,
      file,
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

const logout = async () => {
  try {
    const response = await axiosInstance.post('/auth/logout');
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

const createRequest = async (preparedFormData: any) => {
  try {
    const response = await axiosInstance.post(
      '/transportations/create',
      preparedFormData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

const saveSignSignature = async (payload: any) => {
  try {
    const response = await axiosInstanceFormData.post(
      '/profile/contract',
      payload,
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};
const updateDetailsTitle = async (body: { title: string }, id: number) => {
  try {
    const response = await axiosInstance.post(
      `/transportations/${id}/update/title`,
      body,
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

const readNotification = async (notification_id: string) => {
  try {
    const response = await axiosInstance.post('/notifications/read', {
      notification_id,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};
const approvePriceOffer = async (body: { price_offer: string }, id: number) => {
  try {
    const response = await axiosInstance.post(
      `/transportations/${id}/approve_price_offer`,
      body,
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

const readAllNotifications = async () => {
  try {
    const response = await axiosInstance.post('/notifications/read_all');
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};
// Route.post('/transportations/:id/confirm_received_goods', 'Clients/TransportationController.confirmReceivedGoods')
//       .as('client_confirm_received_goods');
//
//     Route.post('/transportations/:id/reject_received_goods', 'Clients/TransportationController.rejectReceivedGoods')
//       .as('client_reject_received_goods');
const confirmReceivedGoods = async ({ id }: { id: number }) => {
  try {
    const response = await axiosInstance.post(
      `/transportations/${id}/confirm_received_goods`,
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};
const rejectReceivedGoods = async ({ id }: { id: number }) => {
  try {
    const response = await axiosInstance.post(
      `/transportations/${id}/reject_received_goods`,
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};
const updatePaymentMethod = async ({
  id,
  body,
}: {
  id: number;
  body: { payment_type: string; payment_bill: string };
}) => {
  try {
    const response = await axiosInstance.post(
      `/transportations/${id}/update_payment_method`,
      body,
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};
const updateLastMile = async ({
  id,
  body,
}: {
  id: number;
  body: LastMileConfig;
}) => {
  try {
    const response = await axiosInstance.post(
      `/transportations/${id}/update_last_mile`,
      body,
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

const approveTariff = async (body: { price_offer: string }, id: number) => {
  try {
    const response = await axiosInstance.post(
      `/transportations/${id}/approve_tariff`,
      body,
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export {
  updateLastMile,
  updatePaymentMethod,
  logout,
  updateProfileInfo,
  registerClient,
  confirmVerifyRegistration,
  forgotPasswordClient,
  loginClient,
  fetchRefreshToken,
  resetPasswordClient,
  resetPasswordProfile,
  createRequest,
  saveSignSignature,
  uploadDocument,
  updateDetailsTitle,
  readNotification,
  approvePriceOffer,
  readAllNotifications,
  confirmReceivedGoods,
  rejectReceivedGoods,
  handleCheckRegisteredUser,
  handleSetupPassword,
  approveTariff
};

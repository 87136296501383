import React, { useEffect, useState } from 'react';
import { CircularProgress, Modal } from '@mui/material';
import {
  ButtonsWrapper,
  CloseButtonWrapper,
  ContentContainer,
  ContentWrapper,
  Title,
  UniversalLayout,
} from './styles';
import CrossSvg from '../../assets/ReactSvg/CrossSvg';
import { ChatWrapper } from '../styles';
import { Colors } from '../colors';
import Button from '../ui/Button';
import { useDetailsContext } from '../Threads/DetailsContext';
import ChatZone from '../Threads/ChatZone';

interface IUniversalDetailsModal {
  type?: 'document';
  open: boolean;
  onClose: () => void;
  title: string;
  btnGreenText?: string;
  btnRedText?: string;
  btnGreenFunc?: () => void;
  btnRedFunc?: () => void;
  content: React.ReactNode;
  isLastMile?: boolean;
  paymentType?: boolean;
  id?: string;
}

const UniversalDetailsModal = ({
  title,
  btnGreenText,
  btnRedText,
  btnGreenFunc,
  btnRedFunc,
  content,
  isLastMile,
  paymentType,
  open,
  onClose,
  type,
  id,
}: IUniversalDetailsModal) => {
  const { isLoading, config } = useDetailsContext();
  const isLastMileModal = title === 'Введіть дані останньої милі';
  return (
    <>
      <Modal
        style={{
          outline: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        open={open}
        onClose={isLastMileModal ? () => {} : onClose}
      >
        <UniversalLayout id={id}>
          <CloseButtonWrapper>
            <CrossSvg onClick={onClose} />
          </CloseButtonWrapper>
          <ContentWrapper>
            <ContentContainer
              disableScroll={paymentType}
              isLastMileModal={!isLastMileModal}
            >
              <Title>{title}</Title>
              {type === 'document' ? (
                <div style={{ width: '100%', overflow: 'auto' }}>{content}</div>
              ) : (
                content
              )}
              {(btnGreenText || btnRedText) && (
                <ButtonsWrapper isLastMile={isLastMile}>
                  {btnGreenText && (
                    <Button
                      text={
                        isLoading ? (
                          <CircularProgress size={20} />
                        ) : (
                          btnGreenText
                        )
                      }
                      type={'color'}
                      onClick={() => {
                        if (btnGreenFunc) btnGreenFunc();
                      }}
                    />
                  )}
                  {btnRedText && (
                    <Button
                      text={btnRedText}
                      type={'red'}
                      onClick={() => {
                        if (btnRedFunc) btnRedFunc();
                      }}
                    />
                  )}
                </ButtonsWrapper>
              )}
            </ContentContainer>
            <ChatWrapper id={'modal-chat'}>
              <ChatZone size="small" id={config?.id} type="modal-chat" />
            </ChatWrapper>
          </ContentWrapper>
        </UniversalLayout>
      </Modal>
    </>
  );
};

export default UniversalDetailsModal;

import { SVGProps } from 'react';
import { Colors } from '../../components/colors';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M3 7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8Z"
          stroke={Colors.primary80}
          strokeLinecap="round"
          strokeLinejoin="round"
    />
    <path d="M17 12.6667V13.3333C17 14.2668 17 14.7335 16.8183 15.09C16.6586 15.4036 16.4036 15.6586 16.09 15.8183C15.7335 16 15.2668 16 14.3333 16H9.66667C8.73325 16 8.26654 16 7.91002 15.8183C7.59641 15.6586 7.34144 15.4036 7.18166 15.09C7 14.7335 7 14.2668 7 13.3333V12.6667M14.7778 9.88889L12 12.6667M12 12.6667L9.22222 9.88889M12 12.6667V6"
          stroke={Colors.primary80}
          strokeLinecap="round"
          strokeLinejoin="round"
    />
  </svg>

);
export default SvgComponent;

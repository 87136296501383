import React from 'react';
import { FullWidthStepFormWrapper, CargoTableSelectWrapper, RequiredLabel } from '../styles';
import CustomDropdown from '../../ui/Dropdown/Dropdown';
import { useFormContext } from '../FormContext';
import CargoTableBase from './table';
import CargoDetails from './CargoDetails';
import { useDesktop, useMobile } from '../../../constants/breakpoints';
import { PrimaryLabel } from '../../styles';

const CargoTable = ({ reference }: any) => {
  const { updateFormData, config, formData } = useFormContext();
  const isDesktop = useDesktop();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFormData('step4', { [e.target.name]: e.target.value });
  };

  return (
    <FullWidthStepFormWrapper className={(formData?.step3?.to_address_type !== 'warehouse' || formData?.step3?.to_address_type !== 'fulfillment_center') ? 'no-task' : ''}>
      {
        (formData?.step4?.cargo_params === 'input_params' && (formData?.step3?.to_address_type === 'warehouse' || formData?.step3?.to_address_type === 'fulfillment_center')) && (
          <CargoTableSelectWrapper ref={reference}>
            <CustomDropdown
              label="Завдання по вантажу"
              placeholder="Оберіть зі списку"
              options={config?.fulfillment_tasks}
              name="fulfillment_task"
              onSelect={handleChange}
              withValueLabel={false}
              defaultValue={config?.fulfillment_tasks?.find((item: any) => item?.value === formData?.step4?.fulfillment_task)}
            />
          </CargoTableSelectWrapper>
        )
      }
      <PrimaryLabel style={{ marginBottom: '6px', paddingLeft: '0.75rem' }}  htmlFor={'test'}>
        Внесіть вантаж <RequiredLabel>*</RequiredLabel>
      </PrimaryLabel>
      <CargoTableBase config={config} />
    </FullWidthStepFormWrapper>
  )
};

export default CargoTable;
import React from 'react';
import { SignAgreementWrapper } from '../styles';
import SignAgreementContent from '../../../components/SignAgreement';

const SignAgreementPage = () => {
  return (
    <SignAgreementWrapper>
        <SignAgreementContent />
    </SignAgreementWrapper>
  )
};

export default SignAgreementPage;

import { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import {
  HoverStyledImage,
  InitialsCircle,
  InputWarningMessage,
  LogoContainer,
  StyledImage,
  UserLogoWrapper,
} from '../styles';
import { photo } from '../../assets/images';
import { Avatar } from '../../constants/types';
import { profileInfo } from '../../utils/axios/get';
import { capitalizeInitials } from '../../utils/global';
import { debounce } from 'lodash';
import { AuthContext } from '../../index';

interface IUserLogo {
  width: string;
  height: string;
  avatar?: Avatar;
  header?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  isEditProfile?: boolean;
  warningMessage?: string;
}

const UserLogo = ({
  width,
  height,
  avatar,
  onChange,
  isEditProfile,
  warningMessage,
  header = false,
}: IUserLogo) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [initialAvatar, setInitialAvatar] = useState<Avatar>(null);
  const [initials, setInitials] = useState('');
  const { updateProfileID, updateIsContractSigned } = useContext(AuthContext);

  const handleImageClick = () => {
    if (isEditProfile && inputRef.current) {
      inputRef.current.click();
    }
  };

  const getAvatarSrc = useMemo(() => {
    if (initialAvatar) {
      return typeof initialAvatar === 'string'
        ? initialAvatar
        : URL.createObjectURL(initialAvatar);
    }
  }, [initialAvatar]);

  const fetchProfileData = async () => {
    const result = await profileInfo();
    if (result.success) {
      updateProfileID(result?.data?.id);
      // @ts-ignore
      updateIsContractSigned(result?.data?.is_contract_signed);
      setInitials(capitalizeInitials(result.data.full_name));
      setInitialAvatar(result.data?.avatar);
    }
  };
  const debouncedFetch = debounce(fetchProfileData, 300);

  useLayoutEffect(() => {
    debouncedFetch();
    return () => {
      debouncedFetch.cancel();
    };
  }, []);

  useEffect(() => {
    setInitialAvatar(avatar);
  }, [avatar]);

  return (
    <UserLogoWrapper>
      <LogoContainer>
        {initialAvatar ? (
          <StyledImage
            src={getAvatarSrc}
            alt="avatar"
            width={width}
            height={height}
          />
        ) : (
          <InitialsCircle width={width} height={height} header={header}>
            {initials}
          </InitialsCircle>
        )}
        {isEditProfile && (
          <HoverStyledImage
            src={photo}
            alt="avatar"
            width={width}
            height={height}
            onClick={handleImageClick}
          />
        )}
      </LogoContainer>

      <input
        type="file"
        ref={inputRef}
        onChange={onChange}
        accept=".png, .jpg, .jpeg"
        style={{ display: 'none' }}
      />
      {warningMessage && (
        <InputWarningMessage>{warningMessage}</InputWarningMessage>
      )}
    </UserLogoWrapper>
  );
};

export default UserLogo;

import React from 'react';
import { FormProvider } from './FormContext';
import FormWizard from './wizard';

const OrderForm = () => {
  return (
    <FormProvider>
      <FormWizard />
    </FormProvider>
  );
};

export default OrderForm;
import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ?? 24}
    height={props.height ?? 24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#3A3875'}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m21.153 10.899-9.016 9.016a5.25 5.25 0 0 1-7.425-7.425l9.016-9.016a3.5 3.5 0 0 1 4.95 4.95l-8.662 8.662a1.75 1.75 0 0 1-2.475-2.475l7.601-7.601"
    />
  </svg>
);
export default SvgComponent;

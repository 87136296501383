import { FC, useEffect, useState } from 'react';
import {
  Badge,
  CargoMarking,
  ContainerHeader,
  HeaderTitleInput,
  OrderDetailsWrapper,
  SvgWrapper,
} from './styles';
import EditIcon from '../../assets/ReactSvg/EditIcon';
import { useMobile } from '../../constants/breakpoints';
import { updateDetailsTitle } from '../../utils/axios/post';
import { CheckmarkIcon } from '../OrderForm/styles';
import CheckIcon from '../../assets/ReactSvg/CheckIcon';
import { useDetailsContext } from '../Threads/DetailsContext';
import ChatZone from '../Threads/ChatZone';

interface ICargoMarkingProps {}

const CargoMarkingHeader: FC<ICargoMarkingProps> = () => {
  const isMobile = useMobile();

  const { config } = useDetailsContext();
  const [title, setTitle] = useState<string>('');
  const [isEditing, setIsEditing] = useState(false);

  const updateTitle = async () => {
    const body = {
      title,
    };
    try {
      await updateDetailsTitle(body, config.id);
    } catch (err) {
      console.error(err);
    } finally {
      setIsEditing(false);
    }
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  useEffect(() => {
    setTitle(`${config?.title}`);
  }, [config?.title]);

  if (isMobile) {
    return (
      <ContainerHeader>
        <OrderDetailsWrapper>
          {isEditing ? (
            <HeaderTitleInput
              type="text"
              value={title}
              onChange={handleTitleChange}
              autoFocus
            />
          ) : (
            <p>{title}</p>
          )}
          <SvgWrapper>
            {isEditing ? (
              <CheckIcon
                onClick={updateTitle}
                width={'1.5rem'}
                height={'1.5rem'}
                style={{ cursor: 'pointer' }}
              />
            ) : (
              <EditIcon
                width={'1.5rem'}
                height={'1.5rem'}
                onClick={handleEditClick}
                style={{ cursor: 'pointer' }}
              />
            )}
          </SvgWrapper>
        </OrderDetailsWrapper>
        <CargoMarking>
          <Badge>{config?.df_number}</Badge>
          <ChatZone size="small" id={config?.id} />
        </CargoMarking>
      </ContainerHeader>
    );
  }

  return (
    <ContainerHeader>
      <CargoMarking>
        <span>Маркування вантажу:</span>
        <Badge>{config?.df_number}</Badge>
      </CargoMarking>
      <OrderDetailsWrapper>
        {isEditing ? (
          <>
            <HeaderTitleInput
              type="text"
              value={title}
              onChange={handleTitleChange}
              autoFocus
            />
          </>
        ) : (
          <>
            <p>{title}</p>
          </>
        )}
        <SvgWrapper>
          {isEditing ? (
            <CheckIcon
              onClick={updateTitle}
              width={'1.5rem'}
              height={'1.5rem'}
              style={{ cursor: 'pointer' }}
            />
          ) : (
            <EditIcon
              onClick={handleEditClick}
              width={'1.5rem'}
              height={'1.5rem'}
              style={{ cursor: 'pointer' }}
            />
          )}
        </SvgWrapper>
      </OrderDetailsWrapper>
    </ContainerHeader>
  );
};

export default CargoMarkingHeader;

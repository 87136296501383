import { useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { useMobile, useTablet } from '../../../constants/breakpoints';
import { HomeWrapper, LoadingCircularWrapper } from '../styles';
import { AuthContext } from '../../../index';
import Modal from '../../../components/ui/UniversalModal';
import { CircularProgress } from '@mui/material';
import { DashboardConfig } from '../../../utils/axios/get';

const Home = () => {
  const isMobile = useMobile();
  const isTablet = useTablet();
  const modalSize = isMobile ? '19.75rem' : isTablet ? '504px' : '432px';
  const { isContractSigned } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [tableConfig, setTableConfig] = useState<any>(null);
  const navigate = useNavigate();
  const fetchDashboardData = async () => {
    const result = await DashboardConfig({
      per_page: 15,
      page: 1,
      view: '',
      search: '',
    });

    if (result.success) {
      setTableConfig(result?.data);
    }
  };
  useEffect(() => {
    fetchDashboardData();
  }, []);
  useEffect(() => {
    if (tableConfig?.length >= 1) {
      navigate('/dashboard');
    }
  }, [tableConfig]);

  const handleCreateRequest = () => {
    navigate('/new-request');
  };
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 25, behavior: 'smooth' });
  }, [isMobile]);

  return (
    <HomeWrapper>
      {isLoading ? (
        <LoadingCircularWrapper>
          <CircularProgress color="inherit" size={'2.5rem'} />
        </LoadingCircularWrapper>
      ) : (
        <Modal
          isOpenModal={true}
          width={modalSize}
          header="Вітаємо!"
          content="Для початку роботи із системою, будь ласка, підпишіть договір про співпрацю і створіть запит на тариф"
          btnDirection="column"
          sign={!isContractSigned}
          primaryButtonText={'Створити запит на тариф'}
          primaryBtnClick={handleCreateRequest}
          onClose={function (): void {
            throw new Error('Function not implemented.');
          }}
        />
      )}
    </HomeWrapper>
  );
};

export default Home;

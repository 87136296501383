import * as React from 'react';
import { SVGProps } from 'react';
import { Colors } from '../../../components/colors';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ?? 24}
    height={props.height ?? 24}
    fill="none"
    {...props}
    viewBox="0 0 24 24"
  >
    <path
      stroke={props.color || Colors.white}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m14 10 7-7m0 0h-6m6 0v6m-11 5-7 7m0 0h6m-6 0v-6"
    />
  </svg>
);
export default SvgComponent;

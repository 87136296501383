import React from 'react';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import { DashboardTableWrapper } from './styles';
import { useDesktop } from '../../constants/breakpoints';

const DashboardTable = ({ data, isShowActive }: any) => {
  const isDesktop = useDesktop();

  return (
    <DashboardTableWrapper>
      {isDesktop && <TableHeader isShowActive={isShowActive} />}
      <TableBody data={data} isShowActive={isShowActive} />
    </DashboardTableWrapper>
  );
};

export default DashboardTable;

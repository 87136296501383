export const getCountryLabel = (countries: any, value: string) => {
  return countries?.find((country: any) => country?.value === value)?.label;
}

export const getAddressTypeLabel = (addressType: any, value: string) => {
  return addressType?.find((address: any) => address?.value === value)?.label;
}

export const getDeliveryMethod = (value: string) => {
  switch (value) {
    case 'pick_up':
      return 'PICKUP'
    case 'supplier':
      return 'Доставить постачальник'
    case 'pickup':
      return 'Самовивіз'
    case 'address_courier':
      return 'Доставка кур\'єром'
    case 'post_office':
      return 'Поштова на відділення'
    case 'supplemental_load_ukraine':
      return 'Догруз по території України'
    case 'consolidated_vehicle_kyiv':
      return 'Збірне авто по Києву'
    case 'dedicated_vehicle':
      return 'Окреме авто'
    default:
      return ''
  }
};

export const getMailOfficeLabel = (value: string) => {
  switch (value) {
    case 'nova_poshta':
      return 'НП';
    case 'meest':
      return 'Міст Експрес';
    case 'delivery':
      return 'Делівері';
    case 'cat':
      return 'САТ';
    default:
      return '';
  }
};

export const getModeOfTransportation = (value: string) => {
  switch (value) {
    case 'land':
      return 'Авто';
    case 'air':
      return 'Авіа';
    case 'sea':
      return 'Море';
    default:
      return '';
  }
};

export const getExportClearance = (value: boolean) => {
  if (!value) {
    return 'замитнює DiFreight';
  } else return 'замитнюю сам';
};

export const getImportClearance = (value: boolean) => {
  if (!value) {
    return 'розмитнює DiFreight';
  } else return 'розмитнюю сам';
};

export const getFulFillmentTaskLabel = (tasks: any, value: string) => {
  return tasks?.find((task: any) => task?.value === value)?.label;
}

export const getLoadingTypeLabel = (loadingTypes: any, value: string) => {
  return loadingTypes?.find((loadType: any) => loadType?.value === value)?.label;
}

export const getPriceOfferServiceLabel = (offerService: any, value: string) => {
  return offerService?.find((service: any) => service?.value === value)?.label;
};

export const getHarmfulTypeLabel = (harmfulTypes: any, value: string) => {
  return harmfulTypes?.find((type: any) => type?.value === value)?.label;
};

export const getCurrentStatusLabel = (statuses: any, value: string) => {
  return statuses?.find((status: any) => status?.value === value)?.label;
}
import styled from 'styled-components';
import { Colors } from '../../components/colors';
import { Link } from 'react-router-dom';

interface IProfileContentWrapper {
  width: string;
}

const RegistrationForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem 0;
  width: 100%;
`;

export const RegistrationLabelForTerms = styled.p`
  color: ${Colors.black60};
  font-size: 0.75rem;
  line-height: 1.313rem;
  font-weight: 400;
  a {
    font-weight: 600;
    color: ${Colors.primary60};
    text-decoration: none;  
  }
`;

const AuthVariantLine = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  background: ${Colors.periwinkle};
  position: relative;
  span {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: -0.625rem;
    font-size: 0.875rem;
    line-height: 1.313rem;
    color: ${Colors.periwinkle};
    background: ${Colors.white};
    padding: 0 0.5rem;
  }
`;

const AuthWithButton = styled.div`
  width: 100%;
  padding: 0.688rem;
  height: 3rem;
  border: 1px solid ${Colors.periwinkle};
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.25s;
  &:hover {
    background: ${Colors.mistyLavender};
    transition: 0.25s;
    > svg {
      > path {
        fill: ${Colors.primary60};
      }
    }
  }
`;

const ForgotPasswordLink = styled(Link)`
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: ${Colors.linkColor};
  text-decoration: none;
  margin-top: 0.375rem;
`;

const ProfileContent = styled.div<IProfileContentWrapper>`
  width: ${props => props.width};
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  border-radius: 0.25rem;
  background: ${Colors.white};
  margin-top: 1.25rem;
  box-shadow: 0 0 33px 0 #00000017;
`;

const ProfileForm = styled.form`
  //max-height: 384px;
  //overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const AddPhoneNumberButton = styled.div`
  display: flex;
  justify-content: initial;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;

  > svg:hover {
    > path {
      fill: ${Colors.primary60};
    }
  }
`;

const AddPhoneNumberText = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.313rem;
`;

const SubmitButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const LoginWarningWrapper = styled.div`
  color: ${Colors.white};
  background: ${Colors.deepRed};
  padding: 0.75rem;
  border-radius: 0.25rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1.75rem;
`;

const HomeWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const LogoutButton = styled.div`
  padding: 1rem 2.625rem;
  color: ${Colors.primary60};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;
  text-align: center;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    color: ${Colors.primary80};
  }
`;

const MessagesPageWrapper = styled.div`
  box-shadow: 0 0 33px 0 #00000017;
  border: 1px solid ${Colors.periwinkle};
  border-radius: 0.25rem;
  width: 100%;
  display: flex;
  align-items: start;
  margin-top: 2.5rem;
  background: ${Colors.white};
  @media (max-width: 767px) {
    margin-top: 1rem;
    height: 100%;
  }
`;

const SignAgreementWrapper = styled.div`
  width: 100%;
`;

const LoadingCircularWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &.statuses_loader {
    top: 75%;
  }
`;

export {
  LogoutButton,
  HomeWrapper,
  RegistrationForm,
  AuthVariantLine,
  AuthWithButton,
  ForgotPasswordLink,
  ProfileContent,
  ProfileForm,
  AddPhoneNumberText,
  SubmitButtons,
  AddPhoneNumberButton,
  LoginWarningWrapper,
  MessagesPageWrapper,
  SignAgreementWrapper,
  LoadingCircularWrapper,
};

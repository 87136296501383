import React, { useEffect, useMemo, useCallback } from 'react';
import { useFormContext } from '../FormContext';
import {
  RadioGroup,
  RadioGroupWrapper,
  RadioGroupWrapperAnimation,
  StepFormWrapper,
  RequiredLabel,
  Content,
} from '../styles';
import { PrimaryLabel } from '../../styles';
import RadioInput from '../../ui/Radio';
import {
  ShippingIconBus,
  ShippingIconPlane,
  ShippingIconShip,
} from '../../../assets/images';
import Button from '../../ui/Button';
import InputBlockSecondary from '../../ui/InputBlock/secondary';
import RadioGroupComponent from '../../ui/RadioGroup';
import { TransportationStepLabels } from '../../../constants/labels';
import { useMobile, useTablet } from '../../../constants/breakpoints';
import { Modal } from '@mui/material';
import CloseModalButton from '../../ui/CloseModalBtn';
import { getSessionStorage, searchByCountry } from '../../../utils/global';
import AutoCompleteInput from '../../ui/Input/AutoCompleteInput';

const TransportationStep = () => {
  const {
    formData,
    updateFormData,
    validateFormData,
    showModal,
    updateShowModal,
    resetError
  } = useFormContext();
  const { step1 } = formData;
  const isMobile = useMobile();
  const isTablet = useTablet();
  const localFormData = getSessionStorage('data');

  const handleModalClose = () => {
    updateShowModal(false);
  };
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === 'OTHER') {
      updateFormData('step1', {
        export_custom_clearance: 'false',
        import_custom_clearance: 'false',
        cargo_requirement: '',
        delivery_conditions: '',
        import_custom_clearance_place: '',
        export_custom_clearance_place: '',
        delivery_comment: '',
      });
    }

    updateFormData('step1', { [e.target.name]: e.target.value });
    resetError('step1');
  }, [updateFormData]);

  const handleChangeInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    maxLength: number,
  ) => {
    if (e.target.value.length <= maxLength) {
      updateFormData('step1', { [e.target.name]: e.target.value });
      resetError('step1');
    }
  };

  useEffect(() => {
    if (
      step1?.import_custom_clearance === 'false' &&
      !step1?.export_custom_clearance &&
      formData?.step1?.cargo_requirement === 'transportation_only'
    ) {
      updateFormData('step1', {
        import_custom_clearance: 'false',
        export_custom_clearance: 'false',
      });
      resetError('step1');
    }
  }, [formData?.step1?.cargo_requirement]);

  useEffect(() => {
    if (!step1.isCompleted && !getSessionStorage('data')) {
      updateFormData('step1', {
        export_custom_clearance: 'false',
        import_custom_clearance: 'false',
        cargo_requirement: '',
        delivery_conditions: '',
        import_custom_clearance_place: '',
        export_custom_clearance_place: '',
        delivery_comment: '',
      });
      resetError('step1');
    }
  }, [step1?.mode_of_transportation]);

  useEffect(() => {
    if (step1?.mode_of_transportation && (localFormData?.step1?.mode_of_transportation !== step1?.mode_of_transportation)) {
      updateFormData('step4', {
        type_of_transportation: '',
        cargo_params: ''
      });
    }
  }, [step1?.mode_of_transportation])

  const conditionConfig = useMemo(
    () => [
      {
        id: 'delivery_conditions',
        text: 'EXW',
        name: 'delivery_conditions',
        onChange: handleChange,
        value: 'EXW',
        isActive: step1?.delivery_conditions === 'EXW',
      },
      {
        id: 'delivery_conditions2',
        text: 'FOB',
        name: 'delivery_conditions',
        onChange: handleChange,
        value: 'FOB',
        isActive: step1?.delivery_conditions === 'FOB',
      },
      {
        id: 'delivery_conditions3',
        text: 'FCA',
        name: 'delivery_conditions',
        onChange: handleChange,
        value: 'FCA',
        isActive: step1?.delivery_conditions === 'FCA',
      },
      {
        id: 'delivery_conditions4',
        text: 'CIF',
        name: 'delivery_conditions',
        onChange: handleChange,
        value: 'CIF',
        isActive: step1?.delivery_conditions === 'CIF',
      },
      {
        id: 'delivery_conditions5',
        text: 'Інше',
        name: 'delivery_conditions',
        onChange: handleChange,
        value: 'OTHER',
        isActive: step1?.delivery_conditions === 'OTHER',
      },
    ],
    [step1, handleChange],
  );

  const includeTaxesConfig = useMemo(
    () => [
      {
        id: 'cargo_requirement',
        text: 'Мені потрібно Cargo (DDP)',
        name: 'cargo_requirement',
        onChange: handleChange,
        value: 'cargo_needed',
        isActive: step1?.cargo_requirement === 'cargo_needed',
      },
      {
        id: 'cargo_requirement2',
        text: 'Мені потрібне лише перевезення',
        name: 'cargo_requirement',
        onChange: handleChange,
        value: 'transportation_only',
        isActive: step1?.cargo_requirement === 'transportation_only',
      },
    ],
    [step1, handleChange],
  );

  const inTariffConfig = useMemo(
    () => [
      {
        id: 'export_custom_clearance',
        text: isMobile ? 'Я' : 'Замитнюю сам',
        name: 'export_custom_clearance',
        onChange: handleChange,
        value: 'true',
        isActive: formData.step1?.export_custom_clearance === 'true',
      },
      {
        id: 'export_custom_clearance2',
        text: isMobile ? 'Difreight' : 'Замитнює Difreight',
        name: 'export_custom_clearance',
        onChange: handleChange,
        value: 'false',
        isActive: formData.step1?.export_custom_clearance === 'false',
      },
    ],
    [step1, handleChange],
  );

  const outTariffConfig = useMemo(
    () => [
      {
        id: 'import_custom_clearance',
        text: isMobile ? 'Я' : 'Розмитнюю сам',
        name: 'import_custom_clearance',
        onChange: handleChange,
        value: 'true',
        isActive: formData.step1?.import_custom_clearance === 'true',
      },
      {
        id: 'import_custom_clearance2',
        text: isMobile ? 'Difreight' : 'Розмитнює Difreight',
        name: 'import_custom_clearance',
        onChange: handleChange,
        value: 'false',
        isActive: formData.step1?.import_custom_clearance === 'false',
      },
    ],
    [step1, handleChange],
  );

  const handleChangeAddress = (value: any, name: string, coordinates?: any) => {
    const coordinatesNameLat = `${name}_coordinates_lat`;
    const coordinatesNameLng = `${name}_coordinates_lng`;
    updateFormData('step1', {
      [name]: value,
      [coordinatesNameLat]: coordinates?.lat,
      [coordinatesNameLng]: coordinates?.lng,
    });
  };

  const renderShippingDetails = () => {
    return (
      <div style={{ paddingBottom: '1rem' }}>
        <RadioGroupWrapperAnimation
          className={
            step1?.mode_of_transportation === 'air' ||
            step1?.mode_of_transportation === 'sea'
              ? 'visible fade-in'
              : ''
          }
        >
          <RadioGroupComponent
            dataConfig={conditionConfig}
            isCompressed={false}
          />

          {step1?.delivery_conditions === 'OTHER' && (
            <InputBlockSecondary
              id={'otherConditionInput'}
              placeholder={'Ваш коментар'}
              type={'text'}
              onChange={e => handleChangeInput(e, 32)}
              text={'Коли обрано поле «Інше», просимо написати коментар'}
              name={'delivery_comment'}
              value={step1?.delivery_comment}
              isTextarea={true}
            />
          )}
        </RadioGroupWrapperAnimation>

        <RadioGroupWrapperAnimation
          className={
            step1?.mode_of_transportation === 'land' ||
            (!!step1?.delivery_conditions)
              ? 'visible fade-in'
              : ''
          }
        >
          <RadioGroupComponent
            dataConfig={includeTaxesConfig}
            isCompressed={true}
            className={'cargo'}
          />
        </RadioGroupWrapperAnimation>

        <RadioGroupWrapperAnimation
          className={
            step1?.cargo_requirement === 'transportation_only'
              ? 'visible tariff fade-in'
              : ''
          }
        >
          <RadioGroupComponent
            dataConfig={inTariffConfig}
            isCompressed={true}
            labelText={'Хто замитнює?'}
            className={
              step1?.export_custom_clearance === 'true'
                ? 'mobileTariff'
                : 'inTariff'
            }
          />

          {step1?.export_custom_clearance === 'true' && (
            // <InputBlockSecondary
            //   id={'export_custom_clearance_place'}
            //   placeholder={'Введіть адресу замитнення'}
            //   type={'text'}
            //   onChange={e => handleChangeInput(e, 32)}
            //   text={'Місце замитнення'}
            //   name={'export_custom_clearance_place'}
            //   value={step1?.export_custom_clearance_place}
            // />
            <AutoCompleteInput
              value={step1?.export_custom_clearance_place}
              name={'export_custom_clearance_place'}
              placeholder={'Введіть адресу замитнення'}
              handleChangeAddress={handleChangeAddress}
              text={'Місце замитнення'}
              className={'secondary-input'}
            />
          )}

          <RadioGroupComponent
            dataConfig={outTariffConfig}
            isCompressed={true}
            labelText={'Хто розмитнює?'}
            className={'outTariff'}
          />

          {step1?.import_custom_clearance === 'true' && (
            // <InputBlockSecondary
            //   id={'import_custom_clearance_place'}
            //   placeholder={'Введіть адресу розмитнення'}
            //   type={'text'}
            //   onChange={e => handleChangeInput(e, 32)}
            //   text={'Місце розмитнення'}
            //   name={'import_custom_clearance_place'}
            //   value={step1?.import_custom_clearance_place}
            // />
            <AutoCompleteInput
              value={step1?.import_custom_clearance_place}
              name={'import_custom_clearance_place'}
              placeholder={'Введіть адресу розмитнення'}
              handleChangeAddress={handleChangeAddress}
              text={'Місце розмитнення'}
              className={'secondary-input'}
            />
          )}
        </RadioGroupWrapperAnimation>
      </div>
    );
  };
  const content = () => {
    return (
      <>
        <RadioGroupWrapper>
          <PrimaryLabel htmlFor={'test'}>
            Оберіть спосіб доставки <RequiredLabel>*</RequiredLabel>
          </PrimaryLabel>
          <RadioGroup>
            <RadioInput
              id={'test'}
              text={TransportationStepLabels.type.land}
              name={'mode_of_transportation'}
              onChange={handleChange}
              value={'land'}
              isActive={formData.step1?.mode_of_transportation === 'land'}
              icon={ShippingIconBus}
            />
            <RadioInput
              id={'test3'}
              text={TransportationStepLabels.type.air}
              name={'mode_of_transportation'}
              onChange={handleChange}
              value={'air'}
              isActive={formData.step1?.mode_of_transportation === 'air'}
              icon={ShippingIconPlane}
            />
            <RadioInput
              id={'test2'}
              text={TransportationStepLabels.type.sea}
              name={'mode_of_transportation'}
              onChange={handleChange}
              value={'sea'}
              isActive={formData.step1?.mode_of_transportation === 'sea'}
              icon={ShippingIconShip}
            />
          </RadioGroup>
          {!step1.isValid && (step1.isCompleted || step1?.hasError) && (
            <RequiredLabel style={{ paddingLeft: '0.75rem', fontWeight: 400, fontSize: '0.875rem' }}>Обовʼязкове поле</RequiredLabel>
          )}
        </RadioGroupWrapper>
        {renderShippingDetails()}
        <Button
          text={'Підтвердити'}
          type={'primary'}
          onClick={() =>
            validateFormData('step1', formData.step1.mode_of_transportation)
          }
        />
      </>
    );
  };
  return (
    <StepFormWrapper>
      {(isMobile || isTablet) ? (
        <Modal
          open={showModal}
          className="transportationStep"
        >
          <Content>
            <CloseModalButton onClose={handleModalClose} />
            {content()}
          </Content>
        </Modal>
      ) : (
        <div className="transportationStep">{content()}</div>
      )}
    </StepFormWrapper>
  );
};

export default TransportationStep;

//@ts-nocheck
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useRef,
} from 'react';
import {
  getCurrencies,
  orderConfig,
  warehousesConfig,
} from '../../utils/axios/get';
import { createRequest } from '../../utils/axios/post';
import { useNavigate } from 'react-router-dom';
import { useMobile } from '../../constants/breakpoints';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { phoneNormalize } from '../../utils/validation';
import {
  getSessionStorage,
  removeSessionStorage,
  setSessionStorage,
} from '../../utils/global';
import { debounce } from 'lodash';
import { AuthContext } from '../../index';

export interface FormData {
  step1: { [key: string]: any };
  step2: { [key: string]: any };
  step3: { [key: string]: any };
  step4: { [key: string]: any };
}

interface FormContextProps {
  currentStep: number;
  nextStep: () => void;
  prevStep: () => void;
  goToStep: (step: number) => void;
  formData: FormData;
  updateFormData: (step: keyof FormData, data: { [key: string]: any }) => void;
  validateFormData: (
    step: keyof FormData,
    data: { [key: string]: any },
  ) => void;
  config: any;
  warehousesConfigState: any;
  showModal: boolean;
  updateShowModal: (value: boolean) => void;
  prepareFormData: () => void;
  resetError: (step: keyof FormData) => void;
  errorFields: any;
  updateErrorFields: (field: string) => void;
  resetErrorFields: () => void;
  resetSelectedErrorField: (field: string) => void;
  currencies: any;
  disableSubmit: boolean;
  isLoading: boolean;
}

const FormContext = createContext<FormContextProps | null>(null);

export const useFormContext = (): FormContextProps => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useFormContext must be used within a FormProvider');
  }
  return context;
};

interface FormProviderProps {
  children: ReactNode;
}

export const FormProvider: React.FC<FormProviderProps> = ({ children }) => {
  const isJustSigned = localStorage.getItem('signedContract');
  const isMobile = useMobile();
  const [currentStep, setCurrentStep] = useState<number>(isMobile ? 0 : 1);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    step1: {
      isValid: false,
      isCompleted: false,
      isTouched: true,
      hasError: false,
    },
    step2: {
      isValid: false,
      isCompleted: false,
      isTouched: false,
      hasError: false,
    },
    step3: {
      isValid: false,
      isCompleted: false,
      isTouched: false,
      hasError: false,
    },
    step4: {
      isValid: false,
      isCompleted: false,
      isTouched: false,
      hasError: false,
      goods: [],
    },
  });
  const [config, setConfig] = useState({});
  const [warehousesConfigState, setWarehousesConfig] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const hasFetchedConfig = useRef(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { isContractSigned } = useContext(AuthContext);
  const navigate = useNavigate();
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(customParseFormat);

  const formatDate = (dateString: string) => {
    const date = dayjs(dateString, 'DD.MM.YY');
    return date.format('YYYY-MM-DDT21:00:00.000[Z]');
  };

  const updateShowModal = (value: boolean) => {
    setShowModal(value);
  };

  const fetchOrderConfigData = async () => {
    if (hasFetchedConfig.current) return;
    hasFetchedConfig.current = true;

    const orderConfigData = await orderConfig();
    const warehousesConfigData = await warehousesConfig();
    const currenciesData = await getCurrencies();
    setWarehousesConfig(warehousesConfigData);
    setConfig(orderConfigData);
    setCurrencies(currenciesData?.currencies);
  };

  const debouncedFetch = debounce(fetchOrderConfigData, 300);

  useEffect(() => {
    debouncedFetch();
    return () => {
      debouncedFetch.cancel();
    };
  }, []);

  useEffect(() => {
    const localStorageFormData = getSessionStorage('data');
    if (localStorageFormData) {
      setFormData(localStorageFormData);
      if (isJustSigned === 'true' && !isMobile) {
        setCurrentStep(4);
      }
      updateFormData('step4', {
        other_file: [],
        msds_file: [],
        packing_list_file: null,
      });
      if (localStorageFormData?.step4?.invoice_file) {
        updateFormData('step4', {
          invoice_file: '',
        });
      }
    }
  }, []);

  const nextStep = () => setCurrentStep(prev => Math.min(prev + 1, 4));
  const prevStep = () => setCurrentStep(prev => Math.max(prev - 1, 1));
  const goToStep = (step: number) => {
    const targetStepKey = `step${step}` as keyof FormData;
    resetError('step1');
    resetError('step2');
    resetError('step3');
    resetError('step4');
    if (targetStepKey === 'step1') {
      validateFormData('step2', formData.step2.country_from);
      validateFormData('step3', formData?.step3.country_to);
    }

    if (targetStepKey === 'step2') {
      validateFormData('step1', formData.step1.mode_of_transportation);
      validateFormData('step3', formData?.step3.country_to);
      if (formData.step1.mode_of_transportation) {
        setCurrentStep(step);
        updateShowModal(true);
      } else {
        updateFormData('step1', {
          hasError: true,
        });
      }
      return;
    } else if (targetStepKey === 'step3') {
      if (formData.step2.contact_person_phone) {
        // updateFormData('step2', {
        //   contact_person_phone:
        //     formData.step2.contact_person_phone.length == 19 ||
        //     (formData.step2.contact_person_phone.length === 13 &&
        //       !formData.step2.contact_person_phone?.includes('('))
        //       ? phoneNormalize(formData.step2.contact_person_phone)
        //       : '',
        // });
      }

      validateFormData('step2', formData.step2.country_from);
      validateFormData('step3', formData?.step3.country_to);
      if (formData.step2.country_from) {
        setCurrentStep(step);
        updateShowModal(true);
      } else {
        updateFormData('step2', {
          hasError: true,
        });
        if (!formData.step1.mode_of_transportation) {
          updateFormData('step1', {
            hasError: true,
          });
        }
      }
      return;
    } else if (targetStepKey === 'step4') {
      validateFormData('step3', formData?.step3.country_to);
      if (formData?.step3.country_to) {
        setCurrentStep(step);
        updateShowModal(true);
      } else {
        updateFormData('step3', {
          hasError: true,
        });
        if (!formData.step2.country_from) {
          updateFormData('step2', {
            hasError: true,
          });
        }
        if (!formData.step1.mode_of_transportation) {
          updateFormData('step1', {
            hasError: true,
          });
        }
      }
      return;
    }

    if (currentStep > step || formData[targetStepKey].isTouched) {
      setCurrentStep(step);
      updateShowModal(true);
    }
  };

  useEffect(() => {
    if (
      formData?.step1?.isValid ||
      formData?.step2?.isValid ||
      formData?.step3?.isValid ||
      currentStep === 4
    ) {
      setSessionStorage('data', JSON.stringify(formData));
    }
  }, [formData]);

  const validateFormData = (
    step: keyof FormData,
    data: { [key: string]: any },
  ) => {
    if (data) {
      setFormData(prev => ({
        ...prev,
        [step]: {
          ...prev[step],
          isValid: true,
          isCompleted: true,
        },
      }));
      nextStep();
    } else {
      setFormData(prev => ({
        ...prev,
        [step]: {
          ...prev[step],
          isCompleted: true,
        },
      }));
    }
    // Code to validate
  };

  const updateFormData = (
    step: keyof FormData,
    data: { [key: string]: any },
  ) => {
    setFormData(prev => ({
      ...prev,
      [step]: {
        ...prev[step],
        ...data,
        isCompleted: false,
      },
    }));
  };

  const resetError = (step: keyof FormData) => {
    updateFormData(step, {
      hasError: false,
    });
  };

  const calculatePriceByCurrency = (price: any) => {
    const currencyName = formData?.step4?.currency_id || 'USD';
    const currentCurrency = currencies?.find(
      (currency: any) => currency?.name === currencyName,
    );
    if (currentCurrency?.exchange_rates?.length) {
      const currentRate =
        currentCurrency?.exchange_rates?.[
          currentCurrency?.exchange_rates?.length - 1
        ]?.rate;
      if (currentRate) {
        const convertedResult = (Number(price) / Number(currentRate)).toFixed(
          2,
        );
        return convertedResult;
      }
    }
    return price;
  };

  const getFulFillmentID = (value: any) => {
    const data = warehousesConfigState?.warehouses?.filter((warehouse: any) => {
      if (warehouse?.name === value) {
        return warehouse?.id;
      }
    });
    return data?.[0]?.id || '';
  };

  const prepareFormData = async () => {
    const { step1, step2, step3, step4 } = formData;

    if (!isContractSigned) {
      setErrorFields(prevData => [...prevData, 'contract']);
      updateFormData('step4', {
        hasError: true,
      });
      return;
    }

    if (
      !formData?.step4?.cargo_params &&
      formData?.step4?.type_of_transportation
    ) {
      setErrorFields(prevData => [...prevData, 'cargo_params']);
      updateFormData('step4', {
        hasError: true,
      });
      return;
    }
    if (
      formData?.step4?.cargo_params === 'packing_list_invoice' &&
      !formData?.step4?.packing_list_file
    ) {
      setErrorFields(prevData => [...prevData, 'files']);
      updateFormData('step4', {
        hasError: true,
      });
      return;
    }

    if (
      formData?.step4?.dangerous_cargo &&
      (!formData.step4?.harmful_materials_class ||
        !formData.step4?.msds_file?.length)
    ) {
      if (!formData.step4?.harmful_materials_class) {
        setErrorFields(prevData => [...prevData, 'harmful_materials_class']);
        updateFormData('step4', {
          hasError: true,
        });
      }
      if (!formData.step4?.msds_file?.length) {
        setErrorFields(prevData => [...prevData, 'msds_file']);
        updateFormData('step4', {
          hasError: true,
        });
      }
      return;
    }

    const preparedFormData = new FormData();
    for (let item in step1) {
      if (
        item === 'isValid' ||
        item === 'isTouched' ||
        item === 'isCompleted' ||
        item === 'hasError'
      ) {
        continue;
      }
      if (item === 'import_custom_clearance_place') {
        if (step1?.import_custom_clearance === 'true') {
          preparedFormData.append(
            `import_custom_clearance_place[address]`,
            step1[item],
          );
        }
        continue;
      }

      if (item === 'import_custom_clearance_place_coordinates_lat') {
        if (step1?.import_custom_clearance === 'true') {
          preparedFormData.append(
            `import_custom_clearance_place[location][lat]`,
            step1[item],
          );
        }
        continue;
      }

      if (item === 'import_custom_clearance_place_coordinates_lng') {
        if (step1?.import_custom_clearance === 'true') {
          preparedFormData.append(
            `import_custom_clearance_place[location][lng]`,
            step1[item],
          );
        }
        continue;
      }

      if (item === 'export_custom_clearance_place') {
        if (step1?.export_custom_clearance == 'true') {
          preparedFormData.append(
            `export_custom_clearance_place[address]`,
            step1[item],
          );
        }
        continue;
      }

      if (item === 'export_custom_clearance_place_coordinates_lat') {
        if (step1?.export_custom_clearance == 'true') {
          preparedFormData.append(
            `export_custom_clearance_place[location][lat]`,
            step1[item],
          );
        }
        continue;
      }

      if (item === 'export_custom_clearance_place_coordinates_lng') {
        if (step1?.export_custom_clearance == 'true') {
          preparedFormData.append(
            `export_custom_clearance_place[location][lng]`,
            step1[item],
          );
        }
        continue;
      }
      preparedFormData.append(item, step1[item]);
    }
    for (let item in step2) {
      if (
        item === 'isValid' ||
        item === 'isTouched' ||
        item === 'isCompleted' ||
        item === 'hasError'
      ) {
        continue;
      }
      if (item === 'delivery_method') {
        if (
          step2?.from_address_type !== 'factory_or_warehouse' &&
          step2?.from_address_type !== 'company_address' &&
          step2?.from_address_type !== 'residence_address'
        ) {
          preparedFormData.append(`delivery_method`, 'supplier');
        } else {
          preparedFormData.append(`delivery_method`, step2[item]);
        }
        continue;
      }
      if (item === 'receiving_place') {
        if (
          (step2?.from_address_type === 'factory_or_warehouse' ||
            step2?.from_address_type === 'company_address' ||
            step2?.from_address_type === 'residence_address') &&
          step2?.delivery_method === 'pick_up'
        ) {
          preparedFormData.append(`receiving_place[address]`, step2[item]);
        }
        continue;
      }
      if (item === 'receiving_place_coordinates_lat') {
        if (
          (step2?.from_address_type === 'factory_or_warehouse' ||
            step2?.from_address_type === 'company_address' ||
            step2?.from_address_type === 'residence_address') &&
          step2?.delivery_method === 'pick_up'
        ) {
          preparedFormData.append(
            `receiving_place[location][lat]`,
            step2[item],
          );
        }
        continue;
      }
      if (item === 'receiving_place_coordinates_lng') {
        if (
          (step2?.from_address_type === 'factory_or_warehouse' ||
            step2?.from_address_type === 'company_address' ||
            step2?.from_address_type === 'residence_address') &&
          step2?.delivery_method === 'pick_up'
        ) {
          preparedFormData.append(
            `receiving_place[location][lng]`,
            step2[item],
          );
        }
        continue;
      }
      if (item === 'address_from_place') {
        if (
          step2?.from_address_type !== 'factory_or_warehouse' ||
          step2?.from_address_type !== 'company_address' ||
          step2?.from_address_type !== 'residence_address' ||
          !step2?.delivery_method
        ) {
          preparedFormData.append(`address_from_place[address]`, step2[item]);
        }
        continue;
      }
      if (item === 'address_from_place_coordinates_lat') {
        if (
          step2?.from_address_type !== 'factory_or_warehouse' ||
          step2?.from_address_type !== 'company_address' ||
          step2?.from_address_type !== 'residence_address' ||
          !step2?.delivery_method
        ) {
          preparedFormData.append(
            'address_from_place[location][lat]',
            step2[item],
          );
        }
        continue;
      }
      if (item === 'address_from_place_coordinates_lng') {
        if (
          step2?.from_address_type !== 'factory_or_warehouse' ||
          step2?.from_address_type !== 'company_address' ||
          step2?.from_address_type !== 'residence_address' ||
          !step2?.delivery_method
        ) {
          preparedFormData.append(
            'address_from_place[location][lng]',
            step2[item],
          );
        }
        continue;
      }
      if (item === 'shipment_date' && step2[item]) {
        preparedFormData.append(`${item}`, formatDate(step2[item]));
        continue;
      }
      preparedFormData.append(item, step2[item]);
    }
    for (let item in step3) {
      if (
        item === 'isValid' ||
        item === 'isTouched' ||
        item === 'isCompleted' ||
        item === 'hasError'
      ) {
        continue;
      }
      if (item === 'address') {
        if (step3[item]) {
          preparedFormData.append(`address_to_place[${item}]`, step3[item]);
        }
        continue;
      }
      if (item === 'address_coordinates_lat') {
        preparedFormData.append(`address_to_place[location][lat]`, step3[item]);
        continue;
      }
      if (item === 'address_coordinates_lng') {
        preparedFormData.append(`address_to_place[location][lng]`, step3[item]);
        continue;
      }
      if (item === 'region') {
        preparedFormData.append(`address_to_place[${item}]`, step3[item]);
        continue;
      }
      if (item === 'fba_warehouse') {
        preparedFormData.append(`address_to_place[${item}]`, step3[item]);
        continue;
      }
      if (item === 'receiving_warehouse_id') {
        preparedFormData.append(
          `address_to_place[${item}]`,
          getFulFillmentID(step3[item]),
        );
        continue;
      }

      preparedFormData.append(item, step3[item]);
    }
    let currencyID = config?.currencies?.indexOf(formData?.step4?.currency_id);
    if (currencyID === -1) {
      currencyID = 1;
    } else {
      currencyID += 1;
    }
    for (let item in step4) {
      if (
        item === 'isValid' ||
        item === 'isTouched' ||
        item === 'isCompleted' ||
        item === 'sizeType' ||
        item === 'currency_id' ||
        item === 'hasError'
      ) {
        continue;
      }
      if (item === 'cargo_readiness') {
        preparedFormData.append(`${item}`, formatDate(step4[item]));
        continue;
      }
      if (formData.step4?.cargo_params === 'packing_list_invoice') {
        if (item === 'goods') {
          continue;
        }
      }
      if (formData.step4?.cargo_params === 'input_params') {
        if (item === 'goods') {
          if (step4?.goods?.length === 0) {
            preparedFormData.append('goods', '[]');
            continue;
          }
          step4[item].forEach((element: any, index: any) => {
            for (let good in element) {
              if (element[good]) {
                if (
                  good === 'length' ||
                  good === 'width' ||
                  good === 'height' ||
                  good === 'volume'
                ) {
                  preparedFormData.append(
                    `goods[${index}][data][dimensions][${good}]`,
                    element[good],
                  );
                  continue;
                }
                if (
                  good === 'has_trademark' ||
                  good === 'trademark_name' ||
                  good === 'net_weight' ||
                  good === 'gross_weight'
                ) {
                  preparedFormData.append(
                    `goods[${index}][data][${good}]`,
                    element[good],
                  );
                  continue;
                }
                if (good === 'price') {
                  const price = calculatePriceByCurrency(element[good]);
                  preparedFormData.append(
                    `goods[${index}][${good}]`,
                    `${price}`,
                  );
                  continue;
                }
                preparedFormData.append(
                  `goods[${index}][${good}]`,
                  element[good],
                );
              }
            }
            preparedFormData.append(
              `goods[${index}][data][currency_id]`,
              currencyID,
            );
          });
          continue;
        }
      }
      if (item === 'invoice_file') {
        if (
          formData?.step4?.cargo_params !== 'packing_list_invoice' ||
          !step4[item]
        ) {
          continue;
        }
        preparedFormData.append(`files[invoices][0][file]`, step4[item]);
        preparedFormData.append(`files[invoices][0][name]`, step4[item]?.name);
        continue;
      }
      if (item === 'packing_list_file') {
        if (formData?.step4?.cargo_params !== 'packing_list_invoice') {
          continue;
        }
        preparedFormData.append(`files[packing_list][0][file]`, step4[item]);
        preparedFormData.append(
          `files[packing_list][0][name]`,
          step4[item].name,
        );
        continue;
      }
      if (item === 'msds_file') {
        step4[item].forEach((element: any, index: any) => {
          preparedFormData.append(
            `files[certificates][${index}][file]`,
            element,
          );
          preparedFormData.append(
            `files[certificates][${index}][name]`,
            element.name,
          );
        });
        continue;
      }
      if (item === 'other_file') {
        step4[item].forEach((element: any, index: any) => {
          preparedFormData.append(
            `files[other_files][${index}][file]`,
            element,
          );
          preparedFormData.append(
            `files[other_files][${index}][name]`,
            element.name,
          );
        });
        continue;
      }
      preparedFormData.append(item, step4[item]);
    }
    setDisableSubmit(true);
    setIsLoading(true);
    const result = await createRequest(preparedFormData);
    if (result?.success) {
      setTimeout(() => {
        setDisableSubmit(false);
        removeSessionStorage('data');
        navigate('/new-request/success');
        localStorage.removeItem('signedContract');
      }, 0);
    } else {
      setDisableSubmit(false);
      const errors = result?.response?.data?.errors;
      errors?.forEach((error: any) => {
        if (error?.field !== 'goods') {
          updateErrorFields(error?.field);
        } else {
          updateErrorFields(`goods.0.packaging_type`);
          updateErrorFields(`goods.0.price`);
          updateErrorFields(`goods.0.name`);
          updateErrorFields(`goods.0.amount`);
        }
        if (error?.field === 'mode_of_transportation') {
          updateFormData('step1', { hasError: true });
        } else if (error?.field === 'country_from') {
          updateFormData('step2', { hasError: true });
        } else if (error?.field === 'country_to') {
          updateFormData('step3', { hasError: true });
        } else if (error?.field === 'type_of_transportation') {
          updateFormData('step4', { hasError: true });
        } else if (error?.field?.startsWith('goods')) {
          updateFormData('step4', { hasError: true });
        }
      });
    }
    setIsLoading(false);
  };

  const resetErrorFields = () => {
    setErrorFields([]);
  };

  const resetSelectedErrorField = (field: string) => {
    const filteredErrorFields = errorFields?.filter(
      (item: any) => item !== field,
    );
    setErrorFields(filteredErrorFields);
  };

  const updateErrorFields = (field: any) => {
    setErrorFields(prevData => [...prevData, field]);
  };

  return (
    <FormContext.Provider
      value={{
        currentStep,
        nextStep,
        prevStep,
        goToStep,
        formData,
        updateFormData,
        validateFormData,
        config,
        warehousesConfigState,
        showModal,
        updateShowModal,
        prepareFormData,
        resetError,
        errorFields,
        updateErrorFields,
        resetErrorFields,
        resetSelectedErrorField,
        currencies,
        disableSubmit,
        isLoading,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

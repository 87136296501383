import React from 'react';
import { GridPriceOfferTitle, GridPriceOfferWrapper, TableRowItem, TableRowWrapper } from './styles';
import dayjs from 'dayjs';
import Bus from '../../assets/ReactSvg/Bus';
import Plane from '../../assets/ReactSvg/Plane';
import Ship from '../../assets/ReactSvg/Ship';
import { useDashboardDesktop, useDesktop, useDesktopBig } from '../../constants/breakpoints';
import { useNavigate } from 'react-router-dom';
import { formatTransportationStatus } from '../../utils/global';
import TablePriceOffers from './TablePriceOffers';
import { Subtitle } from '../Details/styles';
import GridPriceOffers from './GridPriceOffers';

// type TableRowType = {
//   id: number,
//   created_at: string,
//   df_number: string,
//   status: string,
//   mode_of_transportation: string,
//   type_of_transportation: string,
//   delivery_method: string,
//   cargo_requirement: string,
//   country_from: {
//     country_from: string,
//   }
//   country_to: {
//     country_to: string
//   }
// }

const TableRow = ({ data, isShowActive }: any) => {
  const navigate = useNavigate();
  const isDesktopBig = useDashboardDesktop();
  const isDesktop = useDesktop();

  const detailsNavigate = (id: number) => {
    navigate(`/details/${id}`);
  };

  return (
    <TableRowWrapper className={isShowActive ? '' : 'archive'} onClick={() => detailsNavigate(data.id)}>
      <TableRowItem>
        <div className="icon-wrapper">
          {data.mode_of_transportation === 'land' ? (
            <Bus />
          ) : data.mode_of_transportation === 'air' ? (
            <Plane />
          ) : data.mode_of_transportation === 'sea' ? (
            <Ship />
          ) : (
            <></>
          )}
        </div>
      </TableRowItem>
      {!isDesktop && <TableRowItem>Title</TableRowItem>}
      {isDesktop && (
        <TableRowItem>
          {dayjs(data?.created_at)?.format('DD.MM.YY')}
        </TableRowItem>
      )}
      <TableRowItem>
        <div className={
          (data?.status === 'tariff_lost' ||
            data?.status === 'rejected')
            ? 'status error-status'
            : (data?.status === 'received' ||
              data?.status === 'confirmed')
              ? 'status success-status'
              : 'status'
        }>{formatTransportationStatus(data?.status)}</div>
      </TableRowItem>
      {isDesktop && <TableRowItem>{data?.title}</TableRowItem>}
      <TableRowItem>{data?.df_number}</TableRowItem>
      {isDesktopBig && <TableRowItem>
        {!!data?.warehouse_goods?.length && data?.warehouse_goods[0]?.name}
        {!!data?.goods?.length && !data?.warehouse_goods?.length && data?.goods[0]?.name}
        {!data?.warehouse_goods?.length && !data?.goods?.length && '-'}
      </TableRowItem>}
      <TableRowItem>
        <span>{data?.country_from?.country_from}</span>
      </TableRowItem>
      <TableRowItem>
        <span>{data.country_to.country_to}</span>
      </TableRowItem>
      <TableRowItem>{data.type_of_transportation}</TableRowItem>
      <TableRowItem>
        {
          !!data?.warehouse_goods?.length &&
          `${data.warehouse_total?.total_places}, ${data.warehouse_total?.total_gross_weight} кг, ${data.warehouse_total?.total_volume} м3`
        }
        {
          !!data?.goods?.length && !data?.warehouse_goods?.length &&
          `${data.goods_total?.total_amount}, ${data.goods_total?.total_gross_weight} кг, ${data.goods_total?.total_volume} м3`
        }
        {!data?.warehouse_goods?.length && !data?.goods?.length && '-'}
      </TableRowItem>
      <TableRowItem>
        {
          (data?.invoices?.length) ? (
            <>
              {!!data?.invoices?.length && (
                <GridPriceOfferWrapper className={'dashboard-table'}>
                   <span className="green">$ {data?.invoces_total?.total_paid?.toFixed(2)}</span>
                   <span className="red">$ {(data?.invoces_total?.total_amount?.toFixed(2) - data?.invoces_total?.total_paid?.toFixed(2)).toFixed(2)}</span>
                </GridPriceOfferWrapper>
              )}
            </>
          ) : (
            <>
              {(data?.price_offers?.length > 1 || data?.price_offers?.[0]?.total !== 0) && <TablePriceOffers data={data.price_offers} />}
              {(!data?.price_offers?.length) && <div><p>-</p></div>}
            </>
          )
        }
      </TableRowItem>
      {
        isShowActive && (
          <TableRowItem>
            {(data.need_client_actions && data?.status !== 'cargo_issues') && <div className="table-action">Необхідна ваша дія</div>}
            {!data.need_client_actions && <div className="no-action">-</div>}
          </TableRowItem>
        )
      }
    </TableRowWrapper>
  );
};

export default TableRow;

import { SVGProps } from 'react';
import { Colors } from '../../components/colors';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width='32'
    height='31'
    viewBox='0 0 29 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M5.49707 23.0451C6.66227 23.1314 7.64264 22.1504 8.49744 22.1504C9.35225 22.1504 10.74 23.0553 11.4978 23.0451C12.4072 23.0528 13.4724 22.1504 14.4982 22.1504C15.524 22.1504 16.5891 23.0528 17.4985 23.0451C18.6637 23.1314 19.6441 22.1504 20.499 22.1504C21.3537 22.1504 22.7415 23.0553 23.4993 23.0451'
      stroke={Colors.white} strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M9.09779 22.1496C7.82156 20.5596 6.9221 18.5246 6.53908 17.4463C6.41762 17.1043 6.3569 16.9334 6.42742 16.7699C6.49794 16.6066 6.67089 16.53 7.01676 16.3768L13.7579 13.3907C14.1226 13.2292 14.3049 13.1484 14.4985 13.1484C14.692 13.1484 14.8744 13.2292 15.2391 13.3907L21.9802 16.3768C22.326 16.53 22.499 16.6066 22.5695 16.7699C22.6401 16.9334 22.5793 17.1043 22.4579 17.4463C22.0748 18.5246 21.1754 20.5596 19.8991 22.1496'
      stroke={Colors.white} strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M9.09668 15.3996L9.29102 12.8731C9.41233 11.2961 9.47299 10.5075 9.99063 10.0282C10.5083 9.54883 11.2991 9.54883 12.8808 9.54883H16.1139C17.6955 9.54883 18.4864 9.54883 19.004 10.0282C19.5217 10.5075 19.5824 11.2961 19.7037 12.8731L19.898 15.3996'
      stroke={Colors.white} strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M11.3477 9.54967L11.502 8.31462C11.6428 7.18832 11.7132 6.62518 12.0961 6.28719C12.4789 5.94922 13.0464 5.94922 14.1815 5.94922H14.8146C15.9497 5.94922 16.5172 5.94922 16.9 6.28719C17.2829 6.62518 17.3533 7.18832 17.4941 8.31462L17.6484 9.54967'
      stroke={Colors.white} strokeLinecap='round' strokeLinejoin='round' />
  </svg>


);
export default SvgComponent;

import styled from 'styled-components';
import { Colors } from '../colors';

interface DashboardTabProps {
  active: boolean;
}

interface DashboardViewSwitcherProps {
  active: boolean;
}

const TableHeaderWrapper = styled.div`
  height: 4.125rem;
  background: ${Colors.primary80};
  width: 100%;
  display: grid;
  grid-template-columns: 3.46% 4.49% 11.42% 14.77% 10.5% 14.77% 5.41% 5.03% 5.57% 9.96% 7.36% 7.25%;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom: 0.5px solid ${Colors.periwinkle};
  border-top: 0.5px solid ${Colors.periwinkle};
  border-left: 0.5px solid ${Colors.periwinkle};
  border-right: 0.5px solid ${Colors.periwinkle};
  &.archive {
    grid-template-columns: 3.46% 4.49% 11.42% 14.77% 10.5% 22.12% 5.41% 5.03% 5.57% 9.96% 7.36%;
    @media (max-width: 1670px) {
      grid-template-columns: 3.46% 4.49% 11.42% 29.25% 13.88% 7.41% 7.03% 5.57% 9.96% 7.36%;
    }
  }
  @media (max-width: 1670px) {
    grid-template-columns: 3.46% 4.49% 11.42% 22.15% 13.88% 7.41% 7.03% 5.57% 9.96% 7.36% 7.25%;
    //grid-template-columns: 0.0346fr 0.0449fr 0.1142fr 0.1477fr 0.1050fr 0.0541fr 0.0503fr 0.0557fr 0.0996fr 0.0736fr 0.0725fr;
  }
  @media (max-width: 1300px) {
  }
  @media (max-width: 810px) {
    //gap: 0 0.5rem;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    grid-template-rows: auto auto auto;
  }
`;

const TableHeaderItem = styled.div`
  display: flex;
  align-items: start;
  padding: 0.75rem 0.75rem 0 0.75rem;
  justify-content: flex-start;
  color: ${Colors.white};
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.313rem;
  text-align: left;

  &.right {
    justify-content: flex-end;
    text-align: right;
  }
`;

const TableRowWrapper = styled.div`
  height: 6.75rem;
  background: ${Colors.white};
  width: 100%;
  display: grid;
  cursor: pointer;
  grid-template-columns: 3.46% 4.49% 11.42% 14.77% 10.5% 14.77% 5.41% 5.03% 5.57% 9.96% 7.36% 7.25%;
  border-bottom: 0.5px solid ${Colors.periwinkle};
  border-left: 0.5px solid ${Colors.periwinkle};
  border-right: 0.5px solid ${Colors.periwinkle};
  transition: 0.2s;
  &.archive {
    grid-template-columns: 3.46% 4.49% 11.42% 14.77% 10.5% 22.12% 5.41% 5.03% 5.57% 9.96% 7.36%;
    @media (max-width: 1670px) {
      grid-template-columns: 3.46% 4.49% 11.42% 29.25% 13.88% 7.41% 7.03% 5.57% 9.96% 7.36%;
    }
  }
  @media (max-width: 1670px) {
    height: 5.938rem;
    grid-template-columns: 3.46% 4.49% 11.42% 22.15% 13.88% 7.41% 7.03% 5.57% 9.96% 7.36% 7.25%;
    //grid-template-columns: 0.0346fr 0.0449fr 0.1142fr 0.1477fr 0.1050fr 0.0541fr 0.0503fr 0.0557fr 0.0996fr 0.0736fr 0.0725fr;

    //grid-template-columns: 4.27% 5.54% 9.61% 17.61% 12.94% 6.67% 6.20% 6.87% 12.27% 9.07% 8.94%;
  }
  @media (max-width: 1300px) {
    //grid-template-columns: 4.27% 5.54% 12.61% 17.61% 9.94% 6.67% 6.20% 6.87% 12.27% 9.07% 8.94%;
    //gap: 0 0.5rem;
  }
  @media (max-width: 810px) {
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    grid-template-rows: auto auto auto;
    height: auto;
    padding: 0.75rem;
    box-shadow: 0 0 33px 0 #00000017;
  }
  &:hover {
    transition: 0.2s;
    background: ${Colors.mistyLavender};
  }
`;

const TableRowItem = styled.div`
  color: ${Colors.black80};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.875rem;
  line-height: 1.313rem;
  padding: 0 0 0 0.75rem;
  word-wrap: break-word;
  word-break: break-word;
  @media (max-width: 810px) {
    padding: 0.75rem 0.75rem 0 0;
  }

  span {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    @media (max-width: 810px) {
      font-weight: 400;
    }
  }

  div.icon-wrapper {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 0.25rem;
    background: ${Colors.primary80};
    @media (max-width: 810px) {
      width: 1.5rem;
      height: 1.5rem;
    }

    svg {
      stroke: ${Colors.white};
      @media (max-width: 810px) {
        width: 1.375rem;
        height: 1.375rem;
      }
    }
  }

  div.status {
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    background: ${Colors.lightBlue};
    color: ${Colors.primary80};
    &.success-status {
      background: ${Colors.moss_green};
      color: ${Colors.white};
      width: fit-content;
    }
    &.error-status {
      background: ${Colors.terra_cotta};
      color: ${Colors.white};
      width: fit-content;
    }
  }

  div.table-action {
    background: ${Colors.Seafoam_Green};
    color: ${Colors.white};
    padding: 0.5rem 0.75rem;
    border-radius: 0.25rem;
    max-width: 90%;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    word-wrap: normal;
    word-break: normal;
  }
`;

const DashboardTableWrapper = styled.div`
  width: 100%;
  //border-left: 0.5px solid ${Colors.periwinkle};
  //border-right: 0.5px solid ${Colors.periwinkle};
  box-shadow: 0 0 33px 0 #00000017;
  @media (max-width: 810px) {
    box-shadow: none;
  }
`;

const TableBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  //border-bottom-left-radius: 0.25rem;
  @media (max-width: 810px) {
    gap: 1.5rem 0;
  }
  > div:last-child {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
`;

const DashboardControlPanelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (max-width: 460px) {
    flex-direction: column;
    gap: 1rem 0;
  }
`;

const SearchInputWrapper = styled.div`
  max-width: 436px;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  @media (max-width: 1200px) {
    max-width: 300px;
  }
  @media (max-width: 810px) {
    max-width: 376px;
  }

  svg {
    position: absolute;
    left: 1rem;
    top: 0.75rem;
  }
  input {
    height: 3.5rem;
  }
`;

const SearchInputWithIcon = styled.input`
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 3.125rem;
  height: 3rem;
  border-radius: 0.25rem;
  border: 1px solid ${Colors.periwinkle};
  color: ${Colors.primary80};
  font-size: 1rem;
  line-height: 1rem;
  transition: 0.2s;
  &:hover {
    background: ${Colors.mistyLavender};
    transition: 0.2s;
  }

  &::placeholder {
    color: ${Colors.primary60};
  }

  &:focus {
    outline: none;
    border-color: ${Colors.periwinkle};
  }
`;

const DashboardPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 2.5rem 0;
  margin: 2.5rem 0;
  @media (max-width: 480px) {
    margin: 1.25rem 0;
    gap: 1.875rem 0;
  }
`;

const DashboardStatusTabWrapper = styled.div`
  display: flex;
  gap: 0 1.5rem;
  @media (max-width: 460px) {
    width: 100%;
  }
`;

const TabSwitcher = styled.div<DashboardTabProps>`
  width: 120px;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  border-radius: 0.25rem;
  background: ${props => (props.active ? Colors.primary : Colors.white)};
  border: 1px solid
    ${props => (props.active ? 'transparent' : Colors.periwinkle)};
  color: ${props => (props.active ? Colors.white : Colors.primary80)};
  font-size: 1rem;
  line-height: 1rem;
  cursor: pointer;
  transition: 0.2s;
  @media (max-width: 460px) {
    width: 100%;
  }

  &:hover {
    @media (min-width: 1024px) {
      background: ${props =>
        props.active ? Colors.primary60 : Colors.mistyLavender};
      transition: 0.2s;
    }
  }
`;

const ViewSwitcherWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 436px;
  width: 100%;
  @media (max-width: 1200px) {
    max-width: 300px;
  }
  @media (max-width: 1024px) {
    display: none;
  }

  div {
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;

const ViewSwitcherItem = styled.div<DashboardViewSwitcherProps>`
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0.25rem;
  background: ${props => (props.active ? Colors.primary : Colors.white)};
  border: 1px solid ${props => (props.active ? 'transparent' : Colors.primary)};
  transition: 0.2s;

  svg {
    path {
      stroke: ${props => (props.active ? Colors.white : Colors.primary)};
    }
  }
  &:hover {
    transition: 0.2s;
    background: ${props =>
      props.active ? Colors.primary60 : Colors.mistyLavender};
    border: 1px solid
      ${props => (props.active ? 'transparent' : Colors.primary60)};
    svg {
      path {
        stroke: ${props => (props.active ? Colors.white : Colors.primary60)};
      }
    }
  }
`;

const DashboardGridsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2.5rem;
  width: 100%;
  position: relative;

  @media ((min-width: 1025px) and (max-width: 1200px)) {
    //display: flex;
    //justify-content: space-between;
    //flex-wrap: wrap;
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 1024px) {
    width: 100%;
    grid-template-columns: 1fr;
    padding-bottom: 1.5rem;
  }
  @media (max-width: 767px) {
    height: 100%;
  }
`;

const DashboardGridItem = styled.div`
  max-width: 432px;
  width: 100%;
  padding: 1rem;
  box-shadow: 0 0 33px 0 #00000017;
  background: ${Colors.white};
  height: fit-content;
  cursor: pointer;
  border-radius: 0.25rem;
  transition: 0.2s;
  &:hover {
    transition: 0.2s;
    background: ${Colors.mistyLavender};
  }

  @media (max-width: 1023px) {
    max-width: 100%;
    padding: 0.75em;
    border-radius: 0.25em;
  }
  div:last-child {
    padding-bottom: 0;
  }

  div.icon-wrapper {
    width: 100%;
    max-width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    background: ${Colors.primary80};
    @media (max-width: 1024px) {
      margin: 0;
    }
    @media (max-width: 810px) {
      padding: 0 0.125rem;
      width: 1.5rem;
      height: 1.5rem;
    }

    svg {
      stroke: ${Colors.white};
      @media (max-width: 810px) {
        width: 22px;
        height: 22px;
      }
    }
  }
`;

const GridTitleWrapper = styled.div`
  display: flex;
  gap: 0 0.75rem;
  align-items: center;
  justify-content: start;
  @media (max-width: 1024px) {
    justify-content: space-between;
  }
  @media (max-width: 480px) {
    justify-content: start;
  }

  > p {
    word-wrap: break-word;
    word-break: break-word;
    font-size: 1.125rem;
    line-height: 1.688rem;
    color: ${Colors.primary};
  }

  @media ((min-width: 768px) and (max-width: 1023px)) {
    > div {
      display: flex;
      gap: 0.75rem;
      align-items: end;
      > p {
        width: 331px;
        font-size: 1.125rem;
        line-height: 1.688rem;
        color: ${Colors.primary};
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    div.status {
      width: 277px;
      font-size: 0.875rem;
      padding: 0.5rem 0.75rem;
      border-radius: 0.25rem;
      background: ${Colors.lightBlue};
      color: ${Colors.primary80};
      font-weight: 400;
      line-height: 1.313rem;
      &.success-status {
        background: ${Colors.moss_green};
        color: ${Colors.white};
      }
      &.error-status {
        background: ${Colors.terra_cotta};
        color: ${Colors.white};
      }
    }
  }

  @media ((max-width: 767px)) {
    > p {
      width: 256px;
      font-size: 1rem;
      line-height: 1.688rem;
      color: ${Colors.primary};
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    div.status {
      font-size: 0.875rem;
      padding: 0.5rem 0.75rem;
      border-radius: 0.25rem;
      background: ${Colors.lightBlue};
      color: ${Colors.primary80};
    }
  }
`;

const GridHeaderDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 1.5rem;
  margin-top: 1.75rem;
  padding-bottom: 0.75rem;

  p {
    display: flex;
    align-items: center;
    gap: 0 0.125rem;
    color: ${Colors.black80};
    font-size: 1rem;
    line-height: 1.5rem;
  }

  div.status {
    padding: 0.5rem 0.75rem;
    border-radius: 0.25rem;
    background: ${Colors.lightBlue};
    color: ${Colors.primary80};
    &.success-status {
      background: ${Colors.moss_green};
      color: ${Colors.white};
      width: fit-content;
    }
    &.error-status {
      background: ${Colors.terra_cotta};
      color: ${Colors.white};
      width: fit-content;
    }
  }

  @media (max-width: 1023px) {
    padding-bottom: 0;
    margin-top: 0.5em;
    div.status {
      width: 100%;
      font-size: 0.875em;
    }
  }
`;

const GridItemTabletContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 0.75rem;
`;

const GridItemDetailsItem = styled.div`
  padding: 0.75rem 0;
  border-top: 0.5px solid ${Colors.periwinkle};
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.need_client_action-none {
    padding-bottom: 0;
  }

  div {
    span {
      color: ${Colors.primary80};
      font-size: 0.75rem;
      line-height: 0.75rem;
      width: 100%;
      display: block;
    }

    p {
      color: ${Colors.black80};
      font-size: 1rem;
      line-height: 1.5rem;
      margin-top: 0.375rem;
      text-align: right;
    }
  }

  div:nth-child(2) {
    span {
      text-align: right;
    }
  }

  @media (max-width: 767px) {
    border-top: none;
    padding: 0 0 0.5em 0;
    > p {
      margin-top: 0.375rem;
      display: flex;
      align-items: center;
      gap: 0.125rem;
    }
  }
`;
export const GridPriceOffersWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const GridPriceOfferWrapper = styled.div`
  margin-right: 1rem;
  &.dashboard-table {
    display: flex;
    flex-direction: column;
    .green {
      color: ${Colors.Seafoam_Green};
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.313rem;
    }
    .red {
      color: ${Colors.deepRed};
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.313rem;
    }
  }
`;

const GridPriceOfferTitle = styled.div`
  font-size: 0.75rem;
  color: ${Colors.primary60};
`;

const GridPriceOfferBody = styled.div`
  padding-top: 0.375rem;
`;

const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const ActionRequiredButton = styled.div`
  width: 100%;
  background: ${Colors.Seafoam_Green};
  border-radius: 0.25rem;
  padding: 0.5rem 0.75rem !important;
  height: 3rem;
  color: ${Colors.white};
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  word-wrap: normal;
  word-break: normal;

  &:hover {
    background: ${Colors.moss_green};
  }

  @media ((min-width: 768px)and (max-width: 1023px)) {
    width: 277px;
    //margin-top: 0.5em;
  }
  @media (max-width: 767px) {
    margin-top: 1rem;
  }
`;

const TablePriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const TablePriceHeader = styled.div`
  color: ${Colors.primary60};
  font-size: 0.75rem;
  line-height: 1.125rem;
`;

const TablePriceBody = styled.div`
  color: ${Colors.black80};
  font-size: 0.875rem;
  line-height: 1.313rem;
`;

const EmptyStateDashboard = styled.div`
  box-shadow: 0 0 33px 0 #00000017;
  padding: 1.5rem 1rem 1rem;
  border-radius: 0.25rem;
  background: ${Colors.white};
  display: flex;
  flex-direction: column;
  gap: 2.5rem 0;
  max-width: 432px;
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  @media (max-width: 480px) {
    max-width: 316px;
    top: 65%;
  }
  @media (max-height: 480px) {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
  }
  p {
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${Colors.black90};
    width: 100%;
    text-align: center;
  }
`;

export {
  TableHeaderWrapper,
  TableHeaderItem,
  TableRowWrapper,
  DashboardTableWrapper,
  TableBodyWrapper,
  TableRowItem,
  DashboardControlPanelWrapper,
  SearchInputWithIcon,
  DashboardPageWrapper,
  SearchInputWrapper,
  DashboardStatusTabWrapper,
  TabSwitcher,
  ViewSwitcherWrapper,
  ViewSwitcherItem,
  DashboardGridItem,
  DashboardGridsWrapper,
  GridTitleWrapper,
  GridHeaderDetails,
  GridItemDetailsItem,
  BtnContainer,
  ActionRequiredButton,
  TablePriceWrapper,
  TablePriceHeader,
  TablePriceBody,
  GridPriceOfferWrapper,
  GridPriceOfferTitle,
  EmptyStateDashboard,
  GridItemTabletContainer,
};

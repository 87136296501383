import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  // @ts-ignore
} from 'react-places-autocomplete';
import React, { useEffect, useState } from 'react';
import {
  PrimaryInput,
  PrimaryLabel,
  AutoCompleteInputWrapper,
  AutoCompleteResultsWrapper,
  AutoCompleteResultItem,
  RequiredIndicator,
  InputWarningMessage,
} from '../../styles';

const AutoCompleteInput = ({
  value,
  text,
  placeholder,
  name,
  handleChangeAddress,
  searchOptions,
  required,
  warningMessage,
  className = ''
}: any) => {
  const [address, setAddress] = useState<string>(value);
  const [coordinates, setCoordinates] = useState<{
    lat: number;
    lng: number;
  } | null>(null);

  const handleChange = (address: string) => {
    setAddress(address);
  };

  useEffect(() => {
    setAddress(value);
  }, [value]);

  const handleSelect = (address: string) => {
    setAddress(address);
    handleChangeAddress(address, name);
    geocodeByAddress(address)
      .then((results: any) => getLatLng(results[0]))
      .then((latLng: any) => {
        setCoordinates(latLng);
        handleChangeAddress(address, name, latLng);
      })
      .catch((error: any) => console.error('Error', error));
  };

  return (
    <div>
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={searchOptions}
      >
        {({
          getInputProps,
          suggestions,
          getSuggestionItemProps,
          loading,
        }: any) => (
          <AutoCompleteInputWrapper className={className}>
            <PrimaryLabel>
              {text} {required && <RequiredIndicator>*</RequiredIndicator>}
            </PrimaryLabel>
            <PrimaryInput
              {...getInputProps({
                placeholder: placeholder,
                className: 'location-search-input',
                name: name,
              })}
            />
            {suggestions?.length > 0 && (
              <AutoCompleteResultsWrapper className="autocomplete-dropdown-container">
                {loading && <div>Завантаження...</div>}
                {suggestions.map((suggestion: any) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <AutoCompleteResultItem
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </AutoCompleteResultItem>
                  );
                })}
              </AutoCompleteResultsWrapper>
            )}
          </AutoCompleteInputWrapper>
        )}
      </PlacesAutocomplete>
      {warningMessage && (
        <InputWarningMessage>{warningMessage}</InputWarningMessage>
      )}
    </div>
  );
};
export default AutoCompleteInput;

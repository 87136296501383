import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ?? 36}
    height={props.height ?? 36}
    viewBox="0 0 36 36"
    fill="none"
    {...props}
  >
    <path
      stroke="#3A3875"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M22.508 28.48a4.32 4.32 0 0 1-8.64 0m6.906-18.377a3.6 3.6 0 1 0-5.174 0m11.228 7.145c0-1.986-.91-3.89-2.531-5.295-1.62-1.404-3.818-2.193-6.11-2.193-2.29 0-4.489.789-6.109 2.193-1.62 1.405-2.53 3.31-2.53 5.295 0 3.286-.815 5.689-1.832 7.408-1.159 1.96-1.738 2.94-1.715 3.174.026.268.074.352.291.511.19.139 1.144.139 3.051.139h17.689c1.907 0 2.861 0 3.05-.139.218-.159.266-.243.293-.511.022-.234-.557-1.214-1.716-3.174-1.017-1.72-1.831-4.122-1.831-7.408Z"
    />
  </svg>
);
export default SvgComponent;

import React, { FC, useEffect, useState } from 'react';
import {
  DetailsContainer,
  DetailsHeader,
  DownloadFile,
  Info,
  InfoBox,
  Section,
  Subtitle,
  Title,
} from './styles';
import DocumentSvg from '../../assets/ReactSvg/DocumentSvg';
import {
  useDesktop,
  useDesktopBig,
  useTablet,
  useMobile,
} from '../../constants/breakpoints';
import { useDetailsContext } from '../Threads/DetailsContext';
import {
  getAddressTypeLabel,
  getCountryLabel,
  getDeliveryMethod,
  getExportClearance,
  getImportClearance,
  getMailOfficeLabel,
  getModeOfTransportation,
} from './utils';
import dayjs from 'dayjs';
import { getSupplierInstruction } from '../../utils/axios/get';
import { debounce } from 'lodash';
import { LoadingCircularWrapper } from '../../pages/auth/styles';
import { CircularProgress } from '@mui/material';
import utc from 'dayjs/plugin/utc';
import { truncatedString } from '../../utils/global';

interface IOrderDetailsProps {}

const OrderDetails: FC<IOrderDetailsProps> = ({}) => {
  const { config, commonConfig, warehousesConfigData } = useDetailsContext();
  const isDesktop = useDesktop();
  const isBigDesktop = useDesktopBig();
  const isTablet = useTablet();
  const isMobile = useMobile();
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  dayjs.extend(utc);
  const handleGetDocument = async (id: any) => {
    if (isLoadingFile) {
      return;
    }
    setIsLoadingFile(true);
    try {
      await getSupplierInstruction(id);
    } catch (err) {
      console.log(err);
    }
    setIsLoadingFile(false);
  };

  const debouncedDocument = debounce(handleGetDocument, 300);

  const warehouseName = (id: any) => {
    return warehousesConfigData?.find((warehouse: any) => warehouse?.id === id)
      ?.address;
  };

  return (
    <DetailsContainer>
      <>
        <Section>
          <DetailsHeader>
            <span style={{ maxWidth: isTablet ? '60%' : 'auto' }}>
              Місце відправки:{' '}
              {getCountryLabel(
                commonConfig?.countries,
                config?.country_from?.country_from,
              )}
              {config?.country_from?.from_address_type && ' - '}
              {getAddressTypeLabel(
                commonConfig?.sending_types,
                config?.country_from?.from_address_type,
              )}
              {config?.delivery_method && ' - '}
              {getDeliveryMethod(config?.delivery_method)}
            </span>
            {config?.status !== 'unprocessed' &&
              config?.status !== 'tariff_sent' && (
                <DownloadFile onClick={() => debouncedDocument(config?.id)}>
                  {isLoadingFile ? (
                    <LoadingCircularWrapper>
                      <CircularProgress color="error" size={'1.5rem'} />
                    </LoadingCircularWrapper>
                  ) : (
                    <>
                      <span>
                        {!isDesktop
                          ? 'Файл постачальнику:'
                          : 'Скачати файл для постачальника:'}
                      </span>
                      <DocumentSvg type="alert" />
                    </>
                  )}
                </DownloadFile>
              )}
          </DetailsHeader>

          <Info>
            {config?.delivery_method === 'pick_up' ? (
              <>
                <InfoBox>
                  <Title>Адреса забору вантажу</Title>
                  <Subtitle>
                    {config?.delivery?.address_from?.address_from_place
                      ?.address ??
                      config?.delivery?.address_from?.address ??
                      '-'}
                  </Subtitle>
                </InfoBox>
                <InfoBox>
                  <Title>{!isDesktop ? 'Доставка' : 'Дата доставки'}</Title>
                  <Subtitle>
                    {config?.shipment_date
                      ? dayjs(config?.shipment_date).format('DD.MM.YYYY')
                      : '-'}
                  </Subtitle>
                </InfoBox>
                <InfoBox>
                  <Title>Контактна особа</Title>
                  <Subtitle>
                    {config?.data?.contact_person_name ?? '-'}
                  </Subtitle>
                </InfoBox>

                <InfoBox>
                  <Title>Номер телефона</Title>
                  <Subtitle>
                    {config?.data?.contact_person_phone ?? '-'}
                  </Subtitle>
                </InfoBox>
              </>
            ) : (
              <>
                <InfoBox>
                  <Title>Адреса</Title>
                  <Subtitle>
                    {config?.delivery?.address_from?.address_from_place
                      ?.address ??
                      config?.delivery?.address_from?.address ??
                      '-'}
                  </Subtitle>
                </InfoBox>
              </>
            )}
          </Info>
        </Section>

        <Section>
          <DetailsHeader>
            <span>
              Місце доставки:{' '}
              {getCountryLabel(
                commonConfig?.countries,
                config?.country_to?.country_to,
              )}
              {config?.country_from?.to_address_type && ' - '}
              {getAddressTypeLabel(
                commonConfig?.receiving_types,
                config?.country_to?.to_address_type,
              )}
            </span>
          </DetailsHeader>
          <Info>
            <InfoBox>
              <Title>
                {config?.country_to?.to_address_type === 'amazon_warehouse' ||
                config?.country_to?.to_address_type === 'fulfillment_center'
                  ? 'Адреса складу'
                  : 'Адреса'}
              </Title>
              {config?.country_to?.to_address_type === 'amazon_warehouse' ? (
                <Subtitle>
                  {config?.delivery?.address_to?.fba_warehouse ?? '-'}
                </Subtitle>
              ) : config?.country_to?.to_address_type ===
                'fulfillment_center' ? (
                <Subtitle>
                  {warehouseName(config?.delivery?.receiving_warehouse_id) ??
                    '-'}
                </Subtitle>
              ) : (
                <Subtitle>
                  {config?.delivery?.address_to?.address_to_place ??
                    config?.delivery?.address_to?.address ??
                    '-'}
                </Subtitle>
              )}
            </InfoBox>
            {config?.country_to?.to_address_type === 'amazon_warehouse' && (
              <InfoBox>
                <Title>Регіон</Title>
                <Subtitle>{config?.delivery?.address_to?.region}</Subtitle>
              </InfoBox>
            )}
          </Info>
        </Section>

        <Section>
          <DetailsHeader>
            <span>
              Деталі запиту:{' '}
              {getModeOfTransportation(config?.mode_of_transportation)}{' '}
              {config?.data?.delivery_conditions && ' - '}
              {config?.data?.delivery_conditions} -{' '}
              {getExportClearance(config?.data?.export_custom_clearance)} -{' '}
              {getImportClearance(config?.data?.import_custom_clearance)}
            </span>
          </DetailsHeader>
          <Info>
            <InfoBox
              style={{
                maxWidth: isMobile ? '150px' : '100%',
                minWidth: isTablet ? '227px' : isDesktop ? '293px' : 'auto',
              }}
            >
              <Title>Адреса замитнення</Title>
              <Subtitle>
                {config?.data?.export_custom_clearance_place?.address ?? '-'}
              </Subtitle>
            </InfoBox>
            <InfoBox
              style={{
                minWidth: isTablet
                  ? '227px'
                  : isDesktop
                    ? '293px'
                    : isBigDesktop
                      ? '533px'
                      : 'auto',
                maxWidth: isMobile ? '150px' : isTablet ? '227px' : 'auto',
              }}
            >
              <Title>Адреса розмитнення</Title>
              <Subtitle>
                {config?.data?.import_custom_clearance_place?.address ?? '-'}
              </Subtitle>
            </InfoBox>
            <InfoBox>
              <Title></Title>
              <Subtitle></Subtitle>
            </InfoBox>
            <InfoBox>
              <Title></Title>
              <Subtitle></Subtitle>
            </InfoBox>
          </Info>
        </Section>

        {config?.last_mile && (
          <Section>
            <DetailsHeader>
              <span>Доставка Останньої милі</span>
              {config?.last_mile?.recipient_data_for_delivery && (
                <span>
                  TTH: {config?.last_mile?.recipient_data_for_delivery}
                </span>
              )}
            </DetailsHeader>
            <Info className={'last-mile'}>
              {config?.last_mile?.delivery_method && (
                <InfoBox>
                  <Title>Спосіб доставки</Title>
                  <Subtitle>
                    {getDeliveryMethod(config?.last_mile?.delivery_method) ??
                      '-'}
                  </Subtitle>
                </InfoBox>
              )}

              {config?.last_mile?.post_service && (
                <InfoBox>
                  <Title>Поштова служба</Title>
                  <Subtitle>
                    {getMailOfficeLabel(config?.last_mile?.post_service) ?? '-'}
                  </Subtitle>
                </InfoBox>
              )}

              {config?.last_mile?.recipient_full_name && (
                <InfoBox>
                  <Title>ПІБ отримувача</Title>
                  <Subtitle>
                    {truncatedString(config?.last_mile?.recipient_full_name)}
                  </Subtitle>
                </InfoBox>
              )}

              {config?.last_mile?.recipient_phone && (
                <InfoBox>
                  <Title>Телефон отримувача</Title>
                  <Subtitle>{config?.last_mile?.recipient_phone}</Subtitle>
                </InfoBox>
              )}

              {config?.last_mile?.recipient_city && (
                <InfoBox>
                  <Title>Місто</Title>
                  <Subtitle>
                    {truncatedString(config?.last_mile?.recipient_city)}
                  </Subtitle>
                </InfoBox>
              )}

              {config?.last_mile?.recipient_address?.address && (
                <InfoBox>
                  <Title>Адреса</Title>
                  <Subtitle>
                    {truncatedString(
                      config?.last_mile?.recipient_address?.address,
                    )}
                  </Subtitle>
                </InfoBox>
              )}

              {config?.last_mile?.post_service_office && (
                <InfoBox>
                  <Title>Nº вантажного відділення</Title>
                  <Subtitle>{config?.last_mile?.post_service_office}</Subtitle>
                </InfoBox>
              )}

              {config?.last_mile?.recipient_data_for_delivery && (
                <InfoBox>
                  <Title>Номер ЄДРПОУ</Title>
                  <Subtitle>
                    {config?.last_mile?.recipient_data_for_delivery}
                  </Subtitle>
                </InfoBox>
              )}

              {config?.last_mile?.tax_number && (
                <InfoBox>
                  <Title>Дані отримувача для ТТН</Title>
                  <Subtitle>{config?.last_mile?.tax_number}</Subtitle>
                </InfoBox>
              )}

              {config?.last_mile?.car_brand && (
                <InfoBox>
                  <Title>Марка авто</Title>
                  <Subtitle>{config?.last_mile?.car_brand}</Subtitle>
                </InfoBox>
              )}

              {config?.last_mile?.car_number && (
                <InfoBox>
                  <Title>Номерні знаки</Title>
                  <Subtitle>{config?.last_mile?.car_number}</Subtitle>
                </InfoBox>
              )}

              {config?.last_mile?.full_name_driver && (
                <InfoBox>
                  <Title>ПІБ водія</Title>
                  <Subtitle>{config?.last_mile?.full_name_driver}</Subtitle>
                </InfoBox>
              )}

              {config?.last_mile?.car_arrival_at && (
                <InfoBox>
                  <Title>Час приїзду авто</Title>
                  <Subtitle>
                    {dayjs
                      .utc(config?.last_mile?.car_arrival_at)
                      .format('HH:mm')}
                  </Subtitle>
                </InfoBox>
              )}

              {config?.last_mile?.price_idea && (
                <InfoBox>
                  <Title>Ідея ціна</Title>
                  <Subtitle>{config?.last_mile?.price_idea}</Subtitle>
                </InfoBox>
              )}

              {config?.last_mile?.delivery_comment && (
                <InfoBox>
                  <Title>Коментар</Title>
                  <Subtitle>
                    {truncatedString(config?.last_mile?.delivery_comment)}
                  </Subtitle>
                </InfoBox>
              )}

              {config?.last_mile?.estimated_cost && (
                <InfoBox>
                  <Title>Страховка або оціночна вартість</Title>
                  <Subtitle>{config?.last_mile?.estimated_cost}</Subtitle>
                </InfoBox>
              )}

              {config?.last_mile?.comment && (
                <InfoBox>
                  <Title>Коментар</Title>
                  <Subtitle>
                    {truncatedString(config?.last_mile?.comment)}
                  </Subtitle>
                </InfoBox>
              )}

              {config?.last_mile?.payer && (
                <InfoBox>
                  <Title>Платник</Title>
                  <Subtitle>Клієнт</Subtitle>
                </InfoBox>
              )}
            </Info>
          </Section>
        )}
      </>
    </DetailsContainer>
  );
};

export default OrderDetails;

import styled from 'styled-components';
import { Colors } from '../../colors';

interface CargoDetailsProp {
  amount?: number;
}

const CargoDetailsWrapper = styled.div<CargoDetailsProp>`
  display: grid;
  //17.938rem 14.625rem 28.125rem 18.125rem 10.25rem 16.438rem
  grid-template-columns: ${props =>
    props?.amount === 6
      ? '17.938rem 14.625rem 28.125rem 18.125rem 10.25rem 16.438rem'
      : props?.amount === 5
        ? '1fr 1fr 1fr 1fr 1fr'
        : props?.amount === 4
          ? '1fr 1fr 1fr 1fr'
          : '1fr 2fr 1fr 1fr 1fr;'};
  gap: 0 1.5rem;
  align-items: start;
  padding-top: 1.5rem;
  border-top: 1px solid ${Colors.aqua};
  @media (max-width: 1800px) {
    //grid-template-columns: 1fr 1fr 1fr;
    //gap: 1.5rem;
  }
  @media (max-width: 1919px) {
    grid-template-columns: ${props =>
      props?.amount === 6
        ? '17fr 14fr 26fr 16fr 9.9fr 15fr'
        : '1fr 1fr 1fr 1fr 1fr'};
    gap: 0 1.5rem;
  }
  @media (max-width: 1300px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    //grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem 1.5rem;
  }
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    //grid-template-columns: 1fr;
    gap: 1rem 0;
  }
`;

const CargoDetailsItem = styled.div`
  padding: 0.75rem 1rem 1rem;
  border: 1px solid ${Colors.periwinkle};
  border-radius: 0.25rem;
  min-height: 6rem;
  height: 100%;
  max-height: fit-content;
  @media (max-width: 1300px) {
    width: 47%;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }

  //@todo need to fix
  &.error-field {
    border: 1px solid ${Colors.deepRed};
  }
  #courier_shipment_date {
    padding: 3px 0.75rem;
  }

  label {
    margin-bottom: 0.75rem;
    display: block;
    font-size: 0.875rem;
    line-height: 1.313rem;
    font-weight: 600;
  }
  .calendar-near {
    @media (max-width: 1024px) {
      left: auto;
    }
  }
  &:nth-child(2),
  &:nth-child(1) {
    > div > div {
      &.small {
        top: 0;
      }
      top: 2.25rem;
      //position: absolute;
      > div {
        //top: 2.25rem;
      }
      //FIX 13.08.2024 Calendar issue
      @media (max-width: 1024px) {
      }
    }
  }
  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(4),
  &:nth-child(3) {
    > div > div > .small {
      &.small {
        width: 100%;
        > .small {
          padding: 0;
          > img {
            margin-right: 0.75rem;
          }
          width: 100%;
          > div > span {
            //padding: 3px .75rem;
            @media (max-width: 480px) {
              font-size: 0.875rem;
            }
          }
        }
      }
    }
  }
`;

const AddDocumentLabel = styled.p`
  color: ${Colors.primary80};
  font-size: 1rem;
  line-height: 1.5rem;
  width: fit-content;
  text-align: left;
`;

const DocumentNameLabel = styled.p`
  width: fit-content;
  text-align: left;
  color: ${Colors.warning};
  font-size: 1rem;
  line-height: 1.5rem;
`;

const AddDocumentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 0.5rem;
  cursor: pointer;
  position: relative;

  .icon-with-hover {
    transition: 0.2s;

    path {
      fill: ${Colors.primary60};
    }
  }

  > div {
    display: flex;
    align-items: center;
    gap: 0 0.5rem;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
`;

const SelectedFilesWrapper = styled.div`
  margin-top: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem 0;
`;

const AddRowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 0.75rem;
  margin-bottom: 0.375rem;
  cursor: pointer;
  transition: 0.2s;
  svg {
    transition: 0.2s;
    &:hover {
      transition: 0.2s;
      path {
        fill: ${Colors.primary60};
      }
    }
  }
`;

const AddRowLabel = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${Colors.primary};
`;

const AddRowContainer = styled.div`
  padding: 1rem;
  border-left: 1px solid ${Colors.periwinkle};
  border-right: 1px solid ${Colors.periwinkle};
  border-bottom: 1px solid ${Colors.periwinkle};
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  margin-bottom: 1.5rem;
`;

interface HeaderItemProps {
  amount?: number;
  ddpAmount?: number;
}

const TableBodyContainer = styled.div<HeaderItemProps>`
  display: grid;
  border-bottom: 0.5px solid ${Colors.periwinkle};
  border-left: 0.5px solid ${Colors.periwinkle};
  border-right: 0.5px solid ${Colors.periwinkle};
  grid-template-columns: ${props =>
    props.ddpAmount === 8 && props.amount === 9
      ? '10.75rem 25.1875rem 24.1875rem 5.1875rem 18.625rem 5.1875rem 5.1875rem 15.1875rem 3.0rem'
      : props.amount === 8 && props.ddpAmount === 8
        ? '10.75rem 27.8125rem 26.75rem 5.1875rem 18.625rem 5.1875rem 15.1875rem 3.0rem'
        : props.ddpAmount === 9 && props.amount === 9
          ? '10.75rem 25.0rem 24.1875rem 5.1875rem 18.625rem 5.1875rem 5.9375rem 5.1875rem 9.25rem 3.0rem'
          : props.amount === 8 && !props.ddpAmount
            ? '10.75rem 27.8125rem 26.75rem 5.1875rem 18.625rem 5.9375rem 5.1875rem 9.25rem 3.0rem'
            : '10.75rem 25.1875rem 24.1875rem 5.1875rem 18.625rem 5.1875rem 5.1875rem 15.1875rem 3.0rem'};

  //'10.75rem 26.688rem 26.688rem 5.188rem 18.625rem 5.188rem 5.188rem 15.188rem' : (props.amount === 8 && props.ddpAmount === 8) ?
  //'10.75rem 29.313rem 29.25rem 5.188rem 18.625rem 5.188rem 15.188rem' : props.ddpAmount === 9 && props.amount === 9 ?
  //'10.75rem 26.688rem 26.688rem 5.188rem 18.625rem 5.188rem 5.938rem 5.188rem 9.25rem' : props.amount === 8 && !props.ddpAmount ?
  //'10.75rem 29.313rem 29.25rem 5.188rem 18.625rem 5.938rem 5.188rem 9.25rem' : ''

  //grid-template-columns: 10.75rem 11.875rem 11.875rem 5.188rem 18.625rem 5.188rem 5.938rem 5.188rem 9.25rem;
  @media(min-width: 1750px) and (max-width: 1850px) {
    grid-template-columns: ${props =>
            props.ddpAmount === 8 && props.amount === 9
                    ? '9.56fr 22.39fr 21.51fr 4.61fr 16.56fr 4.61fr 4.61fr 13.50fr 2.67fr'
                    : props.amount === 8 && props.ddpAmount === 8
                            ? '9.56fr 24.72fr 23.78fr 4.61fr 16.56fr 4.61fr 13.50fr 2.67fr'
                            : props.ddpAmount === 9 && props.amount === 9
                                    ? '9.57fr 22.25fr 21.55fr 4.62fr 16.58fr 4.62fr 5.29fr 4.62fr 8.24fr 2.67fr'
                                    : props.amount === 8 && !props.ddpAmount
                                            ? '9.56fr 24.72fr 23.78fr 4.61fr 16.56fr 5.28fr 4.61fr 8.22fr 2.67fr'
                                            : '9.56fr 22.39fr 21.51fr 4.61fr 16.56fr 4.61fr 4.61fr 13.50fr 2.67fr'};
  }

  @media (max-width: 1200px) {
    grid-template-columns: ${props =>
      props.ddpAmount === 8 && props.amount === 9
        ? '10.75rem 11.875rem 11.875rem 5.1875rem 18.625rem 5.1875rem 5.1875rem 15.1875rem 3.0rem'
        : props.amount === 8 && props.ddpAmount === 8
          ? '10.75rem 11.875rem 11.875rem 5.1875rem 18.625rem 5.1875rem 15.1875rem 3.0rem'
          : props.ddpAmount === 9 && props.amount === 9
            ? '10.75rem 11.875rem 11.875rem 5.1875rem 18.625rem 5.1875rem 5.9375rem 5.1875rem 9.25rem 3.0rem'
            : props.amount === 8 && !props.ddpAmount
              ? '10.75rem 11.875rem 11.875rem 5.1875rem 18.625rem 5.9375rem 5.1875rem 9.25rem 3.0rem'
              : '10.75rem 11.875rem 11.875rem 5.1875rem 18.625rem 5.1875rem 5.1875rem 15.1875rem 3.0rem'};
  }
  //grid-template-columns: 172px 190px 190px 83px 298px 83px 95px 83px 148px;

  @media (max-width: 810px) {
    //grid-template-columns: 172px 178px 178px 83px 298px 83px 95px 83px 148px;

    grid-template-columns: ${
      props =>
        props.ddpAmount === 8 && props.amount === 9
          ? '10.75rem 11.125rem 11.125rem 5.1875rem 18.625rem 5.1875rem 5.1875rem 15.1875rem 3.0rem'
          : props.amount === 8 && props.ddpAmount === 8
            ? '10.75rem 11.125rem 11.125rem 5.1875rem 18.625rem 5.1875rem 15.1875rem 3rem'
            : props.ddpAmount === 9 && props.amount === 9
              ? '10.75rem 11.125rem 11.125rem 5.1875rem 18.625rem 5.1875rem 5.9375rem 5.1875rem 9.25rem 3rem'
              : props.amount === 8 && props.ddpAmount === 9
                ? '10.75rem 11.125rem 11.125rem 5.1875rem 18.625rem 5.9375rem 5.1875rem 9.25rem 3rem'
                : ''
      //overflow-x: auto;
    };
  }
`;

const TableBodyContainerFType = styled.div`
  display: grid;
  border-bottom: 0.5px solid ${Colors.periwinkle};
  border-left: 0.5px solid ${Colors.periwinkle};
  border-right: 0.5px solid ${Colors.periwinkle};
  grid-template-columns: 17.14fr 25.39fr 7.56fr 12.79fr 18.5fr 15fr 4fr;
  @media (max-width: 1200px) {
    grid-template-columns: 18.95fr 18.95fr 10.34fr 17.48fr 17.23fr 12.92fr 4.13fr;
  }
  @media(max-width: 1024px) {
    grid-template-columns: 220px 220px 120px 203px 200px 150px 48px;
  }
`;

const BodyItem = styled.div`
  padding: 0.75rem 0.75rem 0.75rem 1rem;
  text-align: center;
  height: 5.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &.calc_weight {
    align-items: end;
  }
  > svg {
    cursor: pointer;
  }
`;

const TableCheckbox = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0 0.25rem;
`;

const CustomRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  > div:nth-child(2) {
    padding-bottom: 0;
  }
  &.ft {
    padding-bottom: 1px;
  }
`;

const CustomInsideRowWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
  padding-bottom: 0.5rem;
  align-items: center;
  width: 100%;
  &.price {
    padding-bottom: 0;
  }
`;

const ConvertedValueLabel = styled.div`
  font-size: 0.625rem;
  line-height: 0.625rem;
  width: 100%;
  color: ${Colors.primary60};
  padding: 0 0.5rem;
  text-align: left;
`;

const CalculatedItemData = styled.div`
  background: ${Colors.mistyLavender};
  padding: 0.25rem 0.5rem;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: ${Colors.black80};
  width: 100%;
  text-align: left;
  border-radius: 0.25rem;
`;

interface HeaderItemProps {
  amount?: number;
  ddpAmount?: number;
}

const TableHeaderContainer = styled.div<HeaderItemProps>`
  display: grid;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  grid-template-columns: ${props =>
    props.ddpAmount === 8 && props.amount === 9
      ? '10.75rem 25.1875rem 24.1875rem 5.1875rem 18.625rem 5.1875rem 5.1875rem 15.1875rem 3.0rem'
      : props.amount === 8 && props.ddpAmount === 8
        ? '10.75rem 27.8125rem 26.75rem 5.1875rem 18.625rem 5.1875rem 15.1875rem 3.0rem'
        : props.ddpAmount === 9 && props.amount === 9
          ? '10.75rem 25.0rem 24.1875rem 5.1875rem 18.625rem 5.1875rem 5.9375rem 5.1875rem 9.25rem 3.0rem'
          : props.amount === 8 && !props.ddpAmount
            ? '10.75rem 27.8125rem 26.75rem 5.1875rem 18.625rem 5.9375rem 5.1875rem 9.25rem 3.0rem'
            : '10.75rem 25.1875rem 24.1875rem 5.1875rem 18.625rem 5.1875rem 5.1875rem 15.1875rem 3.0rem'};
  background-color: ${Colors.primary80};
  border-bottom: 0.5px solid ${Colors.periwinkle};

  @media(min-width: 1750px) and (max-width: 1850px) {
    grid-template-columns: ${props =>
            props.ddpAmount === 8 && props.amount === 9
                    ? '9.56fr 22.39fr 21.51fr 4.61fr 16.56fr 4.61fr 4.61fr 13.50fr 2.67fr'
                    : props.amount === 8 && props.ddpAmount === 8
                            ? '9.56fr 24.72fr 23.78fr 4.61fr 16.56fr 4.61fr 13.50fr 2.67fr'
                            : props.ddpAmount === 9 && props.amount === 9
                                    ? '9.57fr 22.25fr 21.55fr 4.62fr 16.58fr 4.62fr 5.29fr 4.62fr 8.24fr 2.67fr'
                                    : props.amount === 8 && !props.ddpAmount
                                            ? '9.56fr 24.72fr 23.78fr 4.61fr 16.56fr 5.28fr 4.61fr 8.22fr 2.67fr'
                                            : '9.56fr 22.39fr 21.51fr 4.61fr 16.56fr 4.61fr 4.61fr 13.50fr 2.67fr'};
  }

  @media (max-width: 1200px) {
    //grid-template-columns: 172px 190px 190px 83px 298px 83px 95px 83px 148px;
    grid-template-columns: ${props =>
      props.ddpAmount === 8 && props.amount === 9
        ? '10.75rem 11.875rem 11.875rem 5.1875rem 18.625rem 5.1875rem 5.1875rem 15.1875rem 3.0rem'
        : props.amount === 8 && props.ddpAmount === 8
          ? '10.75rem 11.875rem 11.875rem 5.1875rem 18.625rem 5.1875rem 15.1875rem 3.0rem'
          : props.ddpAmount === 9 && props.amount === 9
            ? '10.75rem 11.875rem 11.875rem 5.1875rem 18.625rem 5.1875rem 5.9375rem 5.1875rem 9.25rem 3.0rem'
            : props.amount === 8 && !props.ddpAmount
              ? '10.75rem 11.875rem 11.875rem 5.1875rem 18.625rem 5.9375rem 5.1875rem 9.25rem 3.0rem'
              : '10.75rem 11.875rem 11.875rem 5.1875rem 18.625rem 5.1875rem 5.1875rem 15.1875rem 3.0rem'};
  }

  @media (max-width: 810px) {
    //overflow-x: auto;
    //grid-template-columns: 10.75rem 11.125rem 11.125rem 5.188rem 18.625rem 5.188rem 5.938rem 5.188rem 9.25rem;
    grid-template-columns: ${props =>
      props.ddpAmount === 8 && props.amount === 9
        ? '10.75rem 11.125rem 11.125rem 5.1875rem 18.625rem 5.1875rem 5.1875rem 15.1875rem 3.0rem'
        : props.amount === 8 && props.ddpAmount === 8
          ? '10.75rem 11.125rem 11.125rem 5.1875rem 18.625rem 5.1875rem 15.1875rem 3rem'
          : props.ddpAmount === 9 && props.amount === 9
            ? '10.75rem 11.125rem 11.125rem 5.1875rem 18.625rem 5.1875rem 5.9375rem 5.1875rem 9.25rem 3rem'
            : props.amount === 8 && props.ddpAmount === 9
              ? '10.75rem 11.125rem 11.125rem 5.1875rem 18.625rem 5.9375rem 5.1875rem 9.25rem 3rem'
              : ''};
  }
`;

const TableHeaderContainerFType = styled.div`
  display: grid;
  grid-template-columns: 17.14fr 25.39fr 7.56fr 12.79fr 18.5fr 15fr 4fr;
  background-color: ${Colors.primary80};
  border-bottom: 0.5px solid ${Colors.periwinkle};
  @media (max-width: 1200px) {
    grid-template-columns: 18.95fr 18.95fr 10.34fr 17.48fr 17.23fr 12.92fr 4.13fr;
  }
  @media(max-width: 1024px) {
    grid-template-columns: 220px 220px 120px 203px 200px 150px 48px;
  }
`;

const HeaderItemContainer = styled.div`
  padding: 0.75rem 1rem;
  text-align: left;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: ${Colors.white};
`;

const HeaderCustomItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderCustomRowWrapper = styled.div`
  display: flex;
  gap: 0 0.75rem;
  align-items: center;
  margin-top: 0.75rem;
`;

export {
  CargoDetailsWrapper,
  CargoDetailsItem,
  AddDocumentLabel,
  AddDocumentWrapper,
  DocumentNameLabel,
  SelectedFilesWrapper,
  AddRowLabel,
  AddRowWrapper,
  AddRowContainer,
  TableBodyContainer,
  BodyItem,
  TableCheckbox,
  CustomRowWrapper,
  CustomInsideRowWrapper,
  CalculatedItemData,
  TableHeaderContainer,
  HeaderItemContainer,
  HeaderCustomItem,
  HeaderCustomRowWrapper,
  ConvertedValueLabel,
  TableBodyContainerFType,
  TableHeaderContainerFType,
};

import React, { useEffect, useState } from 'react';
import DashboardTable from '../../../components/Dashboard/DashboardTable';
import {
  DashboardPageWrapper,
  EmptyStateDashboard,
} from '../../../components/Dashboard/styles';
import DashboardControlPanel from '../../../components/Dashboard/DashboardControlPanel';
import DashboardGrids from '../../../components/Dashboard/DashboardGrids';
import {
  useDashboardPerPageLimit,
  useDesktop,
} from '../../../constants/breakpoints';
import { DashboardConfig } from '../../../utils/axios/get';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { debounce } from 'lodash';
import Button from '../../../components/ui/Button';
import { useNavigate } from 'react-router-dom';
import { paginationStyle } from './styles';
import { CircularProgress } from '@mui/material';
import { LoadingCircularWrapper } from '../styles';

const Dashboard = () => {
  const isDashboardPerPageLimit = useDashboardPerPageLimit();
  const [isShowActive, setIsShowActive] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const [isRowDisplay, setIsRowDisplay] = useState(
    // @ts-ignore
    sessionStorage.getItem('dashboardDisplay') ? !!JSON.parse(sessionStorage.getItem('dashboardDisplay')) : true,
  );
  const [perPage, setPerPage] = useState(15);
  const [searchValue, setSearchValue] = useState('');
  const [tableConfig, setTableConfig] = useState<any>(null);
  const [lastPage, setLastPage] = useState(1);
  const [page, setPage] = useState(
    // @ts-ignore
    +sessionStorage.getItem('dashboard_page') || 1,
  );
  const isDesktop = useDesktop();
  const view = isShowActive ? 'general' : 'archive';
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const fetchDashboardData = async () => {
    setIsLoading(true);
    const result = await DashboardConfig({
      per_page: perPage,
      page,
      view,
      search: searchValue,
    });

    if (result.success) {
      setTotalAmount(result?.total);
      setLastPage(result?.last_page);
      setTableConfig(result?.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    sessionStorage.setItem(`dashboardDisplay`, JSON.stringify(isRowDisplay));
  }, [isRowDisplay]);

  const debouncedFetch = debounce(fetchDashboardData, 300);

  useEffect(() => {
    debouncedFetch();
    return () => {
      debouncedFetch.cancel();
    };
  }, [isShowActive, page, searchValue, perPage]);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    sessionStorage.setItem('dashboard_page', JSON.stringify(value));
    setPage(value);
  };

  useEffect(() => {
    setPerPage(isDashboardPerPageLimit ? 16 : 15);
  }, [isDashboardPerPageLimit]);

  useEffect(() => {
    setPage(1);
  }, [searchValue]);

  return (
    <DashboardPageWrapper>
      <DashboardControlPanel
        active={isShowActive}
        setActive={setIsShowActive}
        isRowDisplay={isRowDisplay}
        setIsRowDisplay={setIsRowDisplay}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      <>
        {isLoading ? (
          <LoadingCircularWrapper>
            <CircularProgress color="inherit" size={'2.5rem'} />
          </LoadingCircularWrapper>
        ) : (
          <>
            {!!tableConfig?.length ? (
              <>
                {isRowDisplay && isDesktop ? (
                  <DashboardTable
                    data={tableConfig || []}
                    isShowActive={isShowActive}
                  />
                ) : (
                  <DashboardGrids
                    data={tableConfig || []}
                    isShowActive={isShowActive}
                  />
                )}
              </>
            ) : (
              <EmptyStateDashboard>
                {searchValue ? (
                  <p>Нажаль перевезень не знайдено…</p>
                ) : (
                  <p>Поки що тут порожньо…</p>
                )}
                <Button
                  text={'Створити нове перевезення'}
                  type={'primary'}
                  onClick={() => {
                    navigate('/new-request');
                  }}
                />
              </EmptyStateDashboard>
            )}
            <div>
              {(!!tableConfig?.length && totalAmount > perPage) && (
                <Stack spacing={2}>
                  <Pagination
                    count={lastPage}
                    page={page}
                    onChange={handleChangePage}
                    sx={paginationStyle}
                  />
                </Stack>
              )}
            </div>
          </>
        )}
      </>
    </DashboardPageWrapper>
  );
};

export default Dashboard;

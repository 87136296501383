import React, { useMemo, useState } from 'react';
import RadioGroupComponent from '../../ui/RadioGroup';
import { useFormContext } from '../FormContext';
import {
  TableHeaderContainer,
  HeaderItemContainer,
  HeaderCustomItem,
  HeaderCustomRowWrapper,
  TableBodyContainerFType,
  TableHeaderContainerFType,
} from './styles';
import CustomDropdown from '../../ui/Dropdown/Dropdown';

type HeaderItemType = 'text' | 'switcher' | 'select' | 'custom';

interface HeaderItem {
  type: HeaderItemType;
  label: string;
  options?: any[];
  isHidden?: boolean;
  name?: string;
}

const CargoTableHeaderFType = ({ configData }: any) => {
  const [calculationSizeType, setCalculationSizeType] = useState('size');
  const { formData, updateFormData } = useFormContext();
  const { step4 } = formData;

  React.useEffect(() => {
    updateFormData('step4', { sizeType: calculationSizeType });
  }, [calculationSizeType]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFormData('step4', { [e.target.name]: e.target.value });
  };

  const currenciesOptions = configData?.currencies?.map((item: any) => {
    return {
      label: item,
      value: item,
    };
  });

  const headers: HeaderItem[] = [
    { type: 'text', label: 'Тип контейнера' },
    { type: 'text', label: 'Назва товару' },
    { type: 'text', label: 'К-ть місць' },
    { type: 'text', label: 'Загальна вага Брутто' },
    {
      type: 'select',
      label: 'Вартість товару',
      options: currenciesOptions,
      name: 'currency_id',
    },
    { type: 'text', label: 'Надлишкова вага' },
    { type: 'text', label: '' },
  ];

  const typeOfCalculationGood = useMemo(
    () => [
      {
        id: 'calculation_type',
        text: 'Розмір',
        name: 'calculation_type',
        onChange: () => setCalculationSizeType('size'),
        value: 'size',
        isActive: calculationSizeType === 'size',
      },
      {
        id: 'calculation_type2',
        text: 'Обʼєм',
        name: 'calculation_type2',
        onChange: () => setCalculationSizeType('volume'),
        value: 'volume',
        isActive: calculationSizeType === 'volume',
      },
    ],
    [step4, calculationSizeType],
  );

  return (
    <TableHeaderContainerFType>
      {headers?.map((header, index) => {
        return (
          <>
            {
              !header.isHidden && (
                <HeaderItemContainer key={index}>
                  {header.type === 'text' && header.label}
                  {header.type === 'switcher' && (
                    <label>
                      {header.label}
                      <input type='checkbox' />
                    </label>
                  )}
                  {
                    header.type === 'custom' && (
                      <HeaderCustomItem>
                        {header.label}
                        <HeaderCustomRowWrapper>
                          <CustomDropdown
                            label={''}
                            placeholder={'Оберіть тип'}
                            options={header?.options ?? []}
                            name={header?.name || ''}
                            defaultValueTable={header?.options?.[0]}
                            onSelect={(e) => handleChange(e)}
                            withValueLabel={false}
                            className={'table-dropdown'}
                          />
                          <RadioGroupComponent
                            dataConfig={typeOfCalculationGood}
                            isCompressed={true}
                            className={'cargoData small'}
                          />
                        </HeaderCustomRowWrapper>
                      </HeaderCustomItem>
                    )
                  }
                  {header.type === 'select' && (
                    <label
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.375rem 0',
                      }}
                    >
                      {header.label}
                      <CustomDropdown
                        label={''}
                        placeholder={'Валюта'}
                        options={header?.options ?? []}
                        name={header?.name || ''}
                        defaultValueTable={header?.options?.[0]}
                        onSelect={(e) => handleChange(e)}
                        withValueLabel={false}
                        className={'table-dropdown'}
                      />
                    </label>
                  )}
                </HeaderItemContainer>
              )
            }
          </>
        );
      })}
    </TableHeaderContainerFType>
  );
};

export default CargoTableHeaderFType;

//@ts-ignore
import Cookies from 'js-cookie';
import axios from 'axios';

const backendBaseUrl = process.env.REACT_APP_BACKEND_API_BASE_URL;

const axiosInstanceFormData = axios.create({
  baseURL: backendBaseUrl,
  timeout: 60000,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

axiosInstanceFormData.interceptors.request.use(
  config => {
    const token = Cookies.get('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

axiosInstanceFormData.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error);
  },
);

export default axiosInstanceFormData;

import styled from 'styled-components';
import { Colors } from './colors';

interface RadioProps {
  active: string;
  icon: boolean;
}

interface IOpenProps {
  open?: boolean;
}

const PrimaryButton = styled.button`
  background: ${Colors.primary};
  color: ${Colors.white};
  border-radius: 0.25rem;
  padding: 1rem;
  height: 3rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  transition: 0.2s;
  &:hover {
    transition: 0.2s;
    background: ${Colors.primary60};
  }
  &.submit-form-btn {
    background: ${Colors.primary60};
  }

  @media (max-width: 767px) {
    &:hover {
      opacity: 1;
      background: ${Colors.primary};
    }
  }
`;

interface IGreenButton {
  smallPadding?: boolean;
}

export const GreenButton = styled.button<IGreenButton>`
  background: ${Colors.Seafoam_Green};
  font-size: 1em;
  font-weight: 400;
  line-height: 1em;
  color: ${Colors.white};
  border-radius: 0.25rem;
  padding: ${props => (props.smallPadding ? '9px 16px' : '1rem')};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;

  &:hover {
    transition: 0.2s;
    background: ${Colors.moss_green};
  }
`;

export const RedButton = styled.button<IGreenButton>`
  background: ${Colors.deepRed};
  font-size: 1em;
  font-weight: 400;
  line-height: 1em;
  color: ${Colors.white};
  border-radius: 0.25rem;
  padding: ${props => (props.smallPadding ? '9px 16px' : '1rem')};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;

  &:hover {
    transition: 0.2s;
    background: ${Colors.terra_cotta};
  }
`;
export const DownloadButton = styled.button`
  border: none;
  a {
    background: ${Colors.periwinkle};
    font-size: 1rem;
    font-weight: 400;
    line-height: 1rem;
    color: ${Colors.primary};
    border-radius: 0.25rem;
    padding: 0.75rem 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    gap: 0.25rem;
    text-decoration: none;
    outline: none;
  }

  @media screen and (max-width: 767px) {
    a {
      padding: 0.5rem;
    }
  }
`;

interface ISecondaryBtn {
  request?: boolean;
}

const SecondaryButton = styled.button<ISecondaryBtn>`
  background: ${props => (props.request ? Colors.lightBlue : Colors.white)};
  color: ${Colors.primary80};
  border-radius: 0.25rem;
  padding: 1rem;
  height: 3rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Colors.periwinkle};
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background: ${props =>
      props.request ? Colors.lightBlue : Colors.mistyLavender};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 2.5rem;
  width: 100%;
  margin-top: 1.5rem;

  a {
    width: 100%;
  }
`;

const ButtonWrapperColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
  width: 100%;
`;

const DateInput = styled.input`
  border: 1px solid ${Colors.periwinkle};
  color: ${Colors.primary};
  border-radius: 0.25rem;
  padding: 0.75rem;
  width: auto;
  height: 2.813rem;
  font-size: 0.875rem;
  line-height: 1.313rem;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  @media (max-width: 480px) {
    color: ${Colors.primary} !important;

    &:disabled {
      color: ${Colors.primary} !important;
      border-color: ${Colors.periwinkle} !important;
      opacity: 1;
    }
  }

  &:disabled {
    background: ${Colors.white};
    border-color: ${Colors.periwinkle};
  }

  &:focus {
    outline: none;
    border-color: ${Colors.periwinkle};
  }

  ::placeholder {
    color: ${Colors.primary60};
  }
`;
const PrimaryInput = styled.input`
  border: 1px solid ${Colors.periwinkle};
  color: ${Colors.primary};
  border-radius: 0.25rem;
  padding: 0.75rem 1rem;
  width: auto;
  height: 2.813rem;
  font-size: 0.875rem;
  line-height: 1.313rem;

  &:disabled {
    background: ${Colors.mistyLavender};
    border: none;
  }

  &:focus {
    outline: none;
    border-color: ${Colors.periwinkle};
  }

  ::placeholder {
    color: ${Colors.primary60};
  }
  &.address-input,
  &#address {
    font-size: 1rem;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 0.125rem 0;
`;

const InputWrapperSecondary = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 0.125rem 0;
  padding-top: 0.5rem;
`;
export const CalendarSvgWrapper = styled.div`
  display: flex;
  align-items: center;

  > svg:hover {
    > path {
      stroke: ${Colors.primary60};
    }
  }
`;

const InputIconWrapper = styled.div`
  width: 100%;
  height: 2.813rem;
  padding: 0.75rem;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  top: 1.625rem;
  cursor: pointer;

  &.icon-details {
    top: 2.15rem;
  }
`;

const InputIconWrapperFull = styled.div`
  position: absolute;
  top: 2.25rem;
  right: 0.625rem;
  cursor: pointer;
`;

const InputWarningMessage = styled.p`
  color: ${Colors.warning};
  font-size: 0.875rem;
  line-height: 1.313rem;
  margin-top: 0.375rem;
`;

const InputErrorMessage = styled.p`
  color: ${Colors.deepRed};
  font-size: 0.875rem;
  line-height: 1.313rem;
  margin-top: 0.375rem;
`;

const PrimaryLabel = styled.label`
  color: ${Colors.primary};
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
`;

// border: 1px solid #CCCBE6;
// color: #3A3875;
// border-radius: 0.25rem;
// padding: 0.75rem 1rem;
// padding-left: 1rem;
// width: auto;
// height: 2.813rem;
// font-size: 0.875rem;
// line-height: 1.313rem;
// width: 100%;
// padding-left: 48px;

const SecondaryLabel = styled.label`
  color: ${Colors.warning};
  font-size: 0.875rem;
  line-height: 1.313rem;
  font-weight: 400;
  margin-left: 0.75rem;
  margin-top: 0.125rem;
`;

interface IInputProps {
  inputStyle?: boolean;
}
const SecondaryInputLabel = styled.label<IInputProps>`
  color: ${props => (props.inputStyle ? Colors.primary : Colors.warning)};
  font-size: ${props => (props.inputStyle ? '1rem' : '0.875rem')};
  line-height: ${props => (props.inputStyle ? '1.5rem' : '1.313rem')};
  font-weight: ${props => (props.inputStyle ? '500' : '400')};
`;

const SecondaryInput = styled.input`
  padding: 0.75rem 1rem 0.75rem 1rem;
  border-radius: 0.25rem;
  border: 1px solid ${Colors.warning};
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${Colors.primary80};
  width: auto;
  height: 2.813rem;

  &:focus {
    outline: none;
    border-color: ${Colors.warning};
  }
`;

const SecondaryTextArea = styled.textarea<IInputProps>`
  padding: 0.75rem 1rem 0.75rem 1rem;
  border-radius: 0.25rem;
  border: ${props =>
    props.inputStyle
      ? `1px solid ${Colors.periwinkle}`
      : `1px solid ${Colors.warning}`};
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${Colors.primary80};
  width: auto;
  height: 4.5rem;
  resize: none;
  overflow-y: hidden;

  &:focus {
    outline: none;
    border: ${props =>
      props.inputStyle
        ? `1px solid ${Colors.periwinkle}`
        : `1px solid ${Colors.warning}`};
  }

  @media screen and (max-width: 767px) {
    height: ${props => (props.inputStyle ? '7.5rem' : '4.5rem')};
  }
`;

const Container = styled.div`
  max-width: 1848px;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 1848px) {
    padding: 0 3%;
  }
  @media (max-width: 1440px) {
    padding: 0 33px;
    overflow-x: hidden;
  }
  @media (max-width: 768px) {
    padding: 0 44px;
  }
  @media (max-width: 450px) {
    padding: 0 1.875rem;
  }
`;

const GlobalWrapper = styled.div`
  display: flex;
  //min-height: 100vh;
  //@todo temp check
  @media (max-width: 767px) {
    overflow-y: hidden;
  }
`;

const AuthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  @media (max-width: 1800px) {
    justify-content: start;
    height: calc(100% - 3rem);
    margin-top: 3rem;
  }
  @media (max-width: 1024px) {
    height: 100%;
    margin-top: 0;
  }
  @media (max-width: 450px) {
    //margin: 1.875rem 0;
    margin-bottom: 0;
  }
`;

const LayoutSmall = styled.div`
  background: ${Colors.white};
  max-width: 27rem;
  width: 100%;
  padding: 1rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 33px 0 #00000017;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3.75rem 0;
  @media (max-width: 810px) {
    max-width: 31.5rem;
    margin: 5rem 0;
  }
  @media (max-width: 390px) {
    max-width: 19.75rem;
    margin: 1.875rem 0;
  }
`;

const LogoImage = styled.img`
  max-width: 15.813rem;
  width: 100%;
  height: auto;
`;

const TitleH2 = styled.h2`
  font-weight: 500;
  font-size: 2rem;
  line-height: 3rem;
  letter-spacing: -0.2px;
  color: ${Colors.primary};
  width: 100%;
  text-align: left;
  margin: 1.75rem 0;
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem 0;
  margin-top: 1.75rem;

  a {
    width: 100%;
  }
`;

const ModalTitleH2 = styled.h2`
  font-size: 3rem;
  line-height: 4.5rem;
  color: ${Colors.primary};
`;

const ModalSubtitle = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${Colors.black90};
  margin-bottom: 1.5rem;

  span {
    color: ${Colors.black90};
    font-weight: 500;
    display: block;
    text-align: center;
    margin-top: 1rem;
  }
`;

const CheckboxBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 0 0.375rem;
  vertical-align: middle;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

interface StyledCheckboxProps {
  checked: boolean;
}

interface IUserLogo {
  width: string;
  height: string;
  header?: boolean;
}

interface StyledModalContentProps {
  width: string;
}

interface StyledModalLayoutProps {
  paddingTop?: string;
  maxWidth?: string;
  padding?: string;
}

interface StyledModalButtonProps {
  gap?: string;
  direction?: string;
}

interface ISign {
  type: string;
}

const Icon = styled.svg`
  fill: none;
  stroke: white;
  strokewidth: 0.125rem;
`;

const StyledCheckbox = styled.div<StyledCheckboxProps>`
  display: inline-block;
  width: 1.125rem;
  height: 1.125rem;
  background: ${props => (props.checked ? '#5F5E8E' : 'transparent')};
  border: ${props => (props.checked ? 'none' : '1px solid #5F5E8E')};
  border-radius: 3px;
  transition: all 150ms;
  cursor: pointer;
  position: relative;

  &:hover {
    border-color: ${Colors.primary60};
    background: ${props => (props.checked ? Colors.primary60 : 'transparent')};
  }

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px rgba(95, 94, 142, 0.5);
  }

  ${HiddenCheckbox}:checked + & {
    background: ${Colors.primary60};
    border: none;
    position: relative;
  }

  &::after {
    content: '';
    position: absolute;
    top: 45%;
    left: 50%;
    width: 3px;
    height: 0.5rem;
    border: solid white;
    border-width: 0 0.125rem 0.125rem 0;
    transform: translate(-50%, -50%) rotate(45deg);
    display: ${({ checked }) => (checked ? 'block' : 'none')};
  }
`;

const CheckboxLabel = styled.label`
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: ${Colors.black80};

  a {
    color: ${Colors.primary};
    text-decoration: none;
    font-weight: 600;
    margin-left: 0.25rem;
  }
`;

const UniversalModalLayout = styled.div<StyledModalLayoutProps>`
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 100%;
  background: ${Colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  border-radius: 0.25rem;
  gap: 2.5rem;
  padding: ${props =>
    props?.padding
      ? props?.padding
      : props.paddingTop
        ? `1rem ${props.paddingTop} 1rem 1rem`
        : '1rem'};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};

  box-shadow: 0px 0px 33px 0px #00000017;

  &.success-details {
    z-index: 1100;
    button {
      max-width: 432px;
      margin: 0 auto;
    }
  }

  @media (max-width: 480px) {
    gap: 1.5rem;
    padding: ${props =>
      props.paddingTop
        ? `0.75rem ${props.paddingTop} 0.75rem 0.75rem`
        : '0.75rem'};
  }

  .details-cancel {
    position: absolute;
    right: 2.5rem;
    top: 2.5rem;
    @media (max-width: 480px) {
      right: 1.875rem;
      top: 1.875rem;
    }
  }

  &:focus-visible {
    outline: none;
    border: none;
  }

  &.details-modal {
    @media (max-width: 1024px) {
      width: 95%;
      overflow-y: scroll;
      max-height: 95vh;
    }
    @media (max-width: 768px) {
      max-width: 42.5rem;
      width: 100%;
    }
    @media (max-width: 680px) {
      width: 90%;
    }
    @media (max-width: 480px) {
      padding: 1.875rem;
      gap: 1.5rem;
    }
  }
`;

const ModalContentWrapper = styled.div<StyledModalContentProps>`
  width: 100%;
  max-width: ${props => props.width};
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FullModalContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 0 2.5rem;
  padding-top: 4rem;
  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 2.5rem 0;
  }
  @media (max-width: 480px) {
    gap: 1.5rem 0;
    padding-top: 2.5rem;
  }
`;

const DetailsModalContentWrapper = styled.div`
  width: 100%;
  h3 {
    font-size: 2rem;
    line-height: 3rem;
    font-weight: 500;
    color: ${Colors.primary};
    letter-spacing: -0.02em;
    @media (max-width: 1024px) {
      text-align: center;
    }
    @media (max-width: 480px) {
      font-size: 1.313rem;
      line-height: 1.969rem;
    }
  }

  button {
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
    line-height: 1rem;
    height: 3rem;
    background: ${Colors.Seafoam_Green};
    color: ${Colors.white};
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
  }

  > div > div {
    display: flex;
    flex-direction: column;
    gap: 1rem 0;
  }
`;

const ButtonWrapperModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 1.5rem !important;
  flex-direction: row !important;
  button {
    max-width: 155px;
    width: 100%;
    @media (max-width: 480px) {
      max-width: 145px;
      padding: 1rem 0.688rem;
    }
  }
  button:nth-child(2) {
    background: ${Colors.deepRed};
  }
`;

const ChatWrapper = styled.div`
  max-width: 27rem;
  width: 100%;
  //height: calc(457px - 74px);
  &#modal-chat {
    width: 27rem;
    @media (max-width: 1440px) {
      width: 100%;
      max-width: 27rem;
    }
    @media(max-width: 1199px) {
      max-width: 100%;
    }
  }

  @media (min-width: 1440px) {
    width: 100%;
  }
  @media (max-width: 1440px) {
    max-width: 100%;
  }

  @media (max-width: 810px) {
    height: 350px;
  }
`;

const ModalHeader = styled.div`
  font-size: 3rem;

  font-weight: 700;
  line-height: 4.5rem;
  letter-spacing: -0.02em;
  text-align: center;
  color: ${Colors.primary};
  @media (max-width: 767px) {
    font-size: 2rem;
    line-height: 2.875rem;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SubContent = styled.div`
  font-size: 1em;
  font-weight: 500;
  line-height: 1.5em;
  text-align: center;
  color: ${Colors.black90};

  cursor: pointer;
  position: relative;
`;

const ModalContent = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: -0.02em;
  text-align: center;
  gap: 1rem;
  @media (max-width: 480px) {
    font-size: 0.875rem;
    line-height: 1.313rem;
  }
`;

const ModalButtonWrapper = styled.div<StyledModalButtonProps>`
  display: flex;
  justify-content: center;
  flex-direction: ${props => props.direction};
  width: 100%;
  gap: ${props => props.gap};
`;

const PdfImage = styled.img`
  max-width: 3rem;
  width: 100%;
  height: auto;
`;

const AlertImage = styled.img`
  max-width: 1.5rem;
  width: 100%;
  height: auto;
`;

const SendFile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.deepRed};
`;

const AlertWarningContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const SendPdfContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
`;

const AlertContent = styled.p`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const SignWrapper = styled.div<ISign>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 1rem;
  gap: 0.75rem;
  border-radius: 0.25rem;
  min-height: 48px;
  @media (max-width: 1670px) {
    padding: 0 1rem;
  }
  &.resign {
    max-height: 100%;
  }
  background: ${props =>
    props.type === 'resign'
      ? Colors.deepRed
      : props.type === 'sign_success'
        ? Colors.lightGreen
        : Colors.lightBlue};
  color: ${props => (props.type === 'resign' ? Colors.white : Colors.primary)};
  cursor: ${props => (props.type !== 'sign_success' ? 'cursor' : 'default')};
  transition: 0.2s;
  @media (max-width: 480px) {
    padding: 0 1rem;
  }
  &:hover {
    background: ${props =>
      props.type !== 'sign_success' ? Colors.primary80 : Colors.lightGreen};
    color: ${props =>
      props.type !== 'sign_success' ? Colors.white : Colors.primary};
    transition: 0.2s;
    svg {
      path {
        stroke: ${props =>
          props.type !== 'sign_success' ? Colors.white : Colors.primary};
      }
    }
  }
`;

const DocumentContainer = styled.div`
  //width: 1.5rem;
  //height: 1.5rem;
`;
const SignNotification = styled.span<ISign>`
  width: ${props => props.type === 'sign' && '100%'};
  text-align: ${props => props.type === 'sign' && 'center'};
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  @media (max-width: 480px) {
    text-align: ${props => (props.type === 'resign' ? 'left' : 'center')};
  }
`;

const StyledImage = styled.img<IUserLogo>`
  border-radius: 50%;
  width: ${props => props.width};
  height: ${props => props.height};
  object-fit: cover;
`;
const InitialsCircle = styled.div<IUserLogo>`
  border-radius: 50%;
  width: ${props => props.width};
  height: ${props => props.height};
  overflow-y: hidden;
  background-color: ${Colors.primary};
  max-height: ${props => (props.header ? '48px' : '128px')};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${props => (props.header ? '1rem' : '3rem')};
  font-weight: ${props => (props.header ? '600' : '700')};
  line-height: ${props => (props.header ? '24' : '72')};
  color: ${Colors.white};
`;
const HoverStyledImage = styled.img<IUserLogo>`
  position: absolute;
  z-index: 10;
  border-radius: 50%;
  //left: 50;
  width: ${props => props.width};
  height: ${props => props.height};
  opacity: 0;
  transition: 0.25s;

  &:hover {
    opacity: 60%;
    transition: 0.25s;
  }

  @media (max-width: 1023px) {
    opacity: 60%;
  }
`;

export const LogoContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UserLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
`;

const CustomRadioWrapper = styled.div<RadioProps>`
  width: 100%;
  border-radius: 0.25rem;
  border: 1px solid
    ${props =>
      props.active === 'true' ? `transparent` : `${Colors.periwinkle}`};
  background: ${props =>
    props.active === 'true' ? `${Colors.primary80}` : `${Colors.white}`};
  padding: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: ${props =>
      props.active === 'true' ? `${Colors.primary80}` : `${Colors.primary60}`};
    transition: 0.3s;

    span {
      color: ${Colors.white};
    }

    svg {
      path {
        stroke: ${Colors.white};
      }
    }
  }

  div {
    display: flex;
    align-items: center;

    span {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
      color: ${props =>
        props.active === 'true' ? `${Colors.white}` : `${Colors.primary}`};
    }

    svg {
      width: 1.75rem;
      height: 1.75rem;

      path {
        stroke: ${props =>
          props.active === 'true' ? `${Colors.white}` : `${Colors.primary}`};
      }
    }
  }

  @media (max-width: 767px) {
    max-width: ${props => (props.icon ? '100%' : '54px')};
  }
`;

const CustomRadioInput = styled.input.attrs({ type: 'radio' })`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

const RequiredIndicator = styled.span`
  width: 7px;
  height: 1.5rem;
  margin-left: 0.25rem;
  color: ${Colors.warning};
`;
interface IDropdownProps {
  validate?: boolean;
}
const Dropdown = styled.div<IDropdownProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  &:focus {
    outline: none;
  }
`;
const DropdownLabel = styled.label`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: ${Colors.primary};
  margin-bottom: 0.25rem;
`;

const DropdownContainer = styled.div`
  position: relative;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: ${Colors.primary80};
  cursor: pointer;

  &.disabled > div {
    background: ${Colors.mistyLavender} !important;
  }

  &.table-dropdown {
    font-size: 0.875rem;
    line-height: 1.313rem;
    color: ${Colors.black80};
  }
`;

const DropdownWithCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 0.5rem;
  width: 100%;

  > div {
    width: 100%;
  }

  &.small {
    > div {
      width: 100%;
    }
  }
`;

const DropdownSelected = styled.div<IOpenProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  border: 1px solid ${Colors.periwinkle};
  border-bottom: ${props =>
    props.open ? 'none' : `1px solid ${Colors.periwinkle}`};
  border-radius: ${props => (props.open ? '0.25rem 0.25rem 0 0' : '0.25rem')};
  background-color: ${Colors.white};

  &.validation_failed {
    border: 1px solid ${Colors.deepRed};
  }

  &.small {
    height: 32px;
    padding: 3px 0 3px 0.75rem !important;
    p {
      font-size: 0.875rem;
      //padding: 3px 0.75rem;
      @media (max-width: 480px) {
        font-size: 0.875rem;
      }
    }
  }
  &.harmful_materials_class {
    border-bottom: 1px solid ${Colors.periwinkle};
    border-top: ${props =>
      props.open ? 'none' : `1px solid ${Colors.periwinkle}`};
    border-radius: ${props => (props.open ? '0 0 0.25rem 0.25rem' : '0.25rem')};
  }

  &.table-dropdown {
    padding: 5.5px 0.5rem;
    height: 2rem;

    &:hover {
      background-color: ${Colors.mistyLavender};
    }

    img {
      right: 0;
    }
  }
  &.error-field {
    border: 1px solid ${Colors.deepRed};
  }
`;
const DropdownPlaceholder = styled.span`
  color: ${Colors.primary80};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;

  &.table-dropdown {
    color: ${Colors.black80};
    font-size: 0.875rem;
    line-height: 1.313rem;
  }
`;

const DropdownOptions = styled.div<IOpenProps>`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid ${Colors.periwinkle};
  border-top: ${props => props.open && 'none'};
  border-radius: ${props => (props.open ? '0 0 0.25rem 0.25rem' : '0.25rem')};
  background-color: ${Colors.white};
  z-index: 1301;
  max-height: 336px;
  overflow-y: auto;
  @media (max-width: 480px) {
    max-height: 336px;
    .dropdown-option {
      font-size: 0.875rem;
    }
  }
  &.harmful_materials_class {
    top: auto;
    bottom: 100%;
    border-bottom: ${props => props.open && 'none'};
    border-top: 1px solid ${Colors.periwinkle};
  }
`;
const DropdownOption = styled.div`
  display: flex;
  padding: 0.75rem 1rem;
  gap: 0.25rem;
  filter: none;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.mistyLavender};
    border-left: 1px solid ${Colors.periwinkle};
  }

  span {
    font-weight: 500;
    width: 2rem;
  }

  &.table-dropdown {
    padding: 5.5px 0.5rem;
  }
`;

const SelectedOption = styled.div`
  display: flex;
  gap: 0.25rem;

  &.table-dropdown {
    span {
      color: ${Colors.black80};
    }
  }

  span {
    font-weight: 500;
  }
`;

const Image = styled.img<IOpenProps>`
  width: 1.5rem;
  height: 1.5rem;
  position: relative;

  transform: ${props => props.open && 'rotate(0.5turn)'};

  > svg:hover {
    > path {
      stroke: ${Colors.primary60};
    }
  }
  &:hover {
    color: red;
  }
`;

const CloseBtn = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CargoTableInput = styled.input`
  border: 1px solid ${Colors.periwinkle};
  border-radius: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: ${Colors.black80};
  padding: 0.25rem 0.5rem;
  width: 100%;
  height: 2rem;

  &.error-field {
    border: 1px solid ${Colors.deepRed};
  }

  &:focus {
    outline: none;
    border-color: ${Colors.periwinkle};
  }
`;

const CargoTableLabel = styled.div`
  border: 1px solid ${Colors.periwinkle};
  border-radius: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: ${Colors.black80};
  padding: 0.25rem 0.5rem;
  width: 100%;
  height: 2rem;
  text-align: right;
`;

const AutoCompleteInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.25rem 0;
  input {
    font-size: 1rem;
  }
  &.secondary-input {
    padding: 0.5rem 0;
    input {
      border: 1px solid ${Colors.warning};
    }
    label {
      color: ${Colors.warning};
      font-size: 0.875rem;
      line-height: 1.313rem;
      font-weight: 400;
    }
  }
`;

const AutoCompleteResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 0 33px 0 #00000017;
  padding: 1rem;
  gap: 0.5rem 0;
`;

const AutoCompleteResultItem = styled.div`
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: ${Colors.black90};
  padding: 0.5rem;
  border-bottom: 0.5px solid ${Colors.periwinkle};
`;

export const DownloadPdf = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  text-align: center;

  > p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: ${Colors.deepRed};
    position: relative;
  }

  cursor: pointer;

  @media screen and (max-width: 767px) {
    > p {
      font-size: 0.875rem;
      line-height: 1.313rem;
    }
  }
`;

export {
  Image,
  SelectedOption,
  DropdownOption,
  DropdownOptions,
  DropdownPlaceholder,
  DropdownSelected,
  DropdownContainer,
  DropdownLabel,
  Dropdown,
  RequiredIndicator,
  UserLogoWrapper,
  SignWrapper,
  StyledImage,
  SignNotification,
  PrimaryButton,
  PrimaryInput,
  PrimaryLabel,
  InputWrapper,
  Container,
  LayoutSmall,
  GlobalWrapper,
  LogoImage,
  TitleH2,
  CheckboxBlock,
  HiddenCheckbox,
  StyledCheckbox,
  Icon,
  CheckboxLabel,
  InputIconWrapper,
  InputWarningMessage,
  InputErrorMessage,
  SecondaryButton,
  ModalWrapper,
  ModalTitleH2,
  ModalSubtitle,
  ButtonWrapper,
  ButtonWrapperColumn,
  UniversalModalLayout,
  ModalContentWrapper,
  ModalHeader,
  ModalContent,
  ModalButtonWrapper,
  PdfImage,
  SendFile,
  AlertImage,
  AlertWarningContentWrapper,
  SendPdfContentWrapper,
  AlertContent,
  AuthWrapper,
  CustomRadioInput,
  CustomRadioWrapper,
  SecondaryLabel,
  SecondaryInput,
  InputWrapperSecondary,
  DocumentContainer,
  DateInput,
  SecondaryTextArea,
  InputIconWrapperFull,
  SecondaryInputLabel,
  InitialsCircle,
  HoverStyledImage,
  CloseBtn,
  CargoTableInput,
  CargoTableLabel,
  DropdownWithCheckboxWrapper,
  DetailsModalContentWrapper,
  ChatWrapper,
  FullModalContentWrapper,
  ButtonWrapperModal,
  AutoCompleteInputWrapper,
  AutoCompleteResultsWrapper,
  AutoCompleteResultItem,
};

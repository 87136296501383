import { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar, LocalizationProvider, TimeClock  } from '@mui/x-date-pickers';
import { Box, Grid, Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/uk';
import Button from '../ui/Button';
import { chevronDown, calendarIcon } from '../../assets/images';
import {
  ButtonWrapper,
  CalendarDate,
  CalendarDateWrapper,
  calendarStyles,
  CalendarWithTimeContainer,
  CalendarWrapper,
  DateContainer,
  DateContainerWrapper,
  Image, TimeItem, TimeListContainerWrapper, TimeListHeader, TimeListItems, TimeListWrapper,
  Title,
} from './styles';

interface IBasicDateCalendar {
  text?: string;
  compact?: boolean;
  open?: boolean;
  handleChangeDate: (date: string) => void;
  onClose: () => void;
  className?: string;
  ref?: any;
  name?: any;
}

export default function BasicDateCalendar({
  text,
  compact,
  open = false,
  handleChangeDate,
  onClose,
  className,
  ref,
  name
}: IBasicDateCalendar) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedHour, setSelectedHour] = useState(selectedDate.hour());
  const [selectedMinute, setSelectedMinute] = useState(selectedDate.minute());
  const [approvedDate, setApprovedDate] = useState(dayjs());

  const handleCalendarToggle = () => {
    onClose();
  };

  const handleDateChange = (newDate: Dayjs | null) => {
    if (newDate) {
      setSelectedDate(newDate);
    }
  };

  const handleHourChange = (hour: number) => {
    setSelectedHour(hour);
  };

  const handleMinuteChange = (minute: number) => {
    setSelectedMinute(minute);
  };

  const handleApproveDate = () => {
    const fullDateTime = selectedDate.set('hour', selectedHour).set('minute', selectedMinute);

    setApprovedDate(fullDateTime);
    if (name === 'car_arrival_at') {
      handleChangeDate(fullDateTime.format('DD.MM.YY HH:mm'));
    } else {
      handleChangeDate(fullDateTime.format('DD.MM.YY'));
    }
    onClose();
  };

  const shouldDisableDate = (date: Dayjs) => {
    const today = dayjs();
    return date.isBefore(today, 'day');
  };

  const renderTimeList = () => (
      <TimeListContainerWrapper>
        <TimeListHeader>Оберіть час</TimeListHeader>
        <TimeListWrapper>
          {/* Hours List */}
          <TimeListItems>
            {Array.from({ length: 24 }, (_, index) => (
              <Grid item key={index}>
                <TimeItem
                  className={selectedHour === index ? 'selected' : ''}
                  onClick={() => handleHourChange(index)}
                >
                  {index.toString().padStart(2, '0')}
                </TimeItem>
              </Grid>
            ))}
          </TimeListItems>

          {/* Minutes List */}
          <TimeListItems>
            {Array.from({ length: 60 }, (_, index) => (
              <Grid item key={index}>
                <TimeItem
                  className={selectedMinute === index ? 'selected' : ''}
                  onClick={() => handleMinuteChange(index)}
                >
                  {index.toString().padStart(2, '0')}
                </TimeItem>
              </Grid>
            ))}
          </TimeListItems>
        </TimeListWrapper>
      </TimeListContainerWrapper>
  );

  if (compact) {
    return (
      <CalendarWrapper id={name === 'car_arrival_at' ? 'car_arrival_at' : ''} ref={ref} className={className} open={open} compact>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="uk">
          <CalendarWithTimeContainer className={name === 'car_arrival_at' ? 'car_arrival_at' : ''}>
            <DateCalendar
              onChange={handleDateChange}
              shouldDisableDate={shouldDisableDate}
              dayOfWeekFormatter={weekday => `${weekday.format('dd')}`}
              showDaysOutsideCurrentMonth
              sx={calendarStyles}
            />
            {
              name === 'car_arrival_at' && (
                <>
                  {renderTimeList()}
                </>
              )
            }
          </CalendarWithTimeContainer>
          <ButtonWrapper>
            <Button
              type="secondary"
              text="Відхилити"
              onClick={handleCalendarToggle}
            />
            <Button
              type="primary"
              text="Обрати"
              onClick={handleApproveDate}
            />
          </ButtonWrapper>
        </LocalizationProvider>
      </CalendarWrapper>
    );
  }

  return (
    <DateContainerWrapper>
      <DateContainer>
        <Title>{text}</Title>
        <CalendarDateWrapper onClick={() => setIsOpen(!isOpen)}>
          <Image src={calendarIcon} alt="calendar" />
          <CalendarDate>{approvedDate.format('DD.MM.YY HH:mm')}</CalendarDate>
          <Image src={chevronDown} alt="arrow" />
        </CalendarDateWrapper>
      </DateContainer>
      <CalendarWrapper open={isOpen}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="uk">
          <CalendarWithTimeContainer className={name === 'car_arrival_at' ? 'car_arrival_at' : ''}>
            <DateCalendar
              onChange={handleDateChange}
              shouldDisableDate={shouldDisableDate}
              dayOfWeekFormatter={weekday => `${weekday.format('dd')}`}
              showDaysOutsideCurrentMonth
              sx={calendarStyles}
            />
            {
              name === 'car_arrival_at' && (
                <>
                  {renderTimeList()}
                </>
              )
            }
          </CalendarWithTimeContainer>
          <ButtonWrapper>
            <Button
              type="secondary"
              text="Відхилити"
              onClick={handleCalendarToggle}
            />
            <Button
              type="primary"
              text="Обрати"
              onClick={handleApproveDate}
            />
          </ButtonWrapper>
        </LocalizationProvider>
      </CalendarWrapper>
    </DateContainerWrapper>
  );
}
import styled from 'styled-components';
import { Colors } from '../colors';

const ListOfThreadsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 550px;
  width: 100%;
  max-height: 752px;
  overflow-y: auto;
  background: ${Colors.white};
  border-radius: 0.25rem;
  &.empty-list {
    height: 752px;
    @media ((min-width: 768px) and (max-height: 860px)) {
      height: 600px;
    }
    @media (max-width: 480px) {
      height: 75vh;
    }
  }
  @media ((min-width: 768px) and (max-height: 860px)) {
    max-height: 600px;
  }
  @media (max-width: 1670px) {
    max-width: 435px;
  }
  @media (max-width: 1024px) {
    width: 15rem;
  }
  @media(max-width: 767px) {
    width: 100%;
  }
  @media (max-width: 480px) {
    max-width: 100%;
    max-height: 75vh;
  }
  div:nth-child(1) {
    max-width: 100%;
    input {
      border-left: none;
      border-top: none;
      border-right: none;
      border-radius: 0;
      border-width: 0.5px;
    }
  }
`;

interface ThreadsItemProps {
  active: string;
}

const ThreadsItem = styled.div<ThreadsItemProps>`
  width: 100%;
  display: flex;
  gap: 0 1rem;
  align-items: start;
  padding: 1rem 1rem 1rem 1.375rem;
  cursor: pointer;
  background: ${props =>
    props?.active === 'true' ? `${Colors.primary80}` : `${Colors.white}`};
  border-bottom: ${props =>
    props?.active === 'true' ? '0' : `0.5px solid ${Colors.periwinkle}`};
  border-left: ${props =>
          props?.active === 'true' ? `6px solid ${Colors.periwinkle}` : `0 solid transparent`};
  transition: 0.2s;
  &:hover {
    background: ${props =>
            props?.active === 'true' ? `${Colors.primary80}` : `${Colors.mistyLavender}`};
    transition: 0.2s;
    border-left: ${props =>
            props?.active === 'true' ? `6px solid ${Colors.periwinkle}` : `6px solid ${Colors.periwinkle}`};
  }
`;

const ThreadsItemTitles = styled.div<ThreadsItemProps>`
  display: flex;
  flex-direction: column;
  gap: 0.375rem 0;
  width: 100%;
  h6 {
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${props =>
      props?.active === 'true' ? `${Colors.white}` : `${Colors.primary}`};
    font-weight: 500;
  }
  p {
    color: ${props =>
      props?.active === 'true' ? `${Colors.white80}` : `${Colors.black60}`};
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;

const ThreadsItemInfo = styled.div<ThreadsItemProps>`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 9px 0;
  p {
    color: ${props =>
      props?.active === 'true' ? `${Colors.white90}` : `${Colors.primary80}`};
    font-size: 0.875rem;
    line-height: 1.313rem;
  }
  .unread-messages {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    border: ${props =>
      props?.active === 'true' ? `0` : `1px solid ${Colors.white}`};
    color: ${Colors.white};
    background: ${Colors.terra_cotta};
    font-size: 0.875rem;
    line-height: 1.313rem;
  }
`;

const SmallChatHeader = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 1em;
  background-color: ${Colors.primary80};
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border: 1px solid ${Colors.periwinkle};
  gap: 0.75rem;

  @media ((min-width: 768px) and (max-width: 1023px)) {
    padding: 1em 0.75em;
  }

  @media (max-width: 767px) {
    max-height: 3rem;
    padding: 0.75rem;
    border-radius: 0.25rem 0.25rem 0 0;
  }
`;

export const SmallChatHeaderContainerIcon = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  > svg:hover {
    > path {
      stroke: ${Colors.primary60};
    }
  }
  > svg:hover {
    > path {
      stroke: ${Colors.primary60};
    }
  }
  @media (max-width: 1023px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const ChatDetailsPage = styled.div`
  width: 100%;
  height: 100%;
  max-width: 70.5%;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  &.small-chat {
    max-width: 100%;
    @media (max-width: 767px) {
    }
  }
`;

export const StatusChat = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 86%;

  @media ((min-width: 768px) and (max-width: 1023px)) {
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    min-width: fit-content;
  }

  @media (max-width: 767px) {
    min-width: fit-content;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

interface ScrollableProps {
  isScrollable?: boolean;
}

const MessageZone = styled.div<ScrollableProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${Colors.white};
  max-height: 752px;
  width: 100%;
  gap: 1.5rem 0;
  padding: 0 1rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-left: 0.5px solid ${Colors.periwinkle};
  overflow-y: ${props => (props.isScrollable ? 'scroll' : 'hidden')};
  height: ${props => (props.isScrollable ? 'auto' : '100%')};
  min-height: 100%;
  &.small {
    border-top: 6px solid ${Colors.periwinkle};
    max-height: calc(28.563rem - 4.625rem);
    min-height: calc(28.563rem - 4.625rem);
    height: ${props => (props.isScrollable ? 'auto' : '100%')};
    border-right: 0.5px solid ${Colors.periwinkle};
    border-bottom: 0.5px solid ${Colors.periwinkle};
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    @media (min-width: 1200px) and (max-width: 1919px) {
      max-height: calc(457px - 74px) !important;
      min-height: calc(457px - 74px) !important;
    }
    @media (max-width: 1440px) {
      margin-bottom: 1.5rem;
    }
    @media(max-width: 1023px) {
      //max-height: calc(100vh - 54px) !important;
      //min-height: calc(100vh - 54px) !important;
      max-height: calc(70vh - 54px) !important;
      min-height: calc(70vh - 54px) !important;
    }
    @media (max-width: 1023px) {
      //max-height: 100%;
      //min-height: auto;
      //padding: 0;
    }
  }
  @media ((min-width: 768px) and (max-height: 860px)) {
    max-height: 600px;
    min-height: 100%;
  }
  @media (max-width: 1024px) {
    max-height: 716px;
  }
  @media (max-width: 480px) {
    border-left: 0;
    overflow-y: auto;
    //height: 100%;
    //max-height: calc(100vh - 100px) !important;
    //min-height: calc(100vh - 100px) !important;
    max-height: calc(70vh - 100px) !important;
    min-height: calc(70vh - 100px) !important;
    border-right: none !important;
    border-bottom: none !important;
    margin-bottom: 0 !important;
    overflow-x: hidden;
  }
  &#message-zone {
    @media(max-width: 480px) {
      border-top: none !important;
    }
  }
  &#modal-chat-zone {
    border-top: 6px solid ${Colors.periwinkle};
    max-height: calc(28.563rem - 4.625rem) !important;
    min-height: calc(28.563rem - 4.625rem) !important;
    @media (min-width: 1200px) and (max-width: 1690px) {
      max-height: calc(457px - 74px) !important;
      min-height: calc(457px - 72px) !important;
    }
    @media (max-width: 480px) {
      border-right: 0.5px solid ${Colors.periwinkle} !important;
      border-left: 0.5px solid ${Colors.periwinkle} !important;
      border-bottom: 0.5px solid ${Colors.periwinkle} !important;
      border-bottom-right-radius: 0.25rem !important;
      border-bottom-left-radius: 0.25rem !important;
      margin-bottom: 1.5rem !important;
      position: relative !important;
      max-height: calc(445px - 100px) !important;
      min-height: calc(445px - 100px) !important;
      border-top-right-radius: 0;
      border-top: 6px solid ${Colors.periwinkle};
    }
    form {
      @media (max-width: 480px) {
        position: sticky !important;
        //padding: 0 !important;
        transform: translateX(-1rem);
        width: calc(100% + 2rem);
      }
    }
  }
`;

interface UserType {
  entity: string;
}

const MessageItem = styled.div<UserType>`
  padding: 1rem 4rem 1rem 1rem;
  gap: 0.75rem 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: fit-content;
  background: ${props =>
    props.entity === 'clients' ? `${Colors.primary80}` : `${Colors.baby_blue}`};
  max-width: 85%;
  margin-left: ${props => (props.entity === 'clients' ? `auto` : `0`)};
  border-radius: ${props =>
    props.entity === 'clients'
      ? `.25rem .25rem 0 .25rem`
      : `.25rem .25rem .25rem 0`};
  p {
    color: ${props =>
      props.entity === 'clients' ? `${Colors.white}` : `${Colors.black90}`};
    font-size: 1rem;
    line-height: 1.5rem;
    word-wrap: break-word;
  }
  a {
    color: ${props =>
      props.entity === 'clients' ? `${Colors.white}` : `${Colors.black90}`};
    font-size: 1rem;
    line-height: 1.5rem;
  }
  span {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: ${props =>
      props.entity === 'clients' ? `${Colors.white80}` : `${Colors.primary80}`};
    display: flex;
    align-items: center;
    gap: 0 0.25rem;
    svg {
      padding-bottom: 0.125rem;
    }
  }
  .document-wrapper {
    display: flex;
    gap: 0 0.25rem;
    svg {
      path {
        stroke: ${props =>
          props.entity === 'clients' ? `${Colors.white}` : `${Colors.black90}`};
      }
    }
    div.document-content {
      display: flex;
      flex-direction: column;
      gap: 0.125rem 0;
      p {
        font-size: 1rem;
        line-height: 1.5rem;
        color: ${props =>
          props.entity === 'clients' ? `${Colors.white}` : `${Colors.black90}`};
      }
      .size {
        color: ${props =>
          props.entity === 'clients'
            ? `${Colors.white80}`
            : `${Colors.black90}`};
        font-size: 0.75rem;
        line-height: 1.125rem;
      }
    }
  }
`;

const SendMessageDate = styled.div`
  display: flex;
  justify-content: center;
  font-size: 0.875rem;
  line-height: 1.313rem;
  text-align: center;
  color: ${Colors.black80};
`;

export const SvgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  > svg:hover {
    > path {
      stroke: ${Colors.primary60};
    }
  }
`;

const UploadedFileWrapper = styled.div`
  display: flex;
  position: relative;
  gap: 0 0.25rem;
  padding-top: 2.5rem;
  svg {
    path {
      stroke: ${Colors.primary};
    }
  }
  div.document-content {
    display: flex;
    flex-direction: column;
    gap: 0.125rem 0;
    p {
      font-size: 1rem;
      line-height: 1.5rem;
      color: ${Colors.primary};
      font-weight: 500;
    }
    .size {
      color: ${Colors.primary80};
      font-size: 0.75rem;
      line-height: 1.125rem;
      font-weight: 400;
    }
    .error {
      color: ${Colors.deepRed};
      font-size: 0.75rem;
      line-height: 1.125rem;
      font-weight: 400;
    }
  }
  .cancel-icon {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
`;

const ChatTitle = styled.div`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${Colors.primary};
  background: ${Colors.white};
  position: sticky;
  top: 0;
  z-index: 3;
  padding: 1rem;
  cursor: pointer;
  border-bottom: 0.5px solid ${Colors.periwinkle};
  margin: 0 -1rem;
`;

const SendMessageForm = styled.form`
  display: flex;
  align-items: center;
  width: calc(100% + 2rem);
  height: 3.5rem;
  border-top: 0.5px solid ${Colors.periwinkle};
  position: sticky;
  bottom: 0;
  left: 0;
  padding: 0 1rem;
  background: ${Colors.white};
  transform: translateX(-1rem);
  &.has-error {
    border: 0.5px solid ${Colors.deepRed};
    border-radius: 0.25rem;
  }
  &.small {
    //padding: 0;
    @media (max-width: 767px) {
      padding: 0 1rem;
    }
  }
  &.empty_small_form {
    //position: absolute;
    @media(max-width: 767px) {
      position: fixed;
    }
  }
  &#send-file {
    padding: 0 1rem 0 0;
    margin-top: 1rem;
  }
  @media (max-width: 767px) {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 1rem;
    background: ${Colors.white};
    transform: none;
    width: 100%;
  }
`;

const SendMessageTextInput = styled.input`
  width: 100%;
  color: ${Colors.black60};
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0 0.75rem;
  height: 100%;
  border: none;
  @media(max-width: 370px) {
    font-size: 0.838rem;
    line-height: 1.313rem;
  }
  &:focus {
    outline: none;
  }
`;

const SendMessageButtonIcon = styled.button`
  height: 3rem;
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
`;

const UploadFileWrapper = styled.div`
  position: relative;
  width: 1.5rem;
  height: 100%;
  display: flex;
  align-items: center;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  @media (min-width: 1440px) {
    width: fit-content;
  }
`;

const EmptyChatState = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 0.5rem 0;
  max-width: 100%;
  padding: 1rem;
  p {
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${Colors.white60};
    text-align: center;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
`;

const MentionZone = styled.div`
  display: flex;
  flex-direction: column;
  background: ${Colors.white};
  box-shadow: 0 0 33px 0 #00000017;
  margin-bottom: -1.5rem;
  :first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  :last-child {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  p {
    font-size: 1rem;
    padding: 0.75rem;
    line-height: 1.5rem;
    cursor: pointer;
    color: ${Colors.black90};
    transition: 0.3s;
    &:hover {
      transition: 0.3s;
      background: ${Colors.primary80};
      color: ${Colors.white};
    }
  }
`;

const MessageContentWrapper = styled.div<ScrollableProps>`
  //height: 100%;
  height: ${props => (props.isScrollable ? 'auto' : 'fit-content')};
  //min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 1.5rem 0;
  position: ${props => (props.isScrollable ? 'relative' : 'absolute')};
  //position: absolute;
  bottom: 0;
  left: 0;
  padding: ${props => (props.isScrollable ? '0' : '0 1rem')};
  //&.big {
  //  height: 100% !important;
  //  position: relative !important;
  //  padding: 0 !important;
  //}
  &.message-content-wrapper_small {
    @media (max-width: 1023px) {
      height: ${props => (props.isScrollable ? 'auto' : 'fit-content')};
    }
  }
  @media (max-width: 767px) {
    //height: auto;
    height: ${props => (props.isScrollable ? 'auto' : 'fit-content')};

    position: ${props => (props.isScrollable ? 'relative' : 'absolute')};
    padding: 6vh 1rem 0 1rem;
    bottom: 6vh;
    //position: absolute;
    //max-height: calc(100vh - 120px);
  }
  &.big {
    @media(max-width: 767px) {
      padding: 0 1rem 0 1rem !important;
      bottom: 0 !important;
    }
  }
  &.ios {
    @media(max-width: 767px) {
      bottom: 6vh;
      padding: 6vh 1rem 0 1rem;
    }
    //@todo ios check
  }
  &#modal-chat {
    @media (max-width: 767px) {
      height: ${props => (props.isScrollable ? 'auto' : 'fit-content')};

      position: ${props => (props.isScrollable ? 'relative' : 'absolute')};
      //height: 100%;
      padding: 1rem 1rem 0;
      bottom: 0;
      //max-height: calc(445px - 100px) !important;
      //min-height: calc(445px - 100px) !important;
    }
    //min-height: calc(28.563rem - 4.625rem) !important;
  }
`;

export {
  ListOfThreadsWrapper,
  ThreadsItem,
  ThreadsItemTitles,
  ThreadsItemInfo,
  MessageZone,
  MessageItem,
  ChatTitle,
  SendMessageForm,
  SendMessageTextInput,
  SendMessageButtonIcon,
  UploadFileWrapper,
  EmptyChatState,
  UploadedFileWrapper,
  MentionZone,
  SendMessageDate,
  MessageContentWrapper,
  SmallChatHeader,
};

//@ts-nocheck
import React, { useState } from 'react';
import TableRow from './TableRow';
import { AddRowWrapper, AddRowLabel, AddRowContainer } from './styles';
import AddIcon from '../../../assets/ReactSvg/AddIcon';
import { SecondaryLabel } from '../../styles';
import CalculatedRow from './CalculatedRow';
import { useFormContext } from '../FormContext';
import TableRowFType from './TableRowFType';
import CalculatedRowFType from './CalculatedRowFType';

const CargoTableBody = () => {
  const { formData, updateFormData } = useFormContext();

  const rowsIndexesArray = formData?.step4?.goods?.map(
    (item: any, index: number) => index,
  );
  const [rows, setRows] = useState(
    rowsIndexesArray?.length ? rowsIndexesArray : [0],
  );
  const addRow = () => {
    const rowIndex = rows.length;

    // @ts-ignore
    setRows(prevState => [...prevState, rowIndex]);
  };

  const removeRow = (index: number) => {
    // @ts-ignore
    setRows(prevState => {
      // @ts-ignore
      const newRows = prevState
        ?.filter((_, i) => i !== index)
        ?.map((item, i) => (i >= index ? item - 1 : item));
      return newRows;
    });

    const updatedGoods = [...(formData.step4.goods || [])];

    for (let i = index; i < updatedGoods.length - 1; i++) {
      updatedGoods[i] = updatedGoods[i + 1];
    }

    updatedGoods.pop();

    updateFormData('step4', { goods: updatedGoods });
  };

  const renderAddButton = () => {
    return (
      <AddRowContainer>
        <AddRowWrapper onClick={addRow}>
          <AddIcon />
          <AddRowLabel>Додати ще один вантаж</AddRowLabel>
        </AddRowWrapper>
        <SecondaryLabel style={{ marginLeft: 0 }}>
          Не забудьте завантажити пакінг ліст, оскільки ви обрали послугу Карго
        </SecondaryLabel>
      </AddRowContainer>
    );
  };

  return (
    <div>
      {rows?.map((item: any) => {
        return (
          <>
            {formData?.step4?.type_of_transportation === 'FTL' ||
            formData?.step4?.type_of_transportation === 'FCL' ? (
              <TableRowFType indexRow={item} removeRow={removeRow} />
            ) : (
              <TableRow indexRow={item} removeRow={removeRow} />
            )}
          </>
        );
      })}
      {formData?.step4?.type_of_transportation === 'FTL' ||
      formData?.step4?.type_of_transportation === 'FCL' ? (
        <CalculatedRowFType />
      ) : (
        <CalculatedRow />
      )}

      {renderAddButton()}
    </div>
  );
};

export default CargoTableBody;

import * as React from 'react';
import { SVGProps } from 'react';
import { Colors } from '../../components/colors';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='128'
    height='128'
    viewBox='0 0 128 128'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M114.666 95.8222L79.2374 65.4222M48.7613 65.4222L13.3329 95.8222M10.666 40.0889L54.2123 69.0472C57.7385 71.3921 59.5016 72.5646 61.4194 73.0187C63.1135 73.4199 64.8852 73.4199 66.5793 73.0187C68.4971 72.5646 70.2602 71.3921 73.7864 69.0472L117.333 40.0889M36.266 105.956H91.7327C100.694 105.956 105.174 105.956 108.597 104.299C111.607 102.842 114.055 100.516 115.589 97.6562C117.333 94.4048 117.333 90.1484 117.333 81.6356V49.2089C117.333 40.6961 117.333 36.4397 115.589 33.1883C114.055 30.3282 111.607 28.0029 108.597 26.5456C105.174 24.8889 100.694 24.8889 91.7327 24.8889H36.266C27.3052 24.8889 22.8248 24.8889 19.4022 26.5456C16.3916 28.0029 13.9439 30.3282 12.4099 33.1883C10.666 36.4397 10.666 40.6961 10.666 49.2089V81.6356C10.666 90.1484 10.666 94.4048 12.4099 97.6562C13.9439 100.516 16.3916 102.842 19.4022 104.299C22.8248 105.956 27.3052 105.956 36.266 105.956Z'
      stroke={Colors.periwinkle} strokeWidth='4' strokeLinecap='round' strokeLinejoin='round' />
  </svg>

);
export default SvgComponent;

//@ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import {
  CargoDetailsWrapper,
  CargoDetailsItem,
  AddDocumentWrapper,
  AddDocumentLabel,
  DocumentNameLabel,
  SelectedFilesWrapper,
} from './styles';
import CustomDropdown from '../../ui/Dropdown/Dropdown';
import { useFormContext } from '../FormContext';
import InputBlock from '../../ui/InputBlock';
import Checkbox from '../../ui/Checkbox';
import { PrimaryLabel } from '../../styles';
import AddIcon from '../../../assets/ReactSvg/AddIcon';
import OfficeIcon from '../../../assets/ReactSvg/OfficeIcon';
import RemoveIcon from '../../../assets/ReactSvg/RemoveIcon';
import DocumentSvg from '../../../assets/ReactSvg/DocumentSvg';
import { useMobile, useTablet } from '../../../constants/breakpoints';
import { SvgWrapper } from '../../Threads/styles';

const CargoDetails = ({ config }: any) => {
  const { formData, updateFormData, errorFields, resetErrorFields } =
    useFormContext();
  const [includeBatteries, setIncludeBatteries] = useState(
    !!formData?.step4?.has_battery,
  );
  const [mouseOn, setMouseOn] = useState({
    msds_file: false,
    other_files: false,
  });
  const { step4, step1 } = formData;
  const [includeHarmfulMaterials, setIncludeHarmfulMaterials] = useState(
    step4?.dangerous_cargo || false,
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const isMobile = useMobile();
  const isTablet = useTablet();
  const loadingTypes =
    step4?.type_of_transportation === 'FTL'
      ? config?.loading_types
      : config?.loading_types?.slice(0, 2);

  useEffect(() => {
    if (!includeHarmfulMaterials) {
      updateFormData('step4', { harmful_materials_class: '' });
    }
  }, [includeHarmfulMaterials]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetErrorFields();
    updateFormData('step4', { [e.target.name]: e.target.value });
  };

  const handleChangeDate = (e: any) => {
    updateFormData('step4', { cargo_readiness: e.target.value });
  };

  const handleChangeCheckbox = (name: string, value: boolean) => {
    resetErrorFields();
    if (name === 'has_battery') {
      setIncludeBatteries(!value);
    } else if (name === 'dangerous_cargo') {
      setIncludeHarmfulMaterials(!value);
    }
    updateFormData('step4', { [name]: !value });
  };

  const onUpdateFile = (event: any, name: string) => {
    resetErrorFields();
    const file = event.target.files[0];
    if (file) {
      updateFormData('step4', {
        [name]: formData.step4[name] ? [...formData.step4[name], file] : [file],
      });
    }
  };

  const renderAddDocumentComponent = (fileName: string, format: string) => {
    return (
      <AddDocumentWrapper>
        <div>
          <DocumentSvg />
          <AddDocumentLabel>Додати файл</AddDocumentLabel>
        </div>
        <AddIcon className={mouseOn[fileName] ? 'icon-with-hover' : ''} />
        <input
          onMouseEnter={() =>
            setMouseOn(prev => ({ ...prev, [fileName]: true }))
          }
          name={fileName}
          onMouseLeave={() =>
            setMouseOn(prev => ({ ...prev, [fileName]: false }))
          }
          type="file"
          ref={inputRef}
          onChange={event => onUpdateFile(event, fileName)}
          accept={format}
        />
      </AddDocumentWrapper>
    );
  };

  // harmful_materials_class
  const removeFile = (name: string, fileName: string) => {
    const filteredArray = step4[name]?.filter(
      (item: any) => item.name !== fileName,
    );
    updateFormData('step4', { [name]: filteredArray });
  };

  const renderSelectedFiles = (typeName: string) => {
    return (
      <SelectedFilesWrapper>
        {step4[typeName]?.map((item: any, index: any) => {
          return (
            <AddDocumentWrapper key={index}>
              <SvgWrapper>
                <OfficeIcon />
                <DocumentNameLabel>
                  {item?.name?.replace(/(.{12}).*?(\.[^.]+)$/, '$1$2')}
                </DocumentNameLabel>
              </SvgWrapper>
              <RemoveIcon onClick={() => removeFile(typeName, item.name)} />
            </AddDocumentWrapper>
          );
        })}
      </SelectedFilesWrapper>
    );
  };

  return (
    <CargoDetailsWrapper
      amount={
        includeHarmfulMaterials && step1?.mode_of_transportation === 'land'
          ? 6
          : step1?.mode_of_transportation === 'land' && !includeHarmfulMaterials
            ? 5
            : step1?.mode_of_transportation !== 'land' &&
                !includeHarmfulMaterials
              ? 4
              : 3
      }
    >
      {step1?.mode_of_transportation === 'land' && (
        <CargoDetailsItem>
          <CustomDropdown
            label={'Тип погрузки'}
            placeholder={'Оберіть тип погрузки'}
            options={
              step4?.type_of_transportation === 'FTL'
                ? config?.loading_types
                : config?.loading_types?.slice(0, 2)
            }
            name={'loading_type'}
            onSelect={e => {
              handleChange(e);
            }}
            className={
              isMobile || isTablet ? 'small' : 'small harmful_materials_class'
            }
            withValueLabel={false}
            defaultValue={loadingTypes?.find(
              (item: any) => item?.value === step4?.loading_type,
            )}
          />
        </CargoDetailsItem>
      )}
      <CargoDetailsItem>
        <InputBlock
          id={'courier_shipment_date'}
          placeholder={'Дата'}
          type={'text'}
          text={'Дата готовності вантажу'}
          onChange={handleChangeDate}
          name={'cargo_readiness'}
          value={step4?.cargo_readiness}
          className={'calendar-near'}
          updateFormData={updateFormData}
          step={'step4'}
        />
      </CargoDetailsItem>

      <CargoDetailsItem
        className={
          errorFields?.includes('harmful_materials_class') ? 'error-field' : ''
        }
      >
        <PrimaryLabel>Небезпечний вантаж</PrimaryLabel>
        <div style={{ display: 'flex', alignItems: 'center', gap: '0 .25rem' }}>
          <Checkbox
            checked={includeHarmfulMaterials}
            onChange={() =>
              handleChangeCheckbox('dangerous_cargo', includeHarmfulMaterials)
            }
            id={'dangerous_cargo'}
            label={''}
          />
          {includeHarmfulMaterials && (
            <CustomDropdown
              placeholder={'Оберіть зі списку'}
              options={config?.harmful_materials_classes}
              name={'harmful_materials_class'}
              onSelect={e => {
                handleChange(e);
              }}
              className={
                isMobile || isTablet ? 'small' : 'small harmful_materials_class'
              }
              withValueLabel={false}
              // displayValue={!!step4?.harmful_materials_class}
              defaultValue={config?.harmful_materials_classes?.find(
                (item: any) => item?.value === step4?.harmful_materials_class,
              )}
              disabled={!includeHarmfulMaterials}
            />
          )}
        </div>
      </CargoDetailsItem>

      {includeHarmfulMaterials && (
        <CargoDetailsItem
          className={errorFields?.includes('msds_file') ? 'error-field' : ''}
        >
          <PrimaryLabel>Завантажити MSDS Сертифікат</PrimaryLabel>
          {renderAddDocumentComponent('msds_file', 'application/pdf, .pdf')}
          {renderSelectedFiles('msds_file')}
        </CargoDetailsItem>
      )}

      <CargoDetailsItem>
        <PrimaryLabel>Містить батареї</PrimaryLabel>
        <Checkbox
          checked={includeBatteries}
          onChange={() => handleChangeCheckbox('has_battery', includeBatteries)}
          id={'batteries'}
          label={''}
        />
      </CargoDetailsItem>
      <CargoDetailsItem>
        <PrimaryLabel>Фото, відео, інші файли</PrimaryLabel>
        {renderAddDocumentComponent(
          'other_file',
          'image/*,video/*, .pdf, .docx, .doc, .xls, .xlsx',
        )}
        {renderSelectedFiles('other_file')}
      </CargoDetailsItem>
    </CargoDetailsWrapper>
  );
};

export default CargoDetails;

import styled from 'styled-components';
import { Colors } from '../colors';

export const DownloadDocumentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 650px;
  background-color: ${Colors.white};
  padding: 1rem;
  gap: 2.5rem;
  border-radius: 0.25rem;

  @media (max-width: 767px) {
    gap: 1.5rem;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
`;
export const ModalTitle = styled.div`
  text-align: center;
  color: ${Colors.primary};
  font-size: 1.9rem;
  font-weight: 500;
  line-height: 3rem;

  @media (max-width: 767px) {
    font-size: 1.313rem;
    line-height: 2rem;
  }
`;

import styled from 'styled-components';
import { Colors } from '../../colors';

export const DepartureStepContainer = styled.div`
  .departureStep {
    max-width: 45.125em;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    padding: 1em;
    background: ${Colors.white};
    border-top: 1px solid ${Colors.aqua};
  }
`;

export const DeliveryDirectionStepModal = styled.div`
  .deliveryDirectionStep {
    max-width: 45.125rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem;
    background: ${Colors.white};
    border-top: 1px solid ${Colors.aqua};

    @media (max-width: 1023px) {
      gap: 1rem;
    }
  }
`;

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  &.inner-border {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid ${Colors.aqua};

    @media (max-width: 1023px) {
      padding-bottom: 1rem;
    }
  }

  @media (max-width: 1023px) {
    gap: 1rem;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const FirstMileContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  border-top: 1px solid ${Colors.aqua};
  border-bottom: 1px solid ${Colors.aqua};
  padding: 1.5rem 0;

  @media ((min-width: 768px) and (max-width: 1023px)) {
    padding: 1rem 0;
    gap: 1rem;
  }
`;

export const StyledText = styled.span`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  text-transform: uppercase;
  color: ${Colors.primary};
`;

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  div {
    max-width: 100%;
  }
`;

export const InnerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media ((min-width: 768px) and (max-width: 1023px)) {
    gap: 1rem;
  }
`;

export const ContentWrapper = styled.div`
  padding-bottom: 1.5rem;
  border-bottom: 1px solid ${Colors.aqua};

  @media (max-width: 1023px) {
    padding-bottom: 1rem;
  }
`;

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={36} height={36} fill="none" {...props}>
    <path
      stroke="#3A3875"
      strokeWidth={2}
      d="M30.225 26.306a3.775 3.775 0 1 1-7.55 0 3.775 3.775 0 0 1 7.55 0ZM15.125 26.306a3.775 3.775 0 1 1-7.55 0 3.775 3.775 0 0 1 7.55 0Z"
    />
    <path
      stroke="#3A3875"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M22.675 26.305h-7.55M3.8 5.92h15.1c2.136 0 3.203 0 3.867.663.663.664.663 1.732.663 3.867v12.835m.755-13.59h2.72c1.253 0 1.88 0 2.399.294.519.294.841.831 1.486 1.905l2.565 4.275c.32.535.48.802.563 1.099.082.297.082.608.082 1.232v4.03c0 1.411 0 2.117-.303 2.643-.199.344-.485.63-.83.829-.525.303-1.23.303-2.642.303M3.8 19.51v3.02c0 1.411 0 2.117.303 2.643.2.344.485.63.83.829.525.303 1.23.303 2.642.303M3.8 10.45h9.06M3.8 14.98h6.04"
    />
  </svg>
);
export default SvgComponent;

import * as React from 'react';
import { SVGProps } from 'react';
import { Colors } from '../../components/colors';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ?? 24}
    height={props.height ?? 24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={Colors.primary80}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21 9.4H3M16 1v4.2M8 1v4.2M7.8 22h8.4c1.68 0 2.52 0 3.162-.343a3.079 3.079 0 0 0 1.311-1.377c.327-.674.327-1.556.327-3.32V8.14c0-1.764 0-2.646-.327-3.32a3.079 3.079 0 0 0-1.311-1.377C18.72 3.1 17.88 3.1 16.2 3.1H7.8c-1.68 0-2.52 0-3.162.343A3.08 3.08 0 0 0 3.327 4.82C3 5.494 3 6.376 3 8.14v8.82c0 1.764 0 2.646.327 3.32a3.08 3.08 0 0 0 1.311 1.377C5.28 22 6.12 22 7.8 22Z"
    />
  </svg>
);
export default SvgComponent;

import React, { useEffect, useState } from 'react';
import CustomDropdown from '../../ui/Dropdown/Dropdown';
import TableInput from '../../ui/Input/TableInput';
import Checkbox from '../../ui/Checkbox';
import { useFormContext } from '../FormContext';
import {
  TableBodyContainer,
  BodyItem,
  TableCheckbox,
  CustomRowWrapper,
  CustomInsideRowWrapper,
  ConvertedValueLabel,
} from './styles';
import {
  footIntoMeters,
  formatAsMoney,
  poundsIntoKilograms,
  cubicFootIntoMeters,
  getSessionStorage,
} from '../../../utils/global';
import DeleteRowIcon from '../../../assets/ReactSvg/DeleteRowIcon';

const packingTypesConfig = [
  {
    label: 'Коробка',
    value: 'box',
  },
  {
    label: 'Палета',
    value: 'pallet',
  },
  {
    label: 'Мішок',
    value: 'bag',
  },
  {
    label: 'Негабарит',
    value: 'oversized',
  },
];

const TableRow = ({ indexRow, removeRow }: any) => {
  const { formData, updateFormData, errorFields, resetSelectedErrorField } =
    useFormContext();
  const [checked, setChecked] = useState(
    !!formData?.step4?.goods?.[indexRow]?.has_trademark,
  );
  const localData = getSessionStorage('data');

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number,
  ) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === 'amount' && value) {
      formattedValue = value?.replace(/^0+/, '');
    }

    const updatedGoods = [...(formData.step4.goods || [])];

    let updatedItem = updatedGoods[index];

    if (updatedItem) {
      updatedItem = {
        ...updatedItem,
        [name]: name === 'price' ? value : formattedValue,
      };
    } else {
      updatedItem = {
        [name]: name === 'price' ? value : formattedValue,
      };
    }
    updatedGoods[index] = updatedItem;

    if (
      name === 'packaging_type' ||
      name === 'price' ||
      name === 'amount' ||
      name === 'name'
    ) {
      resetSelectedErrorField(`goods.${indexRow}.${name}`);
    }

    updateFormData('step4', { goods: updatedGoods });
  };

  const changeFormData = (value: any, name: any) => {
    const updatedGoods = [...(formData.step4.goods || [])];

    let updatedItem = updatedGoods[indexRow];

    if (updatedItem) {
      updatedItem = {
        ...updatedItem,
        [name]: value,
      };
    } else {
      updatedItem = {
        [name]: value,
      };
    }

    updatedGoods[indexRow] = updatedItem;
    updateFormData('step4', { goods: updatedGoods });
  };

  const calculateVolume = () => {
    return (
      +formData.step4?.goods?.[indexRow]?.width *
        +formData.step4?.goods?.[indexRow]?.length *
        +formData.step4?.goods?.[indexRow]?.height || 0
    );
  };

  useEffect(() => {
    changeFormData(calculateVolume(), 'volume');
  }, [
    formData.step4?.goods?.[indexRow]?.width,
    formData.step4?.goods?.[indexRow]?.length,
    formData.step4?.goods?.[indexRow]?.height,
  ]);

  useEffect(() => {
    if (!checked && !localData?.step4?.goods?.[indexRow]?.has_trademark) {
      changeFormData('', 'trademark_name');
    }
  }, [checked]);

  return (
    <TableBodyContainer
      ddpAmount={
        formData.step1?.cargo_requirement === 'transportation_only' ? 8 : 9
      }
      amount={formData.step4?.sizeType !== 'volume' ? 9 : 8}
    >
      <BodyItem>
        <CustomDropdown
          label={''}
          placeholder={'Оберіть тип'}
          options={packingTypesConfig}
          name={'packaging_type'}
          onSelect={e =>
            handleChange(
              e as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
              indexRow,
            )
          }
          withValueLabel={false}
          defaultValue={packingTypesConfig?.find(
            (item: any) =>
              item?.value ===
              formData?.step4?.goods?.[indexRow]?.packaging_type,
          )}
          className={
            errorFields?.includes(`goods.${indexRow}.packaging_type`) ||
            errorFields?.includes('goods')
              ? 'error-field table-dropdown'
              : 'table-dropdown'
          }
        />
      </BodyItem>
      <BodyItem>
        <TableInput
          name={'name'}
          placeholder={''}
          onChange={(e: any) => handleChange(e, indexRow)}
          type={'text'}
          id={`name${indexRow}`}
          className={
            errorFields?.includes(`goods.${indexRow}.name`) ||
            errorFields?.includes('goods')
              ? 'error-field'
              : ''
          }
          value={formData?.step4?.goods?.[indexRow]?.name}
        />
      </BodyItem>
      <BodyItem>
        <TableCheckbox>
          <Checkbox
            checked={checked}
            id="enter_mark"
            // has_trademark
            onChange={() => {
              setChecked(!checked);
              changeFormData(!checked, 'has_trademark');
            }}
          />
          {checked && (
            <TableInput
              name={'trademark_name'}
              placeholder={''}
              onChange={(e: any) => handleChange(e, indexRow)}
              value={formData?.step4?.goods?.[indexRow]?.trademark_name}
              type={'text'}
              id={`trademark_name${indexRow}`}
              disabled={!checked}
            />
          )}
        </TableCheckbox>
      </BodyItem>
      <BodyItem>
        <TableInput
          name={'amount'}
          placeholder={''}
          onChange={(e: any) => handleChange(e, indexRow)}
          type={'number'}
          id={`amount${indexRow}`}
          className={
            errorFields?.includes(`goods.${indexRow}.amount`) ||
            errorFields?.includes('goods')
              ? 'error-field'
              : ''
          }
          value={formData?.step4?.goods?.[indexRow]?.amount}
        />
      </BodyItem>

      <BodyItem
        className={formData.step4?.type_weight === 'ft/lb' ? 'calc_weight' : ''}
      >
        {formData.step4?.sizeType === 'size' ? (
          <CustomRowWrapper
            className={formData.step4?.type_weight === 'ft/lb' ? 'ft' : ''}
          >
            <CustomInsideRowWrapper
              className={formData.step4?.type_weight !== 'ft/lb' ? 'price' : ''}
            >
              <TableInput
                name={'length'}
                placeholder={'Довжина'}
                onChange={(e: any) => handleChange(e, indexRow)}
                type={'number'}
                id={`length${indexRow}`}
                value={formData?.step4?.goods?.[indexRow]?.length}
              />
              <TableInput
                name={'width'}
                placeholder={'Ширина'}
                onChange={(e: any) => handleChange(e, indexRow)}
                type={'number'}
                id={`width${indexRow}`}
                value={formData?.step4?.goods?.[indexRow]?.width}
              />
              <TableInput
                name={'height'}
                placeholder={'Висота'}
                onChange={(e: any) => handleChange(e, indexRow)}
                type={'number'}
                id={`height${indexRow}`}
                value={formData?.step4?.goods?.[indexRow]?.height}
              />
            </CustomInsideRowWrapper>
            {formData.step4?.type_weight === 'ft/lb' && (
              <CustomInsideRowWrapper>
                <ConvertedValueLabel>
                  {footIntoMeters(
                    formData?.step4?.goods?.[indexRow]?.length || 0,
                  )}{' '}
                  м
                </ConvertedValueLabel>
                <ConvertedValueLabel>
                  {footIntoMeters(
                    formData?.step4?.goods?.[indexRow]?.width || 0,
                  )}{' '}
                  м
                </ConvertedValueLabel>
                <ConvertedValueLabel>
                  {footIntoMeters(
                    formData?.step4?.goods?.[indexRow]?.height || 0,
                  )}{' '}
                  м
                </ConvertedValueLabel>
              </CustomInsideRowWrapper>
            )}
          </CustomRowWrapper>
        ) : (
          <CustomRowWrapper>
            <CustomInsideRowWrapper
              className={formData.step4?.type_weight !== 'ft/lb' ? 'price' : ''}
            >
              <TableInput
                name={'volume'}
                placeholder={'Обʼєм'}
                onChange={(e: any) => handleChange(e, indexRow)}
                type={'number'}
                id={`volume${indexRow}`}
                value={formData?.step4?.goods?.[indexRow]?.volume}
              />
            </CustomInsideRowWrapper>

            {formData.step4?.type_weight === 'ft/lb' && (
              <CustomInsideRowWrapper>
                <ConvertedValueLabel>
                  {cubicFootIntoMeters(
                    formData?.step4?.goods?.[indexRow]?.volume || 0,
                  )}{' '}
                  м
                </ConvertedValueLabel>
              </CustomInsideRowWrapper>
            )}
          </CustomRowWrapper>
        )}
      </BodyItem>

      {formData.step4?.sizeType !== 'volume' && (
        <BodyItem
          className={
            formData.step4?.type_weight === 'ft/lb' ? 'calc_weight' : ''
          }
        >
          <CustomRowWrapper>
            <CustomInsideRowWrapper
              className={formData.step4?.type_weight !== 'ft/lb' ? 'price' : ''}
            >
              <TableInput
                name={'volume'}
                placeholder={''}
                type={'text'}
                id={`volume${indexRow}`}
                value={
                  formData?.step4?.goods?.[indexRow]?.volume ||
                  calculateVolume() ||
                  ''
                }
                disabled={true}
              />
            </CustomInsideRowWrapper>
            {formData.step4?.type_weight === 'ft/lb' && (
              <CustomInsideRowWrapper>
                <ConvertedValueLabel className={'volume'}>
                  {cubicFootIntoMeters(
                    !!formData?.step4?.goods?.[indexRow]?.volume
                      ? formData?.step4?.goods?.[indexRow]?.volume
                      : calculateVolume(),
                  )}{' '}
                  м3
                </ConvertedValueLabel>
              </CustomInsideRowWrapper>
            )}
          </CustomRowWrapper>
        </BodyItem>
      )}

      {formData.step1?.cargo_requirement !== 'transportation_only' && (
        <BodyItem
          className={
            formData.step4?.type_weight === 'ft/lb' ? 'calc_weight' : ''
          }
        >
          <CustomRowWrapper>
            <CustomInsideRowWrapper
              className={formData.step4?.type_weight !== 'ft/lb' ? 'price' : ''}
            >
              <TableInput
                name={'net_weight'}
                placeholder={''}
                onChange={(e: any) => handleChange(e, indexRow)}
                type={'number'}
                id={`net_weight${indexRow}`}
                value={formData?.step4?.goods?.[indexRow]?.net_weight}
              />
            </CustomInsideRowWrapper>

            {formData.step4?.type_weight === 'ft/lb' && (
              <CustomInsideRowWrapper>
                <ConvertedValueLabel>
                  {poundsIntoKilograms(
                    formData?.step4?.goods?.[indexRow]?.net_weight || 0,
                  )}{' '}
                  кг
                </ConvertedValueLabel>
              </CustomInsideRowWrapper>
            )}
          </CustomRowWrapper>
        </BodyItem>
      )}

      <BodyItem
        className={formData.step4?.type_weight === 'ft/lb' ? 'calc_weight' : ''}
      >
        <CustomRowWrapper>
          <CustomInsideRowWrapper
            className={formData.step4?.type_weight !== 'ft/lb' ? 'price' : ''}
          >
            <TableInput
              name={'gross_weight'}
              placeholder={''}
              onChange={(e: any) => handleChange(e, indexRow)}
              type={'number'}
              id={`gross_weight${indexRow}`}
              value={formData?.step4?.goods?.[indexRow]?.gross_weight}
            />
          </CustomInsideRowWrapper>

          {formData.step4?.type_weight === 'ft/lb' && (
            <CustomInsideRowWrapper>
              <ConvertedValueLabel>
                {poundsIntoKilograms(
                  formData?.step4?.goods?.[indexRow]?.gross_weight || 0,
                )}{' '}
                кг
              </ConvertedValueLabel>
            </CustomInsideRowWrapper>
          )}
        </CustomRowWrapper>
      </BodyItem>
      <BodyItem>
        <CustomRowWrapper>
          <CustomInsideRowWrapper className={'price'}>
            <TableInput
              name={'price'}
              placeholder={''}
              onChange={(e: any) => handleChange(e, indexRow)}
              type={'number'}
              id={`price${indexRow}`}
              className={
                errorFields?.includes(`goods.${indexRow}.price`) ||
                errorFields?.includes('goods')
                  ? 'error-field'
                  : ''
              }
              value={formData?.step4?.goods?.[indexRow]?.price}
            />
          </CustomInsideRowWrapper>
        </CustomRowWrapper>
      </BodyItem>

      <BodyItem>
        {indexRow !== 0 && (
          <DeleteRowIcon onClick={() => removeRow(indexRow)} />
        )}
      </BodyItem>
    </TableBodyContainer>
  );
};

export default TableRow;

import React, { useRef, useState } from 'react';
import {
  Dropdown,
  DropdownContainer,
  DropdownLabel,
  DropdownOption,
  DropdownOptions,
  DropdownPlaceholder,
  DropdownSelected,
  SelectedOption,
  Image,
  InputWarningMessage,
  RequiredIndicator,
} from '../../styles';
import { chevronDown } from '../../../assets/images';

interface CustomRedionDropdownProps {
  label: string;
  placeholder: string;
  options: string[];
  name: string;
  onSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
  showWarning?: boolean;
  value?: any;
  disabled?: boolean;
  defaultValue?: any;
  required?: boolean;
  warningMessage?: string;
  validate?: boolean;
}

const CustomDropdown: React.FC<CustomRedionDropdownProps> = ({
  label,
  placeholder,
  options,
  name,
  onSelect,
  value,
  disabled,
  defaultValue,
  required,
  warningMessage,
  validate = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleOptionClick = (option: string) => {
    if (option === 'Нажаль немає доступних варіантів') {
      return;
    }
    const syntheticEvent = {
      target: {
        name,
        value: option,
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>;

    onSelect(syntheticEvent);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <Dropdown ref={dropdownRef}>
      <DropdownLabel>
        {label} {required && <RequiredIndicator>*</RequiredIndicator>}
      </DropdownLabel>
      <DropdownContainer
        className={disabled ? 'disabled' : ''}
        onClick={() => {
          if (!disabled) {
            setIsOpen(!isOpen);
          }
        }}
      >
        <DropdownSelected
          open={isOpen}
          className={validate ? 'validation_failed' : ''}
        >
          {value ? (
            <SelectedOption>
              <p>{value}</p>
            </SelectedOption>
          ) : (
            <DropdownPlaceholder>{placeholder}</DropdownPlaceholder>
          )}

          <Image src={chevronDown} alt="arrow" open={isOpen} />
        </DropdownSelected>
        {isOpen && (
          <DropdownOptions open={isOpen}>
            {options.map(option => (
              <DropdownOption
                key={option}
                className="dropdown-option"
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </DropdownOption>
            ))}
          </DropdownOptions>
        )}
      </DropdownContainer>
      {warningMessage && (
        <InputWarningMessage>{warningMessage}</InputWarningMessage>
      )}
    </Dropdown>
  );
};

export default CustomDropdown;

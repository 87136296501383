import React, { useState, forwardRef, useRef } from 'react';
import {
  PrimaryInput,
  InputIconWrapper,
  InputIconWrapperFull,
  InputErrorMessage,
  InputWarningMessage,
  DateInput,
  CalendarSvgWrapper,
} from '../../styles';
import { ForgotPasswordLink } from '../../../pages/auth/styles';
import ShowPasswordIcon from '../../../assets/images/EyeShow.svg';
import HidePasswordIcon from '../../../assets/images/EyeHide.svg';
import CalendarIcon from '../../../assets/ReactSvg/CalendarIcon';
import BasicDateCalendar from '../../Calendar';
import EyeIcon from '../../../assets/ReactSvg/EyeIcon';
import HideEyeIcon from '../../../assets/ReactSvg/HideEyeIcon';

export type InputType = {
  id: string;
  placeholder: string;
  type: string;
  value?: string | undefined;
  onChange: (e?: any) => void;
  errorMessage?: string | undefined;
  warningMessage?: string | undefined;
  isForgotLabel?: boolean | undefined;
  disabled?: boolean;
  required?: boolean;
  ref?: any;
  name?: string;
  step?: string;
  isPhoneMode?: boolean;
  className?: string;
  updateFormData?: (
    step: any,
    data: {
      [key: string]: any;
    },
  ) => void;
};

const Input = forwardRef<HTMLInputElement, InputType>(
  (
    {
      id,
      placeholder,
      type,
      value,
      onChange,
      errorMessage,
      warningMessage,
      isForgotLabel,
      disabled = false,
      required = false,
      name,
      step,
      isPhoneMode,
      updateFormData,
      className,
    },
    ref,
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const [isShowCalendar, setIsShowCalendar] = useState<boolean>(false);
    const isDatePickerMode =
      name === 'shipment_date' ||
      name === 'cargo_readiness' ||
      name === 'car_arrival_at';
    const calendarRef = useRef<HTMLDivElement>(null);

    const handleChangeDate = (date: string) => {
      const nameToUpdate = name || 'shipment_date';
      if (updateFormData) updateFormData(step, { [nameToUpdate]: date });
    };

    const toggleShowPassword = () => {
      setShowPassword(prevShowPassword => !prevShowPassword);
    };

    const handleOpenCalendar = () => {
      if (!isShowCalendar) {
        setIsShowCalendar(prev => !prev);
      }
    };
    const handleCloseClanedar = () => {
      setIsShowCalendar(prev => !prev);
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target as Node)
      ) {
        setIsShowCalendar(false);
      }
    };

    React.useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    if (isDatePickerMode) {
      return (
        <div style={{ display: 'contents' }} ref={calendarRef}>
          <DateInput
            name={name}
            id={id}
            placeholder={placeholder}
            required={required}
            value={value}
            onChange={onChange}
            disabled={true}
            ref={ref}
          />
          <InputIconWrapper
            className={name === 'cargo_readiness' ? 'icon-details' : ''}
            onClick={handleOpenCalendar}
          >
            <CalendarSvgWrapper>
              <CalendarIcon width={'1.5rem'} height={'1.5rem'} />
            </CalendarSvgWrapper>
            <BasicDateCalendar
              compact={isDatePickerMode}
              open={isShowCalendar}
              handleChangeDate={
                name === 'car_arrival_at' ? onChange : handleChangeDate
              }
              onClose={handleCloseClanedar}
              className={className}
              name={name}
            />
          </InputIconWrapper>
          {warningMessage && (
            <InputWarningMessage>{warningMessage}</InputWarningMessage>
          )}
        </div>
        // div style={{ display: 'contents' }} ref={calendarRef}
      );
    }
    if (isPhoneMode) {
      return (
        <>
          <PrimaryInput
            name={name}
            id={id}
            placeholder={placeholder}
            required={required}
            value={value}
            onChange={onChange}
            disabled={disabled}
            ref={ref}
          />
        </>
      );
    }

    return (
      <>
        <PrimaryInput
          name={name}
          id={id}
          placeholder={placeholder}
          required={required}
          type={
            type !== 'password' ? type : !showPassword ? 'password' : 'text'
          }
          value={value}
          onChange={onChange}
          disabled={disabled}
          ref={ref}
        />
        {type === 'password' && (
          <InputIconWrapperFull onClick={toggleShowPassword}>
            {showPassword ? (
              <EyeIcon width={'1.5rem'} height={'1.5rem'} />
            ) : (
              <HideEyeIcon width={'1.5rem'} height={'1.5rem'} />
            )}
          </InputIconWrapperFull>
        )}
        {warningMessage && (
          <InputWarningMessage>{warningMessage}</InputWarningMessage>
        )}
        {errorMessage && <InputErrorMessage>{errorMessage}</InputErrorMessage>}
        {type === 'password' && isForgotLabel && (
          <ForgotPasswordLink to="/change-password">
            Забули пароль?
          </ForgotPasswordLink>
        )}
      </>
    );
  },
);

export default Input;

import React from 'react';
import { TableHeaderWrapper, TableHeaderItem } from './styles';
import { useDashboardDesktop } from '../../constants/breakpoints';

const TableHeader = ({isShowActive} : any) => {
  const isDesktopBig = useDashboardDesktop();

  return (
    <TableHeaderWrapper className={isShowActive ? '' : 'archive'}>
      <TableHeaderItem></TableHeaderItem>
      <TableHeaderItem>Дата</TableHeaderItem>
      <TableHeaderItem>Статус</TableHeaderItem>
      <TableHeaderItem>Назва перевезення</TableHeaderItem>
      <TableHeaderItem>DF номер</TableHeaderItem>
      {
        isDesktopBig && (
          <TableHeaderItem>Назва товару</TableHeaderItem>
        )
      }
      <TableHeaderItem>Країна відправки</TableHeaderItem>
      <TableHeaderItem>Країна доставки</TableHeaderItem>
      <TableHeaderItem>Тип</TableHeaderItem>
      <TableHeaderItem>К-ть місць, вага, обʼєм</TableHeaderItem>
      <TableHeaderItem>Сума</TableHeaderItem>
      {
        isShowActive && (
          <TableHeaderItem>Необхідна дія</TableHeaderItem>
        )
      }
    </TableHeaderWrapper>
  );
};

export default TableHeader;
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from './FormContext';
import {
  StepHeader,
  StepNavigationWrapper,
  StepWrapper,
  NavigationButtonWrapper,
  StepDetailsTabletLabel,
  CheckmarkIcon,
  StepDetailsInfo,
} from './styles';
import {
  OrderFormStepsTablet,
  OrderFormStepsDesktop,
  OrderFormStepDetails,
  TransportationStepLabels,
} from '../../constants/labels';
import {
  useDesktop,
  useDesktopBig,
  useMobile,
  useTablet,
} from '../../constants/breakpoints';
import Button from '../ui/Button';
import { Checkmark } from '../../assets/images';
import AlertCircle from '../../assets/ReactSvg/AlertCircle';
import { FormData } from './FormContext';
import { TransportationModes } from '../../constants/types';
import CheckIcon from '../../assets/ReactSvg/CheckIcon';

const StepNavigation: React.FC = () => {
  const { currentStep, formData, goToStep, config, prepareFormData, disableSubmit } =
    useFormContext();
  const isDesktopBig = useDesktopBig();
  const isDesktop = useDesktop();
  const isTablet = useTablet();
  const isMobile = useMobile();
  const [getFromAddressTypeLabel, setFromAddressTypeLabel] = useState('');
  const [getToAddressTypeLabel, setToAddressTypeLabel] = useState('');

  useEffect(() => {
    setFromAddressTypeLabel(
      config?.sending_types?.find(
        (type: any) => type.value === formData.step2?.from_address_type,
      )?.label,
    );
    setToAddressTypeLabel(
      config?.receiving_types?.find(
        (type: any) => type.value === formData.step3?.to_address_type,
      )?.label,
    );
  }, [config, formData]);

  const mode = formData.step1?.mode_of_transportation as
    | TransportationModes
    | undefined;

  const StepperDetailsConfig = useMemo(
    () => [
      {
        title: mode ? TransportationStepLabels.type[mode] : '',
        subtitle:
          formData.step1.delivery_comment ||
          formData.step1?.delivery_conditions ||
          '',
      },
      {
        title:
          `${formData.step2?.country_from ? formData.step2?.country_from : ''}${getFromAddressTypeLabel ? ` - ${getFromAddressTypeLabel}` : ''}` ||
          '',
        subtitle:
          formData.step2?.receiving_place ||
          formData.step2?.address_from_place ||
          '',
      },
      {
        title:
          `${formData.step3?.country_to ? formData.step3?.country_to : ''}${getToAddressTypeLabel ? ` - ${getToAddressTypeLabel}` : ''}` ||
          '',
        subtitle:
          formData.step3?.address ||
          formData.step3?.receiving_warehouse_id ||
          formData.step3?.fba_warehouse ||
          '',
      },
      {
        title:
          `${formData.step4?.type_of_transportation ? formData.step4?.type_of_transportation : ''}` ||
          '',
      },
    ],
    [formData, getToAddressTypeLabel, getFromAddressTypeLabel],
  );

  const checkRequiredFields = () => {
    return (
      formData?.step1?.mode_of_transportation &&
      formData?.step2?.country_from &&
      formData?.step3?.country_to &&
      formData?.step4?.type_of_transportation &&
      formData?.step4?.cargo_params &&
      checkGoods()
    );
  };

  const checkGoods = () => {
    return formData?.step4?.goods?.every(
      (good: any) =>
        good?.name && good?.packaging_type && good?.amount && good?.price,
    );
  };

  return (
    <StepNavigationWrapper>
      {[1, 2, 3, 4].map(step => {
        const targetStepKey = `step${step}` as keyof FormData;
        const isActive = currentStep === step && isDesktop;
        return (
          <StepWrapper
            key={step}
            onClick={() => goToStep(step)}
            active={isActive.toString()}
          >
            <StepHeader active={isActive.toString()}>
              <p>{step}</p>
              {isDesktopBig || isTablet || isMobile ? (
                <h4>{OrderFormStepsDesktop[step - 1]}</h4>
              ) : (
                <h4>{OrderFormStepsTablet[step - 1]}</h4>
              )}
              {((step !== currentStep &&
                formData[targetStepKey].isValid &&
                !formData[targetStepKey].hasError &&
                isDesktop) ||
                (formData[targetStepKey].isValid &&
                  !formData[targetStepKey].hasError)) && (
                <CheckIcon className={'completed-icon'} />
                // <CheckmarkIcon className={'completed-icon'} src={Checkmark} alt='completed-icon' />
              )}
              {((formData[targetStepKey].hasError && (isMobile || isTablet)) ||
                (step !== currentStep &&
                  isDesktop &&
                  formData[targetStepKey].hasError)) && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <AlertCircle />
                </div>
              )}
            </StepHeader>
            {((
              step !== currentStep &&
              isDesktop) ||
              (StepperDetailsConfig[step - 1].title &&
                formData[targetStepKey].isTouched &&
                (isMobile || isTablet))) && (
              // Update with formData values
              <StepDetailsInfo>
                <div>
                  <h5>{StepperDetailsConfig[step - 1].title}</h5>
                  <p>{StepperDetailsConfig[step - 1].subtitle}</p>
                </div>
              </StepDetailsInfo>
            )}
            {(isTablet || isMobile) &&
              !StepperDetailsConfig[step - 1].title && (
                <StepDetailsTabletLabel>
                  {OrderFormStepDetails[step - 1]}
                </StepDetailsTabletLabel>
              )}
          </StepWrapper>
        );
      })}
      <NavigationButtonWrapper>
        {/*disabled={errorFields?.length > 0}*/}
        <Button
          className={checkRequiredFields() ? '' : 'submit-form-btn'}
          text="Розрахувати тариф"
          type="primary"
          disabled={disableSubmit}
          onClick={() => {
            prepareFormData();
          }}
        />
      </NavigationButtonWrapper>
    </StepNavigationWrapper>
  );
};

export default StepNavigation;

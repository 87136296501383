import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" {...props}>
    <circle cx={12} cy={12} r={12} fill="#fff" />
    <path fill="#5F5E8E" d="M12.332 18a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z" />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m9.633 12 1.8 1.8 3.6-3.6"
    />
  </svg>
);
export default SvgComponent;

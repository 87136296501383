import DocumentSvg from '../../../assets/ReactSvg/DocumentSvg';
import {
  DownloadButton,
  GreenButton,
  PrimaryButton,
  RedButton,
  SecondaryButton,
} from '../../styles';

type ButtonType = {
  text: string | React.ReactNode;
  type: 'primary' | 'secondary' | 'submit' | 'color' | 'download' | 'red';
  onClick: () => void;
  isRequestSend?: boolean;
  smallPadding?: boolean;
  disabled?: boolean;
  className?: string;
  style?: any;
};

const Button = ({
  text,
  type,
  onClick,
  isRequestSend,
  smallPadding,
  disabled,
  className,
  style,
}: ButtonType) => {
  return (
    <>
      {type === 'primary' || type === 'submit' ? (
        <PrimaryButton
          className={className}
          disabled={disabled}
          onClick={onClick}
        >
          {text}
        </PrimaryButton>
      ) : type === 'secondary' ? (
        <SecondaryButton
          disabled={disabled}
          onClick={onClick}
          request={isRequestSend}
        >
          {text}
        </SecondaryButton>
      ) : type === 'color' ? (
        <GreenButton
          disabled={disabled}
          smallPadding
          onClick={onClick}
          style={style || {}}
        >
          {text}
        </GreenButton>
      ) : type === 'red' ? (
        <RedButton
          disabled={disabled}
          smallPadding
          onClick={onClick}
          style={style || {}}
        >
          {text}
        </RedButton>
      ) : type === 'download' ? (
        <DownloadButton>
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              // @ts-ignore
              onClick();
            }}
          >
            {text}
            <div>
              <DocumentSvg />
            </div>
          </a>
        </DownloadButton>
      ) : (
        <></>
      )}
    </>
  );
};

export default Button;

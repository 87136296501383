import { SVGProps } from 'react';
import { Colors } from '../../components/colors';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width='32'
    height='32'
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M30.2249 26.3063C30.2249 28.3912 28.5347 30.0813 26.4499 30.0813C24.365 30.0813 22.6748 28.3912 22.6748 26.3063C22.6748 24.2214 24.365 22.5312 26.4499 22.5312C28.5347 22.5312 30.2249 24.2214 30.2249 26.3063Z'
      stroke={Colors.white} strokeWidth='2' />
    <path
      d='M15.1248 26.3063C15.1248 28.3912 13.4347 30.0813 11.3498 30.0813C9.26486 30.0813 7.57471 28.3912 7.57471 26.3063C7.57471 24.2214 9.26486 22.5312 11.3498 22.5312C13.4347 22.5312 15.1248 24.2214 15.1248 26.3063Z'
      stroke={Colors.white} strokeWidth='2' />
    <path
      d='M22.675 26.3052H15.125M3.7998 5.91992H18.9C21.0355 5.91992 22.1032 5.91992 22.7667 6.58333C23.4301 7.24675 23.4301 8.3145 23.4301 10.45V23.2851M24.1851 9.69497H26.9052C28.1581 9.69497 28.7844 9.69497 29.3037 9.98897C29.8229 10.283 30.1453 10.8201 30.7897 11.8943L33.3547 16.1692C33.6754 16.7037 33.8358 16.9711 33.9181 17.2679C34.0002 17.5646 34.0002 17.8763 34.0002 18.4999V22.5301C34.0002 23.9414 34.0002 24.647 33.6967 25.1727C33.498 25.517 33.212 25.8029 32.8677 26.0017C32.342 26.3052 31.6364 26.3052 30.2251 26.3052M3.7998 19.5101V22.5301C3.7998 23.9414 3.7998 24.647 4.10326 25.1727C4.30205 25.517 4.58799 25.8029 4.93232 26.0017C5.45793 26.3052 6.16356 26.3052 7.57485 26.3052'
      stroke={Colors.white} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M3.7998 10.4492H12.8599M3.7998 14.9793H9.83988' stroke={Colors.white} strokeWidth='2' strokeLinecap='round'
          strokeLinejoin='round' />
  </svg>

);
export default SvgComponent;

import * as React from 'react';
import { SVGProps } from 'react';
import { Colors } from '../../components/colors';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke={
        props.type === 'resign'
          ? Colors.white
          : props.type === 'alert'
            ? Colors.deepRed
            : Colors.primary
      }
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M14 2.77V6.9c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437c.214.11.494.11 1.054.11h4.13M16 13.5H8m8 4H8m2-8H8m6-7H8.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4 4.78 4 5.62 4 7.3v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311c.642.327 1.482.327 3.162.327h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C20 20.22 20 19.38 20 17.7V8.5l-6-6Z"
    />
  </svg>
);
export default SvgComponent;

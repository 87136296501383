import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from '../FormContext';
import {
  AddressContainer,
  RadioWrapper,
  StyledText,
  FirstMileContent,
  InnerContent,
  ContentContainer,
  DepartureStepContainer,
} from './styles';
import { Content } from '../styles';
import InputBlock from '../../ui/InputBlock';
import Button from '../../ui/Button';
import RadioInput from '../../ui/Radio';
import CustomDropdown from '../../ui/Dropdown/Dropdown';
import { phoneNormalize, phoneValidation } from '../../../utils/validation';
import { Modal } from '@mui/material';
import { useMobile, useTablet } from '../../../constants/breakpoints';
import CloseModalButton from '../../ui/CloseModalBtn';
import AutoCompleteInput from '../../ui/Input/AutoCompleteInput';
import { getSessionStorage, searchByCountry } from '../../../utils/global';
import { InputWrapper, PrimaryLabel } from '../../styles';
import { PhoneInput } from 'react-international-phone';

interface IDepartureStep {
  [key: string]: string | null;
}

interface InputProps {
  text: string;
  placeholder: string;
  name: string;
  value: string;
  id: string;
  isPhoneMode?: boolean;
}

export interface AddressProps {
  value: string;
  label: string;
}

const DepartureStep: React.FC = () => {
  const {
    formData,
    updateFormData,
    validateFormData,
    config,
    showModal,
    updateShowModal,
    resetError,
  } = useFormContext();
  const isMobile = useMobile();
  const isTablet = useTablet();
  const { step2 } = formData;
  const { delivery_method } = step2;
  const [updatedValueAddress, setUpdatedValueAddress] =
    useState<AddressProps | null>(null);

  const handleModalClose = () => {
    updateShowModal(false);
  };
  const localData = getSessionStorage('data');

  const isShowWarning =
    !step2.isValid && (step2.isCompleted || step2?.hasError);

  const validateForm = () => {
    if (formData.step2.contact_person_phone) {
      //   updateFormData('step2', {
      //     contact_person_phone:
      //       (formData.step2.contact_person_phone.length == 19 || (formData.step2.contact_person_phone.length === 13 && !formData.step2.contact_person_phone?.includes('(')))
      //         ? phoneNormalize(formData.step2.contact_person_phone)
      //         : '',
      //   });
      // }
    }

    validateFormData('step2', formData.step2.country_from);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    updateFormData('step2', { [name]: value });
    // if (name === 'contact_person_phone') {
    //   updateFormData('step2', {
    //     [name]:
    //       formData.step2?.contact_person_phone?.length > value.length
    //         ? value
    //         : phoneValidation(value),
    //   });
    // }
    resetError('step2');
  };

  const handleChangePhone = (value: any) => {
    updateFormData('step2', { contact_person_phone: value });
  }


  const handleChangeAddress = (value: any, name: string, coordinates?: any) => {
    const coordinatesNameLat = `${name}_coordinates_lat`;
    const coordinatesNameLng = `${name}_coordinates_lng`;
    updateFormData('step2', {
      [name]: value,
      [coordinatesNameLat]: coordinates?.lat,
      [coordinatesNameLng]: coordinates?.lng,
    });
  };

  const inputProps: { [key: string]: InputProps[] } = {
    pick_up: [
      {
        text: 'Адреса забору',
        name: 'receiving_place',
        id: 'receiving_place',
        value: step2.receiving_place,
        placeholder: 'Вкажіть адресу забору',
      },
      {
        text: 'Дата забору вантажу',
        name: 'shipment_date',
        id: 'pick_up_shipment_date',
        value: step2.shipment_date,
        placeholder: 'Вкажіть дату забору',
      },
      {
        text: 'Контактна особа відправника',
        name: 'contact_person_name',
        id: 'contact_person_name',
        value: step2.contact_person_name,
        placeholder: 'Вкажіть контактну особу',
      },
      {
        text: 'Телефон контактної особи відправника',
        name: 'contact_person_phone',
        id: 'contact_person_phone',
        value: step2.contact_person_phone,
        placeholder: 'Вкажіть телефон контактної особи',
        isPhoneMode: true,
      },
    ],
    supplier: [
      {
        text: 'Дата отримання на складі',
        name: 'shipment_date',
        id: 'supplier_shipment_date',
        value: step2.shipment_date,
        placeholder: 'Вкажіть дату отримання на складі',
      },
    ],
    courier: [
      {
        text: 'Дата отримання на складі',
        name: 'shipment_date',
        id: 'courier_shipment_date',
        value: step2.shipment_date,
        placeholder: 'Вкажіть дату отримання на складі',
      },
      {
        text: 'Трек номер',
        name: 'track_number',
        id: 'track_number',
        value: step2.track_number,
        placeholder: 'Вкажіть трек номер',
      },
      {
        text: "Назва кур'єрської служби",
        name: 'courier_service_name',
        id: 'courier_service_name',
        value: step2.courier_service_name,
        placeholder: "Вкажіть назву кур'єрської служби",
      },
    ],
  };

  const renderContent = useMemo(() => {
    const commonProps = {
      type: 'text',
      onChange: handleChange,
      updateFormData: updateFormData,
      step: 'step2',
    };

    const inputs = inputProps[delivery_method];

    return inputs ? (
      <>
        {inputs?.map(props => {
          return (
            <>
              {props?.name === 'receiving_place' ? (
                <AutoCompleteInput
                  value={step2?.receiving_place}
                  name={'receiving_place'}
                  searchOptions={searchByCountry(step2?.country_from)}
                  placeholder={'Вкажіть адресу забору'}
                  handleChangeAddress={handleChangeAddress}
                  text={'Адреса забору'}
                />
              ) : props?.name === 'contact_person_phone' ? (
                <InputWrapper>
                  <PrimaryLabel htmlFor={'phone'}>
                    Телефон контактної особи відправника
                  </PrimaryLabel>
                  <PhoneInput
                    defaultCountry="ua"
                    value={step2?.contact_person_phone}
                    onChange={(phone) => handleChangePhone(phone)}
                    name={'contact_person_phone'}
                  />
                </InputWrapper>
              ): (
                <InputBlock
                  {...commonProps}
                  {...props}
                  key={props.id}
                  id={props.id}
                />
              )}
            </>
          );
        })}
      </>
    ) : null;
  }, [delivery_method, inputProps]);

  useEffect(() => {
    setUpdatedValueAddress(null);
    let fieldToCheck =
      step2?.delivery_method === 'pick_up'
        ? step2?.receiving_place
        : step2?.address_from_place;

    if (fieldToCheck) {
      config?.sending_countries?.forEach((item: any) => {
        if (fieldToCheck?.includes(item?.label)) {
          setUpdatedValueAddress(item);
        } else if (fieldToCheck?.includes('Сполучені Штати Америки')) {
          setUpdatedValueAddress({
            value: 'US',
            label: 'США',
          });
        } else if (fieldToCheck?.includes('Велика Британія')) {
          setUpdatedValueAddress({
            value: 'GB',
            label: 'Великобританія',
          });
        }
      });
    }
  }, [step2?.address_from_place, step2?.receiving_place]);

  useEffect(() => {
    if (
      step2?.country_from &&
      !step2.isCompleted &&
      step2?.country_from !== localData?.step2?.country_from
    ) {
      if (updatedValueAddress?.value !== step2?.country_from)
        updateFormData('step2', {
          address_from_place: '',
          receiving_place: '',
        });
    }
  }, [step2?.country_from]);

  const content = () => {
    return (
      <ContentContainer>
        <AddressContainer
          className={
            !step2?.from_address_type ||
            step2?.from_address_type === 'df_warehouse' ||
            (step2?.from_address_type === 'port_or_airport' &&
              !step2?.address_from_place)
              ? 'inner-border'
              : ''
          }
        >
          <CustomDropdown
            label="Країна відправлення"
            placeholder="Оберіть країну відправлення"
            options={config?.sending_countries ?? []}
            name="country_from"
            required
            showWarning={isShowWarning}
            onSelect={handleChange}
            valueUpdated={updatedValueAddress}
            defaultValue={
              config?.sending_countries?.find(
                (item: any) => item.value === step2.country_from,
              ) || null
            }
          />
          <CustomDropdown
            type={'address'}
            label="Тип адреси"
            placeholder="Оберіть тип адреси"
            options={config?.sending_types ?? []}
            name="from_address_type"
            onSelect={handleChange}
            defaultValue={
              config?.sending_types?.find(
                (item: any) => item.value === step2.from_address_type,
              ) || null
            }
          />
          {step2?.from_address_type === 'port_or_airport' && (
            <AutoCompleteInput
              value={step2?.address_from_place}
              name={'address_from_place'}
              searchOptions={searchByCountry(step2?.country_from)}
              placeholder={'Адреса'}
              handleChangeAddress={handleChangeAddress}
              text={'Адреса'}
            />
          )}
        </AddressContainer>
        {(step2?.from_address_type === 'factory_or_warehouse' ||
          step2?.from_address_type === 'company_address' ||
          step2?.from_address_type === 'residence_address') && (
          <FirstMileContent>
            <StyledText>Перша Миля</StyledText>
            <RadioWrapper>
              <RadioInput
                id={'pickUp'}
                text={'PickUp'}
                name={'delivery_method'}
                onChange={handleChange}
                value={'pick_up'}
                isActive={delivery_method === 'pick_up'}
              />
              {step2?.from_address_type === 'df_warehouse' && (
                <>
                  <RadioInput
                    id={'supplier'}
                    text={'Доставить постачальник'}
                    name={'delivery_method'}
                    onChange={handleChange}
                    value={'supplier'}
                    isActive={delivery_method === 'supplier'}
                  />
                  <RadioInput
                    id={'courier'}
                    text={'Доставить курʼєрська служба'}
                    name={'delivery_method'}
                    onChange={handleChange}
                    value={'courier'}
                    isActive={delivery_method === 'courier'}
                  />
                </>
              )}
            </RadioWrapper>
            <InnerContent>{renderContent}</InnerContent>
          </FirstMileContent>
        )}
        <Button
          text={'Підтвердити'}
          type={'primary'}
          onClick={() => validateForm()}
        />
      </ContentContainer>
    );
  };

  useEffect(() => {
    updateFormData('step2', { isTouched: true });
  }, []);

  useEffect(() => {
    if (!step2.isCompleted && !step2.isValid) {
      const clearFields = (fields: IDepartureStep) => {
        updateFormData('step2', fields);
      };

      switch (delivery_method) {
        case 'pick_up':
          clearFields({
            shipment_date: '',
            track_number: null,
            courier_service_name: null,
          });
          break;
        case 'supplier':
          clearFields({
            receiving_place: null,
            shipment_date: '',
            contact_person_name: null,
            contact_person_phone: null,
            track_number: null,
            courier_service_name: null,
          });
          break;
        case 'courier':
          clearFields({
            receiving_place: null,
            shipment_date: '',
            contact_person_name: null,
            contact_person_phone: null,
          });
          break;
        default:
          break;
      }
    }
  }, [delivery_method]);

  useEffect(() => {
    if (!step2.isCompleted && !getSessionStorage('data')) {
      updateFormData('step2', {
        delivery_method: '',
        receiving_place: null,
      });
    }
    if (
      step2?.from_address_type === 'factory_or_warehouse' ||
      step2?.from_address_type === 'company_address' ||
      step2?.from_address_type === 'residence_address'
    ) {
      updateFormData('step2', {
        delivery_method: 'pick_up',
      });
    }
  }, [step2?.from_address_type]);

  const isIOS = () => {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  };

  return (
    <DepartureStepContainer>
      {isMobile || isTablet ? (
        <Modal open={showModal} className="departureStep">
          <Content
            className={
              !step2?.delivery_method
                ? 'departureStep-content-visible'
                : 'departureStep-content-scroll'
            }
            id={(isIOS() && step2?.delivery_method) ? 'ios_departure' : ''}
          >
            <CloseModalButton onClose={handleModalClose} />
            {content()}
          </Content>
        </Modal>
      ) : (
        <div className="departureStep">
          <>{content()}</>
        </div>
      )}
    </DepartureStepContainer>
  );
};

export default DepartureStep;

import Input, { InputType } from '../Input';
import { InputWrapper, PrimaryLabel, RequiredIndicator } from '../../styles';
import { forwardRef } from 'react';

type InputBlockType = InputType & {
  text: string;
};

const InputBlock = forwardRef<HTMLInputElement, InputBlockType>(
  (
    {
      id,
      type,
      placeholder,
      value,
      onChange,
      updateFormData,
      errorMessage,
      warningMessage,
      isForgotLabel,
      text,
      disabled,
      required,
      name,
      step,
      isPhoneMode,
      className,
    },
    ref,
  ) => {
    return (
      <InputWrapper>
        <PrimaryLabel htmlFor={id}>
          {text} {required && <RequiredIndicator>*</RequiredIndicator>}
        </PrimaryLabel>
        <Input
          name={name}
          required={required}
          id={id}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          errorMessage={errorMessage}
          warningMessage={warningMessage}
          isForgotLabel={isForgotLabel}
          disabled={disabled}
          ref={ref}
          step={step}
          isPhoneMode={isPhoneMode}
          updateFormData={updateFormData}
          className={className}
        />
      </InputWrapper>
    );
  },
);

export default InputBlock;

import React from 'react';
import CargoTableHeader from './TableHeader';
import CargoTableBody from './TableBody';
import styled from 'styled-components';
import { useFormContext } from '../FormContext';
import CargoTableHeaderFType from './TableHeaderFType';

const TableContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100%;
  overflow-y: hidden;
  @media(max-width: 1900px) {
    overflow-x: auto;
  }

  @media (max-width: 810px) {
    overflow-x: auto;
  }
`;


const CargoTableBase = ({config}: any) => {
  const { formData } = useFormContext();
  return (
    <TableContainer>
      {
        (formData?.step4?.type_of_transportation === 'FTL' || formData?.step4?.type_of_transportation === 'FCL' ) ? (
          <CargoTableHeaderFType configData={config} />
        ) : <CargoTableHeader configData={config} />
      }
      <CargoTableBody />
    </TableContainer>
  )
};

export default CargoTableBase;
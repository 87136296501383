import React, { useContext, useState } from 'react';
import { TitleH2 } from '../../../components/styles';
import {
  LayoutSmall,
  ButtonWrapper,
  AuthWrapper,
} from '../../../components/styles';
import { RegistrationForm } from '../styles';
import InputBlock from '../../../components/ui/InputBlock';
import Button from '../../../components/ui/Button';
import { Link, useNavigate } from 'react-router-dom';
import {
  ForgotPasswordError,
  ForgotPasswordWarning,
  ForgotPasswordUserNotExist,
} from '../../../constants/errors';
import { forgotPasswordClient } from '../../../utils/axios/post';
import { AuthContext } from '../../../index';
import FullLogoPng from '../../../assets/png/FullLogoPng.png';
import { CircularProgress } from '@mui/material';

const ChangePasswordPage = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [errorsAndWarnings, setErrorsAndWarnings] = useState({
    error: '',
    warning: '',
  });
  const [email, setEmail] = useState('');

  const handleValidate = () => {
    if (!email) {
      setErrorsAndWarnings(prevState => ({
        ...prevState,
        warning: ForgotPasswordWarning,
      }));
      return null;
    }
    if (!email.includes('@')) {
      setErrorsAndWarnings(prevState => ({
        ...prevState,
        error: ForgotPasswordError,
      }));
      return null;
    }
    return 'OK';
  };

  const handleChangePassword = async (e: any) => {
    e.preventDefault();
    const isValidationSuccess = handleValidate();
    if (!isValidationSuccess) {
      return;
    } else {
      setIsLoading(true);
      const result = await forgotPasswordClient(email);
      if (result?.success) {
        navigate('/change-password/update-success', { state: { email } });
      } else {
        if (result?.data?.errors?.length) {
          if (result?.data?.errors[0]?.message === 'Wrong email') {
            setErrorsAndWarnings(prevState => ({
              ...prevState,
              error: ForgotPasswordError,
            }));
          }
          if (
            result?.data?.errors[0]?.message ===
            'exists validation failed on email'
          ) {
            setErrorsAndWarnings(prevState => ({
              ...prevState,
              warning: ForgotPasswordUserNotExist,
            }));
          }
        }
        setIsLoading(false);
      }
    }
  };

  return (
    <AuthWrapper>
      <LayoutSmall>
        <img
          style={{
            width: '15.813rem',
            height: '3.5rem',
          }}
          src={FullLogoPng}
          alt={'DiFreight'}
        />
        <TitleH2>Зміна пароля</TitleH2>
        <RegistrationForm onSubmit={handleChangePassword}>
          <InputBlock
            id="email"
            placeholder="Вкажіть вашу електронну скриньку"
            type="text"
            value={email}
            onChange={e => {
              setEmail(e.target.value);
              setErrorsAndWarnings(prevData => ({
                ...prevData,
                error: '',
                warning: '',
              }));
            }}
            text="E-mail"
            warningMessage={errorsAndWarnings.warning}
            errorMessage={errorsAndWarnings.error}
          />
          <ButtonWrapper>
            <Button
              text={
                isLoading ? (
                  <CircularProgress color="inherit" size={'1.5rem'} />
                ) : (
                  'Скинути'
                )
              }
              type="primary"
              onClick={() => {}}
            />
            <Link to={isAuthenticated ? '/profile' : '/login'}>
              <Button text="Скасувати" type="secondary" onClick={() => {}} />
            </Link>
          </ButtonWrapper>
        </RegistrationForm>
      </LayoutSmall>
    </AuthWrapper>
  );
};

export default ChangePasswordPage;

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ?? 36}
    height={props.height ?? 36}
    fill="none"
    viewBox="0 0 36 36"
    {...props}
  >
    <path
      stroke={props.color ?? '#3A3875'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m32.25 26.95-9.964-8.55m-8.572 0L3.75 26.95M3 11.275l12.247 8.144c.992.66 1.488.99 2.027 1.117.477.113.975.113 1.452 0 .539-.127 1.035-.457 2.027-1.117L33 11.275M10.2 29.8h15.6c2.52 0 3.78 0 4.743-.466a4.394 4.394 0 0 0 1.967-1.868c.49-.915.49-2.112.49-4.506v-9.12c0-2.394 0-3.591-.49-4.506a4.394 4.394 0 0 0-1.967-1.868C29.58 7 28.32 7 25.8 7H10.2c-2.52 0-3.78 0-4.743.466A4.394 4.394 0 0 0 3.49 9.334C3 10.25 3 11.446 3 13.84v9.12c0 2.394 0 3.591.49 4.506a4.394 4.394 0 0 0 1.967 1.868c.963.466 2.223.466 4.743.466Z"
    />
  </svg>
);
export default SvgComponent;

import { TRANSPORTATION_STATUSES } from './transportations';

export const checkEmptyFields = <T extends Record<string, string>>(
  obj: T,
): boolean => {
  const emptyFields = Object.values(obj).some(value => value !== '');
  return !emptyFields;
};

export const capitalizeInitials = (str: string) => {
  const words = str.split(' ');
  if (words.length > 1) {
    return (words[0][0] + words[1][0]).toUpperCase();
  } else if (words.length === 1) {
    return words[0][0].toUpperCase();
  } else {
    return '';
  }
};

export const truncatedString = (stringValue: string, length: number = 32) => {
  if (stringValue?.length > length) {
    return stringValue?.slice(0, length) + '...';
  } else {
    return stringValue;
  }
};

export const formatAsMoney = (numberString: string) => {
  return numberString + '.00';
};

export const formatFileSize = (bytes: number) => {
  const kilobytes = bytes / 1024;
  const megabytes = kilobytes / 1024;

  if (megabytes >= 1) {
    return `${megabytes.toFixed(2)} MB`;
  } else {
    return `${kilobytes.toFixed(2)} KB`;
  }
};

export const formatCurrency = (value: number) => {
  return `$ ${(value || 0).toFixed(2)}`;
};

export const footIntoMeters = (foot: number) => {
  return (foot * 0.3048).toFixed(2);
};

export const poundsIntoKilograms = (pounds: number) => {
  return (pounds * 0.454).toFixed(2);
};

export const cubicFootIntoMeters = (foot: number) => {
  return (foot * 0.0283).toFixed(2);
};

export const searchByCountry = (country: string | null | undefined) => {
  if (!country) return undefined;

  return { componentRestrictions: { country } };
};

export const setSessionStorage = (key: string, data: any) => {
  sessionStorage.setItem(key, data);
};

export const getSessionStorage = (key: string) => {
  // @ts-ignore
  return JSON.parse(sessionStorage.getItem(key));
};

export const removeSessionStorage = (key: string) => {
  sessionStorage.removeItem(key);
};

export const formatTransportationStatus = (
  status: keyof typeof TRANSPORTATION_STATUSES,
) => {
  return TRANSPORTATION_STATUSES[status];
};

export const parseToFloatDigits = (
  value: any,
  fractionDigit: number,
  name?: string,
) => {
  // if (name === 'volume' && value > 999.99) {
  //   return 999.99
  // } else if (name === 'weight' && value > 99999.99) {
  //   return 99999.99;
  // }
  return value.toFixed(fractionDigit);
};

export const createUserUid = (entity: string, id: string | number) => {
  return entity + ':' + id;
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const year = String(date.getFullYear()).slice(-2); // Get the last two digits of the year

  return `${day}.${month}.${year}`;
};

import * as React from 'react';
import { SVGProps } from 'react';
import { Colors } from '../../components/colors';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle cx='12' cy='12' r='12' fill={Colors.white} />
    <path
      d='M6 10.1203C6 9.0281 6 8.48199 6.21799 8.06483C6.40973 7.69787 6.71569 7.39953 7.09202 7.21256C7.51984 7 8.0799 7 9.2 7H14.8C15.9201 7 16.4802 7 16.908 7.21256C17.2843 7.39953 17.5903 7.69787 17.782 8.06483C18 8.48199 18 9.0281 18 10.1203V13.6307C18 14.7229 18 15.269 17.782 15.6862C17.5903 16.0531 17.2843 16.3514 16.908 16.5384C16.4802 16.751 15.9201 16.751 14.8 16.751H13.1225C12.7065 16.751 12.4984 16.751 12.2995 16.7908C12.123 16.8261 11.9521 16.8845 11.7917 16.9645C11.6108 17.0546 11.4483 17.1813 11.1235 17.4347L9.53317 18.6753C9.25578 18.8917 9.11708 18.9999 9.00036 19C8.89885 19.0001 8.80282 18.9551 8.73949 18.8777C8.66667 18.7888 8.66667 18.6156 8.66667 18.2692V16.751C8.04669 16.751 7.7367 16.751 7.48236 16.6845C6.79218 16.5042 6.25308 15.9785 6.06815 15.3055C6 15.0575 6 14.7553 6 14.1507V10.1203Z'
      stroke={Colors.primary80} stroke-linecap='round' stroke-linejoin='round' />
  </svg>

);
export default SvgComponent;

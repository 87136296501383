import React, { createContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css';
import reportWebVitals from './reportWebVitals';
import {
  RouterProvider,
} from 'react-router-dom';
import { Container, GlobalWrapper } from './components/styles';
import { router } from './routes';
// @ts-ignore
import Cookies from 'js-cookie';
import { io } from 'socket.io-client';
import toast from 'react-hot-toast';
import { createUserUid } from './utils/global';
import { getThreadsInfo } from './utils/axios/chat';
import { debounce } from 'lodash';
import { getNotificationInfo } from './utils/axios/get';
import { fetchRefreshToken } from './utils/axios/post';

export const AuthContext = createContext<{
  isAuthenticated: boolean;
  setIsAuthenticated: (auth: boolean) => void,
  setToken: (token: string, refreshToken: string, expires?: number) => void,
  socketClient: any,
  profileID?: any,
  updateProfileID: (id: string) => void,
  clearToken: () => void,
  isContractSigned?: boolean,
  updateIsContractSigned?: (value: boolean) => void,
  chatNotificationInfo?: number,
  handleFetchChatNotificationInfo?: () => void,
  notificationInfo?: number,
  handleFetchNotificationInfo?: () => void,
}>({
  isAuthenticated: false,
  setIsAuthenticated: () => {
  },
  setToken: () => {
  },
  clearToken: () => {
  },
  socketClient: null,
  updateProfileID: () => {
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const REACT_APP_CLIENT_URL = process.env.REACT_APP_CLIENT_URL;

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const token = Cookies.get('token');
    return !!token;
  });
  const [socketClient, setSocketClient] = useState(null);
  const [profileID, setProfileID] = useState('');
  const [isContractSigned, setIsContractSigned] = useState(false);
  const [chatNotificationInfo, setChatNotificationInfo] = useState<any>(0);
  const [notificationInfo, setNotificationInfo] = useState<any>(0);

  const setToken = (token: string, refreshToken: string, expires?: number) => {
    Cookies.set('token', token, { secure: true, sameSite: 'Strict', expires });
    Cookies.set('refreshToken', refreshToken, { secure: true, sameSite: 'Strict', expires });
    setIsAuthenticated(true);
  };


  const updateIsContractSigned = (value: boolean) => {
    setIsContractSigned(value);
  };

  const clearToken = () => {
    Cookies.remove('token');
    Cookies.remove('refreshToken');
    setIsAuthenticated(false);
  };

  const widget = document.querySelector('div[data-b24-crm-button-cont]') as HTMLElement;
  if (!isAuthenticated) {
    if (widget) {
      widget.style.display = 'none';
    }
  }

  const handleGetRefreshToken = async (token: any) => {
    const result = await fetchRefreshToken(token);
    if (result?.success) {
      setToken(result?.data?.token, result?.data?.refreshToken);
      window.location.href = `${REACT_APP_CLIENT_URL}/`;
      setIsAuthenticated(true);
      return;
    } else {
      clearToken();
      window.location.href = `${REACT_APP_CLIENT_URL}/login`;
    }
  }
  const debouncedFetchToken = debounce(handleGetRefreshToken, 300);


  React.useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      setIsAuthenticated(true);
      return;
    } else {
      const refreshToken = Cookies.get('refreshToken');
      if (refreshToken) {
        debouncedFetchToken(refreshToken);
        return () => {
          debouncedFetchToken.cancel();
        };
      }
    }

  }, []);

  const handleFetchChatNotificationInfo = async () => {
    const result = await getThreadsInfo();
    if (result?.success) {
      setChatNotificationInfo(result?.unread_count);
    }
  }

  const handleFetchNotificationInfo = async () => {
    const result = await getNotificationInfo();
    if (result?.success) {
      setNotificationInfo(result?.count);
    }
  }

  const debouncedFetchNotificationInfo = debounce(handleFetchNotificationInfo, 300);

  const debouncedFetchChatNotificationInfo = debounce(handleFetchChatNotificationInfo, 300);


  useEffect(() => {
    debouncedFetchChatNotificationInfo();
    debouncedFetchNotificationInfo();
    return () => {
      debouncedFetchChatNotificationInfo.cancel();
      debouncedFetchNotificationInfo.cancel();
    };
  }, [])

  const updateProfileID = (id: string) => {
    setProfileID(id);
  };
  const chatBaseUrl = process.env.REACT_APP_CHAT_URL || '';

  React.useEffect(() => {
    if (isAuthenticated && profileID) {
      const socket = io(
        chatBaseUrl,
        {
          transports: ['websocket'],
          auth: { token: Cookies.get('token') },
          query: { uid: createUserUid('clients', profileID) },
        },
      );

      socket.on('connect', () => {
        console.log('--- connected!!!', socket);
      });
      socket.on('new:message', (data: any) => {
        // console.log('dataMessage', data);

        // queryClient.invalidateQueries(ThreadKeys.threadsInfo());
      });
      socket.on('notification:reload', () => {
        console.log('NEW_NOTIFICATION');
        handleFetchNotificationInfo();
        // queryClient.invalidateQueries(NotificationKeys.notificationCount.invalidate());
        // queryClient.invalidateQueries(NotificationKeys.notifications.invalidate());
      });

      // @ts-ignore
      setSocketClient(socket);

      return () => {
        console.log('--- closed');
        socket.close();
      };
    }
  }, [isAuthenticated, profileID]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        setToken,
        clearToken,
        socketClient,
        profileID,
        updateProfileID,
        isContractSigned,
        updateIsContractSigned,
        chatNotificationInfo,
        handleFetchChatNotificationInfo,
        notificationInfo,
        handleFetchNotificationInfo,
      }}>
      <RouterProvider router={router} />
    </AuthContext.Provider>
  );
};

root.render(
  <React.StrictMode>
    <GlobalWrapper>
      <Container>
        <App />
      </Container>
    </GlobalWrapper>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

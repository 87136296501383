import styled from 'styled-components';
import { Colors } from '../colors';

interface IProps {
  isModal?: boolean;
}

const TableContainer = styled.div<IProps>`
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  @media (max-width: 1900px) {
    overflow-x: ${props => (props.isModal ? 'none' : 'scroll')};
  }

  @media (max-width: 1676px) {
    overflow-x: ${props => (props.isModal ? 'auto' : 'auto')};
    overflow-y: ${props => (props.isModal ? 'clip' : 'auto')};
  }

  @media (max-width: 810px) {
    overflow-x: ${props => (props.isModal ? 'scroll' : 'auto')};
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.625rem 0 0.625rem 0.75rem;

  @media screen and (max-width: 767px) {
    justify-content: flex-start;
    flex-direction: column;
    padding: 0;
    gap: 0.625rem;
    margin-bottom: 0.625rem;
  }
`;
export const TitleButtons = styled.div`
  display: flex;
  gap: 0.625rem;

  max-height: 2.125rem;
  @media (max-width: 767px) {
    max-height: 100%;
  }

  > button:nth-child(1) {
    width: fit-content;
  }
  > button:nth-child(2) {
    width: fit-content;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;

    > button:nth-child(1) {
      min-width: 100%;
    }
    > button:nth-child(2) {
      min-width: 100%;
    }
  }
`;

const TableReceivedGoodsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0 !important;
  background: transparent;

  h5 {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${Colors.primary};
    text-transform: uppercase;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 0.75rem;
      line-height: 1.125rem;
    }
  }

  @media (max-width: 1670px) {
  }
`;

const TableReceivedGoodsHeader = styled.div<IProps>`
  background: ${Colors.primary80};
  border-bottom: 0.5px solid ${Colors.periwinkle};
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  height: ${props => (props.isModal ? '4rem' : '2.813rem')};
  display: grid;
  grid-template-columns: ${props =>
    props.isModal
      ? '50fr 10fr 10fr 11.4fr 11.2fr 13.5fr'
      : '81.6fr 10fr 10fr 11.4fr 11.2fr 13.5fr'};
  padding: 0.75rem;

  @media (min-width: 1440px) and (max-width: 1919px) {
    grid-template-columns: ${props =>
      props.isModal
        ? '34.6fr 10fr 10fr 11.4fr 11.2fr 13.5fr'
        : '81.6fr 10fr 10fr 11.4fr 11.2fr 13.5fr'};
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    min-width: ${props => props.isModal && '47.5rem'};
    grid-template-columns: ${props =>
      props.isModal
        ? '20fr 10fr 10fr 11.4fr 11.2fr 13.5fr'
        : '12fr 10fr 10fr 11.4fr 11.2fr 13.5fr'};
  }
  @media (max-width: 767px) {
    min-width: 47.938rem;
    grid-template-columns: ${props =>
      props.isModal
        ? '20.7fr 10fr 10fr 11.4fr 11.2fr 13.5fr'
        : '20.2fr 10fr 10fr 11.4fr 11.2fr 13.5fr'};
    height: auto;
  }
`;

const TableReceivedGoodsHeaderItem = styled.div`
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: ${Colors.white};

  @media (max-width: 480px) {
    font-size: 0.75rem;
    line-height: 1.125rem;
    padding: 0.75rem;
  }
  &.right {
    text-align: end;
  }
`;

const TableReceivedGoodsBody = styled.div`
  background: ${Colors.white};
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-left: 0.5px solid ${Colors.periwinkle};
  border-right: 0.5px solid ${Colors.periwinkle};
  box-shadow: 0 0 33px 0 #00000017;
  @media (max-width: 1670px) {
  }
`;

const TableReceivedGoodsRow = styled.div<IProps>`
  border-bottom: 0.5px solid ${Colors.periwinkle};
  display: grid;
  height: 3.5rem;

  grid-template-columns: ${props =>
    props.isModal
      ? '50fr 10fr 10fr 11.4fr 11.2fr 13.5fr'
      : '81.6fr 10fr 10fr 11.4fr 11.2fr 13.5fr'};

  @media (min-width: 1440px) and (max-width: 1919px) {
    grid-template-columns: ${props =>
      props.isModal
        ? '34.6fr 10fr 10fr 11.4fr 11.2fr 13.5fr'
        : '81.6fr 10fr 10fr 11.4fr 11.2fr 13.5fr'};
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    min-width: ${props => props.isModal && '47.5rem'};
    grid-template-columns: ${props =>
      props.isModal
        ? '20fr 10fr 10fr 11.4fr 11.2fr 13.5fr'
        : '12fr 10fr 10fr 11.4fr 11.2fr 13.5fr'};
  }

  @media (max-width: 767px) {
    min-width: 42.5rem;
    grid-template-columns: ${props =>
      props.isModal
        ? '20.7fr 10fr 10fr 11.4fr 11.2fr 13.5fr'
        : '20.2fr 10fr 10fr 11.4fr 11.2fr 13.5fr'};
  }
`;

const TableReceivedGoodsRowItem = styled.div`
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: ${Colors.black80};
  display: flex;
  align-items: center;
  gap: 0 0.25rem;
  padding: 0 0.75rem;
  @media (max-width: 480px) {
    font-size: 0.75rem;
    line-height: 1.125rem;
    padding: 1.219rem 0.75rem;
  }

  &.right {
    justify-content: end;
  }

  &.calculated_row {
    > div.units-value {
      width: 100%;
      justify-content: end;
    }
  }

  p {
    padding: 0.25rem 0.375rem;
  }

  div.units-value {
    background: ${Colors.mistyLavender};
    padding: 0.25rem 0.375rem;
    font-size: 0.875rem;
    line-height: 1.313rem;
    color: ${Colors.black60};
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    gap: 0 0.25rem;

    span {
      font-weight: 600;
      color: ${Colors.black80};
    }
  }
`;

export {
  TableReceivedGoodsWrapper,
  TableReceivedGoodsHeader,
  TableReceivedGoodsHeaderItem,
  TableReceivedGoodsBody,
  TableReceivedGoodsRow,
  TableReceivedGoodsRowItem,
  TableContainer,
};

import { useNavigate } from 'react-router-dom';
import Button from '../ui/Button';
import {
  BtnWrapper,
  PageContainer,
  Picture,
  Subtitle,
  Title,
  TitleWrapper,
} from './styles';
import errorPage from '../../assets/png/errorPage.png';
import pageNotFound from '../../assets/png/pageNotFound.png';

interface IErrorPage {
  type: 'error_page' | 'page_not_found';
}

const ErrorPage = ({ type }: IErrorPage) => {
  const navigate = useNavigate();
  const handleNavigateToMainPage = () => {
    navigate('');
  };

  const renderPicture = () => {
    switch (type) {
      case 'page_not_found':
        return <Picture src={pageNotFound} alt="page not found" />;
      case 'error_page':
        return <Picture src={errorPage} alt="error screen" />;
    }
  };
  const renderContent = () => {
    switch (type) {
      case 'page_not_found':
        return (
          <>
            <Title>
              Схоже, цей маршрут заблоковано. Давайте знайдемо кращий шлях!
            </Title>
          </>
        );
      case 'error_page':
        return (
          <>
            <Title>Щось пішло не так при роботі зі сторінкою.</Title>
            <Subtitle>
              Наша команда вже займається вирішенням цієї проблеми.
            </Subtitle>
          </>
        );
    }
  };

  return (
    <PageContainer>
      {renderPicture()}
      <TitleWrapper>{renderContent()}</TitleWrapper>
      <BtnWrapper>
        <Button
          text={'На головну'}
          type={'primary'}
          onClick={handleNavigateToMainPage}
        />
      </BtnWrapper>
    </PageContainer>
  );
};

export default ErrorPage;

import { SVGProps } from 'react';
import { Colors } from '../../components/colors';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width='12'
    height='17'
    viewBox='0 0 12 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M0.666016 8.5H11.3327M11.3327 8.5L7.33268 4.5M11.3327 8.5L7.33268 12.5' stroke={Colors.primary}
          strokeLinecap='round' strokeLinejoin='round' />
  </svg>

);
export default SvgComponent;

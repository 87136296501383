import { CloseBtn } from '../../styles';
import CrossSvg from '../../../assets/ReactSvg/CrossSvg';
import { SvgWrapper } from '../../Details/styles';

type CloseModalButtonProps = {
  onClose: () => void;
  className?: string;
};

const CloseModalButton = ({ onClose, className }: CloseModalButtonProps) => {
  return (
    <CloseBtn className={className}>
      <SvgWrapper>
        <CrossSvg onClick={onClose} style={{ cursor: 'pointer' }} />
      </SvgWrapper>
    </CloseBtn>
  );
};

export default CloseModalButton;

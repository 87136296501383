import axiosInstance from '../index';
import { InternalAxiosRequestConfig } from 'axios';

export const profileInfo = async () => {
  try {
    const response = await axiosInstance.get('/profile');
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const getSignContract = async () => {
  try {
    const response = await axiosInstance.get('/profile/contract');
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const getContractStatus = async () => {
  try {
    const response = await axiosInstance.get('/profile/contract/check');
    return response.data;
  } catch (err) {
    console.error(err);

    return err;
  }
};

export const orderConfig = async () => {
  try {
    axiosInstance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        config.headers['Accept-Language'] = 'ua';
        return config;
      },
      error => {
        return Promise.reject(error);
      },
    );
    const response = await axiosInstance.get('/common_data');
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const warehousesConfig = async () => {
  try {
    axiosInstance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        config.headers['Accept-Language'] = 'ua';
        return config;
      },
      error => {
        return Promise.reject(error);
      },
    );
    const response = await axiosInstance.get('/warehouses_data');
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const DashboardConfig = async ({
  page,
  view,
  search,
  per_page,
}: {
  page: number;
  per_page: number;
  view: string;
  search: string;
}) => {
  try {
    axiosInstance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        config.headers['Accept-Language'] = 'ua';
        return config;
      },
      error => {
        return Promise.reject(error);
      },
    );
    const response = await axiosInstance.get(
      `/transportations/list?per_page=${per_page}&page=${page}&view=${view}&search=${search}`,
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};
export const DetailsConfig = async (id: string) => {
  try {
    axiosInstance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        config.headers['Accept-Language'] = 'ua';
        return config;
      },
      error => {
        return Promise.reject(error);
      },
    );
    const response = await axiosInstance.get(`/transportations/${id}`);
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const getNotificationsList = async () => {
  try {
    const response = await axiosInstance.get('/notifications/list');
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const getNotificationInfo = async () => {
  try {
    const response = await axiosInstance.get('/notifications/info');
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const getSubflightStatuses = async () => {
  try {
    axiosInstance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        config.headers['Accept-Language'] = 'ua';
        return config;
      },
      error => {
        return Promise.reject(error);
      },
    );
    const response = await axiosInstance.get('/subflight_statuses');
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const getCurrencies = async () => {
  try {
    const response = await axiosInstance.get('/currencies');
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const getSupplierInstruction = async (id: string, openPreview?: boolean) => {
  try {
    axiosInstance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        config.headers['Content-Type'] = 'application/octet-stream';
        config.timeout = 60000;
        return config;
      },
      error => {
        return Promise.reject(error);
      },
    );
    const response = await axiosInstance.get(`/transportations/${id}/supplier_instruction`, {
      responseType: 'arraybuffer',  // Ensures the response is handled as a buffer
        headers: {
        'Content-Type': 'application/octet-stream',  // Adjust this to the content type of the file
      }
    })
  .then(response => {
      // Create a Blob from the buffer
      const blob = new Blob([response.data], { type: 'application/pdf' });  // Adjust the MIME type accordingly

      // Create a download link
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      if (openPreview) {
        a.target = '_blank';
      } else {
        a.download = 'supplier_instruction.pdf';  // Set the file name and extension
      }
      document.body.appendChild(a);

      a.click();


      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    })
      .catch(error => {
        console.error('There was an error downloading the file:', error);
      });
    // return response?.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

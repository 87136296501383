//@ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import { addContact } from '../../../assets/images';
import { InputWarningMessage, InputWrapper, PrimaryLabel, StyledImage } from '../../../components/styles';
import { useMobile, useTablet } from '../../../constants/breakpoints';
import Button from '../../../components/ui/Button';
import InputBlock from '../../../components/ui/InputBlock';
import SignAlert from '../../../components/ui/SignAlert';
import UserLogo from '../../../components/UserLogo';
import { debounce } from 'lodash';

import {
  ProfileContent,
  ProfileForm,
  SubmitButtons,
  AddPhoneNumberButton,
  AddPhoneNumberText,
  LogoutButton,
  LoadingCircularWrapper,
} from '../styles';
import { AvatarWarnings, ProfileWarnings } from '../../../constants/errors';
import { useNavigate } from 'react-router-dom';
import { phoneNormalize, phoneValidation } from '../../../utils/validation';
import { profileInfo } from '../../../utils/axios/get';
import {
  logout,
  resetPasswordProfile,
  updateProfileInfo,
} from '../../../utils/axios/post';
import { CircularProgress } from '@mui/material';
import { Avatar } from '../../../constants/types';
import { checkEmptyFields, removeSessionStorage } from '../../../utils/global';
import { AuthContext } from '../../../index';
import AddIcon from '../../../assets/ReactSvg/AddIcon';
import { PhoneInput } from 'react-international-phone';

interface IProfileData {
  email: string;
  full_name: string;
  phones: string[];
  avatar?: Avatar;
  is_contract_signed?: boolean;
  signed_contracts?: boolean;
}
const REACT_APP_URL = process.env.REACT_APP_URL;

const Profile = ({}) => {
  const isMobile = useMobile();
  const isTablet = useTablet();
  const navigate = useNavigate();
  const { clearToken } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordLoading, setPasswordLoading] = useState(false);
  const [isRequestSend, setIsRequestSend] = useState(false);
  const [phoneErrors, setPhoneErrors] = useState<[]>([]);
  const [isAvatarChanged, setIsAvatarChanged] = useState(false);
  const [isUpdateForm, setIsUpdateForm] = useState(false);
  const [isEditProfile, setIsEditProfile] = useState(false);
  const [profileData, setProfileData] = useState<IProfileData>({
    email: '',
    full_name: '',
    phones: [''],
    avatar: null,
  });
  const [isLoadingData, setIsLoadingData] = useState(true);

  const [validationInfo, setValidationInfo] = useState({
    email: '',
    full_name: '',
    phones: '',
    email_error: '',
    avatar: '',
  });

  const check = checkEmptyFields(validationInfo);
  const API_URL = process.env.REACT_APP_URL;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    setProfileData(prevData => ({
      ...prevData,
      [id]: value,
    }));
    setValidationInfo(prevValidation => ({
      ...prevValidation,
      [id]: '',
    }));
  };

  const handleChangePhone = (
    value: any,
    idx: number,
  ) => {
    // const { value } = e.target;
    setPhoneErrors([]);
    setValidationInfo(prev => ({
      ...prev,
      phones: '',
    }));
    setProfileData((prev: IProfileData) => {
      const updatedPhones = [...prev.phones];
      const result =
        updatedPhones[idx]?.length > value?.length
          ? value
          : value;

      return {
        ...prev,
        phones: updatedPhones.map((phone, index) =>
          index === idx ? result : phone,
        ),
      };
    });
  };

  const handleAddPhoneNumber = () => {
    if (isEditProfile) {
      setProfileData(prevData => ({
        ...prevData,
        phones: [...prevData.phones, ''],
      }));
    }
  };

  const validateForm = async () => {
    for (let key in profileData) {
      if (
        profileData.hasOwnProperty(key) &&
        !profileData[key as keyof typeof profileData] &&
        key !== 'is_contract_signed'
      ) {
        setValidationInfo(prevData => ({
          ...prevData,
          // @ts-ignore
          [key]: ProfileWarnings[key as keyof typeof profileData],
        }));
      }
      if (profileData.full_name?.length > 128) {
        setValidationInfo(prevData => ({
          ...prevData,
          full_name: ProfileWarnings.full_name_limit,
        }));
      }
    }
  };

  const handleChangePassword = async () => {
    if (isRequestSend) return;
    setPasswordLoading(true);
    try {
      const result = await resetPasswordProfile();
      if (result.success) setIsRequestSend(true);
    } catch (err) {
      throw err;
    } finally {
      setPasswordLoading(false);
    }
  };

  const handleLogout = async () => {
    const result = await logout();
    if (result.success) {
      clearToken();
      removeSessionStorage('data');
      navigate('/login');
    }
  };

  const phoneErrorsMessage = (idx: number) => {
    const errorField = phoneErrors.find(
      (item: { field: string; message: string }) =>
        item.field === `phones.${idx}`,
    );
    const uniqueError = phoneErrors.find(
      (item: { field: string; message: string }) => item.field === 'phones',
    );
    const requiredField = phoneErrors.find(
      (item: { field: string; message: string }) =>
        item.message.includes('This field is required'),
    );
    if (requiredField) {
      return ProfileWarnings.phones.required;
    }
    if (errorField) {
      return ProfileWarnings.phones.isValid;
    }
    if (uniqueError) {
      return ProfileWarnings.phones.unique;
    }

    return '';
  };
  // const clearNumbers = () => {
  //   const updatedPhones = profileData.phones?.filter(
  //     phone => phone !== '+380' && phone !== '',
  //   );
  //
  //   if (updatedPhones.length === 0) {
  //     updatedPhones.push('');
  //   }
  //
  //   setProfileData(prevData => ({ ...prevData, phones: updatedPhones }));
  // };

  const updateProfile = async () => {
    setIsLoading(true);

    const formData = new FormData();

    if (profileData.avatar && isAvatarChanged) {
      formData.append('avatar', profileData.avatar);
    }
    formData.append('full_name', profileData.full_name);
    formData.append('email', profileData.email);
    profileData.phones.forEach(phone => {
      if (phone.length > 4) {
        formData.append('phones[]', phoneNormalize(phone));
      }
    });

    const result = await updateProfileInfo(formData);

    if (result.response && result.response.status === 422) {
      const errorFields = result.response.data.errors.map(
        (item: { field: string; message: string }) => ({
          field: item.field,
          message: item.message,
        }),
      );

      if (errorFields.some((f: any) => f.field === 'avatar')) {
        setValidationInfo(prevValidation => ({
          ...prevValidation,
          avatar: AvatarWarnings.image_size,
        }));
      }

      setPhoneErrors(errorFields);
    }

    setIsLoading(false);
    if (result.success) {
      setPhoneErrors([]);
      setValidationInfo({
        email: '',
        full_name: '',
        phones: '',
        email_error: '',
        avatar: '',
      });
      debouncedFetch();
      setIsEditProfile(false);
    }
  };

  const handleFormToggler = async () => {
    if (isEditProfile) {
      validateForm();
      // clearNumbers();
      setIsUpdateForm(true);
    }
    setIsEditProfile(true);
  };

  useEffect(() => {
    if (isUpdateForm) {
      setIsUpdateForm(false);
      if (check) {
        updateProfile();
      }
    }
  }, [isUpdateForm]);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setProfileData(prev => ({ ...prev, avatar: file }));
    setValidationInfo(prev => ({
      ...prev,
      avatar: '',
    }));
    setIsAvatarChanged(true);
  };

  const fetchProfileData = async () => {
    const result = await profileInfo();
    if (result.success) {
      // const updatedPhones = result.data.phones.map((phone: string) =>
      //   phoneValidation(phone),
      // );
      setProfileData(result.data);
      // setProfileData(prev => ({ ...prev, phones: updatedPhones }));
    }
    setIsLoadingData(false);
  };
  const debouncedFetch = debounce(fetchProfileData, 300);

  useEffect(() => {
    debouncedFetch();
    return () => {
      debouncedFetch.cancel();
    };
  }, []);

  const downloadFile = (url: any) => {
    const link = document.createElement('a');
    link.href = REACT_APP_URL + url;
    link.target = '_blank';
    link.download = 'DiFreight_contract.pdf'; // Specify the desired file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {isLoadingData ? (
        <LoadingCircularWrapper>
          <CircularProgress color="inherit" size={'2.5rem'} />
        </LoadingCircularWrapper>
      ) : (
        <>
          <ProfileContent
            width={isMobile ? '19.75rem' : isTablet ? '504px' : '432px'}
          >
            <UserLogo
              width="8rem"
              height="8rem"
              avatar={profileData.avatar}
              isEditProfile={isEditProfile}
              onChange={handleFileChange}
              warningMessage={validationInfo.avatar}
            />
            {profileData?.is_contract_signed ? (
              <a
                href={
                  REACT_APP_URL +
                  profileData?.signed_contracts?.[
                    profileData?.signed_contracts?.length - 1
                  ]?.file
                }
                download
                target={'_blank'}
              >
                {!isEditProfile && <SignAlert type="active_contract" />}
              </a>
            ) : (!profileData?.is_contract_signed && profileData?.signed_contracts?.length > 0) ? (
              <>{!isEditProfile && <SignAlert type="resign" />}</>
            ) : <>{!isEditProfile && <SignAlert type="sign" />}</>}
            <ProfileForm>
              <InputBlock
                id={'full_name'}
                placeholder={'ПІБ користувача'}
                type={'text'}
                onChange={handleChange}
                value={profileData.full_name}
                text={'ПІБ користувача'}
                warningMessage={validationInfo.full_name}
                disabled={!isEditProfile}
              />
              <InputBlock
                id={'email'}
                placeholder={'E-mail'}
                type={'text'}
                onChange={handleChange}
                value={profileData?.email}
                text={'E-mail'}
                warningMessage={validationInfo.email}
                errorMessage={validationInfo.email_error}
                disabled
              />
              {profileData.phones?.map((phone: string, idx: number) => {
                return (
                  <>
                    <InputWrapper>
                      <PrimaryLabel htmlFor={'phone'}>
                        Телефон {idx + 1}
                      </PrimaryLabel>
                      <PhoneInput
                        value={phone}
                        onChange={(phone) => {
                          handleChangePhone(phone, idx);
                        }}
                        disabled={!isEditProfile}
                      />
                    </InputWrapper>
                    {phoneErrorsMessage(idx) && (
                      <InputWarningMessage>{phoneErrorsMessage(idx)}</InputWarningMessage>
                    )}
                  </>
                  // <InputBlock
                  //   key={idx}
                  //   id={'phones'}
                  //   placeholder={'+ 38 (093) 000 00 00'}
                  //   type={'text'}
                  //   onChange={e => handleChangePhone(e, idx)}
                  //   value={phone}
                  //   text={`Телефон ${idx + 1}`}
                  //   disabled={!isEditProfile}
                  //   warningMessage={phoneErrorsMessage(idx)}
                  // />
                );
              })}
              {isEditProfile && (
                <AddPhoneNumberButton onClick={handleAddPhoneNumber}>
                  <AddIcon />
                  {/* <StyledImage
                    src={addContact}
                    alt="add contanct"
                    width={'1.5rem'}
                    height={'1.5rem'}
                    
                  /> */}
                  <AddPhoneNumberText>
                    Додати ще один номер телефону
                  </AddPhoneNumberText>
                </AddPhoneNumberButton>
              )}
            </ProfileForm>

            <SubmitButtons>
              <Button
                text={
                  isLoading ? (
                    <CircularProgress color="inherit" size={'1.5em'} />
                  ) : isEditProfile ? (
                    'Зберегти зміни'
                  ) : (
                    'Редагувати дані'
                  )
                }
                type={'primary'}
                onClick={handleFormToggler}
              />
              <Button
                text={
                  isPasswordLoading ? (
                    <CircularProgress color="inherit" size={'1.5em'} />
                  ) : isRequestSend ? (
                    'Інструкції відправлено на пошту'
                  ) : (
                    'Змінити пароль'
                  )
                }
                type={'secondary'}
                onClick={handleChangePassword}
                isRequestSend={isRequestSend}
              />
              <LogoutButton
                onClick={handleLogout}
                style={{
                  padding: isMobile
                    ? '1rem'
                    : isTablet
                      ? '1rem 78px'
                      : '1rem 2.625rem',
                }}
              >
                Вийти з облікового запису
              </LogoutButton>
            </SubmitButtons>
          </ProfileContent>
        </>
      )}
    </>
  );
};

export default Profile;

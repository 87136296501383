import styled from 'styled-components';
import { Colors } from '../components/colors';

interface IMenu {
  open: boolean;
}

export const OutletWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 6rem;
  @media (max-width: 1024px) {
    transform: none;
    padding-top: 6rem;
  }
`;

export const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 6rem;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${Colors.periwinkle};
  background-color: ${Colors.white};
`;

export const ContainmentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 120rem;
  padding: 1.5rem 2.25rem;

  @media (max-width: 1024px) {
    padding: 1.5rem 2.75rem;
  }

  @media (max-width: 767px) {
    padding: 1rem 1.875rem;
  }
`;

export const TransportationButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2.5rem;

  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.125rem;

  span,
  a {
    cursor: pointer;
    color: ${Colors.primary};
  }
`;

export const MyTransportationBtn = styled.div`
  color: ${Colors.primary};
`;

export const NewTransportationBtn = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  background: ${Colors.primary80};
  padding: 0.938rem 0.75rem;
  gap: 0.5rem;
  border-radius: 0.25rem;
  height: 3rem;

  span {
    cursor: pointer;
    color: ${Colors.white};
  }
  &:hover {
    background: ${Colors.primary60};
  }
`;

export const AvatarWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 2.5rem;

  div {
    display: flex;
    gap: 1.5rem;
  }

  @media (min-width: 1024px) {
    margin-left: 4.313rem;
  }
`;

export const NotificationLogo = styled.img`
  cursor: pointer;
  width: 2.25rem;
  height: 2.25rem;
`;

export const AddNewTransportation = styled.img`
  width: 2rem;
  height: 2rem;
`;

export const LogoSmall = styled.img`
  width: 3rem;
  height: 3rem;
  cursor: pointer;
`;

export const Burger = styled.img`
  position: relative;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
`;

export const Menu = styled.nav<IMenu>`
  display: ${props => (props.open ? 'flex' : 'none')};
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 6rem;
  right: 0;
  background-color: ${Colors.white};
  padding: 1.875rem;
  gap: 0.625rem;
  box-shadow: 0 0 33px 0 #00000017;

  a,
  p {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.125rem;
    cursor: pointer;
    color: ${Colors.primary};
    position: relative;
  }
`;

export const NotificationInfoRound = styled.span`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: -0.25rem;
  background: ${Colors.terra_cotta};
  color: ${Colors.white};
  font-size: 0.875rem;
  line-height: 1.313rem;
  cursor: pointer;
  &.chat-details {
    position: relative;
    top: auto;
    right: auto;
    left: auto;
    font-weight: 400;
    border: 0.5px solid ${Colors.white};
  }
  @media (max-width: 767px) {
    right: auto;
    left: 1rem;
  }
`;

export const SvgIconWrapper = styled.div`
  cursor: pointer;
  > svg:hover {
    > path {
      stroke: ${Colors.primary60};
    }
  }
`;

export const NotificationWrapperData = styled.div`
  position: relative;
  cursor: pointer;

  span {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: -0.25rem;
    background: ${Colors.terra_cotta};
    color: ${Colors.white};
    font-size: 0.875rem;
    line-height: 1.313rem;
  }
  &.threads {
    span {
      right: -0.5rem;
    }
  }

  > svg:hover {
    > path {
      stroke: ${Colors.primary60};
    }
  }
`;

export const BackGroupShadow = styled.div`
  width: 100%;
  height: 200vh;
  overflow-y: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background: #000;
  opacity: 0.7;
  &.details {
    z-index: 1001;
  }
`;

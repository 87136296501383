import {
  DashboardGridsWrapper,
  DashboardGridItem,
  GridTitleWrapper,
  GridHeaderDetails,
  GridItemDetailsItem,
  ActionRequiredButton,
  BtnContainer,
  GridItemTabletContainer,
  GridPriceOfferTitle,
  GridPriceOffersWrapper,
} from './styles';
import Bus from '../../assets/ReactSvg/Bus';
import Plane from '../../assets/ReactSvg/Plane';
import Ship from '../../assets/ReactSvg/Ship';
import SmallArrowRight from '../../assets/ReactSvg/SmallArrowRight';
import dayjs from 'dayjs';
import { TransportationStepLabels } from '../../constants/labels';
import { TransportationModes } from '../../constants/types';
import { Link, useNavigate } from 'react-router-dom';
import { useDesktop, useMobile, useTablet } from '../../constants/breakpoints';
import { formatCurrency, formatTransportationStatus, truncatedString } from '../../utils/global';
import GridPriceOffers from './GridPriceOffers';
import { Subtitle } from '../Details/styles';
import { GridPriceOfferWrapper } from './styles';

const DashboardGrids = ({ data, isShowActive }: any) => {
  const navigate = useNavigate();
  const isDesktop = useDesktop();
  const isMobile = useMobile();
  const isTablet = useTablet();

  const detailsNavigate = (id: number) => {
    navigate(`/details/${id}`);
  };
  const renderGridItem = () => {
    return (
      <>
        {isDesktop && (
          <>
            {data?.map((config: any) => {
              const mode = config.mode_of_transportation as
                | TransportationModes
                | undefined;
              return (
                <DashboardGridItem onClick={() => detailsNavigate(config.id)}>
                  <GridTitleWrapper>
                    <div className='icon-wrapper'>
                      {config.mode_of_transportation === 'land' ? (
                        <Bus />
                      ) : config.mode_of_transportation === 'air' ? (
                        <Plane />
                      ) : config.mode_of_transportation === 'sea' ? (
                        <Ship />
                      ) : (
                        <></>
                      )}
                    </div>
                    <p>{truncatedString(config.title)}</p>
                  </GridTitleWrapper>

                  <GridHeaderDetails>
                    <p>
                      {config.country_from.country_from}
                      <SmallArrowRight />
                      {config.country_to.country_to}
                    </p>
                    <div className={
                      (config?.status === 'tariff_lost' ||
                        config?.status === 'rejected')
                        ? 'status error-status'
                        : (config?.status === 'received' ||
                          config?.status === 'confirmed')
                          ? 'status success-status'
                          : 'status'
                    }>
                      {formatTransportationStatus(config?.status)}
                    </div>
                  </GridHeaderDetails>

                  <GridItemDetailsItem>
                    <div>
                      <span>Доставка</span>
                      <p>
                        {mode ? TransportationStepLabels.type[mode] : ''} -{' '}
                        {config.type_of_transportation}
                      </p>
                    </div>
                  </GridItemDetailsItem>

                  <GridItemDetailsItem>
                    <div>
                      <span>Дата</span>
                      <p>{dayjs(config?.created_at)?.format('DD.MM.YY')}</p>
                    </div>

                    <div>
                      <span>К-ть місць, вага, обʼєм</span>
                      <p>
                        {config?.warehose_goods?.length ? (
                          <>
                            {`${config?.warehouse_total?.total_places}, ${config?.warehouse_total?.total_gross_weight} кг, ${config?.warehouse_total?.total_volume} м3`}
                          </>
                        ) : config?.goods?.length ? (
                            <>
                              {`${config?.goods_total?.total_amount}, ${config?.goods_total?.total_gross_weight} кг, ${config?.goods_total?.total_volume} м3`}
                            </>
                          ) : (
                            <>
                              -
                            </>
                          )
                        }
                      </p>
                    </div>
                  </GridItemDetailsItem>

                  {
                    (config?.invoices?.length || (config?.price_offers?.length > 1 || config?.price_offers?.[0]?.total !== 0)) && (
                      <GridItemDetailsItem
                        className={
                          !config?.need_client_actions
                            ? 'need_client_action-none'
                            : ''
                        }
                      >
                        {config?.invoices?.length ? (
                          <>
                            {!!config?.invoces_total && (
                              <GridPriceOfferWrapper>
                                <GridPriceOfferTitle>Сума</GridPriceOfferTitle>
                                <Subtitle className={'dashboard'}>
                                  ${' '}
                                  <span className='green'>
                                {config?.invoces_total?.total_paid?.toFixed(2)}
                              </span>
                                  /
                                  <span className='red'>
                                {(config?.invoces_total?.total_amount?.toFixed(
                                  2,
                                ) - config?.invoces_total?.total_paid?.toFixed(2)).toFixed(2)}
                              </span>
                                </Subtitle>
                              </GridPriceOfferWrapper>
                            )}
                          </>
                        ) : (
                          <>
                            {(config?.price_offers?.length > 1 || config?.price_offers?.[0]?.total !== 0) && (
                              <GridPriceOffersWrapper>
                                {(!!config?.price_offers?.length) && (
                                  <GridPriceOffers data={config.price_offers} />
                                )}
                                {/*{(!config?.price_offers?.length || config?.status === 'unprocessed') && (*/}
                                {/*  <div>*/}
                                {/*    <span>Цінова</span>*/}
                                {/*    <p style={{ textAlign: 'left' }}>-</p>*/}
                                {/*  </div>*/}
                                {/*)}*/}
                              </GridPriceOffersWrapper>
                            )}
                          </>
                        )}
                      </GridItemDetailsItem>
                    )
                  }

                  {(config?.need_client_actions && isShowActive && config.status !== 'cargo_issues') && (
                    <Link to={`/details/${config?.id}`}>
                      <ActionRequiredButton>
                        Необхідна ваша дія
                      </ActionRequiredButton>
                    </Link>
                  )}
                </DashboardGridItem>
              );
            })}
          </>
        )}
        {isTablet && (
          <>
            {data?.map((config: any) => {
              const mode = config.mode_of_transportation as
                | TransportationModes
                | undefined;
              return (
                <DashboardGridItem onClick={() => detailsNavigate(config.id)}>
                  <GridTitleWrapper>
                    <div>
                      <div className='icon-wrapper'>
                        {config.mode_of_transportation === 'land' ? (
                          <Bus />
                        ) : config.mode_of_transportation === 'air' ? (
                          <Plane />
                        ) : config.mode_of_transportation === 'sea' ? (
                          <Ship />
                        ) : (
                          <></>
                        )}
                      </div>
                      <p>{truncatedString(config.title)}</p>
                    </div>
                    <div
                      className={
                        (config?.status === 'tariff_lost' ||
                          config?.status === 'rejected')
                          ? 'status error-status'
                          : (config?.status === 'received' ||
                            config?.status === 'confirmed')
                            ? 'status success-status'
                            : 'status'
                      }
                    >
                      {formatTransportationStatus(config?.status)}
                    </div>
                  </GridTitleWrapper>

                  <GridHeaderDetails>
                    <p>
                      {config.country_from.country_from}
                      <SmallArrowRight />
                      {config.country_to.country_to}
                    </p>
                    <p>
                      {mode ? TransportationStepLabels.type[mode] : ''} -{' '}
                      {config.type_of_transportation}
                    </p>
                    <p>{dayjs(config?.created_at)?.format('DD.MM.YY')}</p>
                    <p>
                      {config?.warehose_goods?.length ? (
                        <>
                          {`${config?.warehouse_total?.total_places}, ${config?.warehouse_total?.total_gross_weight} кг, ${config?.warehouse_total?.total_volume} м3`}
                        </>
                      ) : config?.goods?.length ? (
                        <>
                          {`${config?.goods_total?.total_amount}, ${config?.goods_total?.total_gross_weight} кг, ${config?.goods_total?.total_volume} м3`}
                        </>
                      ) : (
                        <>
                          -
                        </>
                      )
                      }
                    </p>
                  </GridHeaderDetails>
                  <BtnContainer>
                    <GridItemTabletContainer>
                      {config?.invoices?.length ? (
                        <>
                          {!!config?.invoces_total && (
                            <GridPriceOfferWrapper>
                              <GridPriceOfferTitle>Сума</GridPriceOfferTitle>
                              <Subtitle className={'dashboard'}>
                                ${' '}
                                <span className='green'>
                                  {config?.invoces_total?.total_paid?.toFixed(
                                    2,
                                  )}
                                </span>
                                /
                                <span className='red'>
                                {(config?.invoces_total?.total_amount?.toFixed(
                                  2,
                                ) - config?.invoces_total?.total_paid?.toFixed(2)).toFixed(2)}
                                </span>
                              </Subtitle>
                            </GridPriceOfferWrapper>
                          )}
                        </>
                      ) : (
                        <>
                          {(config?.price_offers?.length > 1 || config?.price_offers?.[0]?.total !== 0) && (
                            <GridPriceOffersWrapper>
                              {(!!config?.price_offers?.length) && (
                                <GridPriceOffers data={config.price_offers} />
                              )}
                              {/*{(!config?.price_offers?.length || config?.status === 'unprocessed') && (*/}
                              {/*  <div>*/}
                              {/*    <span>Цінова</span>*/}
                              {/*    <p style={{ textAlign: 'left' }}>-</p>*/}
                              {/*  </div>*/}
                              {/*)}*/}
                            </GridPriceOffersWrapper>
                          )}
                        </>
                      )}
                      {(config?.need_client_actions && isShowActive && config.status !== 'cargo_issues') && (
                        <Link to={`/details/${config?.id}`}>
                          <ActionRequiredButton>
                            Необхідна ваша дія
                          </ActionRequiredButton>
                        </Link>
                      )}
                    </GridItemTabletContainer>
                  </BtnContainer>
                </DashboardGridItem>
              );
            })}
          </>
        )}
        {isMobile && (
          <>
            {data?.map((config: any) => {
              const mode = config.mode_of_transportation as
                | TransportationModes
                | undefined;
              return (
                <DashboardGridItem onClick={() => detailsNavigate(config.id)}>
                  <GridTitleWrapper>
                    <div className='icon-wrapper'>
                      {config.mode_of_transportation === 'land' ? (
                        <Bus />
                      ) : config.mode_of_transportation === 'air' ? (
                        <Plane />
                      ) : config.mode_of_transportation === 'sea' ? (
                        <Ship />
                      ) : (
                        <></>
                      )}
                    </div>
                    <p>{truncatedString(config.title)}</p>
                  </GridTitleWrapper>

                  <GridHeaderDetails>
                    <div
                      className={
                        (config?.status === 'tariff_lost' ||
                          config?.status === 'rejected')
                          ? 'status error-status'
                          : (config?.status === 'received' ||
                            config?.status === 'confirmed')
                            ? 'status success-status'
                            : 'status'
                      }
                    >
                      {formatTransportationStatus(config?.status)}
                    </div>
                  </GridHeaderDetails>

                  <GridItemDetailsItem>
                    <p>
                      {config.country_from.country_from}
                      <SmallArrowRight />
                      {config.country_to.country_to}
                    </p>
                    <div>
                      <p>
                        {mode ? TransportationStepLabels.type[mode] : ''} -{' '}
                        {config.type_of_transportation}
                      </p>
                    </div>
                    <div>
                      <p>{dayjs(config?.created_at)?.format('DD.MM.YY')}</p>
                    </div>
                  </GridItemDetailsItem>

                  <GridItemDetailsItem>
                    <div>
                      <p>
                        {config?.warehose_goods?.length ? (
                            <>
                              {`${config?.warehouse_total?.total_places}, ${config?.warehouse_total?.total_gross_weight} кг, ${config?.warehouse_total?.total_volume} м3`}
                            </>
                          ) : config?.goods?.length ? (
                            <>
                              {`${config?.goods_total?.total_amount}, ${config?.goods_total?.total_gross_weight} кг, ${config?.goods_total?.total_volume} м3`}
                            </>
                          ) : (
                            <>
                              -
                            </>
                          )
                        }
                      </p>
                    </div>
                  </GridItemDetailsItem>
                  <div>
                    {/*"ready_for_loading"*/}
                    {config?.invoices?.length ? (
                      <>
                        {!!config?.invoces_total && (
                          <GridPriceOfferWrapper>
                            <GridPriceOfferTitle>Сума</GridPriceOfferTitle>
                            <Subtitle className={'dashboard'}>
                              ${' '}
                              <span className='green'>
                                {config?.invoces_total?.total_paid?.toFixed(2)}
                              </span>
                              /
                              <span className='red'>
                                {(config?.invoces_total?.total_amount?.toFixed(
                                  2,
                                ) - config?.invoces_total?.total_paid?.toFixed(2)).toFixed(2)}
                              </span>
                            </Subtitle>
                          </GridPriceOfferWrapper>
                        )}
                      </>
                    ) : (
                      <>
                        {(config?.price_offers?.length > 1 || config?.price_offers?.[0]?.total !== 0) && (
                          <GridPriceOffersWrapper>
                            {(!!config?.price_offers?.length) && (
                              <GridPriceOffers data={config.price_offers} />
                            )}
                            {/*{(!config?.price_offers?.length || config?.status === 'unprocessed') && (*/}
                            {/*  <div>*/}
                            {/*    <span>Цінова</span>*/}
                            {/*    <p style={{ textAlign: 'left' }}>-</p>*/}
                            {/*  </div>*/}
                            {/*)}*/}
                          </GridPriceOffersWrapper>
                        )}
                      </>
                    )}
                  </div>
                  {(config?.need_client_actions && isShowActive && config.status !== 'cargo_issues') && (
                    <Link to={`/details/${config?.id}`}>
                      <ActionRequiredButton>
                        Необхідна ваша дія
                      </ActionRequiredButton>
                    </Link>
                  )}
                </DashboardGridItem>
              );
            })}
          </>
        )}
      </>
    );
  };

  return <DashboardGridsWrapper>{renderGridItem()}</DashboardGridsWrapper>;
};

export default DashboardGrids;

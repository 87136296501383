import { SVGProps } from 'react';
import { Colors } from '../../components/colors';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width='32'
    height='31'
    viewBox='0 0 29 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M12.9655 12.2659L8.55578 7.57646C8.16541 7.15539 8.22792 6.84782 8.6883 6.58153C9.55373 6.08094 10.2279 6.06734 11.1402 6.56796L15.7179 9.08016C16.0438 9.25899 16.3634 9.44027 16.6988 9.53917'
      stroke={Colors.white} strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M15.2988 16.1518L17.2684 22.5044C17.4293 23.0233 17.7124 23.1173 18.1511 22.8657C18.9758 22.3929 19.3082 21.8468 19.3306 20.8628L19.4424 15.9246C19.4573 15.265 19.4546 14.6195 19.9655 14.1328'
      stroke={Colors.white} strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M11.4056 13.6515L13.1667 12.3648L17.2942 9.35552L17.2979 9.35286L17.3049 9.34773C17.4023 9.27642 18.8545 8.2178 19.6781 7.857C20.6904 7.41354 21.6335 7.6197 22.648 7.90306C23.1728 8.04962 23.4352 8.12289 23.6246 8.25961C23.9249 8.47652 24.1202 8.81038 24.1604 9.17553C24.1858 9.40568 24.1188 9.66653 23.9851 10.1882C23.7265 11.1969 23.4359 12.1059 22.5406 12.7493C21.812 13.2728 20.1566 13.9845 20.0456 14.032L20.0377 14.0354L20.0335 14.0372L15.3286 16.0597L13.3187 16.9213C12.5899 17.2337 12.2256 17.3899 11.9777 17.6798C11.3971 18.3586 11.3151 19.5871 11.0984 20.4323C10.9786 20.8994 10.322 21.7088 9.7369 21.5873C9.37567 21.5124 9.36875 21.0602 9.32379 20.7718L8.89095 17.9959C8.78744 17.332 8.7795 17.3184 8.24862 16.898L6.02876 15.1402C5.79819 14.9576 5.40479 14.7256 5.51956 14.3794C5.70547 13.8187 6.74417 13.6623 7.214 13.7935C8.06414 14.0309 9.18333 14.5751 10.0694 14.4184C10.4477 14.3514 10.767 14.1181 11.4056 13.6515Z'
      stroke={Colors.white} strokeLinecap='round' strokeLinejoin='round' />
  </svg>


);
export default SvgComponent;

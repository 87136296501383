import { useMediaQuery } from '@react-hook/media-query';

export const useMobile = () =>
  useMediaQuery('(min-width: 0px) and (max-width: 767px)');
export const useTablet = () =>
  useMediaQuery('(min-width: 768px) and (max-width: 1023px)');
export const useDesktop = () => useMediaQuery('(min-width: 1024px)');
export const useDesktopBig = () => useMediaQuery('(min-width: 1601px)');
export const useMacHeight = () => useMediaQuery('(max-height: 860px)');
export const useDashboardDesktop = () => useMediaQuery('(min-width: 1670px)')

export const useDashboardPerPageLimit = () =>
  useMediaQuery('(min-width: 1671px)');

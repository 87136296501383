//@ts-nocheck
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useChatContext } from './ChatContext';
import dayjs from 'dayjs';
import {
  MessageZone,
  MessageItem,
  ChatTitle,
  SendMessageForm,
  SendMessageTextInput,
  SendMessageButtonIcon,
  UploadFileWrapper,
  EmptyChatState,
  UploadedFileWrapper,
  MentionZone,
  SendMessageDate,
  MessageContentWrapper,
  SmallChatHeader,
  ChatDetailsPage,
  SmallChatHeaderContainerIcon,
  StatusChat,
  SvgWrapper, MessageContentContainer,
} from './styles';
import { NotificationInfoRound } from '../../layout/styles';
import SendIcon from '../../assets/ReactSvg/SendIcon';
import OfficeIcon from '../../assets/ReactSvg/OfficeIcon';
import { Colors } from '../colors';
import {
  getMessages,
  getThreadsList,
  sendMessage,
} from '../../utils/axios/chat';
import NotificationsIcon from '../../assets/ReactSvg/NotificationsIcon';
import DocumentBig from '../../assets/ReactSvg/DocumentBig';
import { formatFileSize, truncatedString } from '../../utils/global';
import { getFileLinkChat } from '../../utils/requestHelpers';
import CrossSvg from '../../assets/ReactSvg/CrossSvg';
import MessageReadIcon from '../../assets/ReactSvg/MessageReadIcon';
import {
  useDesktop,
  useMacHeight,
  useMobile,
  useTablet,
} from '../../constants/breakpoints';
import { CircularProgress, Modal } from '@mui/material';
import { Content } from '../OrderForm/styles';
import CloseModalButton from '../ui/CloseModalBtn';
import { Status, StatusText } from '../OrderTracking/styles';
import ChatIcon from '../../assets/ReactSvg/ChatIcon';
import { AuthContext } from '../../index';
import ExpandSvg from '../../assets/ReactSvg/ShippingIcon/ExpandSvg';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';

interface UploadedFile {
  name: string;
  size: number;
}

enum FileSize {
  '6MB' = 6291456,
}

const CHAT_BASE_URL = `${process.env.REACT_APP_CHAT_URL}/api/v1/download`;

const ChatZone = ({ size, id, type }: any) => {
  const {
    messages,
    currentChat,
    updateCurrentChat,
    currentChatID,
    updateCurrentChatMessages,
    updateThreadsList,
    currentChatUsers,
    threadsList,
    updateCurrentChatID,
    chatID,
  } = useChatContext();
  const {
    socketClient,
    handleFetchChatNotificationInfo,
    chatNotificationInfo,
  } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const navigation = useNavigate();
  const [inputMessage, setInputMessage] = useState('');
  const [uploadedFile, setUploadedFile] = useState<UploadedFile | null>(null);
  const [mentionedUsers, setMentionedUsers] = useState<string[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const scrollRef = useRef(null);
  const [isShowModal, setIsShowModal] = useState(!!currentChatID);
  const [socketMessages, setSocketMessages] = useState<any>([]);
  const [onMouseOn, setMouseOn] = useState(false);

  const contentRef = useRef<HTMLDivElement | null>(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const [scrollableSize, setIsScrollableSize] = useState(false);
  const [error, setError] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false);
  const [notificationAmount, setNotificationAmount] = useState(0);

  const isMobile = useMobile();
  const isDesktop = useDesktop();
  const isTablet = useTablet();
  const isMacHeight = useMacHeight();

  useEffect(() => {
    handleFetchChatNotificationInfo();
  }, []);

  const handleGetThreadsDetails = async () => {
    if (size === 'small' && id) {
      const threadsData = await getThreadsList();
      if (threadsData?.success) {
        const currentChat = threadsData?.threads?.find(
          (thread: any) => thread?.uid === `transportations:${id}`,
        );
        updateCurrentChatID(`transportations:${id}`);
        updateCurrentChat(currentChat?.name);
        setNotificationAmount(
          +currentChat?.messages_count - +currentChat?.messages_seen,
        );
      }
    }
  };
  const debouncedFetch = debounce(handleGetThreadsDetails, 300);

  useEffect(() => {
    debouncedFetch();
    return () => {
      debouncedFetch.cancel();
    };
  }, [size, id, chatNotificationInfo]);

  const useThreadSocket = (params: any) => {
    const { threadUid } = params;

    const newMessageListener = (data: any) => {
      if (data.thread_uid === currentChatID) {
        setSocketMessages((prevState: any) => [...prevState, data?.message]);
        scrollToBottom();
      }
    };

    const newMessageGlobal = () => {
      handleFetchChatNotificationInfo();
    };

    const deleteMessageListener = async () => {
      const newMessages = await getMessages(currentChatID);
      if (newMessages?.success) {
        updateCurrentChatMessages(newMessages?.messages?.data);
      }
    };

    useEffect(() => {
      if (socketClient) {
        socketClient.on('thread_new:message', newMessageListener);
        socketClient.on('new:message', newMessageGlobal);
        socketClient.on('thread_delete:message', deleteMessageListener);
      }

      return () => {
        if (socketClient) {
          socketClient.off('thread_new:message', newMessageListener);
          socketClient.on('thread_delete:message', deleteMessageListener);
        }
      };
    }, [socketClient, threadUid]);
  };

  useThreadSocket({
    threadUid: currentChatID,
  });

  const getConfig = async () => {
    const chatResult = await getMessages(`transportations:${id}`);
    if (chatResult.success) {
      updateCurrentChatMessages(chatResult?.messages?.data);
      updateCurrentChatID(`transportations:${id}`);
    }
  };

  const debouncedFetchMessages = debounce(getConfig, 300);

  useEffect(() => {
    if (id) {
      debouncedFetchMessages();
      return () => {
        debouncedFetchMessages.cancel();
      };
    }
  }, [id]);

  const downloadFile = (message: any) => {
    const link = document.createElement('a');
    link.href = message?.file;
    link.download = message?.file_name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderMessageZone = (arrayOfMessages: any, isSocketArray?: boolean) => {
    let previousCreatedAt = '';
    return arrayOfMessages?.map((message: any, index: string) => {
      const messageDate = dayjs(message?.created_at).format('DD.MM.YYYY');
      const isDifferentTime = previousCreatedAt !== messageDate;
      previousCreatedAt = messageDate;

      return (
        <>
          {isDifferentTime && !isSocketArray && (
            <SendMessageDate>{messageDate}</SendMessageDate>
          )}
          <MessageItem key={index} entity={message?.user_entity}>
            {message?.file && (
              <a
                target={'_blank'}
                href={`${CHAT_BASE_URL}/${message?.file}`}
                download={message?.file_name}
                className="document-wrapper"
              >
                <DocumentBig />
                <div className="document-content">
                  <p>{isMobile ? truncatedString(message?.file_name, 20) : truncatedString(message?.file_name, 32)}</p>
                  <p className={'size'}>{formatFileSize(message?.file_size)}</p>
                </div>
              </a>
            )}
            {message?.content && <>{renderLink(message?.content)}</>}
            <span>
              {dayjs(message?.created_at).format('HH:mm')}
              {message?.user_entity === 'clients' && (
                <MessageReadIcon width={'1rem'} height={'1rem'} />
              )}
            </span>
          </MessageItem>
        </>
      );
    });
  };

  const validateMessage = () => {
    return /[^\s]/.test(inputMessage);
  };

  const renderLink = (str: string) => {
    if (str?.startsWith('https://')) {
      return (
        <a target={'_blank'} href={str}>
          {str}
        </a>
      );
    } else {
      return <p>{str}</p>;
    }
  };

  useEffect(() => {
    setUploadedFile(null);
  }, [currentChatID]);

  const handleSendMessage = async (e: any) => {
    e.preventDefault();
    const payload = {
      uid: currentChatID,
      content: inputMessage,
      mentioned_users: mentionedUsers,
      file: uploadedFile,
    };
    if (uploadedFile && uploadedFile.size > FileSize['6MB']) {
      return setUploadedFile(null);
    }
    if ((!inputMessage || !validateMessage()) && !uploadedFile) {
      setError(true);
      return;
    }
    setLoading(true);
    const result = await sendMessage(currentChatID, payload);

    try {
      setInputMessage('');
      setUploadedFile(null);
      if (result?.success) {
        setSocketMessages([]);
        const newMessages = await getMessages(currentChatID);
        if (newMessages?.success) {
          updateCurrentChatMessages(newMessages?.messages?.data);
        }
        const newThreadsList = await getThreadsList();
        if (newThreadsList?.success) {
          updateThreadsList(newThreadsList?.threads);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onUpdateFile = (event: any) => {
    const file = event.target.files[0];
    event.target.value = '';
    if (file) {
      setUploadedFile(file);
      setError(false);
    }
  };

  const renderUploadedFile = () => {
    let isBigFile = false;
    if (uploadedFile && uploadedFile?.size > FileSize['6MB']) {
      isBigFile = true;
    }
    return (
      <>
        {uploadedFile && (
          <Modal open={uploadedFile}>
            <Content className={'content-uploaded-file'}>
              <UploadedFileWrapper>
                <DocumentBig />
                <div className="document-content">
                  <p>{isMobile ? truncatedString(uploadedFile?.name, 20) : truncatedString(uploadedFile?.name, 32)}</p>
                  <p className={'size'}>{formatFileSize(uploadedFile?.size)}</p>
                  {isBigFile && (
                    <p className={'error'}>Завантажте файл нe більше 6МБ</p>
                  )}
                </div>
                <CrossSvg
                  onClick={() => setUploadedFile(null)}
                  className={'cancel-icon'}
                />
              </UploadedFileWrapper>
              <SendMessageForm
                className={
                  !messages?.length
                    ? 'empty_small_form'
                    : error
                      ? 'has-error'
                      : size === 'small'
                        ? 'small'
                        : ''
                }
                id={'send-file'}
                onSubmit={handleSendMessage}
              >
                <SendMessageTextInput
                  type={'text'}
                  placeholder={'Напишіть повідомлення...'}
                  value={inputMessage}
                  onChange={e => {
                    setInputMessage(e.target.value);
                    setError(false);
                  }}
                />
                <SendMessageButtonIcon type={'submit'}>
                  {loading ? <CircularProgress size={16} /> : <SendIcon />}
                </SendMessageButtonIcon>
              </SendMessageForm>
            </Content>
          </Modal>
        )}
      </>
    );
  };

  const renderMentionZone = () => {
    return (
      <>
        {inputMessage?.charAt(inputMessage.length - 1) === '@' && (
          <MentionZone>
            {currentChatUsers?.map((user: any, index: string) => {
              return (
                <p
                  key={index}
                  onClick={() => {
                    setInputMessage(prevData => prevData + user?.full_name);
                    if (user?.user_uid) {
                      setMentionedUsers(prevData => [
                        ...prevData,
                        user?.user_uid,
                      ]);
                    }
                  }}
                >
                  {user?.full_name}
                </p>
              );
            })}
          </MentionZone>
        )}
      </>
    );
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      scrollToBottom();
    });
  }, [currentChat, messages, isShowModal, currentChatID]);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      setTimeout(() => {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight + 72;
      }, 100);
    }
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      if (currentChatID) {
        if (!isMobile) {
          if (contentRef.current && scrollRef.current) {
            const bigSize = isMacHeight ? 100 : 75;

            const sizeValue = size === 'small' ? scrollRef.current?.scrollHeight - 1 : isMacHeight ? 590 : 745;


            const sizeToRemove = size === 'big' ? bigSize : 1;
            setIsScrollable(contentRef.current.scrollHeight > sizeValue);

            // if (contentRef.current.scrollHeight !== scrollRef.current?.scrollHeight) {
            //   setIsScrollable(contentRef.current.scrollHeight > scrollRef.current?.scrollHeight - 1);
            // }
          }
        } else {
          if (contentRef.current && scrollRef.current) {
            setIsScrollable(contentRef.current.scrollHeight > scrollRef.current?.scrollHeight - 100);
          }
            // const elementContent = document.getElementById('message-content');
          // const elementZone = document.getElementById('message-zone');
          // const elementContentModal = document.getElementById('modal-chat');
          // const elementZoneModal = document.getElementById('modal-chat-zone');
          // if (!elementContent && !elementZone) {
          //   setIsScrollable(elementContentModal?.scrollHeight > elementZoneModal?.scrollHeight);
          // } else {
          //   setIsScrollable(elementContent?.scrollHeight > elementZone?.scrollHeight);
          // }
          // console.log('element', elementContent, elementZone);
          // console.log('element_modal', elementContentModal?.scrollHeight, elementZoneModal?.scrollHeight);


          // if (messages?.length > 8) {
          //   setIsScrollable(true);
          // } else {
          //   setIsScrollable(false);
          // }
        }
      }
    });
  }, [currentChatID, size, contentRef?.current?.scrollHeight, type, messages?.length, scrollRef?.current?.scrollHeight, showChatModal]);

  // useEffect(() => {
  //   const sizeValue =
  //     type === 'modal-chat'
  //       ? 340
  //       : size === 'small'
  //         ? 380
  //         : isMacHeight
  //           ? 575
  //           : 700;
  //   if (messages?.length === 0) {
  //     return;
  //   }
  //   if (contentRef.current) {
  //     setIsScrollableSize(contentRef.current.scrollHeight > sizeValue)
  //   }
  // }, [contentRef?.current, messages])
  const isIOS = () => {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  };

  const renderContent = () => {
    return (
      <MessageZone
        ref={scrollRef}
        className={
          !currentChatID || !messages?.length ? `${size} empty-zone` : `${size}`
        }
        id={type === 'modal-chat' ? 'modal-chat-zone' : 'message-zone'}
        isScrollable={isScrollable}
      >
        {!!currentChatID ? (
          <>
            {(((size === 'small' && isMobile) || size === 'big') && type !== 'modal-chat') && (
              <>
                <ChatTitle onClick={() => {
                  if (size !== 'small') {
                    navigation(`/details/${chatID}`)
                  }
                }}>
                  {currentChat}
                </ChatTitle>
              </>
            )}
            <MessageContentWrapper
              className={isTablet ? `message-content-wrapper_small ${size}` : isIOS() ? `${size} ios` : `${size}`}
              id={type === 'modal-chat' ? 'modal-chat' : 'message-content'}
              isScrollable={isScrollable}
              ref={contentRef}
            >
              {renderMessageZone(messages)}
              {renderMessageZone(socketMessages, true)}
              {renderUploadedFile()}
              {renderMentionZone()}
              <SendMessageForm
                className={
                  !messages?.length
                    ? 'empty_small_form'
                    : error
                      ? 'has-error'
                      : size === 'small'
                        ? 'small'
                        : ''
                }
                onSubmit={handleSendMessage}
              >
                <UploadFileWrapper>
                  <OfficeIcon
                    className={'icon'}
                    width={'1.5rem'}
                    height={'1.5rem'}
                    color={onMouseOn ? Colors.primary60 : Colors.primary80}
                  />
                  <input
                    onMouseEnter={() => setMouseOn(true)}
                    onMouseLeave={() => setMouseOn(false)}
                    type="file"
                    ref={inputRef}
                    onChange={event => onUpdateFile(event)}
                    accept={'*'}
                  />
                </UploadFileWrapper>

                <SendMessageTextInput
                  type={'text'}
                  placeholder={'Напишіть повідомлення...'}
                  value={inputMessage}
                  onChange={e => {
                    setInputMessage(e.target.value);
                    setError(false);
                  }}
                />
                <SendMessageButtonIcon type={'submit'}>
                  {loading ? (
                    <CircularProgress size={16} />
                  ) : (
                    <SvgWrapper
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <SendIcon />
                    </SvgWrapper>
                  )}
                </SendMessageButtonIcon>
              </SendMessageForm>
            </MessageContentWrapper>
          </>
        ) : threadsList?.length > 0 ? (
          <EmptyChatState>
            <p>Щоб розпочати спілкування - оберіть чат.</p>
          </EmptyChatState>
        ) : (
          <EmptyChatState>
            <NotificationsIcon />
            <p>
              Поки що тут порожньо… Повідомлення з’являтимуться по мірі обробки
              заявок на перевезення.
            </p>
          </EmptyChatState>
        )}
      </MessageZone>
    );
  };

  const handleModalClose = () => {
    setIsShowModal(false);
    setShowChatModal(false);
    document.body.style.overflow = '';
    if (size === 'big') {
      updateCurrentChatID('');
    }
  };

  useEffect(() => {
    setIsShowModal(!!currentChatID);
  }, [currentChatID]);

  return (
    <>
      {(isMobile && size === 'big') ||
      (showChatModal && size === 'small' && type !== 'modal-chat') ? (
        <Modal
          open={isShowModal}
          onClose={handleModalClose}
          className={'chat-modal'}
          disableScrollLock
        >
          <Content className={'chat-content'}>
            <CloseModalButton
              onClose={handleModalClose}
              className={'close-modal-icon'}
            />
            {renderContent()}
          </Content>
        </Modal>
      ) : (
        <ChatDetailsPage className={size === 'small' ? 'small-chat' : ''}>
          {size === 'small' && (
            <SmallChatHeader>
              <SmallChatHeaderContainerIcon>
                {(isTablet || isMobile) && type !== 'modal-chat' ? (
                  <>
                    {notificationAmount > 0 && (
                      <NotificationInfoRound className={'chat-details'}>
                        {notificationAmount ?? 0}
                      </NotificationInfoRound>
                    )}
                  </>
                ) : (
                  <ChatIcon />
                )}
              </SmallChatHeaderContainerIcon>
              <StatusChat>
                <StatusText>Чат</StatusText>
                {(isTablet || isMobile) && type !== 'modal-chat' ? (
                  <SvgWrapper>
                    <ExpandSvg
                      width={'1.5rem'}
                      height={'1.5rem'}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        debouncedFetchMessages();
                        setShowChatModal(true);
                        setIsShowModal(true);
                        document.body.style.overflow = 'hidden';
                      }}
                    />
                  </SvgWrapper>
                ) : (
                  <>
                    {notificationAmount > 0 && (
                      <NotificationInfoRound className={'chat-details'}>
                        {notificationAmount ?? 0}
                      </NotificationInfoRound>
                    )}
                  </>
                )}
              </StatusChat>
            </SmallChatHeader>
          )}
          {((size === 'small' && isDesktop) ||
            size !== 'small' ||
            type === 'modal-chat') && <>{renderContent()}</>}
        </ChatDetailsPage>
      )}
    </>
  );
};

export default ChatZone;

import styled from 'styled-components';
import { Colors } from '../colors';

export const DirectionWrapper = styled.div`
  border: 1px solid ${Colors.periwinkle};
  border-radius: 0.25em;

  @media (max-width: 767px) {
    max-height: 100vh;
    overflow-y: scroll;
  }
  &:focus {
    border: 1px solid ${Colors.periwinkle};
    outline: none;
  }
`;
type BlueButtonProps = {
  open: boolean;
};

export const DirectionButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 1.5rem !important;
`;

export const BlueButton = styled.div<BlueButtonProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1em 0.75em;
  border-radius: 0.25em;
  background-color: ${Colors.primary80};
  border-bottom-left-radius: ${props => (props.open ? 0 : '0.25em')};
  border-bottom-right-radius: ${props => (props.open ? 0 : '0.25em')};

  cursor: pointer;

  > div {
    display: flex;
    align-items: center;
    gap: 0.75em;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 1rem 0;
    &.modal {
      flex-direction: row;
      gap: 0.75rem;
    }
  }
`;

export const BlueButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  &:first-child > div {
    display: flex;
    align-items: center;
    gap: 0 0.75rem;
  }
  @media (max-width: 767px) {
    width: 100%;
    justify-content: space-between;
    &:first-child > div {
      display: flex;
      align-items: center;
      gap: 0 0.75rem;
    }
  }
`;

export const Direction = styled.div`
  font-size: 1em;
  font-weight: 700;
  line-height: 1.5em;
  text-transform: uppercase;
  color: ${Colors.white};

  @media (max-width: 767px) {
    font-size: 0.875rem;
  }
`;

export const DetailsContainer = styled.div`
  width: 100%;
  background-color: ${Colors.white};
  box-shadow: 0 0 33px 0 #00000017;
  border-radius: 0.25em;

  > div:last-child {
    border-bottom: none;
  }

  @media (max-width: 767px) {
    padding-bottom: 100px;
  }
`;

export const Section = styled.div`
  padding: 0.75em;
  border-bottom: 0.5px solid ${Colors.periwinkle};
`;

export const DetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.75em;
  background-color: ${Colors.mistyLavender};
  margin-bottom: 1em;

  > span {
    font-size: 1em;
    font-weight: 700;
    line-height: 1.5em;
    text-transform: uppercase;
    color: ${Colors.primary};
  }

  @media ((max-width: 767px)) {
    flex-direction: column;
    gap: 0.75rem;
    > span {
      font-size: 0.875rem;
    }
  }
`;
export const DownloadFile = styled.div`
  display: flex;
  gap: 0.5em;

  font-size: 1em;
  font-weight: 500;
  line-height: 1.5em;
  color: ${Colors.deepRed};

  cursor: pointer;
  position: relative;

  @media ((min-width: 768px) and (max-width: 1199px)) {
    min-width: 12.938em;
    flex-direction: row;
    align-items: center;
  }

  @media ((max-width: 767px)) {
    font-size: 0.875rem;
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2.5em;
  margin-bottom: 0.5em;

  padding: 0 0.75em 0.75em 0.75em;
  font-size: 1em;
  font-weight: 700;
  line-height: 1.5em;
  color: ${Colors.primary};
  text-transform: uppercase;

  @media ((min-width: 1440px) and (max-width: 1919px)) {
    flex-direction: column;
    gap: 0.5em;
  }

  @media ((min-width: 768px) and (max-width: 1199px)) {
    flex-direction: column;
    gap: 0.5em;
    font-size: 0.875em;
  }
  @media (max-width: 767px) {
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 1em;
  }
`;

export const CargoMarking = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625em;

  @media ((min-width: 1440px) and (max-width: 1919px)) {
    width: 100%;
    justify-content: flex-start;
  }
  @media ((min-width: 768px) and (max-width: 1199px)) {
    width: 100%;
    justify-content: flex-start;
  }
  @media (max-width: 767px) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const Badge = styled.div`
  color: ${Colors.white};
  padding: 0.25em 0.5em 0.25em 0.5em;
  background-color: ${Colors.Seafoam_Green};
  border-radius: 0.25em;
  text-transform: uppercase;
  @media (max-width: 767px) {
    padding: 0.844rem 0.5rem;
    width: 100%;
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.313rem;
    font-weight: 700;
  }
`;

export const HeaderTitleInput = styled.input`
  width: 100%;
  font-size: 1em;
  font-weight: 700;
  line-height: 1.5em;
  color: ${Colors.primary60};
  text-transform: uppercase;

  border: none;
  border-bottom: 1px solid ${Colors.periwinkle};
  border-radius: 0;
  background: none;
  padding: 0;
  margin: 0;
  outline: none;

  @media (max-width: 767px) {
    width: 17.5rem;
    font-size: 0.75em;
  }
`;

export const SvgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;

  @media (max-width: 767px) {
    align-items: end;
    justify-content: flex-end;
  }

  cursor: pointer;
  > svg:hover {
    > path {
      stroke: ${Colors.primary60};
    }
  }
`;
export const OrderDetailsWrapper = styled.form`
  width: 51.188rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1em;
  font-weight: 700;
  line-height: 1.5em;
  color: ${Colors.primary};
  text-transform: uppercase;

  gap: 0.75rem;

  > p {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  @media ((min-width: 1440px) and (max-width: 1919px)) {
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
  }

  @media ((min-width: 768px) and (max-width: 1199px)) {
    font-size: 0.875em;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
  }

  @media (max-width: 767px) {
    width: 100%;
    align-items: center;
    justify-content: space-between;
    > p {
      font-size: 0.75em;
      width: 17.5rem;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2.5em;
  padding: 0.5em 0.75em;

  > div:nth-child(1) {
    max-width: 33.313rem;
  }
  > div:nth-child(3) {
    min-width: 17.813rem;
  }

  &.last-mile {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto 1fr;
    @media (max-width: 1670px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media (max-width: 767px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media ((min-width: 1440px) and (max-width: 1919px)) {
    gap: 1.5em;

    > div:nth-child(1) {
      max-width: 18.313rem;
    }
    > div:nth-child(2) {
      min-width: 6.625rem;
    }
    > div:nth-child(3) {
      min-width: 10.625rem;
    }
    > div:nth-child(4) {
      min-width: 10.625rem;
    }
  }

  @media ((min-width: 768px) and (max-width: 1199px)) {
    gap: 1.5em;

    > div:nth-child(1) {
      max-width: 14.188rem;
    }
    > div:nth-child(2) {
      min-width: 4.25rem;
    }
    > div:nth-child(3) {
      min-width: 8.281rem;
    }
    > div:nth-child(4) {
      min-width: 8.281rem;
    }
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: none;
    flex-wrap: wrap;
    gap: 1.5em;
    padding: 0 0.75rem;

    > div:nth-child(1) {
      width: 11.5rem;
    }
    > div:nth-child(2) {
      max-width: 7.438rem;
    }
    > div:nth-child(3) {
      max-width: 11.5rem;
      min-width: 11.5rem;
    }
    > div:nth-child(4) {
      max-width: 7.438rem;
    }
  }
`;

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 33.313em;

  @media (max-width: 767px) {
    gap: 0.25rem;
  }
`;

export const Title = styled.div`
  font-size: 0.875em;
  font-weight: 600;
  line-height: 1.313em;
  color: ${Colors.primary};

  @media (max-width: 767px) {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
`;

export const Subtitle = styled.div`
  font-size: 0.875em;
  font-weight: 400;
  line-height: 1.313em;
  color: ${Colors.black80};
  display: flex;
  gap: 0 0.25rem;
  word-break: break-all;

  &.dashboard {
    align-items: center;
    padding-top: 6px;
    font-size: 1rem;
    line-height: 1.5rem;
    word-break: normal;
    word-wrap: normal;
    .green {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
    }
    .red {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  .status_bill {
    background-color: ${Colors.dodger_blue};
    padding: 4px 8px;
    color: ${Colors.white};
    border-radius: 4px;
  }

  .green {
    color: ${Colors.Seafoam_Green};
    font-weight: 700;
  }
  .red {
    color: ${Colors.deepRed};
    font-weight: 700;
  }
  @media (max-width: 767px) {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
`;
interface IPriceOffersContainer {
  core: boolean;
}
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75em;
`;

export const BillTotal = styled.div`
  display: flex;
  gap: 1.25rem;
  margin-top: 1rem;
  min-height: 1.875rem;
  align-items: flex-end;
  justify-content: start;
  @media (max-width: 767px) {
    border-top: 1px solid ${Colors.periwinkle};
    justify-content: space-between;
  }
`;
export const TitleContainerPriceOffer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: start;
    margin-bottom: 0.5rem;
  }
`;
export const PaymentMethodContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const PriceOffersContainer = styled.div<IPriceOffersContainer>`
  margin-top: ${props => (props.core ? '1.5rem' : 0)};
  margin-bottom: ${props => (props.core ? '2.5rem' : 0)};
  position: relative;

  @media ((min-width: 768px) and (max-width: 1199px)) {
    margin-top: ${props => (props.core ? '0.75rem' : 0)};
    margin-bottom: ${props => (props.core ? '1.5rm' : 0)};
  }
`;

export const PaymentMethod = styled.div`
  display: flex;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  background-color: ${Colors.periwinkle};
  align-items: center;
  max-height: 32px;
  > p {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
  }

  @media screen and (max-width: 767px) {
    max-height: 1.25rem;

    > p {
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1rem;
    }
  }
`;

export const PaymentMethodBtn = styled.div`
  min-width: 18.375rem;

  max-height: 2.125rem;
  position: absolute;
  top: 0;
  right: 0;

  @media ((min-width: 1200px) and (max-width: 1440px)) {
    top: -5px;
  }

  @media ((min-width: 768px) and (max-width: 1199px)) {
    top: 0;
  }
  @media (max-width: 767px) {
    width: 100%;
    position: relative;
    margin-bottom: 0.5rem;
  }
`;
export const Label = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 1em;
  font-weight: 700;
  line-height: 1.5em;
  color: ${Colors.primary};
  padding: 0.625em 0.75em;

  text-transform: uppercase;

  @media ((min-width: 768px) and (max-width: 1199px)) {
    font-size: 0.875em;
  }

  @media (max-width: 767px) {
    font-size: 0.75rem;
    padding: 0;
    padding-bottom: 0.25rem;
  }
`;
export const InnerLabel = styled.div`
  display: flex;
  gap: 1em;
  align-items: center;
  margin-right: -0.75em;
`;

export const DocumentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  @media (max-width: 767px) {
    gap: 0.75rem;
  }
`;

export const DocumentContainer = styled.div<IModal>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1em 0.75em;
  gap: 1.5em;
  border-radius: 0.25em;
  background-color: ${Colors.white};

  .cost {
    min-width: 7rem;
  }

  @media (min-width: 1920px) {
    height: 5rem;
    > div:first-child {
      min-width: ${props => (props.modal ? '493px' : '50.563rem')};
    }
    > div:nth-child(2) {
      width: 7.438rem;
      min-width: 119px;
    }
    > div:nth-child(3) {
      width: 7rem;
      min-width: 112px;
    }
  }

  @media ((min-width: 1440px)and (max-width: 1919px)) {
    > div:first-child {
      min-width: ${props => (props.modal ? '28rem' : '23.188rem')};
    }
    > div:nth-child(2) {
      width: 5.438rem;
      min-width: 5.438rem;
    }
    > div:nth-child(3) {
      width: 7rem;
      min-width: 7rem;
    }
  }

  @media ((min-width: 768px) and (max-width: 1199px)) {
    > div:first-child {
      width: 11.5em;
    }
  }

  @media (max-width: 767px) {
    padding: 0.75rem;
    flex-wrap: wrap;
    gap: 1.25rem;
    > div:first-child {
      min-width: 4.688rem !important;
      width: 4.688rem;
    }
  }
`;

export const BillContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1em 0.75em;
  gap: 1.5em;
  border-radius: 0.25em;
  background-color: ${Colors.white};
  height: 5rem;

  @media (min-width: 1440px) {
    > div:first-child {
      width: 15rem;
    }
    > div:nth-child(2) {
      width: 55.188rem;
    }
  }

  @media ((min-width: 768px) and (max-width: 1199px)) {
    > div:nth-child(1) {
      width: 7rem;
    }
    > div:nth-child(2) {
      width: 9.563rem;
    }
    > div:nth-child(3) {
      width: 13.25rem;
    }
  }

  @media (max-width: 767px) {
    padding: 12px;
    flex-wrap: wrap;
    gap: 20px;
    > div:first-child {
      width: 16rem;
    }

    .bills {
      width: 20px;
    }
  }
`;

export const MobileWrapperModal = styled.div`
  @media(max-width: 767px) {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  &:focus {
    border: none;
    outline: none;
  }
`;

type DocumentsFileProps = {
  open: boolean;
};
export const DocumentsFile = styled.div<DocumentsFileProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1em 0.75em;
  border-radius: 0.25em;
  background-color: ${Colors.white};

  cursor: pointer;

  border-bottom-left-radius: ${props => (props.open ? 0 : '.25em')};
  border-bottom-right-radius: ${props => (props.open ? 0 : '.25em')};

  > div:first-child {
    width: 22rem;
  }

  @media ((min-width: 768px) and (max-width: 1199px)) {
    > div:first-child {
      width: auto;
    }
  }

  @media (max-width: 767px) {
    padding: 0.75rem;

    > div:first-child {
      width: 13.75rem;
    }
  }
`;

export const DocumentDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375em;

  @media screen and (max-width: 767px) {
    min-width: 10rem;
  }
`;

export const RightSideWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  flex-direction: row;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  min-width: 12em;

  @media (max-width: 767px) {
    width: 100%;
  }
`;
export const DownloadButtonWrapper = styled.div`
  max-width: 8.313rem;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const Wrapper = styled.div<IModal>`
  border-radius: 0.25em;
  border: 1px solid ${Colors.periwinkle};
  cursor: pointer;

  width: ${props => (props.modal ? '66.25rem' : '100%')};

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const ExpandedDocument = styled.div`
  @media (max-width: 767px) {
    width: 100%;
    overflow-x: auto;
  }
`;

export const DocumentTitle = styled.div<IModal>`
  display: flex;
  justify-content: space-between;

  border-bottom: 0.5px solid ${Colors.periwinkle};
  font-size: 0.875rem;
  font-weight: 700;
  color: ${Colors.white};
  background-color: ${Colors.primary80};

  > div {
    padding: 0.75em;
  }

  @media (min-width: 1440px) {
    > div {
      text-align: end;
    }
    > div:nth-child(1) {
      width: ${props => (props.modal ? '200px' : '41.688rem')};
      text-align: start;
    }
    > div:nth-child(2) {
      width: 14.813rem;
    }
    > div:nth-child(3) {
      width: 14.75rem;
    }
    > div:nth-child(4) {
      text-align: end;
      width: 14.75rem;
    }
  }

  @media ((min-width: 768px) and (max-width: 1199px)) {
    > div:nth-child(1) {
      min-width: ${props => (props.modal ? '14.063rem' : '17.063rem')};
      max-width: ${props => (props.modal ? '14.063rem' : '17.063rem')};
    }
    > div:nth-child(2) {
      text-align: end;
      min-width: 9.313em;
      max-width: 9.313em;
    }
    > div:nth-child(3) {
      text-align: end;
      min-width: 6.813em;
      max-width: 6.813em;
    }
    > div:nth-child(4) {
      min-width: 9.313em;
      max-width: 9.313em;
      text-align: end;
    }
  }

  @media (max-width: 767px) {
    font-size: 0.75rem;
    //overflow-x: scroll;
    justify-content: space-between;
    min-width: 28.75rem;

    > div:nth-child(1) {
      min-width: 10.625rem;
      max-width: 10.625rem;
      position: sticky;
      left: 0;
      background: ${Colors.primary80};
    }
    > div:nth-child(2) {
      min-width: 5.938rem;
      max-width: 5.938rem;
    }
    > div:nth-child(3) {
      min-width: 4.25rem;
      max-width: 4.25rem;
      text-align: end;
    }
    > div:nth-child(4) {
      min-width: 6.313rem;
      max-width: 6.313rem;
      text-align: end;
    }
  }
`;

export const TitleText = styled.div``;
export const ListContainer = styled.div`
  background-color: ${Colors.white};
  border-radius: 0.25em;
  border: 1px solid ${Colors.periwinkle};

  > div:first-child {
    border-top-right-radius: 0.25em;
    border-top-left-radius: 0.25em;
  }
`;
export const TariffList = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${Colors.white};
  border-bottom: 0.5px solid ${Colors.periwinkle};

  > div:nth-child(1) {
    color: ${Colors.primary};
    min-width: 50%;
  }
  > div:nth-child(2) {
    text-align: right;
    min-width: 50%;
  }

  @media ((min-width: 1440px) and (max-width: 1919px)) {
    > div:nth-child(1) {
      min-width: 19.438rem;
    }
    > div:nth-child(2) {
      width: 100%;
    }
  }

  @media ((min-width: 768px) and (max-width: 1199px)) {
    > div:nth-child(1) {
      min-width: 18.813rem;
    }
    > div:nth-child(2) {
      min-width: 23.688rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;

    > div:nth-child(1) {
      font-weight: 600;
      min-width: 50%;
    }
    > div:nth-child(2) {
      text-align: left;
      min-width: 50%;
    }
  }
`;
export const TariffItem = styled.div`
  padding: 1.063em 0.75em;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.313rem;
  color: ${Colors.black80};

  @media (max-width: 767px) {
    font-size: 0.75rem;
    padding: 0.25rem 0.75rem;
  }
`;
export const ServiceList = styled.div<IModal>`
  display: flex;
  justify-content: space-between;

  background-color: ${Colors.white};
  border-bottom: 0.5px solid ${Colors.periwinkle};

  > div {
    padding: 0.375rem 0.75rem;
  }

  @media (min-width: 1440px) {
    > div:nth-child(1) {
      width: ${props => (props.modal ? '200px' : '41.688rem')};
      text-align: start;
    }
    > div:nth-child(2) {
      width: 14.813rem;
      text-align: end;
    }
    > div:nth-child(3) {
      width: 14.75rem;
      text-align: end;
    }
    > div:nth-child(4) {
      text-align: end;
      width: 14.75rem;
    }
  }

  @media ((min-width: 768px) and (max-width: 1199px)) {
    > div {
      padding: 0.406rem 0.75em;
    }

    > div:nth-child(1) {
      min-width: ${props => (props.modal ? '14.063rem' : '17.063rem')};
      max-width: ${props => (props.modal ? '14.063rem' : '17.063rem')};
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    > div:nth-child(2) {
      min-width: 9.313em;
      max-width: 9.313em;
      text-align: end;
    }
    > div:nth-child(3) {
      min-width: 6.813em;
      max-width: 6.813em;
      text-align: end;
    }
    > div:nth-child(4) {
      min-width: 9.313em;
      max-width: 9.313em;
      text-align: end;
    }
  }
  @media (max-width: 767px) {
    justify-content: space-between;
    min-width: 460px;

    > div:nth-child(1) {
      min-width: 10.625rem;
      max-width: 10.625rem;
      position: sticky;
      left: 0;
      background: ${Colors.white};
    }
    > div:nth-child(2) {
      min-width: 5.938rem;
      max-width: 5.938rem;
    }
    > div:nth-child(3) {
      min-width: 4.25rem;
      max-width: 4.25rem;
      text-align: end;
    }
    > div:nth-child(4) {
      min-width: 6.313rem;
      max-width: 6.313rem;
      text-align: end;
    }
  }
`;
export const ListInfo = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.313rem;
  color: ${Colors.black80};
`;

interface ITotal {
  document?: boolean;
}
export const Total = styled.div<ITotal>`
  display: flex;
  justify-content: end;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.313rem;
  color: ${Colors.black80};
  border-radius: 0.25em;
  background-color: ${Colors.white};
  padding: ${props => (props.document ? '1.063em 0.75em' : 0)};

  > span {
    padding: 0.75em 1.063em;
  }

  @media ((min-width: 768px) and (max-width: 1199px)) {
    > span {
      padding: 0.75em 1.063em;
      width: 379px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  @media (max-width: 767px) {
    min-width: 460px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    > span {
      width: 292px;
      font-size: 0.75rem;
    }
  }
`;

export const EmptyGoods = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 1rem;

  > p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }

  @media screen and (max-width: 767px) {
    > p {
      text-align: center;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.125rem;
    }
  }
`;

export const TariffTotal = styled.div`
  display: flex;
  justify-content: end;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.313rem;
  color: ${Colors.black80};
  border-radius: 0.25em;
  background-color: ${Colors.white};

  padding: 0.75em 1.063em;

  @media ((min-width: 768px) and (max-width: 1199px)) {
    padding: 0.75em 1.063em;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  @media (max-width: 767px) {
    color: ${Colors.primary};
    font-size: 0.75rem;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
`;

type StatusProps = {
  type: 'success' | 'failure';
};

export const Status = styled.div<StatusProps>`
  font-size: 1em;
  font-weight: 400;
  line-height: 1em;
  color: ${Colors.white};
  padding: 0.563em 1em;
  border-radius: 0.25em;
  background-color: ${props =>
    props.type === 'success' ? Colors.Seafoam_Green : Colors.deepRed};
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: ${props =>
      props.type === 'success' ? Colors.moss_green : Colors.terra_cotta};
  }
`;

export const AmazonTransit = styled.div`
  font-size: 1em;
  font-weight: 500;
  line-height: 1.5em;
  background-color: ${Colors.periwinkle};
  padding: 0.25rem 0.5rem;
  border-radius: 0.25em;
  text-transform: none;

  @media ((min-width: 768px) and (max-width: 1199px)) {
    width: fit-content;
    padding: 0;
  }

  @media (max-width: 767px) {
    width: 100%;
    text-align: center;
  }
`;
export const TranzitWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0.625em 0;

  @media (max-width: 767px) {
    padding: 0.25rem 0;
  }
`;
export const ConclusionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin-bottom: 1rem;

  @media (max-width: 767px) {
    gap: 0.25rem;
  }
`;
export const ConclusionItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.375em;

  font-weight: 400;
  line-height: 1.5em;
  color: ${Colors.primary80};

  > span {
    font-size: 1em;
  }
  > p {
    font-size: 0.875em;
  }

  @media (max-width: 767px) {
    > span {
      font-size: 0.875rem;
    }
  }
`;

export const DocumentsModal = styled.div`
  overflow-x: auto;
`;
export const DocumentsList = styled.div`
  @media screen and (max-width: 767px) {
    overflow-x: scroll;
  }
`;

export const HeaderItem = styled.div`
  font-size: 0.875em;
  font-weight: 700;
  line-height: 1.313em;
  color: ${Colors.white};
`;
export const DocumentFilesList = styled.div<IModal>`
  position: relative;
  background-color: ${Colors.white};
  > div:last-child {
    border-bottom: none;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

  max-height: ${props => (props.modal ? '9rem' : 'auto')};
`;

interface IModal {
  modal?: boolean;
}
export const ListHeader = styled.div<IModal>`
  display: flex;
  justify-content: space-between;
  background-color: ${Colors.primary80};
  padding: 0.75rem;

  > div:nth-child(1) {
    width: ${props => (props.modal ? '38.063rem' : '499px')};
    min-width: ${props => (props.modal ? '38.063rem' : '499px')};
  }
  > div:nth-child(2) {
    width: 14.313rem;
    min-width: 14.313rem;
  }
  > div:nth-child(3) {
    width: 8.188rem;
    min-width: 8.188rem;
  }
  > div:nth-child(4) {
    width: 5.688rem;
    text-align: center;
  }

  @media ((min-width: 768px) and (max-width: 1199px)) {
    > div:nth-child(1) {
      width: 13.5rem;
      min-width: 13.5rem;
    }
    > div:nth-child(2) {
      width: 13.5rem;
      min-width: 13.5rem;
    }
    > div:nth-child(3) {
      width: 8.188rem;
      min-width: 8.188rem;
    }
    > div:nth-child(4) {
      width: 7.313rem;
      text-align: center;
    }
  }

  @media (max-width: 767px) {
    font-size: 0.75rem;
    min-width: 100%;
    min-width: 650px;

    justify-content: flex-start;

    > div:nth-child(1) {
      width: 11.5rem;
      min-width: 11.5rem;
      position: sticky;
      left: 0;
      background: ${Colors.primary80};
      padding-left: 0.5rem;
    }
    > div:nth-child(2) {
      width: 13.5rem;
      min-width: 13.5rem;
    }
    > div:nth-child(3) {
      width: 8.188rem;
      min-width: 8.188rem;
    }
    > div:nth-child(4) {
      width: 7.313rem;
      text-align: center;
    }
  }
`;
export const DocumentsItem = styled.div<IModal>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid ${Colors.periwinkle};
  padding: 0.75rem 1rem;
  background-color: ${Colors.white};

  > div:nth-child(1) {
    width: ${props => (props.modal ? '38.063rem' : '499px')};
    min-width: ${props => (props.modal ? '38.063rem' : '499px')};

    > p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 36.563rem;
    }
  }
  > div:nth-child(2) {
    width: 14.313rem;
    min-width: 14.313rem;
  }
  > div:nth-child(3) {
    width: 8.188rem;
    min-width: 8.188rem;
  }
  > div:nth-child(4) {
    width: 5.688rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    > a {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media ((min-width: 768px) and (max-width: 1199px)) {
    > div:nth-child(1) {
      width: 13.5rem;
      min-width: 13.5rem;

      > p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 12.5rem;
      }
    }
    > div:nth-child(2) {
      width: 13.5rem;
      min-width: 13.5rem;
    }
    > div:nth-child(3) {
      width: 8.188rem;
      min-width: 8.188rem;
    }
    > div:nth-child(4) {
      width: 7.313rem;
    }
  }

  @media (max-width: 767px) {
    font-size: 0.75rem;
    min-width: 650px;
    justify-content: flex-start;

    > div:nth-child(1) {
      width: 11.5rem;
      min-width: 11.5rem;
      position: sticky;
      left: 0;
      background: ${Colors.white};
      padding-left: 0.5rem;

      > p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 12.5rem;
      }
    }
    > div:nth-child(2) {
      width: 13.5rem;
      min-width: 13.5rem;
    }
    > div:nth-child(3) {
      width: 8.188rem;
      min-width: 8.188rem;
    }
    > div:nth-child(4) {
      width: 7.313rem;
    }
  }
`;
export const Item = styled.div`
  font-size: 0.875em;
  font-weight: 400;
  line-height: 1.5em;
  color: ${Colors.black80};
`;
export const AddFile = styled.div`
  display: flex;
  gap: 0.75em;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5em;
  color: ${Colors.primary};
  cursor: pointer;

  padding: 0.75em 1em;
  background-color: ${Colors.white};
  border-radius: 0.25em;

  @media (max-width: 767px) {
    border-radius: 0;
    min-width: 700px;

    > span {
      font-size: 0.875rem;
    }
  }
`;

import React from 'react';
import { DashboardStatusTabWrapper, TabSwitcher } from './styles';

const DashboardStatusTab = ({ active, setActive }: any) => {
  return (
    <DashboardStatusTabWrapper>
      <TabSwitcher
        active={active}
        onClick={() => setActive(true)}
      >
        Активні
      </TabSwitcher>
      <TabSwitcher
        active={!active}
        onClick={() => setActive(false)}
      >
        Архівні
      </TabSwitcher>
    </DashboardStatusTabWrapper>
  )
};

export default DashboardStatusTab;

import { DocumentContainer, SignNotification, SignWrapper } from '../../styles';
import DocumentSvg from '../../../assets/ReactSvg/DocumentSvg';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

type SignAlert = {
  type: 'resign' | 'sign' | 'sign_success' | 'active_contract';
};

const SignAlert = ({ type }: SignAlert) => {
  let content;
  switch (type) {
    case 'resign':
      content = 'Необхідно перепідписати договір про співпрацю';
      break;
    case 'sign':
      content = 'Підписати договір про співпрацю';
      break;
    case 'sign_success':
      content = 'Договір про співпрацю успішно підписано';
      break;
    case 'active_contract':
      content = 'Скачати підписаний договір про співпрацю';
      break;
    default:
      content = '';
      break;
  }

  useEffect(() => {
    sessionStorage.setItem('signedFrom', window?.location?.href);
  }, [type]);

  return (
    <>
      {(type === 'sign_success' || type === 'active_contract') ? (
        <SignWrapper type={type}>
          <DocumentContainer>
            <DocumentSvg type={type} />
          </DocumentContainer>
          <SignNotification type={type}>{content}</SignNotification>
        </SignWrapper>
      ) : (
        <Link to='/sign-agreement'>
          <SignWrapper className={type === 'resign' ? 'resign' : ''} type={type}>
            <DocumentContainer>
              <DocumentSvg type={type} />
            </DocumentContainer>
            <SignNotification type={type}>{content}</SignNotification>
          </SignWrapper>
        </Link>
      )}
    </>

  );
};

export default SignAlert;

import React from 'react';
import { CustomRadioWrapper, CustomRadioInput } from '../../styles';
import { ReactSVG } from 'react-svg';

export type RadioInputType = {
  id: string;
  text: string;
  name: string;
  onChange: (e: any) => void;
  value: string;
  isActive: boolean;
  icon?: any;
};

const RadioInput = ({
  id,
  text,
  name,
  onChange,
  value,
  isActive,
  icon,
}: RadioInputType) => {
  return (
    <CustomRadioWrapper active={isActive.toString()} icon={!!icon}>
      <div>
        <span>{text}</span>
        {icon && <ReactSVG src={icon} />}
      </div>
      <CustomRadioInput
        type="radio"
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        checked={isActive}
      />
    </CustomRadioWrapper>
  );
};

export default RadioInput;

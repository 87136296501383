import { useEffect, useState } from 'react';
import ExpandDownSvg from '../../assets/ReactSvg/ShippingIcon/ExpandDownSvg';
import { Colors } from '../colors';
import Button from '../ui/Button';
import {
  DocumentDetails,
  Title,
  Subtitle,
  DocumentContainer,
  ButtonWrapper,
  ExpandedDocument,
  DocumentTitle,
  ServiceList,
  Total,
  TitleText,
  ListInfo,
  Wrapper,
  DocumentsFile,
  DocumentWrapper,
  Label,
  RightSideWrapper,
  SvgWrapper, MobileWrapperModal,
} from './styles';
import {
  useDesktop,
  useDesktopBig,
  useMobile,
  useTablet,
} from '../../constants/breakpoints';
import ExpandSvg from '../../assets/ReactSvg/ShippingIcon/ExpandSvg';
import { CircularProgress, Modal } from '@mui/material';
import CrossSvg from '../../assets/ReactSvg/CrossSvg';
import { approvePriceOffer, approveTariff } from '../../utils/axios/post';
import { useDetailsContext } from '../Threads/DetailsContext';
import { getPriceOfferServiceLabel } from './utils';

interface IDocumentInfo {
  setIsSuccessModal: (open: boolean) => void;
  offer?: any;
  modal?: boolean;
}

const DocumentInfo = ({ setIsSuccessModal, offer, modal }: IDocumentInfo) => {
  const isTablet = useTablet();
  const isMobile = useMobile();
  const isBigDesktop = useDesktopBig();
  const isDesktop = useDesktop();

  const {
    handleTogglerPriceOffersModal,
    commonConfig,
    config,
    setIsUpproveTariffModal,
    handleTogglerTableRecieveGoodsModal,
    isTableRecieveGoods,
    setIsShowWarehouseHotButtonsOnDetails,
    isUpproveTariff,
    setIsUpproveTariff,
    isPaymentMethodChoosen,
    handleTogglerLastMilesModal,
    handleTogglerPaymentMethodModal,
    setisPaymentMethodChoosen,
    setIsPriceOffersModal,
    updateConfig,
  } = useDetailsContext();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [calculatedAmount, setCalculatedAmount] = useState(0);

  const handleOpenModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  useEffect(() => {
    const sumAmountOffer = offer?.services?.reduce((sum: any, item: any) => {
      return sum + parseFloat(item?.amount || 0);
    }, 0);
    setCalculatedAmount(sumAmountOffer);
  }, [offer]);
  const apprroveTariff = async () => {
    if (config?.status === 'tariff_under_review') {
      setIsUpproveTariff(true);
      setIsUpproveTariffModal(false);
      if (isPaymentMethodChoosen && config?.direction?.name === 'CN-UA') {
        return handleTogglerLastMilesModal({ show: true });
      }
      if (config?.direction?.name === 'CN-UA') {
        handleTogglerPaymentMethodModal({ show: true });
      }
      if (config?.direction?.name !== 'CN-UA') {
        const body = {
          price_offer: offer?.id,
        };
        setIsApproveLoading(true);

        try {
          const response = await approveTariff(body, offer?.entity_id);
          if (response?.success) {
            updateConfig(config?.id);
            handleTogglerPriceOffersModal({ show: false });
            setIsPriceOffersModal(false);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsApproveLoading(false);
        }
      }

      return;
    }
    setIsApproveLoading(true);

    const body = {
      price_offer: offer?.id,
    };

    try {
      const response = await approvePriceOffer(body, offer?.entity_id);

      if (response?.success) {
        handleTogglerPriceOffersModal({ onSuccess: true });
        // document.body.style.overflow = 'visible';
        // document.body.style.maxHeight = '100vh';
        // document.body.style.background = 'red';
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsApproveLoading(false);
    }
  };

  return (
    <Wrapper>
      {isMobile ? (
        <>
          <DocumentContainer>
            <DocumentDetails>
              <Title>Кі-ть послуг</Title>
              <Subtitle>{calculatedAmount}</Subtitle>
            </DocumentDetails>
            <DocumentDetails className="cost">
              <Title>Вартість</Title>
              <Subtitle>$ {offer?.total.toFixed(2)}</Subtitle>
            </DocumentDetails>
            <SvgWrapper>
              <ExpandSvg color={Colors.primary80} onClick={handleOpenModal} />
            </SvgWrapper>
            {config?.status === 'tariff_under_review' &&
            !isPaymentMethodChoosen ? (
              <ButtonWrapper>
                <Button
                  text={
                    isApproveLoading ? (
                      <CircularProgress color="inherit" size={'1.5rem'} />
                    ) : (
                      'Підтвердити тариф'
                    )
                  }
                  type={'color'}
                  onClick={apprroveTariff}
                />
              </ButtonWrapper>
            ) : config?.status === 'tariff_sent' ? (
              <ButtonWrapper>
                <Button
                  text={
                    isApproveLoading ? (
                      <CircularProgress color="inherit" size={'1.5rem'} />
                    ) : (
                      'Підтвердити тариф'
                    )
                  }
                  type={'color'}
                  onClick={apprroveTariff}
                />
              </ButtonWrapper>
            ) : null}
          </DocumentContainer>
          {isOpenModal && (
            <Modal open={isOpenModal}>
              <MobileWrapperModal>
                <DocumentsFile onClick={handleOpenModal} open={isOpenModal}>
                  <DocumentWrapper>
                    <Label style={{ padding: 0 }}>Цінова пропозиція</Label>
                  </DocumentWrapper>
                  <SvgWrapper>
                    <CrossSvg />
                  </SvgWrapper>
                </DocumentsFile>
                <ExpandedDocument>
                  <DocumentTitle>
                    <TitleText>
                      Назва Послуги
                    </TitleText>
                    <TitleText>
                      Ціна за <br />1 послугу
                    </TitleText>
                    <TitleText>К-ть послуг</TitleText>
                    <TitleText>Загальна ціна</TitleText>
                  </DocumentTitle>
                  {offer?.services?.map((service: any) => {
                    return (
                      <ServiceList>
                        <ListInfo>
                          {getPriceOfferServiceLabel(
                            commonConfig?.price_offer_services,
                            service?.type,
                          )}
                        </ListInfo>
                        <ListInfo>
                          $ {service?.client_price?.toFixed(2)}
                        </ListInfo>
                        <ListInfo>{service?.amount}</ListInfo>
                        <ListInfo>
                          $ {service?.client_price_total?.toFixed(2)}
                        </ListInfo>
                      </ServiceList>
                    );
                  })}
                  <Total document>$ {offer?.total.toFixed(2)}</Total>
                </ExpandedDocument>
              </MobileWrapperModal>
            </Modal>
          )}
        </>
      ) : (
        <>
          <DocumentContainer modal={modal}>
            {!isTablet && (
              <DocumentDetails>
                <Title>Номер документа</Title>
                <Subtitle>{offer?.id}</Subtitle>
              </DocumentDetails>
            )}
            <DocumentDetails>
              <Title>
                {isBigDesktop ? 'Кількість послуг' : 'Кі-ть послуг'}
              </Title>
              <Subtitle>{calculatedAmount}</Subtitle>
            </DocumentDetails>
            <DocumentDetails className="cost">
              <Title>Вартість</Title>
              <Subtitle>$ {offer?.total.toFixed(2)}</Subtitle>
            </DocumentDetails>
            <RightSideWrapper>
              {config?.status === 'tariff_under_review' &&
              !isPaymentMethodChoosen ? (
                <ButtonWrapper>
                  <Button
                    text={
                      isApproveLoading ? (
                        <CircularProgress color="inherit" size={'1.5rem'} />
                      ) : (
                        'Підтвердити тариф'
                      )
                    }
                    type={'color'}
                    onClick={apprroveTariff}
                  />
                </ButtonWrapper>
              ) : config?.status === 'tariff_sent' ? (
                <ButtonWrapper>
                  <Button
                    text={
                      isApproveLoading ? (
                        <CircularProgress color="inherit" size={'1.5rem'} />
                      ) : (
                        'Підтвердити тариф'
                      )
                    }
                    type={'color'}
                    onClick={apprroveTariff}
                  />
                </ButtonWrapper>
              ) : null}
              <SvgWrapper>
                <ExpandDownSvg
                  width={'1.5rem'}
                  height={'1.5rem'}
                  color={Colors.primary}
                  style={{
                    cursor: 'pointer',
                    transform: isOpenModal ? 'rotate(3.142rad)' : 'rotate(0)',
                    transition: '0.5s',
                  }}
                  onClick={handleOpenModal}
                />
              </SvgWrapper>
            </RightSideWrapper>
          </DocumentContainer>
          {isOpenModal && (
            <ExpandedDocument>
              <DocumentTitle modal={modal}>
                <TitleText>Назва Послуги</TitleText>
                <TitleText>Ціна за 1 послугу</TitleText>
                <TitleText>К-ть послуг</TitleText>
                <TitleText>Загальна ціна</TitleText>
              </DocumentTitle>
              {offer?.services?.map((service: any) => {
                return (
                  <ServiceList modal={modal}>
                    <ListInfo>
                      {getPriceOfferServiceLabel(
                        commonConfig?.price_offer_services,
                        service?.type,
                      )}
                    </ListInfo>
                    <ListInfo>$ {service?.client_price.toFixed(2)}</ListInfo>
                    <ListInfo>{service?.amount}</ListInfo>
                    <ListInfo>
                      $ {service?.client_price_total.toFixed(2)}
                    </ListInfo>
                  </ServiceList>
                );
              })}
              <Total document>$ {offer?.total.toFixed(2)}</Total>
            </ExpandedDocument>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default DocumentInfo;

import styled from 'styled-components';
import { Colors } from '../../../components/colors';

export const DetailsContainer = styled.div`
  width: 100%;
  margin-top: 2.5em;
  display: flex;
  gap: 2.5em;

  @media ((min-width: 1440px) and (max-width: 1919px)) {
    gap: 1.875em;
    margin-top: 1.375em;
  }

  @media (max-width: 1439px) {
    margin-top: 2.5rem;
    gap: 1.5rem;
  }
  @media (max-width: 1023px) {
    flex-direction: column-reverse;
  }
`;

export const OrderDetails = styled.div`
  width: 100%;
  max-width: 86em;
  display: flex;
  flex-direction: column;

  gap: 1.5rem;

  @media (max-width: 767px) {
    gap: 1.875rem;
  }
`;

export const TrackingDetails = styled.div`
  display: flex;
  gap: 2.5em;
  flex-direction: column;
  width: 100%;
  max-width: 27rem;

  @media (min-width: 1919px) {
    max-width: 27rem;
    width: 27rem;
    min-width: 27rem;
  }

  @media ((min-width: 1200px )and (max-width: 1919px)) {
    max-width: 29.2rem;
    width: 29.2rem;
    min-width: 29.2rem;
  }
  @media ((min-width: 1024px) and (max-width: 1199px)) {
    max-width: 21.875rem;
    width: 21.875rem;
  }
  @media (max-width: 1023px) {
    max-width: 100%;
    display: flex;
    align-items: start;
    flex-direction: row;
    gap: 0 1.5rem;
  }
`;

export const phoneValidation = (number: string) => {
  // Remove all non-digit characters except the leading '+' if present
  const inputPhoneValue = number?.replace(/(?!^\+)\D/g, '');

  // If the number starts with '+38(0)', keep the prefix, else remove it
  let formattedPhoneValue = inputPhoneValue.startsWith('+380')
    ? inputPhoneValue.slice(4)
    : inputPhoneValue;

  // Match the parts of the phone number
  const phoneValue = formattedPhoneValue.match(
    /(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/,
  );

  // Construct the formatted phone number
  if (phoneValue) {
    const part1 = phoneValue[1] || '';
    const part2 = phoneValue[2] || '';
    const part3 = phoneValue[3] || '';
    const part4 = phoneValue[4] || '';

    number = `+38 (0${part1}) ${part2}${part3 ? ` ${part3}` : ''}${part4 ? ` ${part4}` : ''}`;
  }

  return number;
};

export const phoneNormalize = (phone: string) => {
  return phone?.replace('(', '').replace(')', '').replaceAll(' ', '');
};

import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import {
  ListOfThreadsWrapper,
  ThreadsItem,
  ThreadsItemTitles,
  ThreadsItemInfo, EmptyChatState,
} from './styles';
import dayjs from 'dayjs';
import SearchDashboardInput from '../Dashboard/SearchDashboardInput';
import { getMessages, getThreadsList } from '../../utils/axios/chat';
import { useChatContext } from './ChatContext';
import { useDesktop, useMobile, useTablet } from '../../constants/breakpoints';
import { truncatedString } from '../../utils/global';
import { debounce } from 'lodash';
import { AuthContext } from '../../index';
import NotificationsIcon from '../../assets/ReactSvg/NotificationsIcon';

const ListOfThreads = () => {
  const [search, setSearch] = useState('');
  const {
    updateCurrentChatMessages,
    updateCurrentChat,
    updateCurrentChatID,
    threadsList,
    currentChatID,
    updateCurrentChatUsers,
    fetchThreads,
  } = useChatContext();
  const isMobile = useMobile();

  const { handleFetchChatNotificationInfo, chatNotificationInfo } =
    useContext(AuthContext);
  const isDesktop = useDesktop();
  const isTablet = useTablet();

  const handleGetMessages = async (
    uid: string,
    name: string,
    entity_id: string,
    users?: any,
  ) => {
    const result = await getMessages(uid);
    if (result?.success) {
      updateCurrentChatMessages(result?.messages?.data);
      updateCurrentChat(name);
      updateCurrentChatID(uid, entity_id);
      updateCurrentChatUsers(users);
      setTimeout(() => {
        // @ts-ignore
        handleFetchChatNotificationInfo();
        fetchThreads();
      }, 10000)
    }
  };
  const filteredThreadsListGlobal = search
    ? threadsList?.filter((element: any) => element?.name?.includes(search))
    : threadsList;

  const renderThreads = () => {
    const filteredThreadsList = search
      ? threadsList?.filter((element: any) => element?.name?.includes(search))
      : threadsList;

    return filteredThreadsList?.map((item: any) => {
      const isActive = item?.uid === currentChatID ? 'true' : 'false';
      return (
        <ThreadsItem
          active={isActive}
          onClick={() =>
            handleGetMessages(
              item?.uid,
              item?.name,
              item?.entity_id,
              item?.users,
            )
          }
        >
          <ThreadsItemTitles active={isActive}>
            <h6>{item.name}</h6>
            <p>
              {isDesktop
                ? truncatedString(item?.last_message?.content)
                : isTablet
                  ? truncatedString(item?.last_message?.content, 14)
                  : truncatedString(item?.last_message?.content, 24)}
            </p>
          </ThreadsItemTitles>

          <ThreadsItemInfo active={isActive}>
            <p>
              {item?.last_message?.created_at
                ? dayjs(item?.last_message?.created_at).format('HH:mm')
                : dayjs(item?.created_at).format('HH:mm')}
            </p>
            {item?.messages_count !== item?.messages_seen && (
              <div className="unread-messages">
                {item?.messages_count - item?.messages_seen}
              </div>
            )}
          </ThreadsItemInfo>
        </ThreadsItem>
      );
    });
  };

  useEffect(() => {
    fetchThreads();
  }, [chatNotificationInfo]);

  return (
    <ListOfThreadsWrapper
      className={filteredThreadsListGlobal?.length < 8 ? 'empty-list' : ''}
    >
      {
        (isMobile && threadsList?.length === 0) ? (
          <EmptyChatState>
            <NotificationsIcon />
            <p>
              Поки що тут порожньо… Повідомлення з’являтимуться по мірі обробки
              заявок на перевезення.
            </p>
          </EmptyChatState>
        ) : (
          <>
            <SearchDashboardInput value={search} setValue={setSearch} />
            {renderThreads()}
          </>
        )
      }
      {/*{*/}
      {/*  (!isMobile && filteredThreadsListGlobal?.length !== 0) && (*/}
      {/*    <>*/}
      {/*      <SearchDashboardInput value={search} setValue={setSearch} />*/}
      {/*      {renderThreads()}*/}
      {/*    </>*/}
      {/*  )*/}
      {/*}*/}

    </ListOfThreadsWrapper>
  );
};

export default ListOfThreads;

import styled from 'styled-components';
import { Colors } from '../colors';

const SignCanvasWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 650px;
  width: 100%;
  @media(max-width: 1023px) {
    max-width: 500px;
  }
  @media(max-width: 767px) {
    max-width: 300px; 
  }
  canvas {
    height: 21rem;
    box-shadow: 0 0 33px 0 #00000017;
    &.error-field {
      border: 1px solid ${Colors.deepRed};
    }
    //@media(max-width: 1300px) {
    //  max-width: 37.5rem;
    //  width: 100%;
    //}
  }
`;

const SignCanvasLabel = styled.p`
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 700;
  color: ${Colors.primary};
  text-transform: uppercase;
  padding: 0.5rem 1rem;
`;

const SignAgreementContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 1.5rem;
  p {
    &.error-label {
      font-size: 1rem;
      line-height: 1.5rem;
      color: ${Colors.deepRed};
      margin-bottom: 0.5rem;
      text-align: right;
    }
  }
  iframe {
    width: 100%;
    height: 100%;
  }
  table {
    colgroup {
      @media(max-width: 810px) {
        col {
          width: 100% !important;
        }
      }
    }
    tbody {
      @media(max-width: 810px) {
        tr {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: auto !important;
          td {
            width: 100%;
            height: auto !important;
          }
        }
      }
      @media(max-width: 480px) {
        tr {
          img {
            width: 100%;
          }
        }
      }
    }
  }
`;

const LoadingContract = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, .75);
`;

const ButtonGroupSignAgreement = styled.div`
  display: flex;
  justify-content: center;
  gap: 0 1.5rem;
  align-items: center;
  margin-bottom: 1.5rem;
  @media(max-width: 480px) {
    margin-bottom: 5rem;
  }
`;

export {
  SignCanvasWrapper,
  SignCanvasLabel,
  SignAgreementContentWrapper,
  ButtonGroupSignAgreement,
  LoadingContract
}
import React from 'react';
import { ModalSubtitle, ModalTitleH2, ModalWrapper } from '../styles';
import { Link } from 'react-router-dom';
import Button from '../ui/Button';
import { ModalSuccessPage } from './styles';

const SuccessNewOrderPage = () => {
  return (
    <ModalSuccessPage>
      <ModalWrapper>
        <ModalTitleH2>Вітаємо!</ModalTitleH2>
        <ModalSubtitle>
          Вашу заявку було передано в обробку. Наша команда зв'яжеться з вами найближчим часом.
        </ModalSubtitle>
        <Link to="/dashboard">
          <Button text="На головну" type="primary" onClick={() => {}} />
        </Link>
      </ModalWrapper>
    </ModalSuccessPage>
  )
};

export default SuccessNewOrderPage;

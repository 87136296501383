export const TRANSPORTATION_STATUSES = {
  'unprocessed': 'Очікує обробки',
  'tariff_request': 'Рахуємо тариф',
  'tariff_sent': 'Підтвердіть тариф',
  'tariff_lost': 'Відхилено тариф',
  'awaiting_warehouse': 'Очікується на складі',
  'received_at_warehouse': 'Отримано на складі',
  'broker_recalculation': 'Отримано на складі',
  'tariff_under_review': 'Тариф на погодженні',
  'ready_for_loading': 'Очікує завантаження',
  // Statuses which don't have an ID
  // 'Статуси з рейсів передані'
  // 'В порту призначення'
  // 'На складі призначення'
  'cargo_in_production': 'Вантаж у виробництві',
  'ready_for_pickup': 'Готовий до видачі',
  'last_mile': 'Остання миля',
  'received': 'Отримано',
  'cargo_issues': 'Проблеми з вантажем',
  'rejected': 'Відхилено',
  'new_tariff_sent': 'Вам необхідно підтвердити тариф.',
  'all_received_warehouse': 'Підтвердіть, чи всі вантажі отримано на складі.',
  'confirmed': 'Підтверджено',
  'flight_scheduling': 'Формування рейсу',
  'scheduled': 'Планується',
  'loading_pending': 'Очікує підтвердження',
  'in_transit': 'В дорозі',
  'completed': 'Завершено',
  'loaded': 'Завантажений',
  'flight_formation': 'Формування рейсу',
  'customs_cleared_1': 'Замитнений',
  'customs_cleared_2': 'Розмитнений',
  'under_inspection_origin': 'На інспекції',
  'under_inspection_destination': 'На інспекції',
  'rolled_onto_next_ship': 'Зролений на наступне судно',
  'at_sea': 'В морі',
  'at_transshipment_port': 'В порту перевалки',
  'at_destination_port': 'В порту призначення',
  'unloaded_at_warehouse': 'Вивантажений на складі',
  'completed_successfully': 'Завершено успішно',
  'lost': 'Програно',
  'at_the_departure_airport': 'В аеропорту',
  'at_the_delivery_airport': 'В аеропорту',
  'at_consolidation_warehouse': 'На складі консолідації',
  'loading_customs_clearance': 'Завантаження/Замитнення авто',
  'dispatched_in_transit': 'Відправлений. В дорозі',
  'in_queue_for_border_crossing': 'В черзі на проходження ПП',
  'heading_to_customs': 'Прямує до митниці',
  'unloaded': 'Вивантажений',
  'cancelled': 'Відмінено',
  'loaded_into_container': 'Завантажено в контейнер',
  'at_the_departure_port': 'В порту',
  'at_the_delivery_port': 'В порту',
  'at_port': 'В порту',
  'exited_port': 'Вивезено з порту',
  'at_yard': 'На ярді',
  'at_warehouse': 'На складі',
  'container_returned': 'Зданий контейнер',
  'at_airport': 'В аеропорту',
  'loaded_container': 'Завантажено в контейнер',
  'in_transit_to_warehouse': 'В дорозі до складу',
  'dispatched_from_port_in_transit': 'Відправлений з порту. В дорозі',
  'expediting_at_port': 'Експедирування в порту',
  'exited_from_port': 'Вивезено з порту',
  'at_customs': 'На митниці',
  'heading_to_warehouse': 'Прямує до складу/порту',
  'unloading_at_warehouse': 'Розвантаження на складі/Здано в порту',
};

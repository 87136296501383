import { AuthWrapper } from '../../components/styles';
import FullLogoPng from '../../assets/png/FullLogoPng.png';
import Modal from '../../components/ui/UniversalModal';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleSetupPassword } from '../../utils/axios/post';

const ExistingClient = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state || {};

  const [isSetPassword, setIsSetPassword] = useState(false);


  const handleSetPassword = async () => {
    if (!isSetPassword) {
      const result = await handleSetupPassword(email);
      if (result?.success) {
      }
      setIsSetPassword(true);
    } else {
      navigate('/login');
    }
    // navigate('/login');
  };

  const logoContent = useMemo(() => {
    if (isSetPassword) {
      return (
        <img
          src={FullLogoPng}
          style={{
            width: '15.813rem',
            height: '3.5rem',
          }}
          alt={'DiFreight'}
        />
      );
    }
    return null;
  }, [isSetPassword]);

  const mainContent = useMemo(() => {
    if (isSetPassword) {
      return 'Лист з інструкціями по встановленню пароля було надіслано на вашу поштову скриньку:';
    }
    return 'Для цієї електронної адреси вже зареєстрований обліковий запис. Щоб встановити або змінити пароль, натисніть на кнопку:';
  }, [isSetPassword]);

  const buttonContent = useMemo(() => {
    if (isSetPassword) return 'Ок';
    return 'Встановити пароль';
  }, [isSetPassword]);

  return (
    <AuthWrapper>
      <Modal
        logo={logoContent}
        btnGap={'2.5em'}
        isOpenModal={true}
        width={'27em'}
        header={'Вітаємо'}
        subcontent={isSetPassword ? email : ''}
        content={mainContent}
        primaryButtonText={buttonContent}
        primaryBtnClick={handleSetPassword}
      />
    </AuthWrapper>
  );
};

export default ExistingClient;

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  FullWidthStepFormWrapper,
  RequiredLabel,
  StepFormWrapper,
  FileDetailsCargoWrapper,
  FileDetailsWrapper,
  Content,
  CargoDataStepContainer,
  FullWidthStepFormWrapperButtons,
  CargoTableSelectWrapper,
  SignContractWrapper,
} from '../styles';
import { Colors } from '../../colors';
import Button from '../../ui/Button';
import { useFormContext } from '../FormContext';
import { PrimaryLabel } from '../../styles';
import RadioGroupComponent from '../../ui/RadioGroup';
import { getFileLink } from '../../../utils/requestHelpers';
import AddIconSvg from '../../../assets/ReactSvg/AddIcon';
import DownloadIconSvg from '../../../assets/ReactSvg/DownloadIcon';
import DocumentSvg from '../../../assets/ReactSvg/DocumentSvg';
import {
  useDesktop,
  useMobile,
  useTablet,
} from '../../../constants/breakpoints';
import { Modal } from '@mui/material';
import CloseModalButton from '../../ui/CloseModalBtn';
import CargoTable from '../CargoTable';
import { getSessionStorage, truncatedString } from '../../../utils/global';
import CargoDetails from '../CargoTable/CargoDetails';
import CustomDropdown from '../../ui/Dropdown/Dropdown';
import { CircularProgress } from '@mui/material';
import SignAlert from '../../ui/SignAlert';
import { AuthContext } from '../../../index';
import ClipIcon from '../../../assets/ReactSvg/ClipIcon';

const CargoDataStep = () => {
  const {
    formData,
    prepareFormData,
    updateFormData,
    showModal,
    updateShowModal,
    resetError,
    errorFields,
    resetErrorFields,
    config,
    disableSubmit,
    isLoading,
  } = useFormContext();
  const { step4, step1 } = formData;
  const inputRef = useRef<HTMLInputElement>(null);
  const scrollRef = useRef(null);
  const { isContractSigned } = useContext(AuthContext);
  const [hasGoodsError, setHasGoodsError] = useState(false);
  const [onMouseOn, setOnMouseOn] = useState({
    packing_list: false,
    invoices: false,
  });
  const signedContract = localStorage.getItem('signedContract');

  const isMobile = useMobile();
  const isTablet = useTablet();
  const isDesktop = useDesktop();

  const dataFromLocal = getSessionStorage('data');

  const handleModalClose = () => {
    updateShowModal(false);
  };

  useEffect(() => {
    const result = errorFields?.some((error: any) =>
      error?.startsWith('goods'),
    );
    setHasGoodsError(result);
  }, [errorFields]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFormData('step4', { [e.target.name]: e.target.value });
    resetError('step4');
    resetErrorFields();
  };

  useEffect(() => {
    updateFormData('step4', { isTouched: true });
    if (step1?.mode_of_transportation === 'air') {
      updateFormData('step4', {
        type_of_transportation: 'Air Consolid',
      });
    }
  }, []);

  const typeOfTransportationLand = useMemo(
    () => [
      {
        id: 'type_of_transportation',
        text: 'LTL',
        name: 'type_of_transportation',
        onChange: handleChange,
        value: 'LTL',
        isActive: step4?.type_of_transportation === 'LTL',
      },
      {
        id: 'type_of_transportation2',
        text: 'FTL',
        name: 'type_of_transportation',
        onChange: handleChange,
        value: 'FTL',
        isActive: step4?.type_of_transportation === 'FTL',
      },
    ],
    [step4, handleChange],
  );

  const typeOfTransportationAir = useMemo(
    () => [
      {
        id: 'type_of_transportation3',
        text: 'LCL',
        name: 'type_of_transportation',
        onChange: handleChange,
        value: 'LCL',
        isActive: step4?.type_of_transportation === 'LCL',
      },
      {
        id: 'type_of_transportation4',
        text: 'FCL',
        name: 'type_of_transportation',
        onChange: handleChange,
        value: 'FCL',
        isActive: step4?.type_of_transportation === 'FCL',
      },
    ],
    [step4, handleChange],
  );

  const typeOfTransportationSea = useMemo(
    () => [
      {
        id: 'type_of_transportation5',
        text: 'Air consolid',
        name: 'type_of_transportation',
        onChange: handleChange,
        value: 'Air Consolid',
        isActive: step4?.type_of_transportation === 'Air Consolid',
      },
    ],
    [step4, handleChange],
  );

  const markingTypeDetails = useMemo(
    () => [
      {
        id: 'marking_type',
        text: 'Постачальник/Я',
        name: 'marking_type',
        onChange: handleChange,
        value: 'supplier',
        isActive: step4?.marking_type === 'supplier',
      },
      {
        id: 'marking_type2',
        text: 'Експедитор',
        name: 'marking_type',
        onChange: handleChange,
        value: 'forwarder',
        isActive: step4?.marking_type === 'forwarder',
      },
    ],
    [step4, handleChange],
  );

  const localFormData = getSessionStorage('data');

  useEffect(() => {
    if (
      step4?.cargo_params === 'input_params' &&
      step4?.cargo_params !== localFormData?.step4?.cargo_params
    ) {
      // updateFormData('step4', {
      //   goods: [],
      // });
    } else if (step4?.cargo_params === 'packing_list_invoice') {
      // updateFormData('step4', {
      //   goods: [],
      // });
    }
  }, [step4?.cargo_params]);

  const cargoParams = useMemo(
    () => [
      {
        id: 'cargo_params',
        text: 'Завантажити Packing List та Invoice',
        name: 'cargo_params',
        onChange: handleChange,
        value: 'packing_list_invoice',
        isActive: step4?.cargo_params === 'packing_list_invoice',
      },
      {
        id: 'cargo_params2',
        text: 'Вказати параметри вантажу',
        name: 'cargo_params',
        onChange: handleChange,
        value: 'input_params',
        isActive: step4?.cargo_params === 'input_params',
      },
    ],
    [step4, handleChange],
  );

  const renderTypeOfTransportation = () => {
    return (
      <>
        {step1?.mode_of_transportation === 'land' ? (
          <RadioGroupComponent
            dataConfig={typeOfTransportationLand}
            isCompressed={true}
            className={'cargoData packageType'}
          />
        ) : step1?.mode_of_transportation === 'sea' ? (
          <RadioGroupComponent
            dataConfig={typeOfTransportationAir}
            isCompressed={true}
            className={'cargoData packageType'}
          />
        ) : step1.mode_of_transportation === 'air' ? (
          <RadioGroupComponent
            dataConfig={typeOfTransportationSea}
            isCompressed={true}
            className={'cargoDataSolo packageType'}
          />
        ) : (
          <></>
        )}
        {errorFields?.includes('type_of_transportation') && (
          <RequiredLabel
            style={{
              fontWeight: 400,
              paddingLeft: '0.75rem',
              paddingTop: '0.125rem',
              fontSize: '0.875rem',
            }}
          >
            Обовʼязкове поле
          </RequiredLabel>
        )}
      </>
    );
  };

  const renderMarkingDetails = () => {
    return (
      <>
        {(step4?.type_of_transportation === 'LCL' ||
          step4?.type_of_transportation === 'LTL' ||
          step4?.type_of_transportation === 'Air Consolid') && (
          <>
            <PrimaryLabel htmlFor={'test'}>
              Хто маркує Ваші коробки
            </PrimaryLabel>
            <RadioGroupComponent
              dataConfig={markingTypeDetails}
              isCompressed={true}
              className={'cargoData packageType'}
            />
          </>
        )}
      </>
    );
  };

  const renderCagoParams = () => {
    return (
      <>
        {step4?.type_of_transportation && (
          <>
            <PrimaryLabel htmlFor={'test'}>
              Завантажте Packing List або вкажіть параметри вантажу{' '}
              <RequiredLabel>*</RequiredLabel>
            </PrimaryLabel>
            <RadioGroupComponent
              dataConfig={cargoParams}
              isCompressed={true}
              className={'cargoData packingList'}
            />
            {errorFields?.includes('cargo_params') && (
              <RequiredLabel
                style={{ fontWeight: 400, paddingLeft: '0.75rem' }}
              >
                Обовʼязкове поле
              </RequiredLabel>
            )}
          </>
        )}
      </>
    );
  };

  const handleChangeTask = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFormData('step4', { [e.target.name]: e.target.value });
  };

  const onUpdateFile = (event: any, name: string) => {
    const file = event.target.files[0];
    if (file) {
      updateFormData('step4', {
        [name]: file,
      });
      resetError('step4');
      resetErrorFields();
    }
  };

  const renderUploadDownloadDocuments = () => {
    return (
      <>
        <PrimaryLabel htmlFor={'test'}>
          Завантажити Packing List та Invoice <RequiredLabel>*</RequiredLabel>
        </PrimaryLabel>

        <FileDetailsWrapper>
          <FileDetailsCargoWrapper>
            <div>
              <a
                href={getFileLink('/static/Packing_template.xlsx')}
                download="PackingList.xlsx"
                target="_blank"
                rel="noopener noreferrer"
                className="text-spray"
              >
                <DocumentSvg />
                Скачати шаблон Packing List
                <DownloadIconSvg />
              </a>
            </div>
          </FileDetailsCargoWrapper>
          <FileDetailsCargoWrapper>
            <div>
              <DocumentSvg />
              <p>Додати файл Packing List</p>
              <AddIconSvg
                className={onMouseOn.packing_list ? 'icon-with-hover' : ''}
              />
            </div>
            {step4.packing_list_file?.name && (
              <div className="attached_documents">
                <ClipIcon color={Colors.warning} />
                <p>{truncatedString(step4.packing_list_file?.name)}</p>
              </div>
            )}
            <input
              onMouseEnter={() =>
                setOnMouseOn(prev => ({ ...prev, packing_list: true }))
              }
              onMouseLeave={() =>
                setOnMouseOn(prev => ({ ...prev, packing_list: false }))
              }
              type="file"
              ref={inputRef}
              onChange={event => onUpdateFile(event, 'packing_list_file')}
              accept=".xlsx, .pdf, .doc, .docx, .xls"
            />
          </FileDetailsCargoWrapper>
          <FileDetailsCargoWrapper>
            <div>
              <DocumentSvg />
              <p>Додати файл Invoice</p>
              <AddIconSvg
                className={onMouseOn.invoices ? 'icon-with-hover' : ''}
              />
            </div>
            <input
              onMouseEnter={() =>
                setOnMouseOn(prev => ({ ...prev, invoices: true }))
              }
              onMouseLeave={() =>
                setOnMouseOn(prev => ({ ...prev, invoices: false }))
              }
              type="file"
              ref={inputRef}
              onChange={event => onUpdateFile(event, 'invoice_file')}
              accept=".xlsx, .pdf, .docx, .doc, .xls, .jpg, .png"
            />
            {step4?.invoice_file?.name && (
              <div className="attached_documents">
                <ClipIcon color={Colors.warning} />
                <p>{truncatedString(step4?.invoice_file?.name)}</p>
              </div>
            )}
          </FileDetailsCargoWrapper>
        </FileDetailsWrapper>
      </>
    );
  };

  const content = () => {
    return (
      <CargoDataStepContainer>
        <FullWidthStepFormWrapper
          style={{
            borderBottom: `1px solid ${Colors.aqua}`,
            paddingBottom: '1rem',
            borderRadius: '0',
          }}
        >
          <StepFormWrapper style={{ borderTop: 0 }}>
            <div
              className={'visible transportationStep'}
              style={{ borderTop: 0 }}
            >
              <PrimaryLabel htmlFor={'test'}>
                Оберіть тип вантажу <RequiredLabel>*</RequiredLabel>
              </PrimaryLabel>
              {renderTypeOfTransportation()}
            </div>
          </StepFormWrapper>
        </FullWidthStepFormWrapper>

        {(step4?.type_of_transportation === 'LCL' ||
          step4?.type_of_transportation === 'LTL' ||
          step4?.type_of_transportation === 'Air Consolid') && (
          <FullWidthStepFormWrapper style={{ marginTop: '1rem' }}>
            <StepFormWrapper style={{ borderTop: 0 }}>
              <div className={'transportationStep'} style={{ borderTop: 0 }}>
                {renderMarkingDetails()}
              </div>
            </StepFormWrapper>
          </FullWidthStepFormWrapper>
        )}

        {step4?.type_of_transportation && (
          <FullWidthStepFormWrapper style={{ borderTop: 0 }}>
            <StepFormWrapper style={{ borderTop: 0 }}>
              <div
                className={'visible transportationStep'}
                style={{ borderTop: 0 }}
              >
                {renderCagoParams()}
              </div>
            </StepFormWrapper>
          </FullWidthStepFormWrapper>
        )}

        {step4?.cargo_params === 'packing_list_invoice' && (
          <FullWidthStepFormWrapper
            style={{
              borderRadius: 0,
            }}
          >
            <StepFormWrapper>
              <div className={'transportationStep'} style={{ borderTop: 0 }}>
                {renderUploadDownloadDocuments()}
                {errorFields?.includes('files') && (
                  <RequiredLabel
                    style={{ fontWeight: 400, paddingLeft: '0.75rem' }}
                  >
                    Обовʼязкове поле Packing List
                  </RequiredLabel>
                )}
              </div>
            </StepFormWrapper>
          </FullWidthStepFormWrapper>
        )}
        {step4?.cargo_params === 'input_params' && (
          <CargoTable reference={scrollRef} />
        )}
        {step4?.cargo_params === 'packing_list_invoice' &&
          (formData?.step3?.to_address_type === 'warehouse' ||
            formData?.step3?.to_address_type === 'fulfillment_center') && (
            <CargoTableSelectWrapper ref={scrollRef}>
              <CustomDropdown
                label="Завдання по вантажу"
                placeholder="Оберіть зі списку"
                options={config?.fulfillment_tasks}
                name="fulfillment_task"
                onSelect={handleChangeTask}
                withValueLabel={false}
                defaultValue={config?.fulfillment_tasks?.find(
                  (item: any) => item?.value === step4?.fulfillment_task,
                )}
              />
            </CargoTableSelectWrapper>
          )}
        {step4?.cargo_params && <CargoDetails config={config} />}
        {!isContractSigned ? (
          <>
            <SignContractWrapper>
              <SignAlert type="sign" />
            </SignContractWrapper>
            {errorFields?.includes('contract') && (
              <RequiredLabel
                style={{
                  fontWeight: 400,
                  paddingLeft: '0.75rem',
                  paddingTop: '0.75rem',
                  fontSize: '0.875rem',
                  textAlign: 'center',
                  display: 'block',
                }}
              >
                Необхідно підписати договір перед відправкою запиту.
              </RequiredLabel>
            )}
          </>
        ) : (
          <>
            {signedContract === 'true' && (
              <SignContractWrapper>
                <SignAlert type="sign_success" />
              </SignContractWrapper>
            )}
          </>
        )}

        {step4?.cargo_params === 'packing_list_invoice' && errorFields?.includes('files') && (
          <RequiredLabel
            style={{
              fontWeight: 400,
              paddingLeft: '0.75rem',
              paddingTop: '0.75rem',
              fontSize: '0.875rem',
              textAlign: 'center',
            }}
          >
            Заповніть обов'язкове поле: Packing List
          </RequiredLabel>
        )}

        {step4?.cargo_params === 'input_params' && hasGoodsError && (
          <RequiredLabel
            style={{
              fontWeight: 400,
              paddingLeft: '0.75rem',
              paddingTop: '0.75rem',
              fontSize: '0.875rem',
              textAlign: 'center',
            }}
          >
            Заповніть обов'язкові поля: Тип упаковки, Назва, Кількість місць та
            Вартість
          </RequiredLabel>
        )}
        {step4?.dangerous_cargo &&
          (errorFields?.includes('harmful_materials_class') ||
            errorFields?.includes('msds_file')) && (
            <RequiredLabel
              style={{
                fontWeight: 400,
                paddingLeft: '0.75rem',
                paddingTop: '0.75rem',
                fontSize: '0.875rem',
                textAlign: 'center',
              }}
            >
              Заповніть наступні дані щодо вантажу: Тип небезпечного вантажу,
              MSDS Сертифікат
            </RequiredLabel>
          )}

        <FullWidthStepFormWrapperButtons>
          <Button
            text={
              isLoading ? (
                <CircularProgress color="inherit" size={'1.5rem'} />
              ) : (
                'Підтвердити'
              )
            }
            disabled={disableSubmit}
            type={'primary'}
            onClick={() => {
              prepareFormData();
              if (isMobile || isTablet) {
                scrollToTable();
              }
            }}
          />
        </FullWidthStepFormWrapperButtons>
      </CargoDataStepContainer>
    );
  };

  const scrollToTable = () => {
    if (scrollRef.current) {
      // @ts-ignore
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };
  const isIOS = () => {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  };

  return (
    <div
      style={{ paddingBottom: '1rem' }}
      // Temp padding, remove after adding table
    >
      {isMobile || isTablet ? (
        <Modal open={showModal}>
          <Content
            className={
              isIOS() && step4?.type_of_transportation && step4?.cargo_params
                ? 'ios cargoTable-content'
                : 'cargoTable-content'
            }
          >
            <CloseModalButton onClose={handleModalClose} />
            {content()}
          </Content>
        </Modal>
      ) : (
        <>{content()}</>
      )}
    </div>
  );
};

export default CargoDataStep;

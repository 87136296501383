import axiosInstanceChat from '../indexChat';

export const getThreadsList = async () => {
  try {
    const response = await axiosInstanceChat.get('/threads');
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const getMessages = async (uid: string) => {
  try {
    const response = await axiosInstanceChat.get(`/threads/${uid}/messages`);
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const sendMessage = async (uid: string, payload: any) => {
  try {
    const config = payload.file
      ? { headers: { 'Content-Type': 'multipart/form-data' } }
      : undefined;

    const response = await axiosInstanceChat.post(`/threads/${uid}/message`, payload, config);
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const getThreadsInfo = async () => {
  try {
    const response = await axiosInstanceChat.get('/threads/info');
    return response.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

import React from 'react';
import { InputType } from '../Input';
import {
  InputWrapperSecondary,
  SecondaryInputLabel,
  SecondaryInput,
  SecondaryTextArea,
} from '../../styles';

type InputBlockType = InputType & {
  text: string;
  name: string;
  isTextarea?: boolean;
  inputStyle?: boolean;
};

const InputBlockSecondary = ({
  id,
  type,
  placeholder,
  value,
  onChange,
  text,
  name,
  isTextarea = false,
  inputStyle,
}: InputBlockType) => {
  return (
    <InputWrapperSecondary>
      <SecondaryInputLabel htmlFor={id} inputStyle={inputStyle}>
        {text}
      </SecondaryInputLabel>
      {isTextarea ? (
        <SecondaryTextArea
          inputStyle={inputStyle}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          name={name}
        />
      ) : (
        <SecondaryInput
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          name={name}
        />
      )}
    </InputWrapperSecondary>
  );
};

export default InputBlockSecondary;

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ?? 25}
    height={props.height ?? 24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      stroke={props.color ?? '#8988AC'}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.087 12.713c-.136-.215-.204-.323-.242-.49a1.172 1.172 0 0 1 0-.446c.038-.167.106-.274.242-.49C4.213 9.505 7.562 5 12.667 5s8.455 4.505 9.58 6.287c.137.215.205.323.243.49.029.125.029.322 0 .446-.038.167-.106.274-.242.49C21.122 14.495 17.772 19 12.668 19c-5.106 0-8.455-4.505-9.58-6.287Z"
    />
    <path
      stroke={props.color ?? '#8988AC'}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12.667 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
    />
  </svg>
);
export default SvgComponent;

import React, { useEffect, useState } from 'react';
import {
  LayoutSmall,
  ModalSubtitle,
  ModalTitleH2,
  ModalWrapper,
  AuthWrapper,
} from '../../../components/styles';
import { Link, useParams } from 'react-router-dom';
import Button from '../../../components/ui/Button';
import { confirmVerifyRegistration } from '../../../utils/axios/post';
import FullLogoPng from '../../../assets/png/FullLogoPng.png';

const ConfirmRegistrationPage = () => {
  const { code } = useParams();
  const [isVerifiedSuccess, setIsVerifiedSuccess] = useState(true);

  useEffect(() => {
    Promise.resolve(confirmRegistration());
  }, []);

  const confirmRegistration = async () => {
    const postBody = {
      hash: code,
    };
    const response = await confirmVerifyRegistration(postBody);
    if (!response.success && response.message === 'Unable to verify account') {
      setIsVerifiedSuccess(false);
    }
  };

  return (
    <AuthWrapper>
      <LayoutSmall>
        <img
          style={{
            width: '15.813rem',
            height: '3.5rem'
          }}
          src={FullLogoPng}
          alt={'DiFreight'}
        />
        <ModalWrapper>
          <ModalTitleH2>Вітаємо!</ModalTitleH2>
          <ModalSubtitle>
            {isVerifiedSuccess ? (
              <>
                Процес реєстрації успішно завершено. Тепер ви можете увійти до
                особистого кабінету, використовуючи дані, вказані при
                реєстрації.
              </>
            ) : (
              <>Ця поштова скринька вже успішно підтверджена.</>
            )}
          </ModalSubtitle>
          <Link to="/login">
            <Button
              text="Перейти на сторінку входу"
              type="primary"
              onClick={() => {}}
            />
          </Link>
        </ModalWrapper>
      </LayoutSmall>
    </AuthWrapper>
  );
};

export default ConfirmRegistrationPage;

import React from 'react';
import {
  LayoutSmall,
  ModalWrapper,
  ModalSubtitle,
  ModalTitleH2,
  AuthWrapper,
} from '../../components/styles';
import { Link } from 'react-router-dom';
import Button from '../../components/ui/Button';
import FullLogoPng from '../../assets/png/FullLogoPng.png';

const SetPasswordSuccessPage = () => {

  return (
    <AuthWrapper>
      <LayoutSmall>
        <img
          src={FullLogoPng}
          style={{
            width: '15.813rem',
            height: '3.5rem',
          }}
          alt={'DiFreight'}
        />
        <ModalWrapper>
          <ModalTitleH2>Вітаємо!</ModalTitleH2>
          <ModalSubtitle>
            Пароль було успішно встановлено. Тепер ви можете скористатись ним для входу в особистий кабінет.
          </ModalSubtitle>
          <Link to="/login">
            <Button text="Увійти в особистий кабінет" type="primary" onClick={() => {}} />
          </Link>
        </ModalWrapper>
      </LayoutSmall>
    </AuthWrapper>
  );
};

export default SetPasswordSuccessPage;

import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Layout } from '../layout';
import { AuthContext } from '../index';

const PrivateRoute = () => {
  const { isAuthenticated } = useContext(AuthContext);
  return isAuthenticated ? <Layout /> : <Navigate to="/login" />;
};

export default PrivateRoute;

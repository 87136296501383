import React from 'react';
import {
  TableBodyContainer,
  BodyItem,
  CalculatedItemData,
} from './styles';
import { useFormContext } from '../FormContext';
import { parseToFloatDigits } from '../../../utils/global';

const CalculatedRow = () => {
  const { formData } = useFormContext();
  const { step4 } = formData;

  return (
    <TableBodyContainer
      ddpAmount={formData.step1?.cargo_requirement === 'transportation_only' ? 8 : 9}
      amount={formData.step4?.sizeType !== 'volume' ? 9 : 8}
    >
      <BodyItem>
      </BodyItem>

      <BodyItem>
      </BodyItem>

      <BodyItem>
      </BodyItem>

      <BodyItem>
        <CalculatedItemData>
          {parseToFloatDigits(step4?.goods?.reduce((sum: any, item: any) => {
            return sum + parseFloat(item?.amount || 0);
          }, 0), 3) || 0}
        </CalculatedItemData>
      </BodyItem>


      <BodyItem>
        {
          formData.step4?.sizeType === 'volume' && (
            <CalculatedItemData>
              {parseToFloatDigits(step4.goods?.reduce((sum: any, item: any) => {
                return sum + parseFloat(item?.volume || 0);
              }, 0), 3, 'volume') || 0}


            </CalculatedItemData>
          )
        }
      </BodyItem>


      {
        formData.step4?.sizeType === 'size' && (
          <BodyItem>
            <CalculatedItemData>
              {parseToFloatDigits(step4.goods?.reduce((sum: any, item: any) => {
                return sum + parseFloat(item?.volume || 0);
              }, 0), 3, 'volume') || 0}
            </CalculatedItemData>
          </BodyItem>
        )
      }


      {
        formData.step1?.cargo_requirement !== 'transportation_only' && (
          <BodyItem>
            <CalculatedItemData>
              {parseToFloatDigits(step4?.goods?.reduce((sum: any, item: any) => {
                return sum + parseFloat(item?.net_weight || 0);
              }, 0), 3, 'weight') || 0}
            </CalculatedItemData>
          </BodyItem>
        )
      }

      <BodyItem>
        <CalculatedItemData>
          {parseToFloatDigits(step4?.goods?.reduce((sum: any, item: any) => {
            return sum + parseFloat(item?.gross_weight || 0);
          }, 0), 3, 'weight') || 0}
        </CalculatedItemData>
      </BodyItem>

      <BodyItem>
        <CalculatedItemData>
          {parseToFloatDigits(step4?.goods?.reduce((sum: any, item: any) => {
            return sum + parseFloat(item?.price || 0);
          }, 0), 3) || 0}
        </CalculatedItemData>
      </BodyItem>

    </TableBodyContainer>

  );
};

export default CalculatedRow;

import {
  CheckboxBlock,
  HiddenCheckbox,
  StyledCheckbox,
  CheckboxLabel,
} from '../../styles';

type CheckboxType = {
  checked: boolean;
  onChange: (e?: any) => void;
  id: string;
  label?: string;
  link?: string;
  labelForLink?: string;
};

const Checkbox = ({
  checked,
  onChange,
  id,
  label,
  link,
  labelForLink,
  ...props
}: CheckboxType) => {
  return (
    <CheckboxBlock>
      <HiddenCheckbox onClick={onChange} {...props} />
      <StyledCheckbox id={id} onClick={onChange} checked={checked} />
      <CheckboxLabel htmlFor={id}>
        {label}
        {link && labelForLink && <a target={'_blank'} href={link}>{labelForLink}</a>}
      </CheckboxLabel>
    </CheckboxBlock>
  );
};

export default Checkbox;

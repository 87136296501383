import React, { useEffect, useState } from 'react';
import CustomDropdown from '../../ui/Dropdown/Dropdown';
import TableInput from '../../ui/Input/TableInput';
import Checkbox from '../../ui/Checkbox';
import { useFormContext } from '../FormContext';
import {
  BodyItem,
  CustomRowWrapper,
  CustomInsideRowWrapper,
  TableBodyContainerFType,
} from './styles';
import DeleteRowIcon from '../../../assets/ReactSvg/DeleteRowIcon';

const packingTypesConfig = [
  {
    label: 'Коробка',
    value: 'box',
  },
  {
    label: 'Палета',
    value: 'pallet',
  },
  {
    label: 'Мішок',
    value: 'bag',
  },
  {
    label: 'Негабарит',
    value: 'oversized',
  },
];

const TableRowFType = ({ indexRow, removeRow }: any) => {
  const {
    formData,
    updateFormData,
    errorFields,
    resetSelectedErrorField,
    config,
  } = useFormContext();
  const [checked, setChecked] = useState(
    !!formData?.step4?.goods?.[indexRow]?.excess_weight,
  );
  const [packaging_type, setPackagingType] = useState([]);

  useEffect(() => {
    // const filteredData = config?.packaging_types?.filter((item: any) => (item?.value !== 'oversized') && (item?.value !== 'bag') && (item?.value !== 'pallet') && (item?.value !== 'box'));
    const filteredData =
      formData?.step1?.mode_of_transportation === 'sea'
        ? config?.packaging_types?.slice(4, 17)
        : config?.packaging_types?.slice(17);
    setPackagingType(filteredData);
  }, [config?.packaging_types]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number,
  ) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === 'amount' && value) {
      formattedValue = value?.replace(/^0+/, '');
    }

    const updatedGoods = [...(formData.step4.goods || [])];

    let updatedItem = updatedGoods[index];

    if (updatedItem) {
      updatedItem = {
        ...updatedItem,
        [name]: name === 'price' ? value : formattedValue,
      };
    } else {
      updatedItem = {
        [name]: name === 'price' ? value : formattedValue,
      };
    }
    updatedGoods[index] = updatedItem;

    if (
      name === 'packaging_type' ||
      name === 'price' ||
      name === 'amount' ||
      name === 'name'
    ) {
      resetSelectedErrorField(`goods.${indexRow}.${name}`);
    }

    updateFormData('step4', { goods: updatedGoods });
  };

  const changeFormData = (value: any, name: any) => {
    const updatedGoods = [...(formData.step4.goods || [])];

    let updatedItem = updatedGoods[indexRow];

    if (updatedItem) {
      updatedItem = {
        ...updatedItem,
        [name]: value,
      };
    } else {
      updatedItem = {
        [name]: value,
      };
    }

    updatedGoods[indexRow] = updatedItem;
    updateFormData('step4', { goods: updatedGoods });
  };

  const calculateVolume = () => {
    return (
      +formData.step4?.goods?.[indexRow]?.width *
        +formData.step4?.goods?.[indexRow]?.length *
        +formData.step4?.goods?.[indexRow]?.height || 0
    );
  };

  useEffect(() => {
    changeFormData(calculateVolume(), 'volume');
  }, [
    formData.step4?.goods?.[indexRow]?.width,
    formData.step4?.goods?.[indexRow]?.length,
    formData.step4?.goods?.[indexRow]?.height,
  ]);

  return (
    <TableBodyContainerFType>
      <BodyItem>
        <CustomDropdown
          label={''}
          placeholder={'Оберіть тип'}
          options={packaging_type}
          name={'packaging_type'}
          onSelect={e =>
            handleChange(
              e as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
              indexRow,
            )
          }
          withValueLabel={false}
          defaultValue={packaging_type?.find(
            (item: any) =>
              item?.value ===
              formData?.step4?.goods?.[indexRow]?.packaging_type,
          )}
          className={
            errorFields?.includes(`goods.${indexRow}.packaging_type`) ||
            errorFields?.includes('goods')
              ? 'error-field table-dropdown'
              : 'table-dropdown'
          }
        />
      </BodyItem>
      <BodyItem>
        <TableInput
          name={'name'}
          placeholder={''}
          onChange={(e: any) => handleChange(e, indexRow)}
          type={'text'}
          id={`name${indexRow}`}
          className={
            errorFields?.includes(`goods.${indexRow}.name`) ||
            errorFields?.includes('goods')
              ? 'error-field'
              : ''
          }
          value={formData?.step4?.goods?.[indexRow]?.name}
        />
      </BodyItem>

      <BodyItem>
        <TableInput
          name={'amount'}
          placeholder={''}
          onChange={(e: any) => handleChange(e, indexRow)}
          type={'number'}
          id={`amount${indexRow}`}
          className={
            errorFields?.includes(`goods.${indexRow}.amount`) ||
            errorFields?.includes('goods')
              ? 'error-field'
              : ''
          }
          value={formData?.step4?.goods?.[indexRow]?.amount}
        />
      </BodyItem>

      <BodyItem>
        <TableInput
          name={'gross_weight'}
          placeholder={''}
          onChange={(e: any) => handleChange(e, indexRow)}
          type={'number'}
          id={`gross_weight${indexRow}`}
          value={formData?.step4?.goods?.[indexRow]?.gross_weight}
        />
      </BodyItem>
      {/*excess_weight*/}
      <BodyItem>
        <CustomRowWrapper>
          <CustomInsideRowWrapper className={'price'}>
            <TableInput
              name={'price'}
              placeholder={''}
              onChange={(e: any) => handleChange(e, indexRow)}
              type={'number'}
              id={`price${indexRow}`}
              className={
                errorFields?.includes(`goods.${indexRow}.price`) ||
                errorFields?.includes('goods')
                  ? 'error-field'
                  : ''
              }
              value={formData?.step4?.goods?.[indexRow]?.price}
            />
          </CustomInsideRowWrapper>
        </CustomRowWrapper>
      </BodyItem>

      <BodyItem>
        <Checkbox
          checked={checked}
          id="excess_weight"
          // has_trademark
          onChange={() => {
            setChecked(!checked);
            changeFormData(!checked, 'excess_weight');
          }}
        />
      </BodyItem>

      <BodyItem>
        {indexRow !== 0 && (
          <DeleteRowIcon onClick={() => removeRow(indexRow)} />
        )}
      </BodyItem>
    </TableBodyContainerFType>
  );
};

export default TableRowFType;

import styled from 'styled-components';
import { Colors } from '../colors';

export const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 80px;

  color: ${Colors.primary};

  @media (min-width: 1990px) {
    margin-top: 120px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 2.5rem 0;

  @media (min-width: 768px) {
    margin: 2.5rem 0 3.75rem 0;
  }
`;

export const Title = styled.span`
  font-size: 1.313rem;
  font-weight: 500;
  line-height: 31.5px;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 2rem;
    line-height: 3rem;
  }
`;
export const Subtitle = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.313rem;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 1.125rem;
    line-height: 27px;
  }
`;

export const Picture = styled.img`
  height: 9em;
  width: 9.25em;

  @media (min-width: 768px) {
    height: 15.375rem;
    width: 328px;
  }
  @media (min-width: 1440px) {
    height: 329px;
    width: 438px;
  }
  @media (min-width: 1990px) {
    height: 325px;
    width: 432px;
  }
`;
export const BtnWrapper = styled.div`
  width: 19.75rem;

  @media (min-width: 768px) {
    width: 328px;
  }
  @media (min-width: 1440px) {
    width: 438px;
  }
  @media (min-width: 1990px) {
    width: 432px;
  }
`;

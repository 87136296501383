import {
  LayoutSmall,
  TitleH2,
  ButtonWrapperColumn,
  AuthWrapper,
} from '../../../components/styles';
import {
  AuthVariantLine,
  AuthWithButton,
  LoginWarningWrapper,
  RegistrationForm, RegistrationLabelForTerms,
} from '../styles';
import React, { useContext } from 'react';
import InputBlock from '../../../components/ui/InputBlock';
import Checkbox from '../../../components/ui/Checkbox';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../../components/ui/Button';
import { loginClient } from '../../../utils/axios/post';
import { AuthContext } from '../../../index';
import {
  AuthEmailEmptyError,
  AuthEmailWarning,
  AuthPasswordEmptyError,
  AuthUnverifiedEmailError,
} from '../../../constants/errors';
import FullLogoPng from '../../../assets/png/FullLogoPng.png';
import { CircularProgress } from '@mui/material';
import GoogleIcon from '../../../assets/ReactSvg/GoogleIcon';

const LoginPage = () => {
  const { setToken } = useContext(AuthContext);
  const googleAuthUrl = process.env.REACT_APP_GOOGLE_AUTH_URL;
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [authInfo, setAuthInfo] = React.useState({
    email: '',
    password: '',
  });

  const [warningStatus, setWarningStatus] = React.useState('');
  const [errors, setErrors] = React.useState({
    email: '',
    password: '',
  });

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    setAuthInfo(prevData => ({
      ...prevData,
      [id]: value,
    }));
    setWarningStatus('');
    setErrors(prevData => ({
      ...prevData,
      [id]: '',
    }));
  };

  const validatePayload = () => {
    if (!authInfo.email) {
      setErrors(prevState => ({
        ...prevState,
        email: AuthEmailEmptyError,
      }));
    }
    if (authInfo.password.length < 6 || authInfo.password.length > 20) {
      setErrors(prevState => ({
        ...prevState,
        password: AuthPasswordEmptyError,
      }));
    }
  };

  const handleLogin = async (e: any) => {
    setIsLoading(true);
    e.preventDefault();
    validatePayload();
    if (authInfo.password.length < 6 || authInfo.password.length > 20) {
      setIsLoading(false);
      return;
    }
    const result = await loginClient(authInfo);
    if (result?.success) {
      const timeOfLife = checked ? 90 : 1;
      setToken(result.data.token, result.data.refresh_token, timeOfLife);
      if (checked) {
        localStorage.setItem('isAuth', 'true');
      } else {
        sessionStorage.setItem('isAuth', 'true');
      }
      localStorage.setItem('signedContract', JSON.stringify(null));
      navigate('/');
    } else {
      // if (
      //   result.status === 422 &&
      //   (result?.data?.message === 'Invalid credentials' ||
      //     result?.data?.errors[0]?.message === 'Wrong email' ||
      //     result?.data?.message === 'Недійсні облікові дані')
      // ) {
      //   setWarningStatus(AuthEmailWarning);
      // }
      if (result.status === 422) {
        setWarningStatus(AuthEmailWarning);
      }

      if (result.status === 403 && result?.data?.code === 'E_AUTHORIZATION_UNVERIFIED') {
        setWarningStatus(AuthUnverifiedEmailError);
      }
    }
    setIsLoading(false);
  };

  return (
    <AuthWrapper>
      <LayoutSmall>
        <img
          src={FullLogoPng}
          style={{
            width: '15.813rem',
            height: '3.5rem',
          }}
          alt={'DiFreight'}
        />
        <TitleH2>Вхід</TitleH2>
        {warningStatus && (
          <LoginWarningWrapper>{warningStatus}</LoginWarningWrapper>
        )}
        <RegistrationForm onSubmit={handleLogin}>
          <InputBlock
            id="email"
            placeholder="Вкажіть вашу електронну скриньку"
            type="text"
            onChange={handleChange}
            text="E-mail"
            errorMessage={errors.email}
          />
          <InputBlock
            id="password"
            placeholder="Вкажіть ваш пароль"
            type="password"
            onChange={handleChange}
            text="Пароль"
            isForgotLabel={true}
            errorMessage={errors.password}
          />

          <Checkbox
            checked={checked}
            id="in-system"
            onChange={() => setChecked(!checked)}
            label="Залишатися в системі"
          />

          <AuthVariantLine>
            <span>або</span>
          </AuthVariantLine>

          <Link to={`${googleAuthUrl}`}>
            <AuthWithButton>
              <GoogleIcon width={'1.625rem'} height={'1.625rem'} />
            </AuthWithButton>
          </Link>

          <ButtonWrapperColumn>
            <Button
              disabled={isLoading}
              text={
                isLoading ? (
                  <CircularProgress color="inherit" size={'1.5rem'} />
                ) : (
                  'Увійти'
                )
              }
              type="submit"
              onClick={() => {}}
            />
            <Link to="/register">
              <Button
                text="Створити акаунт"
                type="secondary"
                onClick={() => {}}
              />
            </Link>
          </ButtonWrapperColumn>

          <RegistrationLabelForTerms>
            Натискаючи «Увійти», ви погоджуєтеся з
            <a href='https://diffreight.com/en/terms-and-conditions' target={'_blank'}> Умовами надання послуг </a>
            та підтверджуєте, що <a href='https://diffreight.com/en/privacy' target={'_blank'}> Політика конфіденційності </a> нашої компанії застосовується до вас.
          </RegistrationLabelForTerms>
        </RegistrationForm>
      </LayoutSmall>
    </AuthWrapper>
  );
};

export default LoginPage;

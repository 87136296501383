import { Colors } from '../../../components/colors';

export const paginationStyle = {
  '& .MuiPaginationItem-root': {
    fontFamily: 'Montserrat',
    fontSize: '1em',
    fontWeight: 400,
    lineHeight: '1.5em',
    color: Colors.primary,

    borderRadius: '0.25rem',
    minWidth: '1.5rem',
    height: '1.5rem',
  },
  '& .MuiPaginationItem-root.Mui-selected': {
    color: Colors.white,
    backgroundColor: Colors.primary,
  },
  '& .MuiPaginationItem-icon': {
    color: Colors.primary,
  },
  '& .MuiPaginationItem-icon.Mui-disabled': {
    color: Colors.primary,
    opacity: 0.4,
  },
  '@media (min-width: 768px)': {
    '& .MuiPaginationItem-icon.Mui-disabled': {
      color: Colors.white80,
      opacity: 1,
    },
  },
};

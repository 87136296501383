import React, { useEffect, useState, useRef, useContext } from 'react';

import {
  bus,
  burger,
  addNewTransportation,
  notification,
  message,
} from '../../assets/images/index';
import { useDesktop, useMobile, useTablet } from '../../constants/breakpoints';
import {
  TransportationButtonWrapper,
  NewTransportationBtn,
  AddNewTransportation,
  Burger,
  AvatarWrapper,
  NotificationLogo,
  Menu,
  ContainmentContainer,
  HeaderWrapper,
  NotificationWrapperData,
  BackGroupShadow,
  NotificationInfoRound,
  SvgIconWrapper,
} from '../styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import UserLogo from '../../components/UserLogo';
import FullLogoPng from '../../assets/png/FullLogoPng.png';
import LogoIconPng from '../../assets/png/LogoIconPng.png';
import NotificationItem from '../../components/Notifications/NotificationItem';
import {
  NotificationsAllWrapper,
  NotificationModalHeader,
  NotificationModalTitle,
  NotificationModalButton,
} from '../../components/Notifications/styles';
import {
  getNotificationInfo,
  getNotificationsList,
} from '../../utils/axios/get';
import { debounce } from 'lodash';
import { readAllNotifications } from '../../utils/axios/post';
import { AuthContext } from '../../index';
import MessageIcon from '../../assets/ReactSvg/MessageIcon';
import NotificationIcon from '../../assets/ReactSvg/NotificationIcon';
import BurgerIcon from '../../assets/ReactSvg/BurgerIcon';
import BusIcon from '../../assets/ReactSvg/BusIcon';
import { SvgWrapper } from '../../components/Threads/styles';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isCreatingNewRequest, setIsCreatingNewRequest] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const notificationRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const [notifications, setNotifications] = useState<any>([]);
  const location = useLocation();
  const isTablet = useTablet();
  const isMobile = useMobile();
  const isDesktop = useDesktop();
  const navigate = useNavigate();
  const {
    chatNotificationInfo,
    notificationInfo,
    handleFetchNotificationInfo,
    socketClient,
    isAuthenticated,
  } = useContext(AuthContext);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if (!menuOpen) {
      setShowNotifications(false);
      document.body.style.overflow = '';
      document.body.style.maxHeight = '100%';
    }
  };

  const updateShowNotification = () => {
    setShowNotifications(false);
    document.body.style.overflow = '';
    document.body.style.maxHeight = '100%';
  };

  const handleReadAllNotifications = async () => {
    const result = await readAllNotifications();
    if (result?.success) {
      setShowNotifications(false);
      document.body.style.overflow = '';
      document.body.style.maxHeight = '100%';
      handleFetchNotifications();
      // @ts-ignore
      handleFetchNotificationInfo();
    }
  };

  useEffect(() => {
    setIsCreatingNewRequest(location.pathname.includes('new-request'));
    if (isAuthenticated) {
      // @ts-ignore
      handleFetchNotificationInfo();
      handleFetchNotifications();
      document.body.style.overflow = '';
      document.body.style.maxHeight = '100%';
      setMenuOpen(false);
      if (isMobile) {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        if (location.pathname.includes('new-request')) {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          document.body.style.maxHeight = '100vh';
        }
      }
    }
    const widget = document.querySelector('div[data-b24-crm-button-cont]') as HTMLElement;
    if (location.pathname !== '/profile' && location.pathname !== '/' && location.pathname !== '/new-request' && location.pathname !== '/dashboard') {
      if (widget) {
        widget.style.display = 'none';
      }
    } else {
      if (widget) {
        widget.style.display = 'block';
      }
    }
  }, [location.pathname]);

  const renderNotifications = () => {
    return (
      <NotificationsAllWrapper
        ref={notificationRef}
        className={notifications?.length > 4 ? 'long' : ''}
      >
        <NotificationModalHeader>
          <NotificationModalTitle>СПОВІЩЕННЯ</NotificationModalTitle>
          <NotificationModalButton onClick={() => handleReadAllNotifications()}>
            {isMobile ? 'Прочитати всі' : 'Позначити всі як прочитані'}
          </NotificationModalButton>
        </NotificationModalHeader>
        {notifications?.map((notification: any) => {
          if (!notification?.payload?.transportation?.df_number) {
            return;
          }
          return (
            <NotificationItem
              notification={notification}
              updateShowNotification={updateShowNotification}
              handleFetchNotificationInfo={handleFetchNotificationInfo}
              handleFetchNotifications={handleFetchNotifications}
            />
          );
        })}
      </NotificationsAllWrapper>
    );
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target as Node)
    ) {
      setShowNotifications(false);
      document.body.style.overflow = '';
      document.body.style.maxHeight = '100%';
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleFetchNotifications = async () => {
    const result = await getNotificationsList();
    if (result?.success) {
      setNotifications(result?.notifications?.data);
    }
  };

  const debouncedFetch = debounce(handleFetchNotifications, 300);

  const handleClickOutsideHeader = (event: MouseEvent) => {
    if (
      headerRef.current &&
      !headerRef.current.contains(event.target as Node)
    ) {
      if (menuOpen) {
        setMenuOpen(false);
      }
    }
  };

  const useNotificationSocket = () => {
    useEffect(() => {
      if (socketClient) {
        socketClient.on('notification:reload', handleFetchNotificationInfo);
      }

      return () => {
        if (socketClient) {
          socketClient.off('notification:reload', handleFetchNotificationInfo);
        }
      };
    }, [socketClient]);
  };

  useNotificationSocket();

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideHeader);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideHeader);
    };
  }, []);

  useEffect(() => {
    debouncedFetch();
    return () => {
      debouncedFetch.cancel();
    };
  }, []);

  return (
    <>
      <HeaderWrapper>
        <ContainmentContainer>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate('/dashboard');
            }}
          >
            {isDesktop ? (
              <img
                style={{
                  width: '15.813rem',
                  height: '3.5rem',
                }}
                src={FullLogoPng}
                alt={'DiFreight'}
              />
            ) : (
              <img
                style={{
                  width: '3rem',
                  height: '3.5rem',
                }}
                src={LogoIconPng}
                alt={'DiFreight'}
              />
            )}
          </div>

          <TransportationButtonWrapper>
            {(isDesktop || isTablet) && (
              <Link to="/dashboard">Мої перевезення</Link>
            )}
            {!isCreatingNewRequest && (
              <Link to="/new-request">
                <NewTransportationBtn>
                  <AddNewTransportation
                    src={addNewTransportation}
                    alt={'new transportation'}
                  />
                  {isDesktop ? (
                    <span>Нове перевезення</span>
                  ) : (
                    <span>Нове</span>
                  )}
                </NewTransportationBtn>
              </Link>
            )}
          </TransportationButtonWrapper>
          {isMobile ? (
            <SvgIconWrapper onClick={toggleMenu} style={{ position: 'relative' }}>
              {/*@ts-ignore*/}
              {!!(chatNotificationInfo + notificationInfo) && (
                <NotificationInfoRound style={{ left: 'auto', right: '-0.5rem' }}>
                  {/*@ts-ignore*/}
                  {chatNotificationInfo + notificationInfo}
                </NotificationInfoRound>
              )}
              <BurgerIcon />
            </SvgIconWrapper>
          ) : (
            <AvatarWrapper>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Link to={'/threads'}>
                  <NotificationWrapperData className={'threads'}>
                    <MessageIcon width={'2.25rem'} height={'2.25rem'} />
                    {!!chatNotificationInfo && (
                      <NotificationInfoRound>
                        {chatNotificationInfo}
                      </NotificationInfoRound>
                    )}
                  </NotificationWrapperData>
                </Link>
                <NotificationWrapperData
                  onClick={() => {
                    if (!showNotifications) {
                      document.body.style.overflow = 'hidden';
                      document.body.style.maxHeight = '100vh';
                      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    }
                    setShowNotifications(!showNotifications);
                  }}
                >
                  <NotificationIcon width={'2.25rem'} height={'2.25rem'} />
                  {!!notificationInfo && (
                    <NotificationInfoRound>
                      {notificationInfo}
                    </NotificationInfoRound>
                  )}
                </NotificationWrapperData>

                {showNotifications && <>{renderNotifications()}</>}
              </div>
              <Link to={'/profile'}>
                <UserLogo width="3rem" height="3rem" header />
              </Link>
            </AvatarWrapper>
          )}
          <Menu ref={headerRef} open={menuOpen} onClick={toggleMenu}>
            <Link to="/profile">
              <UserLogo width="2.25rem" height="2.25rem" header />
              <span>Мій профіль</span>
            </Link>
            <Link to="/dashboard">
              <SvgIconWrapper>
                <BusIcon />
              </SvgIconWrapper>
              <span>Мої перевезення</span>
            </Link>
            <Link to="/threads">
              <SvgIconWrapper>
                <MessageIcon />
              </SvgIconWrapper>
              {!!chatNotificationInfo && (
                <NotificationInfoRound>
                  {chatNotificationInfo}
                </NotificationInfoRound>
              )}
              <span>Повідомлення</span>
            </Link>
            <p
              onClick={() => {
                if (!showNotifications) {
                  document.body.style.overflow = 'hidden';
                  document.body.style.maxHeight = '100vh';
                  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }
                setShowNotifications(!showNotifications);
              }}
            >
              <SvgIconWrapper>
                <NotificationIcon />
              </SvgIconWrapper>
              {!!notificationInfo && (
                <NotificationInfoRound>
                  {notificationInfo}
                </NotificationInfoRound>
              )}
              <span>Сповіщення</span>
            </p>
          </Menu>
        </ContainmentContainer>
        {showNotifications && <>{renderNotifications()}</>}
      </HeaderWrapper>
      {showNotifications && <BackGroupShadow></BackGroupShadow>}
    </>
  );
};

export default Header;

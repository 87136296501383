import { useFormContext } from './FormContext';
import StepNavigation from './StepNavigation';
import TestStep from './Steps/TestStep';
import { WizardWrapper } from './styles';
import TransportationStep from './Steps/TransportationStep';
import DepartureStep from './Steps/DepartureStep';
import DeliveryDirectionStep from './Steps/DeliveryDirectionStep';
import CargoDataStep from './Steps/CargoDataStep';

const FormWizard = () => {
  const { currentStep } = useFormContext();

  return (
    <WizardWrapper>
      <StepNavigation />
      {currentStep === 1 && <TransportationStep />}
      {currentStep === 2 && <DepartureStep />}
      {currentStep === 3 && <DeliveryDirectionStep />}
      {currentStep === 4 && <CargoDataStep />}
    </WizardWrapper>
  );
};

export default FormWizard;

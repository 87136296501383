import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ?? 27}
    height={props.height ?? 26}
    viewBox="0 0 27 27"
    fill="none"
    {...props}
    style={{ backgroundSize: 'contain' }}
  >
    <path
      fill={props.color ?? '#3A3875'}
      fillRule="evenodd"
      d="M17.9 7.69c-1.277-1.2-2.877-1.823-4.678-1.823-3.178 0-5.878 2.145-6.845 5.034a7.325 7.325 0 0 0-.388 2.324c0 .81.144 1.589.389 2.322l-.006.005.005.006c.966 2.89 3.667 5.034 6.845 5.034 1.645 0 3.034-.445 4.123-1.178 1.3-.867 2.167-2.156 2.456-3.678h-6.58v-4.734h11.514c.144.8.222 1.634.222 2.5 0 3.723-1.334 6.857-3.645 8.99-2.023 1.867-4.79 2.956-8.09 2.956-4.778 0-8.901-2.756-10.912-6.745a12.074 12.074 0 0 1-1.311-5.478c0-1.978.478-3.834 1.311-5.479l.93.721-.93-.722C4.32 3.745 8.444 1 13.222 1c3.3 0 6.057 1.211 8.18 3.19L17.9 7.69Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgComponent;
